<template>
  <div class="table-mobile">
    <div class="table-mobile-grid" v-if="histories && histories.length > 0">
      <div
        class="table-mobile-grid-item"
        v-for="(trade, index) in histories"
        :key="index"
        @click="goToTradeDetails(trade)"
      >
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <p class="table-mobile-grid-item-title">{{ trade.action }}</p>
            <div class="td-with-image">
              <img
                v-if="trade.coinFrom.iconUrl"
                style="border-radius: 100%; height: 28px; object-fit: cover"
                :src="trade.coinFrom.iconUrl"
                alt=""
              />
              <span>{{ trade.coinFrom.name }}</span>
            </div>
          </div>
          <div class="table-item-right">
            <p class="table-mobile-grid-item-title">Date</p>
            <span>{{ formateDateAndTime(trade.date) }}</span>
          </div>
        </div>
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <p class="table-mobile-grid-item-title">Amount</p>
            <span>{{ formatAmount(trade.amount) }}</span>
          </div>
          <div class="table-item-right">
            <p class="table-mobile-grid-item-title">Price</p>
            <span>{{ formatAmount(trade.price) }}</span>
          </div>
        </div>
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <p class="table-mobile-grid-item-title">Fee</p>
            <span>{{ formatAmount(trade.tradingFee) }}</span>
          </div>
          <div class="table-item-right">
            <p class="table-mobile-grid-item-title">Total</p>
            <span>{{ formatAmount(trade.totalAmount) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="table-mobile-grid"
      v-if="tradeHistory && histories && histories.length <= 0"
    >
      <div class="table-mobile-grid-item">
        <div class="text-center">
          <br /><br />
          <h6>No trade history</h6>
          <br /><br />
        </div>
      </div>
    </div>
    <div class="table-mobile-grid" v-if="tradeHistory == null">
      <div class="table-mobile-grid-item">
        <div class="d-flex justify-content-center align-items-center">
          <br />
          <LoadingComponent />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  props: ["tradeHistory", "histories"],
  components: {
    LoadingComponent,
  },

  methods: {
    goToTradeDetails: function (trade) {
      this.$router.push({
        name: "SingleTrade",
        query: { id: trade.id },
      });
    },
  },
};
</script>

<style></style>
