import Localbase from "localbase";
import localforage from "localforage";
import router from "../router";
let db = new Localbase("db");
db.config.debug = false;

import Decimal from "decimal.js";
import axios from "axios";

export const setSideNotificationModal = ({ commit }, status) => {
  commit("SET_SIDENOTIFICATION_MODAL", status);
};

// update current page name
export const setCurrentPageName = ({ commit }, data) => {
  commit("SET_CURRENT_PAGE_NAME", data);
  commit("SET_MARKETPLACE_MODAL", false);
};

// set marketplace modal
export const setMarketPlaceModal = ({ commit }, data) => {
  commit("SET_MARKETPLACE_MODAL", data);
};

// set tab Project clicked
export const setTabProjectClicked = ({ commit }, data) => {
  commit("SET_TAB_PROJECT_CLICKED", data);
};

// set current Project
export const setCurrentProject = ({ commit }, data) => {
  // console.log(data);
  commit("SET_CURRENT_PROJECT", data);
};

// set vetrify pin code modal
export const setVerifyPinCodeModal = ({ commit }, payload) => {
  commit("SET_VERIFY_PIN_CODE_MODAL", payload);
};

// set alert message modal
export const setAlertMessageModal = ({ commit }, payload) => {
  commit("SET_ALERT_MESSAGE_MODAL", payload);
};

// set alert popUp
export const setAlertpopUp = ({ commit }, payload) => {
  commit("SET_ALERT_POPUP", payload);
};

// set modal loading
export const setModalLoading = ({ commit }, status) => {
  commit("SET_MODAL_LOADING", status);
};

// set page loading
export const setPageLoading = ({ commit }, status) => {
  commit("SET_PAGE_LOADING", status);
};

// set deposit address
export const setDepositeAdresses = ({ commit }, payload) => {
  commit("SET_DEPOSIT_ADDRESS", payload);
};

// set select project modal
export const setSelectCountryModal = ({ commit }, payload) => {
  commit("SET_SELECT_COUNTRY_MODAL", payload);
};

// set select project modal
export const setSelectProjectModal = ({ commit }, payload) => {
  commit("SET_SELECT_PROJECT_MODAL", payload);
};

// set select project modal
export const setProjectModal = ({ commit }, payload) => {
  commit("SET_PROJECT_MODAL", payload);
};

// set select network modal
export const setSelectNewtorkModal = ({ commit }, payload) => {
  commit("SET_NETWORK_MODAL", payload);
};

// set select portfolio modal
export const setPortfolioModal = ({ commit }, payload) => {
  commit("SET_PORTFOLIO_MODAL", payload);
};

// set transaction details modal
export const setTransactionDetailsModal = ({ commit }, payload) => {
  commit("SET_TRANSACTION_DETAILS_MODAL", payload);
};

// set small pop up
export const setSmallPopUp = ({ commit }, payload) => {
  commit("SET_SMALLPOPUP", payload);
};

// set share modal
export const setShareModal = ({ commit }, status) => {
  commit("SET_SHARE_MODAL", status);
};

export const logoutUserFromAccount = ({ commit }) => {
  return new Promise((resolve) => {
    commit("LOGOUT");

    db.collection("user").doc("user-info").delete();
    db.collection("user").doc("login-info").delete();
    localStorage.clear();
    localStorage.clear();
    // Clear localforage
    localforage.clear();
    setTimeout(() => {
      location.reload();
    }, 1000);

    delete axios.defaults.headers.common["Authorization"];
    resolve();
  });
};

// set login
export const globalFunction = ({ dispatch, commit }) => {
  // const token = localStorage.getItem("Appoen");
  localforage.getItem("Appoen").then((token) => {
    if (token) {
      commit("SET_USER_LOGGED_IN");
      commit("SAVE_TOKEN", token);
      dispatch("getUserProfile", token);
      dispatch("getUserSelf");
      dispatch("getLoginDetails");

      dispatch("getProjects", {
        page: 1,
        limit: 30,
      });
      dispatch("getWalletBalance", {
        page: 1,
        limit: 1000,
      });
      dispatch("getTradeHistory", {
        page: 1,
        limit: 1000,
      });
      dispatch("getAllPortfolio", {
        page: 1,
      });
      dispatch("getAllDividend", {
        page: 1,
      });
      dispatch("getTransactionHistory");
      dispatch("getDepositTransactionHistory");
      dispatch("getWithdrawalTransactionHistory");
      dispatch("getApprovedDocuments");
      dispatch("checkUserVerificationStatus");
      dispatch("getLevel2VerificationData");
      dispatch("getUnreadNotificationCount");
      dispatch("getUserNotifications", {
        page: 1,
        limit: 20,
      });
      dispatch("getSliderNotifications");
      dispatch("getAllCoins");
      dispatch("getLoginHistories");
      dispatch("getLoginDevices");
      dispatch("getActivities");
    }
  });

  // var createGuest = require("cross-domain-storage/guest");
  // var bazStorage = createGuest("http://localhost:8086/");
  // console.log(bazStorage);
  // bazStorage.get("Appoen", function (error, data) {
  //   // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
  //   if (error) {
  //     console.log(error);
  //   } else {
  //     console.log(data);
  //   }
  // });
};

export const login = ({ commit }, data) => {
  // console.log(data);
  commit("SAVE_USER_PROFILE", data);
  commit("SET_USER_LOGGED_IN");
};

// getUser login details
export const getLoginDetails = async ({ commit }) => {
  let loginInfo = await db.collection("user").doc("login-info").get();
  // console.log(loginInfo);
  if (loginInfo) {
    commit("SAVE_USER_LOGIN_INFO", loginInfo);
  }
};

// getUser login details
export const saveLoginDetails = ({ commit }, payload) => {
  let saveToDb = db.collection("user").doc("login-info").set(payload);
  if (saveToDb) {
    commit("SAVE_USER_LOGIN_INFO", payload);
  }
};

// getUserProfile
export const getUserProfile = ({ commit, dispatch, state }) => {
  let url = `${state.RELSIFY_URL}/api/profile`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        let saveToDb = db
          .collection("user")
          .doc("user-info")
          .set(data.data.data);
        if (saveToDb) {
          commit("SAVE_USER_PROFILE", data.data.data);
        }
      } else {
        sessionStorage.clear();
        localStorage.clear();
        location.reload();
      }
    })
    .catch((error) => {
      error;
      dispatch("logoutUserFromAccount");
    });
};

// getProjects
// export const getProjects = ({ commit, state }, payload) => {
//   let url = `${state.RELSIFY_URL}/api/projects?page=${payload.page}&limit=${payload.limit}`;

//   axios
//     .get(url)
//     .then((data) => {
//       if (!data.data.error) {
//         // console.log(data.data);

//         commit("SAVE_PROJECTS", data.data.data);
//         state.projectCurrentPage = data.data.meta.page;
//         state.projectTotalPage = data.data.meta.pages;
//       }
//     })
//     .catch((error) => console.log(error));
// };

export const getProjects = async ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/projects?page=${payload.page}&limit=${payload.limit}`;

  const response = await axios.get(url);

  try {
    if (!response.data.error) {
      // console.log(response.data);

      commit("SAVE_PROJECTS", response.data.data);
      state.projectCurrentPage = response.data.meta.page;
      state.projectTotalPage = response.data.meta.pages;

      // console.log(state.projectCurrentPage);
      // console.log(state.projectTotalPage);
    }
  } catch (error) {
    error;
  }

  return response;
};

export const filterProjects = async ({ commit, state }, url) => {
  const response = await axios.get(url);

  try {
    if (!response.data.error) {
      // console.log(response.data);

      commit("SAVE_PROJECTS", response.data.data);
      state.projectCurrentPage = response.data.meta.page;
      state.projectTotalPage = response.data.meta.pages;
    }
  } catch (error) {
    error;
  }

  return response;
};

// get a Project
export const getSingleProject = ({ commit, state }, id) => {
  let url = `${state.RELSIFY_URL}/api/project/${id}/details`;
  // console.log(id);
  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);
        if (data.data.data) {
          let project = data.data.data;
          state.projectToBuy = project;
          project.selectedToken = project.tradeInfo.allowedCoins[0];
          if (project.tradeInfo.allowedCoins.length > 1) {
            project.selectedSellingToken = project.tradeInfo.allowedCoins[1];
          } else {
            project.selectedSellingToken = project.tradeInfo.allowedCoins[0];
          }

          state.amount = new Decimal(
            project.tradeInfo.minimumBuyingAmount
          ).toFixed(2);
          state.equivalentAmount = new Decimal(
            project.tradeInfo.minimumBuyingAmount
          )
            .times(project.tradeInfo.buyPrice)
            .toFixed(2);
          commit("SET_CURRENT_PROJECT", project);
        }
      }
    })
    .catch((error) => console.log(error));
};

// get All Portfolio
export const getAllPortfolio = ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/portfolio?page=${payload.page}&limit=1000`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_PORTFOLIOS", data.data.data);
        // state.portfolioCurrentPage = data.data.meta.page;
        // state.portfolioTotalPage = data.data.meta.pages;
      }
    })
    .catch((error) => console.log(error));
};

// get Trade History
export const getTradeHistory = ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/trade/history?page=${payload.page}&limit=${payload.limit}`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data.data);

        commit("SAVE_TRADE_HISTORY", data.data.data);
        state.tradeHistoryCurrentPage = data.data.meta.page;
        state.tradeHistoryTotalPage = data.data.meta.pages;
      }
    })
    .catch((error) => console.log(error));
};

// get Transaction History
export const getTransactionHistory = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/transaction-history`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data.data);

        commit("SAVE_TRANSACTION_HISTORY", data.data.data);
        // state.tradeHistoryCurrentPage = data.data.meta.page;
        // state.tradeHistoryTotalPage = data.data.meta.pages;
      }
    })
    .catch((error) => console.log(error));
};

// get Deposit Transaction History
export const getDepositTransactionHistory = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/deposit-history`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data.data);

        commit("SAVE_DEPOSIT_TRANSACTION_HISTORY", data.data.data);
        state.depositTransactionCurrentPage = data.data.meta.page;
        state.depositTransactionTotalPage = data.data.meta.pages;
      }
    })
    .catch((error) => console.log(error));
};

// get Withdrawal Transaction History
export const getWithdrawalTransactionHistory = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/withdrawal-history`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data.data);

        commit("SAVE_WITHDRAWAL_TRANSACTION_HISTORY", data.data.data);
        state.withdrawalTransactionCurrentPage = data.data.meta.page;
        state.withdrawalTransactionTotalPage = data.data.meta.pages;
      }
    })
    .catch((error) => console.log(error));
};

// get All dividend
export const getAllDividend = ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/v1/dividend?page=${payload.page}&limit=20`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_DIVIDENDS", data.data.data);
        // state.portfolioCurrentPage = data.data.meta.page;
        // state.portfolioTotalPage = data.data.meta.pages;
      }
    })
    .catch((error) => console.log(error));
};

// Approved Documents
export const getApprovedDocuments = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/verification/approved/document`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_APPROVED_DOCUMENTS", data.data.data);
      }
    })
    .catch((error) => console.log(error));
};

// verification status
export const checkUserVerificationStatus = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/verification/status`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_USER_VERIFICATION_STATUS", data.data.data);
      }
    })
    .catch((error) => console.log(error));
};

export const getLevel2VerificationData = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/verification/level-two`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        commit("SAVE_LEVEL_2_VERIFICATION_DATA", data.data.data);
      }
    })
    .catch((error) => console.log(error));
};

// get User Notifications
export const getUserNotifications = ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/user-notification?page=${payload.page}&limit=${payload.limit}`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_NOTIFICATIONS", data.data.data);
      }
    })
    .catch((error) => console.log(error));
};

// get Notifications
export const getSliderNotifications = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/notification/slider`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_SLIDER_NOTIFICATIONS", data.data.data);
      }
    })
    .catch((error) => console.log(error));
};

// get Unread Notifications
export const getUnreadNotificationCount = ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/user-notification/unread/count`;

  axios
    .get(url)
    .then((data) => {
      if (!data.data.error) {
        // console.log(data.data);

        commit("SAVE_UNREAD_NOTIFICATION_COUNT", data.data.data);
      }
    })
    .catch((error) => console.log(error));
};

// getProjects
export const getWalletBalance = async ({ commit, state }, payload) => {
  let url = `${state.RELSIFY_URL}/api/balance?page=${payload.page}&limit=${payload.limit}`;

  const response = await axios.get(url);

  try {
    if (!response.data.error) {
      commit("SAVE_USER_WALLET_BALANCE", response.data.data);
      state.userWalletBalanceCurrentPage = response.data.meta.page;
      state.userWalletBalanceTotalPage = response.data.meta.pages;
    }
  } catch (error) {
    error;
  }

  // .then((data) => {
  //   if (!data.data.error) {
  //     console.log(data.data);

  //     commit("SAVE_USER_WALLET_BALANCE", data.data.data);
  //     state.userWalletBalanceCurrentPage = data.data.meta.page;
  //     state.userWalletBalanceTotalPage = data.data.meta.pages;
  //   }
  // })
  // .catch((error) => console.log(error));

  return response;
};

// get all coins

export const getAllCoins = async ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/coin`;

  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      // console.log(result)
      commit("SAVE_All_COINS", result.data.data);
      // console.log(result.data.data);
      // state.currentCoin = result.data.data[0];
    }
  } catch (error) {
    error;
  }
  return result;
};

export const getWalletAddresses = async ({ commit, state }, code) => {
  let url = `${state.RELSIFY_URL}/api/deposit-address/${code}`;

  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      commit("SET_DEPOSIT_ADDRESS", result.data.data);
      state.currentDeposit = result.data.data[0];
      // console.log(result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const getLoginHistories = async ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/user-login-history`;

  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      commit("SET_LOGIN_HISTORIES", result.data.data);
      // console.log(result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const getLoginDevices = async ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/user-login-device`;

  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      commit("SET_LOGIN_DEVICES", result.data.data);
      // console.log(result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const getActivities = async ({ commit, state }) => {
  let url = `${state.RELSIFY_URL}/api/user-recent-activity/message`;

  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      commit("SET_ACTIVITY_LOGS", result.data.data);
      // console.log(result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const saveGoogleDetailsToDB = async (
  { state, dispatch, commit },
  payload
) => {
  let url = `${state.RELSIFY_URL}/api/verify-google`;
  var payloadData = {
    idToken: payload.id_token,
  };

  const result = await axios.post(url, payloadData);

  try {
    // console.log(result.data);
    if (!result.data.error) {
      // commit("SET_ACTIVITY_LOGS", result.data.data);
      // console.log(result.data);
      // localStorage.setItem("Appoen", result.data.meta.token);
      // localStorage.setItem("actForms", result.data.data._id);

      await localforage.setItem("Appoen", result.data.meta.token);
      await localforage.setItem("actForms", result.data.data._id);

      // Set user logged in
      commit("SET_USER_LOGGED_IN");

      axios.defaults.headers.common["Authorization"] = result.data.meta.token;
      setTimeout(() => {
        dispatch("globalFunction");
        dispatch("saveLoginDetails", result.data.data);
        // dispatch("setPageLoading", false);
      }, 2200);

      setTimeout(() => {
        dispatch("setPageLoading", false);

        // location.reload();
        router.push({ name: "Home" });
      }, 2900);
    }
  } catch (error) {
    error;
    // console.log(error);
  }
  return result;
};

export const getSingleCoin = async ({ state }, code) => {
  let url = `${state.RELSIFY_URL}/api/coin/${code}`;

  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      state.currentCoin = result.data.data;
      state.currentCoin.selectedNetwork = state.currentCoin.networks[0];
      // console.log(state.currentCoin);
    }
  } catch (error) {
    error;
  }
  return result;
};

// FETCH MORE ON SCROLL

export const fetchMoreProjects = async ({ state }, currentPage) => {
  let url;
  if (state.selectedCategory == "") {
    url = `${state.RELSIFY_URL}/api/projects?page=${currentPage}&limit=20`;
  } else {
    url = `${state.RELSIFY_URL}/api/projects?page=${currentPage}&limit=20&category=${state.selectedCategory}`;
  }

  const result = await axios.get(url);
  return result;
};

export const fetchMoreWalletBalance = async ({ state }, currentPage) => {
  state;
  let url = `${state.RELSIFY_URL}/api/balance?page=${currentPage}&limit=20`;

  const result = await axios.get(url);
  return result;
};

export const fetchMoreTrade = async ({ state }, currentPage) => {
  let url = `${state.RELSIFY_URL}/api/trade/history?page=${currentPage}&limit=20`;

  const result = await axios.get(url);
  return result;
};

export const fetchMoreDividends = async ({ state }, currentPage) => {
  let url = `${state.RELSIFY_URL}/api/v1/dividend?page=${currentPage}&limit=20`;

  const result = await axios.get(url);
  return result;
};

export const getDepositAccount = async ({ state, commit }, payload) => {
  let url = `${state.RELSIFY_URL}/api/wallet/deposit/bank-account`;
  const result = await axios.post(url, payload);

  try {
    if (!result.data.error) {
      commit("SAVE_CURRENT_DEPOSIT_ACCOUNT", result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const getDepositLink = async ({ state, commit }, payload) => {
  let url = `${state.RELSIFY_URL}/api/wallet/deposit/link`;
  const result = await axios.post(url, payload);

  try {
    if (!result.data.error) {
      commit("SAVE_CURRENT_DEPOSIT_LINK", result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const getSupportedBankList = async ({ state, commit }) => {
  let url = `${state.RELSIFY_URL}/api/wallet/banks/list`;
  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      commit("SAVE_SUPPORTED_BANKS", result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const resolveBankAccount = async ({ state, commit }, payload) => {
  let url = `${state.RELSIFY_URL}/api/wallet/banks/resolve/${payload.bankCode}/${payload.accountNumber}`;
  const result = await axios.get(url);

  try {
    if (!result.data.error) {
      commit("SAVE_CURRENT_WITHDRAWAL_ACCOUNT_INFO", result.data.data);
    }
  } catch (error) {
    error;
  }
  return result;
};

export const changeUserCurrency = async ({ state, dispatch }, payload) => {
  let url = `${state.RELSIFY_URL}/api/user/currency`;
  const result = await axios.put(url, payload);

  try {
    if (!result.data.error) {
      dispatch("globalFunction");
    }
  } catch (error) {
    error;
  }

  return result;
};

export const getUserSelf = async ({ state, commit }, payload) => {
  let url = `${state.RELSIFY_URL}/api/user/self`;
  const result = await axios.get(url, payload);

  try {
    if (!result.data.error) {
      commit("SAVE_USERSELF", result.data.data);
    }
  } catch (error) {
    error;
  }

  return result;
};

// SAVE ITEMS AFTER INFINITESCROLL

export const saveMoreProjects = ({ commit }, data) => {
  // console.log(data);
  commit("SAVE_MORE_PROJECTS", data);
};

export const saveMoreWalletBalance = ({ commit }, data) => {
  commit("SAVE_MORE_WALLET_BALANCES", data);
};

export const saveMoreTrade = ({ commit }, data) => {
  commit("SAVE_MORE_TRADES", data);
};

export const saveMoreDividends = ({ commit }, data) => {
  commit("SAVE_MORE_DIVIDENDS", data);
};
