<template>
  <div>
    <UpdateProfileForm v-if="!status" />
    <div v-if="status">
      <VerifiedDiv :title="'Personal Info Verified'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import UpdateProfileForm from "../../forms/profile/UpdateProfileForm.vue";
import VerifiedDiv from "../../VerifiedDiv.vue";
// import { mapActions } from "vuex";
import $ from "jquery";
export default {
  components: {
    UpdateProfileForm,
    VerifiedDiv,
  },

  props: ["status"],

  data() {
    return {
      gender: "",
    };
  },

  methods: {
    // ...mapActions(["setTabProjectClicked"]),

    goBackToPrevious: function () {
      let attrib = $("[tab-id=siteVerificationPhotoID]");
      attrib.click();
    },

    goToNext: function () {
      let attrib = $("[tab-id=siteVerificationIdentityVerify]");
      attrib.click();
    },
  },
};
</script>
