<template>
  <div class="site-security-settings">
    <div class="site-security-settings-pageTitle">
      <h3>Delete Account</h3>
    </div>
    <div class="site-security-settings-contents">
      <div class="site-security-settings-contents-items-inner">
        <div class="form-group mb-4">
          <div class="" style="margin-top: 10px">
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Reason (Optional)</label>
              <textarea
                class="form-control"
                rows="3"
                placeholder="Enter a reason"
                v-model="reasonValue"
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-5">
          <p class="mb-4">
            By clicking <strong>DELETE</strong> you agree to delete your
            account. This action can not be undone.
          </p>
          <button @click="initiateDeleteAccount" class="relsify-button">
            <span v-if="!loading">DELETE</span>
            <span class="loader" v-if="loading"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
export default {
  components: {},

  data() {
    return {
      otpCode: "",
      reasonValue: "",
      loading: false,
      otpCodeErrorMessage: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "setVerifyPinCodeModal"]),
    initiateDeleteAccount: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/close-account/otp`;

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          //   console.log("weeeee", data);
          if (!data.error) {
            let newData = {
              otpId: data.data?.secretTokenId,
              reason: this.reasonValue,
            };

            this.responseData = data.data;
            this.setVerifyPinCodeModal({
              status: true,
              title: "Confirm Account Deletion",
              desc: "Enter the OTP sent to your registered email address",
              redirectUrl: null,
              type: "DELETE_ACCOUNT",
              payload: newData,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Account Deletion Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Account Deletion Error",
            des: "An Error occured while initiating your account deletion. Please try again or contact our support.",
            type: "error",
            payload: null,
          });
          //   console.log("pppp", error);
        });
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}

.form-settings-warning {
  color: rgb(197, 0, 0);
}
</style>
