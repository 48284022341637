<template>
  <div>
    <div class="relsify-table">
      <div class="relsify-table-search">
        <div class="relsify-search">
          <i class="bx bx-search-alt-2"></i>
          <input v-model="searchText" type="search" placeholder="Search " />
        </div>
      </div>
      <!-- {{ balances }} -->

      <div class="table-responsive">
        <table class="table">
          <thead class="text-left">
            <tr class="">
              <th>Token Name</th>
              <th>Balance</th>
              <th>{{ userDefaultCurrency?.symbol + " " }} Value</th>
              <!-- <th>Div.yield</th> -->
            </tr>
          </thead>
          <tbody class="text-left" v-if="balances && balances.length > 0">
            <WalletTableItem
              v-for="(balance, index) in balances"
              :key="index"
              :balance="balance"
            />

            <tr>
              <td colspan="6">
                <div
                  class="text-center d-flex justify-content-center align-items-center"
                >
                  <Infinitescrolltrigger />
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="text-left" v-if="balances && balances.length <= 0">
            <tr>
              <td colspan="6">
                <div class="text-center">
                  <h6>No wallet balance</h6>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody class="text-left" v-if="balances == null">
            <tr>
              <td colspan="6">
                <div class="d-flex justify-content-center align-items-center">
                  <br />
                  <LoadingComponent />
                  <br />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="relsify-small-table">
      <div
        class="relsify-small-table-grid"
        v-if="balances && balances.length > 0"
      >
        <div
          class="relsify-small-table-grid-item"
          v-for="(balance, index) in balances"
          :key="index"
          @click="goToWalletDetails(balance)"
        >
          <div class="td-with-image">
            <img
              v-if="balance.coin.iconUrl"
              style="border-radius: 100%"
              :src="balance.coin.iconUrl"
              alt=""
            />
            <span>{{ balance.coin.name }}</span>
          </div>
          <div>
            <span>
              <!-- {{ addComma(balance.amount) }} -->
              <span v-if="showWalletBalance" class="td-with-image">
                <img
                  v-if="userDefaultCurrency?.iconUrl"
                  :src="userDefaultCurrency?.iconUrl"
                  alt=""
                />
                <span style="margin-bottom: 0px">{{
                  formatUsdValue(balance.value)
                }}</span>
              </span>
              <!-- <span v-if="showWalletBalance"
                >${{ formatUsdValue(balance.value) }}</span
              > -->
              <span v-if="!showWalletBalance">*********</span>
            </span>
          </div>
        </div>
      </div>
      <div
        class="relsify-small-table-grid"
        v-if="balances && balances.length <= 0"
      >
        <div class="text-center">
          <h6>No wallet balance</h6>
        </div>
      </div>
      <div class="relsify-small-table-grid" v-if="balances == null">
        <div class="d-flex justify-content-center align-items-center">
          <br />
          <LoadingComponent />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters } from "vuex";
import WalletTableItem from "./WalletTableItem.vue";
import Infinitescrolltrigger from "./Infinitescrolltrigger.vue";
import LoadingComponent from "../../loading/LoadingComponent.vue";
export default {
  components: {
    WalletTableItem,
    LoadingComponent,
    Infinitescrolltrigger,
  },
  computed: {
    ...mapGetters(["userWalletBalance", "currentCurrencyType"]),

    balances() {
      let balances = null;

      if (this.userWalletBalance && this.userWalletBalance.length > 0) {
        balances = this.userWalletBalance.filter(
          (balance) =>
            String(balance.coin.external).match("true") &&
            String(balance.coin.type).match(
              this.currentCurrencyType.toLowerCase()
            ) &&
            (String(balance.amount).match(this.searchText) ||
              balance.coin.name
                .toLowerCase()
                .match(this.searchText.toLowerCase()))
        );

        if (balances) {
          balances = balances.sort(
            (a, b) => parseFloat(b.value) - parseFloat(a.value)
          );
        }
      }
      return balances;
    },
    userDefaultCurrency: {
      get() {
        return this.$store.state.userSelf?.defaultCurrency;
      },
      set() {},
    },
    showWalletBalance: {
      get() {
        return this.$store.state.showWalletBalance;
      },
      set(value) {
        return (this.$store.state.showWalletBalance = value);
      },
    },
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    goToWalletDetails: function (wallet) {
      this.$router
        .push({
          name: "WalletDetails",
          query: { id: wallet.coinId },
        })
        .catch((err) => err);
    },
  },
};
</script>

<style scoped>
.wallet_balance_page .relsify-small-table {
  display: none;
}
@media screen and (max-width: 580px) {
  .wallet_balance_page .relsify-table {
    display: none;
  }

  .wallet_balance_page .relsify-small-table {
    display: block;
    margin-top: 40px;
    padding-bottom: 20px;
  }

  .relsify-small-table-grid .relsify-small-table-grid-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 20px;
    margin: 13px 0px;
  }

  .relsify-small-table-grid .relsify-small-table-grid-item img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 8px;
  }

  .relsify-small-table-grid .relsify-small-table-grid-item span {
    font-style: normal;
    font-weight: 550;
    font-size: 15.3753px;
    line-height: 18px;
    color: #717171;
  }
}
</style>
