<template>
  <tr v-if="balance" @click="goToWalletDetails(balance)">
    <!-- {{
      balance
    }} -->
    <td>
      <div
        class="td-with-image"
      
        style="cursor: pointer"
      >
        <img
          v-if="balance.coin.iconUrl"
          style="border-radius: 100%"
          :src="balance.coin.iconUrl"
          alt=""
        />
        <span>{{ balance.coin.name }}</span>
      </div>
    </td>
    <td>
      <span v-if="showWalletBalance"
        >{{ formatAmount(balance.amount) }} {{ balance.coin.symbol }}</span
      >
      <span v-if="!showWalletBalance">*********</span>
    </td>
    <td> 
      <div v-if="showWalletBalance" class="td-with-image">
        <img
          v-if="userDefaultCurrency.iconUrl"
          :src="userDefaultCurrency.iconUrl"
          alt=""
        />
        <span style="margin-bottom: 0px">{{ formatUsdValue(balance.value) }}</span>
      </div>
      {{ !showWalletBalance ? '*********' : '' }}</td>
  </tr>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["balance"],

  computed: {
    showWalletBalance: {
      get() {
        return this.$store.state.showWalletBalance;
      },
      set(value) {
        return (this.$store.state.showWalletBalance = value);
      },
    },
    userDefaultCurrency: {
      get() {
        return this.$store.state.userSelf.defaultCurrency;
      },
      set() {},
    }
  },

  data() {
    return {
      loading: false,
      loading2: false,
      secretToken: "",
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "setDepositeAdresses"]),
    getWalletAddresses: function (balance) {
      let coinId = balance.coin.coinId;

      this.$router.push({
        name: "WalletDeposit",
        query: { code: coinId },
      });
    },

    initiateWithdrawalCode: function (balance) {
      let coinId = balance.coin.coinId;
      this.$router.push({
        name: "WalletWithdrawal",
        query: { code: coinId },
      });
    },

    goToWalletDetails: function (wallet) {
      this.$router
        .push({
          name: "WalletDetails",
          query: { id: wallet.coinId },
        })
        .catch((err) => err);
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "loading...";
}

.td-with-image img {
  border-radius: 100%;
  height: 20px;
}
</style>
