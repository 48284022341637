// MUTATION

// Mutation changes data values of the state

import countries from "../api/countries";

import states from "../api/states";
// import state from "./state";

let Countries = countries.getCountryList();
let States = states.getStateList();

export const SAVE_USER_LOGIN_INFO = (state, data) => {
  state.loginInfo = data;
};

export const LOGOUT = (state) => {
  state.token = "";
  state.isUserLoggedIn = false;
  state.user = null;
};

export const SAVE_USER_PROFILE = (state, data) => {
  state.user = data;
  state.isUserLoggedIn = true;
};

export const SET_USER_LOGGED_IN = (state) => {
  state.isUserLoggedIn = true;
};

export const SAVE_TOKEN = (state, payload) => {
  state.token = payload;
};

export const SET_SIDENOTIFICATION_MODAL = (state, status) => {
  state.sideNotificationModal = status;
};

export const SAVE_PROJECTS = (state, payload) => {
  function eliminateDuplicateCategory(arr) {
    return arr
      .map((e) => e["category"])
      .map((e, i, final) => final.indexOf(e) === i && i)
      .filter((obj) => arr[obj])
      .map((e) => arr[e]);
  }

  if (state.projects == null) {
    state.projectCategory = eliminateDuplicateCategory(payload);
  }

  state.projects = payload;

  if (payload.length > 0) {
    state.projectToBuy = payload[0];
  }

  if (payload.length > 1) {
    state.projectTosell = payload[1];

    // console.log(state.projectTosell);
  }
};

export const SAVE_MORE_PROJECTS = (state, data) => {
  for (let i = 0; i < data.length; i++) {
    state.projects.push(data[i]);
  }
};

export const SAVE_MORE_DIVIDENDS = (state, data) => {
  state.dividends = [...state.dividends, ...data];
};

export const SAVE_PORTFOLIOS = (state, payload) => {
  state.portfolios = payload;
};

export const SAVE_TRADE_HISTORY = (state, payload) => {
  state.tradeHistory = payload;
};

export const SAVE_TRANSACTION_HISTORY = (state, payload) => {
  state.transactionHistory = payload;
};

export const SAVE_DEPOSIT_TRANSACTION_HISTORY = (state, payload) => {
  state.depositTransactionHistory = payload;
};

export const SAVE_WITHDRAWAL_TRANSACTION_HISTORY = (state, payload) => {
  state.withdrawalTransactionHistory = payload;
};

export const SAVE_DIVIDENDS = (state, payload) => {
  state.dividends = payload;
};

export const SAVE_APPROVED_DOCUMENTS = (state, payload) => {
  state.approvedDocuments = payload;
};

export const SAVE_USER_VERIFICATION_STATUS = (state, payload) => {
  state.userVerificationStatus = payload;
};
export const SAVE_LEVEL_2_VERIFICATION_DATA = (state, payload) => {
  state.level2VerificationData = payload;
};

export const SAVE_NOTIFICATIONS = (state, payload) => {
  state.allNotifications = payload;
};

export const SAVE_SLIDER_NOTIFICATIONS = (state, payload) => {
  state.sliderNotifications = payload;
};

export const SAVE_UNREAD_NOTIFICATION_COUNT = (state, payload) => {
  state.unreadNotificationCount = payload;
};

export const SAVE_USER_WALLET_BALANCE = (state, payload) => {
  state.userWalletBalance = payload;
};

export const SAVE_All_COINS = (state, payload) => {
  state.allCoins = payload;
};

export const SAVE_MORE_WALLET_BALANCES = (state, data) => {
  for (let i = 0; i < data.length; i++) {
    state.userWalletBalance.push(data[i]);
  }
};

export const SAVE_MORE_TRADES = (state, data) => {
  for (let i = 0; i < data.length; i++) {
    state.tradeHistory.push(data[i]);
  }
};

export const SET_MODAL_LOADING = (state, status) => {
  state.modalLoading = status;
};

export const SET_PAGE_LOADING = (state, status) => {
  state.pageLoading = status;
};

export const GET_COUNTRIES = (state) => {
  // if (state.countries == null) {
  state.countries = Countries;

  let countryFlag = state.countries.find((flag) => flag.isoCode == "NG");
  if (countryFlag) {
    state.countryFlag = countryFlag.flag;
    state.countryDialCode = countryFlag.dialCode;
  }
  // console.log(Countries);
  // }
};

export const GET_STATES = (state) => {
  state.states = States;
  // console.log(state.states);
};

export const SET_COUNTRY_FLAG = (state, country) => {
  // console.log("here");
  let countryFlag = state.countries.find((flag) => flag.isoCode == country);
  // console.log(countryFlag);
  // console.log(country);
  if (countryFlag) {
    // console.log(countryFlag);

    state.countryFlag = countryFlag.flag;
    state.countryDialCode = countryFlag.dialCode;

    // console.log(state.countryFlag);
    // console.log(state.countryDialCode);
  }
};

export const SET_CURRENT_PAGE_NAME = (state, data) => {
  state.currentPageName = data;
};

export const SET_REGISTRATION_TYPE = (state, type) => {
  state.registrationType = type;

  // console.log(state.registrationType);
};

export const SET_MARKETPLACE_MODAL = (state, data) => {
  state.marketPlaceModal = data;
};

export const SET_TAB_PROJECT_CLICKED = (state, data) => {
  state.tabProjectClicked = data;
};

export const SET_CURRENT_PROJECT = (state, data) => {
  state.currentProject = data;
};

export const SET_VERIFY_PIN_CODE_MODAL = (state, payload) => {
  state.verifyPinCodeModal = {
    status: payload.status,
    title: payload.title,
    redirectUrl: payload.redirectUrl,
    type: payload.type,
    desc: payload.desc,
    payload: payload.payload,
  };
};

export const SET_ALERT_MESSAGE_MODAL = (state, payload) => {
  state.alertMessageModal = {
    status: payload.status,
    title: payload.title,
    des: payload.des,
    button1Text: payload.button1Text,
    button1Url: payload.button1Url,
    button2: payload.button2,
    button2Text: payload.button2Text,
    button2Url: payload.button2Url,
    type: payload.type,
    payload: payload.payload,
  };
};

export const SET_ALERT_POPUP = (state, payload) => {
  state.alertpopUp = {
    status: payload.status,
    title: payload.title,
    des: payload.des,
    type: payload.type,
    payload: payload.payload,
  };
};

export const SET_SMALLPOPUP = (state, payload) => {
  state.smallPopup = {
    status: payload.status,
    text: payload.text,
  };
};

export const SET_DEPOSIT_ADDRESS = (state, payload) => {
  state.depositAddress = payload;
};

export const SET_SELECT_COUNTRY_MODAL = (state, payload) => {
  state.selectCountryModal = {
    status: payload.status,
    title: payload.title,
    type: payload.type,
    payload: payload.payload,
  };
};

export const SET_SELECT_PROJECT_MODAL = (state, payload) => {
  state.selectProjectModal = {
    status: payload.status,
    title: payload.title,
    desc: payload.desc,
    payload: payload.payload,
    select1Type: payload.select1Type,
    select2Type: payload.select2Type,
    select3Type: payload.select3Type,
    select4Type: payload.select4Type,
    select5Type: payload.select5Type,
    select6Type: payload.select6Type,
    type: payload.type,
  };
};

export const SET_PROJECT_MODAL = (state, payload) => {
  state.projectModal = {
    status: payload.status,
    title: payload.title,
    desc: payload.desc,
    payload: payload.payload,
    select1Type: payload.select1Type,
    select2Type: payload.select2Type,
    type: payload.type,
  };
};

export const SET_NETWORK_MODAL = (state, payload) => {
  state.selectNetworkModal = {
    status: payload.status,
    title: payload.title,
    desc: payload.desc,
    payload: payload.payload,
    select1Type: payload.select1Type,
    select2Type: payload.select2Type,
    type: payload.type,
  };
};

export const SET_PORTFOLIO_MODAL = (state, payload) => {
  state.portfolioModal = {
    status: payload.status,
    title: payload.title,
    desc: payload.desc,
    payload: payload.payload,
    select1Type: payload.select1Type,
    select2Type: payload.select2Type,
    type: payload.type,
  };
};

export const SET_TRANSACTION_DETAILS_MODAL = (state, payload) => {
  state.transactionDetailModal = {
    status: payload.status,
    payload: payload.payload,
    type: payload.type,
  };
};

export const SET_SHARE_MODAL = (state, payload) => {
  state.shareModal = {
    status: payload.status,
    url: payload.url,
    title: payload.title,
    desc: payload.desc,
    payload: payload.payload,
  };
};

export const SET_LOGIN_HISTORIES = (state, payload) => {
  state.loginHistories = payload;
};

export const SET_LOGIN_DEVICES = (state, payload) => {
  state.loginDevices = payload;
};

export const SET_ACTIVITY_LOGS = (state, payload) => {
  state.activityLogs = payload;
};

export const SAVE_CURRENT_DEPOSIT_ACCOUNT = (state, payload) => {
  state.currentDepositAccount = payload;
};

export const SAVE_CURRENT_DEPOSIT_LINK = (state, payload) => {
  state.currentDepositLink = payload;
};

export const SAVE_SUPPORTED_BANKS = (state, payload) => {
  state.supportedBankList = payload;
};

export const SAVE_CURRENT_WITHDRAWAL_ACCOUNT_INFO = (state, payload) => {
  state.currentWithdrawalAccountInfo = payload;
};

export const SAVE_USERSELF = (state, payload) => {
  state.userSelf = payload;
};

// export const SAVE_CURRENT_SELECTED_NETWORK = (state, payload) => {
//   state.currentSelectedNetwork = payload
// }
