<template>
  <div class="portfolio_allBalnce_coins_item" v-if="portfolio">
    <div class="portfolio_allBalnce_coins_item_left">
      <div class="imaggge_div" v-if="portfolio.balance.coin.iconUrl">
        <img :src="portfolio.balance.coin.iconUrl" alt="" />
      </div>
      <div>
        <h6>{{ portfolio.project?.title }}</h6>
        <!-- <p>{{ portfolio.balance.coin.name }}</p> -->
        <p>{{ portfolio.balance?.coin?.symbol }} unit(s)</p>
      </div>
    </div>
    <div class="portfolio_allBalnce_coins_item_right">
      <span class="balance_amount" v-if="showBalance || showAllBalance">{{
        portfolio.balance.amount
      }}</span>
      <span v-else>************</span>
      <!-- <i
        class="fa fa-eye-slash"
        @click="toggleBalance"
        v-if="showBalance || showAllBalance"
      ></i>
      <i class="fa fa-eye" @click="toggleBalance" v-else></i> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["showAllBalance", "portfolio"],
  data() {
    return {
      showBalance: false,
    };
  },

  methods: {
    toggleBalance: function () {
      this.showBalance = !this.showBalance;

      if (this.showBalance == false) {
        this.$emit("hide-balances", false);
      }
    },
  },
};
</script>

<style scoped>
.portfolio_allBalnce_coins_item {
  background: #ffffff;
  box-shadow: 0px 4px 15px 2px rgba(171, 171, 171, 0.35);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  margin-top: 30px;
}

.portfolio_allBalnce_coins_item_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.imaggge_div {
  border-radius: 100%;
  background: #fff;
  box-shadow: 0px 3.95393px 9.88481px 3.95393px rgba(222, 221, 221, 0.25);
  padding: 7px;
}
.portfolio_allBalnce_coins_item_left img {
  width: 19px;
  height: 19px;
  object-fit: cover;
  border-radius: 100%;
}

.portfolio_allBalnce_coins_item_left h6 {
  margin-top: 9px;
  margin-bottom: 0px;
}

.portfolio_allBalnce_coins_item_left p {
  font-size: 12px;
  margin-bottom: 0px;
}

.portfolio_allBalnce_coins_item_right {
  display: flex;
  align-items: center;
}

.portfolio_allBalnce_coins_item_right span {
  font-size: 15px;
  display: block;
  margin-top: 5px;
  margin-right: 5px;
}

.portfolio_allBalnce_coins_item_right i {
  font-size: 15px;
  color: var(--mainGreenColor);
  cursor: pointer;
}

.balance_amount {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px !important;
}

@media screen and (max-width: 580px) {
}
</style>
