<template>
  <div class="wallet_detaols">
    <div v-if="currentBalance">
      <!-- {{ currentBalance }} -->
      <div class="relsify-product-review-modal-header">
        <div
          class="relsify-product-review-modal-header-image"
          style="padding: 0px"
        >
          <img
            v-if="currentBalance.coin.iconUrl"
            :src="currentBalance.coin.iconUrl"
            alt=""
            style="border-radius: 100%; height: 40px; width: 40px"
          />
        </div>
        <h3>{{ currentBalance.coin.name }}</h3>
      </div>
      <!-- <div class="description-details">
        <div class="description-details-item">
          <p style="margin-bottom: 0px">
            <span class="product-card-description-details-item-title"
              >Total
            </span>
          </p>
          <hr style="margin-top: 3px" />
          <p>
            <span class="product-card-description-details-item-prc green-color"
              >${{ addComma(currentBalance.amount) }}</span
            >
          </p>
        </div>
      </div> -->
      <div class="transactionaass">
        <div class="transaction-transactionList">
          <div class="transaction-transactionListItem">
            <p>Amount:</p>
            <p>
              {{ formatAmount(currentBalance.amount) }} {{ currentBalance.coin.symbol }}
              <!-- {{ addComma(precisionNumber(currentBalance.amount)) }} -->
            </p>
          </div>
          <div class="transaction-transactionListItem">
            <p>USD Value:</p>
            <p>
              ${{ formatUsdValue(currentBalance.usdValue) }}
              <!-- ${{ addComma(precisionNumber(currentBalance.usdValue)) }} -->
            </p>
          </div>
          <div class="table-button" style="text-align: right">
            <button
              class="relsify-button light-shade-green"
              @click="getWalletAddresses(currentBalance)"
            >
              <span>Deposit</span>
            </button>
            <button
              class="relsify-button light-shade-yellow"
              @click="initiateWithdrawalCode(currentBalance)"
            >
              <span>Withdraw</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="currentBalance == null">
      <div class="d-flex justify-content-center align-items-center">
        <br />
        <LoadingComponent />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
    <!-- {{ currentBalance }} -->
  </div>
</template>

<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "@/components/loading/LoadingComponent.vue";
export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["userWalletBalance"]),
  },

  data() {
    return {
      searchText: "",
      currentBalance: null,
    };
  },

  methods: {
    ...mapActions(["getWalletBalance"]),
    getSingleBalance: function (id) {
      // console.log(id);

      setTimeout(() => {
        if (this.userWalletBalance && this.userWalletBalance.length) {
          this.currentBalance = this.userWalletBalance.find(
            (balance) => balance.coin.id == id
          );

          // console.log(this.currentBalance);
        } else {
          this.getSingleBalance(id);
        }
      }, 2000);
    },

    getWalletAddresses: function (balance) {
      let coinId = balance.coin.id;

      this.$router.push({
        name: "WalletDeposit",
        query: { code: coinId },
      });
    },

    initiateWithdrawalCode: function (balance) {
      let coinId = balance.coin.id;
      this.$router.push({
        name: "WalletWithdrawal",
        query: { code: coinId },
      });
    },
  },

  mounted() {
    if (this.$route.query.id) {
      this.getSingleBalance(this.$route.query.id);
    }
  },
};
</script>

<style scoped>
.wallet_detaols {
  width: 69%;
  margin: 50px auto;
  background: #fff;
  padding: 20px;
  border-radius: 15px;
}

.transactionaass {
  /* background: #fff; */
  /* padding: 70px 50px; */
  /* border-radius: 10px; */
  margin-top: 40px;
}
.transaction-transactionList {
  width: 70%;
  margin: auto;
}

.transaction-transactionListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 18px; */
}

.transaction-transactionListItem p {
  text-align: left;
  font-size: 16px;
  color: rgb(136, 132, 132);
}

.transaction-transactionListItem p:last-child {
  text-align: right;
  color: #111;
  font-weight: bold;
  display: block;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

.table-button {
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.table-button button {
  padding: 10px 30px;
  font-size: 15px;
}

@media screen and (max-width: 580px) {
  .wallet_detaols {
    width: 100%;
    margin: 0px auto 10px;
    padding: 20px 30px;
  }
  .transaction-transactionList {
    width: 100%;
    margin: auto;
  }

  .transaction-transactionListItem p {
    font-size: 15px;
  }
}
</style>
