<template>
  <div class="paged_div">
    <div class="container-fluid" style="position: relative">
      <div class="row">
        <div class="col-xl-12">
          <div class="page-header">
            <div class="page-title">
              <h2>Trade</h2>
            </div>

            <router-link to="trade-history" class="page-breadcrum">
              <p>Trade History</p>
              <i class="bx bx-right-arrow-alt"></i>
            </router-link>
          </div>
        </div>
      </div>

      <div class="after-page-header">
        <TradePageTab />

        <div class="row">
          <div class="col-md-9 mx-auto">
            <div class="page-card">
              <div class="page-inner-center-no-content">
                <button class="light-shade-green" @click="showMarketPlace">
                  Choose From Marketplace
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import TradePageTab from "@/components/trade/TradePageTab.vue";
export default {
  components: {
    TradePageTab,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>
