<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Checkout</h2>
          </div>
          <router-link to="trade-history" class="page-breadcrum">
            <p>Trade History</p>
            <i class="bx bx-right-arrow-alt"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="after-page-header" v-if="currentProject">
      <TradePageTab />

      <div class="text-center">
        <div class="notification-alert" v-if="showBuyNotification">
          <div class="notification-alert-item for_fllex">
            <!-- <span
              >You are buying a share of
              {{ capitalizeFirstLetter(currentProject.coin.name) }}</span
            > -->
            <span
              >You are buying a share of
              {{ capitalizeFirstLetter(currentProject.title) }}</span
            >
            <!-- <button class="relsify-button">CTA</button> -->
            <i
              class="bx bx-x closeNotificationAlert"
              @click="showBuyNotification = false"
            ></i>
          </div>
        </div>
      </div>
      <div class="page-card">
        <div class="mt-4">
          <div class="page-inner-content-grid two-grid">
            <div class="page-inner-content-grid-item">
              <div class="project-coins">
                <div class="project-coins-left">
                  <!-- <h5>Project Coin</h5> -->
                  <h5>Project</h5>
                  <p>
                    <img
                      v-if="currentProject.coin.iconUrl"
                      :src="currentProject.coin.iconUrl"
                      alt=""
                    />
                    <!-- <span>{{ currentProject.coin.name }}</span> -->
                    <span>{{ currentProject.title }}</span>
                  </p>
                </div>
                <div class="project-coins-right">
                  <button
                    class="light-shade-green"
                    id="changeProjject"
                    @click="updateCurrentPageType"
                  >
                    Change project
                  </button>
                </div>
              </div>

              <div class="text-center" style="width: 100%">
                <div class="select-button width-100 text-left">
                  <label for="">Select Token To Buy With</label>
                  <div
                    class="select-input select-input-input"
                    style="cursor: pointer !important"
                    @click="
                      showSelectProjectModal(
                        currentProject.tradeInfo.allowedCoins
                      )
                    "
                  >
                    <div
                      v-if="currentProject.selectedToken"
                      style="cursor: pointer !important"
                    >
                      <img
                        v-if="currentProject.selectedToken.iconUrl"
                        :src="currentProject.selectedToken.iconUrl"
                        alt=""
                        style="
                          border-radius: 100%;
                          height: 20px !important;
                          object-fit: cover;
                        "
                      />
                      <input
                        type="text"
                        disabled
                        :value="currentProject.selectedToken.symbol"
                      />
                    </div>
                    <i class="bx bx-chevron-down" id="selectToken"></i>
                  </div>
                </div>
              </div>

              <div class="select-button-notes">
                <div class="select-button-notes-content bg-green">
                  <img
                    src="@/assets/vectors/img/wallet-xm.png"
                    width="10px"
                    alt=""
                  />
                  <p>
                    You have
                    {{ userBalance ? formatAmount(userBalance.amount) : 0 }}
                    {{ userBalance ? userBalance.coin.symbol : "unit" }} in your
                    wallet
                  </p>
                </div>
              </div>

              <div
                class="select-button-notes"
                v-if="currentProject && buyingPrice"
              >
                <div class="select-button-notes-content bg-yellow-light">
                  <p>
                    Note : 1
                    {{
                      // currentProject.coin.symbol
                      "unit"
                    }}
                    =
                    {{
                      formatAmount(buyingPrice.price) + " " + buyingPrice.symbol
                    }}
                  </p>
                </div>
              </div>

              <div class="counter-button-grid">
                <div class="counter-button-grid-item">
                  <label for=""
                    >Amount of
                    {{
                      // currentProject.coin.symbol
                      "Units"
                    }}
                  </label>
                  <div class="counter-button">
                    <i class="bx bx-minus" @click="decreaseAmount"></i>
                    <input
                      type="text"
                      style="width: 100% !important; max-width: 70%"
                      v-model="amount"
                      @input="calculateAmount"
                    />
                    <i class="bx bx-plus" @click="increaseAmount"></i>
                  </div>
                </div>
                <div class="counter-button-grid-item">
                  <label for="">{{ buyingPrice.symbol }} Equivalent </label>
                  <div class="counter-button">
                    <div class="flex_counter_button">
                      <!-- <span>$</span> -->
                      <input
                        style="width: 100% !important; max-width: 100%"
                        type="text"
                        v-model="equivalentAmount"
                        @input="calculateEquivalent"
                      />
                    </div>
                    <!-- <input
                      style="width: 100% !important; max-width: 100%"
                      type="text"
                      v-model="equivalentAmount"
                      @input="calculateEquivalent"
                    /> -->
                  </div>
                </div>
              </div>

              <div class="accpted-terms" v-if="!acceptTerms">
                <div class="relsify-checkbox">
                  <input
                    type="checkbox"
                    @change="toggleTerms"
                    :value="acceptTerms"
                  />
                  <p>
                    I certify that I have read, understand and accept Finnacle’s
                    <a href="" class="green_color">Terms & Conditions</a>
                  </p>
                </div>
              </div>

              <div class="select-counter-button">
                <button
                  class="relsify-button"
                  @click="buyAssets"
                  :disabled="emptyFields"
                >
                  Invest Now
                </button>
              </div>
            </div>
            <div class="page-inner-content-grid-item">
              <CheckoutProjectItem
                :project="currentProject"
                v-if="currentProject"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="after-page-header" v-else>
      <div
        class="d-flex justify-content-center align-items-center"
        style="background: #fff"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <LoadingComponent />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import Decimal from "decimal.js";
import { mapActions, mapGetters } from "vuex";
import TradePageTab from "@/components/trade/TradePageTab.vue";
import CheckoutProjectItem from "../../components/trade/CheckoutProjectItem.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
export default {
  components: {
    TradePageTab,
    CheckoutProjectItem,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["userWalletBalance", "projects"]),
    currentPageType: {
      get() {
        return this.$store.state.currentPageType;
      },
      set(value) {
        return (this.$store.state.currentPageType = value);
      },
    },

    userBalance() {
      let balance = null;
      if (
        this.userWalletBalance &&
        this.userWalletBalance.length &&
        this.currentProject
      ) {
        balance = this.userWalletBalance
          .slice(0)
          .find(
            (balance) =>
              balance.coin.coinId == this.currentProject.selectedToken.coinId
          );
        // console.log(balance);
      }

      return balance;
    },
    buyingPrice() {
      let price = null;
      if (this.currentProject) {
        price = this.currentProject.tradeInfo.buyingPrices
          .slice(0)
          .find(
            (price) => price.symbol == this.currentProject.selectedToken.symbol
          );

        if (price) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.amount = 1;
          // this.amount =
          //   Math.round(
          //     (new Decimal(
          //       this.currentProject.tradeInfo.minimumBuyingAmount
          //     ).toFixed(2) -
          //       price.price) *
          //       1e12
          //   ) / 1e12;

          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.equivalentAmount = new Decimal(
            new Decimal(this.currentProject.tradeInfo.minimumBuyingAmount)
          )
            .times(price.price)
            .toFixed(6);
        }
        // console.log(price);
      }

      return price;
    },

    amount: {
      get() {
        return this.$store.state.amount;
      },
      set(value) {
        return (this.$store.state.amount = value);
      },
    },
    equivalentAmount: {
      get() {
        return this.$store.state.equivalentAmount;
      },
      set(value) {
        return (this.$store.state.equivalentAmount = value);
      },
    },

    acceptTerms: {
      get() {
        console.log(typeof this.$store.state.acceptTerms);
        return this.$store.state.acceptTerms;
      },
      set(value) {
        return (this.$store.state.acceptTerms = value);
      },
    },
  },

  data() {
    return {
      showBuyNotification: true,
      emptyFields: true,
    };
  },

  methods: {
    ...mapActions([
      "setCurrentPageName",
      "setCurrentProject",
      "setSelectProjectModal",
      "getSingleProject",
      "setPageLoading",
      "setAlertpopUp",
    ]),

    toggleTerms: function () {
      this.acceptTerms = !this.acceptTerms;
      if (this.acceptTerms) {
        localStorage.setItem("axxeptTerms", true);
      }
      this.validateForm();
    },

    showSelectProjectModal: function (networks) {
      this.setSelectProjectModal({
        status: true,
        title: "",
        desc: "",
        payload: networks,
        select1Type: "",
        select2Type: "",
        select3Type: "",
        select4Type: "",
        select5Type: "",
        select6Type: "",
        type: "CHECKOUT_PROJECT",
      });
      // console.log(networks);
    },
    decreaseAmount: function () {
      if (this.amount == 1) {
        return;
      }

      this.amount = --this.amount;
      this.calculateAmount();
    },

    increaseAmount: function () {
      this.amount = ++this.amount;
      this.calculateAmount();
    },

    calculateAmount: function () {
      if (!this.amount) {
        // this.amount = 1;
        return;
      }

      // this.equivalentAmount =
      //   Math.round((this.amount + this.buyingPrice.price) * 1e12) / 1e12;
      // console.log(this.amount);
      // console.log(this.buyingPrice.price);
      // console.log(this.equivalentAmount);

      this.equivalentAmount = new Decimal(this.amount)
        .times(this.buyingPrice.price)
        .toFixed(this.calculatePrecisedDecimalPlaces(this.buyingPrice.price));
      //   .toFixed(2)

      this.validateForm();
    },

    calculateEquivalent: function () {
      if (!this.equivalentAmount) {
        // this.equivalentAmount = 1;
        return;
      }

      // console.log(this.buyingPrice);
      this.amount = new Decimal(this.equivalentAmount)
        .dividedBy(this.buyingPrice.price)
        .toFixed(2);

      // Math.round((this.amount - this.buyingPrice.price) * 1e12) / 1e12;
      // new Decimal(this.equivalentAmount).dividedBy(
      //   this.buyingPrice.price
      // );

      this.validateForm();
    },

    validateForm: function () {
      if (!this.amount || !this.acceptTerms) {
        this.emptyFields = true;
        return false;
      }

      this.emptyFields = false;
      return true;
    },

    updateCurrentPageType: function () {
      this.currentPageType = this.$router.currentRoute._rawValue.name;
      this.showMarketPlace();
    },

    checkRouterId: function () {
      if (this.$route.query.id) {
        this.getSingleProject(this.$route.query.id);
      } else {
        setTimeout(() => {
          if (this.projects) {
            let project = this.projects[0];
            this.getSingleProject(project.id);
            // project.selectedToken = project.tradeInfo.allowedCoins[0];

            // this.amount = new Decimal(
            //   project.tradeInfo.minimumBuyingAmount
            // ).toFixed(2);
            // this.equivalentAmount = new Decimal(
            //   project.tradeInfo.minimumBuyingAmount
            // )
            //   .times(project.tradeInfo.buyPrice)
            //   .toFixed(2);

            // this.setCurrentProject(project);
          }
        }, 2000);
      }
    },

    buyAssets: function () {
      let url = `${this.RELSIFY_URL}/api/buy/project`;
      this.setPageLoading(true);
      this.emptyFields = true;

      // const token = sessionStorage.getItem("Appoen");

      let newPayload = {
        projectId: this.currentProject.id,
        coin: this.currentProject.selectedToken.id,
        amount: this.amount,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setPageLoading(false);
          // console.log(data);
          if (!data.error) {
            this.resetFields();

            // this.setAlertpopUp({
            //   status: true,
            //   title: " Successful",
            //   des: data.meta.message,
            //   type: "success",
            //   payload: null,
            // });

            this.globalFunction();
            this.setAlertMessageModal({
              status: true,
              title: "Successful",
              des: data.meta.message,
              button1Text: "Portfolio",
              button1Url: "/portfolio",
              button2: true,
              button2Text: "Buy more",
              button2Url: "CHECKOUT",
              type: "BUY",
              payload: null,
            });

            setTimeout(() => {
              location.reload();
            }, 2000);

            this.loading = false;
          } else {
            this.setPageLoading(false);
            this.setAlertpopUp({
              status: true,
              title: " Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch((error) => {
          this.setPageLoading(false);
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });

          error;
          // console.log(error);
        });
    },

    resetFields() {
      this.$store.state.currentProject = null;
      this.showBuyNotification = true;
      this.totalAmount = 10;
      this.emptyFields = true;
      this.loading = false;
      this.acceptTerms = false;
    },
  },
  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    this.checkRouterId();
    // setTimeout(() => {
    //   console.log(this.userWalletBalance);
    //   console.log(this.currentProject);
    // }, 4000);
  },

  beforeRouteLeave(to, from, next) {
    // this.setCurrentProject(null);
    this.currentPageType = null;
    // localStorage.removeItem("current-project");
    next();
  },

  watch: {
    "$route.query.id": function () {
      this.resetFields();
      this.checkRouterId();
      this.validateForm();
    },

    // buyingPrice: function () {
    //   this.calculateAmount();
    //   this.calculateEquivalent();
    // },
  },
};
</script>
