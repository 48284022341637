<template>
  <div class="product-card">
    <div class="product-card-img">
      <img
        v-if="project.mainImage.optimizedUrl"
        :src="project.mainImage.optimizedUrl"
        alt=""
        style="cursor: pointer"
        @click.self="
          $router.push({
            name: 'ProjectDetails',
            query: { id: project.id },
          })
        "
      />
      <a
        href="javascript:;"
        @click="filterProduct(project.category)"
        class="product-card-category"
        >{{ project.category }}</a
      >
    </div>
    <div class="product-card-description">
      <div class="product-card-description-header">
        <h3
          @click="
            $router.push({
              name: 'ProjectDetails',
              query: { id: project.id },
            })
          "
          style="cursor: pointer"
        >
          {{ project.title }}
        </h3>
        <!-- <p>
          {{ project.description }}
        </p> -->
      </div>
      <hr />

      <div class="product-card-description-details">
        <div class="product-card-description-details-item">
          <!-- <p>
            <span class="product-card-description-details-item-title"
              >Coin</span
            >
            <span class="product-card-description-details-item-prc green-color"
              ><img
                v-if="project.coin.iconUrl"
                :src="project.coin.iconUrl"
                alt=""
                style="border-radius: 60px"
              />
              {{ project.coin.name }}</span
            >
          </p> -->
          <p>
            <span class="product-card-description-details-item-title"
              >Country</span
            >
            <span class="product-card-description-details-item-prc green-color"
              ><img
                v-if="countryFlag"
                :src="countryFlag"
                alt=""
                style="
                  margin-right: 2px;
                  border-radius: 100%;
                  width: 14px;
                  height: 14px;
                "
              />
              {{ project.country }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Target</span
            >
            <span class="product-card-description-details-item-prc green-color">
              {{ project?.baseCurrency?.symbol }}
              {{
                Number(Number(project.target).toFixed(0)).toLocaleString(
                  "en-GB"
                )
              }}</span
            >
          </p>
        </div>
        <div class="product-card-description-details-item">
          <p class="project_hide_mobile">
            <span class="product-card-description-details-item-title"
              >Deadline
            </span>
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.fundraisingPeriod }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Raised</span
            >
            <span class="product-card-description-details-item-prc green-color">
              {{ project?.baseCurrency?.symbol }}
              {{
                Number(Number(project.totalRaised).toFixed(0)).toLocaleString(
                  "en-GB"
                )
              }}</span
            >
          </p>
        </div>
        <div
          class="product-card-description-details-item"
          v-if="currentPageName !== 'Home'"
        >
          <p>
            <span class="product-card-description-details-item-title"
              >City</span
            >
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.city }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Investors</span
            >
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.investors }}</span
            >
          </p>
        </div>
      </div>
      <div class="product-button">
        <button class="relsify-button" @click="proceed(project)">
          <span v-if="!loading">CO-OWN</span>
          <span v-if="loading">
            <span
              class="spinner spinner-border spinner-border-sm"
              role="status"
            ></span>
            <!-- <span style="margin-left: 10px">Adding ...</span> -->
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["project"],
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    countries() {
      return this.$store.state.countries;
    },
    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },

    // currentPageType: {
    //   get() {
    //     return this.$store.state.currentPageType;
    //   },
    //   set(value) {
    //     return (this.$store.state.currentPageType = value);
    //   },
    // },

    countryFlag() {
      let flag = "";
      if (this.countries && this.project) {
        let countryFlag = this.countries.find(
          (flag) =>
            flag.name.toLowerCase() == this.project.country.toLowerCase()
        );
        if (countryFlag) {
          flag = countryFlag.flag;
        }
      }

      return flag;
    },
  },
  methods: {
    ...mapActions(["setCurrentProject", "filterProjects"]),

    proceed: function (project) {
      this.loading = true;
      // project.selectedToken = project.tradeInfo.allowedCoins[0];
      // this.setCurrentProject(project);

      console.log(project);

      localStorage.setItem("current-project", JSON.stringify(project));

      if (this.currentPageType) {
        setTimeout(() => {
          this.$router
            .push({
              name: this.currentPageType,
              query: { id: project.id },
            })
            .catch((err) => err);
          this.closeMarketPlace();
        }, 1500);
      } else {
        setTimeout(() => {
          this.$router
            .push({
              name: "Checkout",
              query: { id: project.id },
            })
            .catch((err) => err);
          this.closeMarketPlace();
        }, 1500);
      }
      // setTimeout(() => {
      //   this.$router
      //     .push({
      //       name: this.currentPageType,
      //     })
      //     .catch((err) => err);
      //   this.closeMarketPlace();
      // }, 1500);
    },

    filterProduct: function (category) {
      if (this.selectedCategory == category) {
        return;
      }
      this.selectedCategory = category;
      let url = `${
        this.RELSIFY_URL
      }/api/projects?page=${1}&limit=${20}&category=${category}`;
      this.filterProjects(url);
    },
  },
};
</script>

<style></style>
