<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Trade History</h2>
          </div>
          <!-- <a href="./trade-history.html" class="page-breadcrum">
            <p>Transaction History</p>
            <i class="bx bx-right-arrow-alt"></i>
          </a> -->
        </div>
      </div>
    </div>

    <TradeHistory />
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import TradeHistory from "@/components/trade/TradeHistory.vue";
export default {
  components: {
    TradeHistory,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
    goBackToWallet: function () {
      this.$router.push({
        name: "UserTransactionHistory",
      });
    },
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>
