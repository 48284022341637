<template>
  <tr v-if="portfolio" @click="showPortfolioModal(portfolio)">
    <td>
      <div class="td-with-image">
        <img
          v-if="portfolio.balance.coin.iconUrl"
          :src="portfolio.balance.coin.iconUrl"
          alt=""
        />
        <!-- <span>{{ portfolio.balance.coin.name }}</span> -->
        <span>{{ portfolio?.project?.title }}</span>
      </div>
    </td>
    <td>
      <span>
        {{
          showPortfolioBalance
            ? formatAmount(portfolio.balance.amount) +
              " " +
              portfolio.balance.coin.symbol
            : "*********"
        }}
      </span>
    </td>
    <td>
      <div v-if="showPortfolioBalance" class="td-with-image">
        <img
          v-if="userDefaultCurrency.iconUrl"
          :src="userDefaultCurrency.iconUrl"
          alt=""
        />
        <span style="margin-bottom: 0px">{{
          formatUsdValue(portfolio.balance.value)
        }}</span>
      </div>
      {{ !showPortfolioBalance ? "*********" : "" }}
    </td>
    <!-- <td>
      <span
        >
        {{ showPortfolioBalance ?  `%${portfolio.balance.ownedPercentage}` : '*********'}}
        </span
      >
    </td> -->
    <!-- <td>
      <span>
        ${{
          showPortfolioBalance ? $numberic(portfolio.balance.usdValue).format("0.0[00000000]") : '*********'
        }}
      </span>
    </td> -->
    <!-- <td class="table-td-positive">+0.5%</td> -->
    <!-- <td class="">
      <button
        class="relsify-button light-shade-green"
        
      >
        Details
      </button>
    </td> -->
    <!-- <td class="">
      <button
        class="relsify-button light-shade-green"
        
      >
        Details
      </button>
    </td> -->
  </tr>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";

export default {
  props: ["portfolio"],

  computed: {
    showPortfolioBalance: {
      get() {
        return this.$store.state.showPortfolioBalance;
      },
      set(value) {
        return (this.$store.state.showPortfolioBalance = value);
      },
    },
    userDefaultCurrency: {
      get() {
        return this.$store.state.userSelf.defaultCurrency;
      },
      set() {},
    },
  },

  methods: {
    ...mapActions([
      "setModalLoading",
      "setAlertpopUp",
      "setPortfolioModal",
      "getSingleProject",
    ]),
    showPortfolioModal: function (portfolio) {
      let projectId = portfolio.project.id;

      this.getSingleProject(projectId);
      this.setPortfolioModal({
        status: true,
        title: "",
        desc: "",
        payload: portfolio,
        select1Type: "",
        select2Type: "",
        type: "PORTFOLIO",
      });
    },
    valuationInDollars(value) {
      return `$${Number(value).toLocaleString()}`;
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "loading...";
}

.td-with-image img {
  border-radius: 100%;
  height: 20px;
}
</style>
