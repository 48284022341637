<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header wallet-page-header">
          <div class="page-title">
            <i class="bx bx-left-arrow-alt" @click="window.history.back()"></i>
            <h2>Dividend</h2>
          </div>
        </div>
        <div class="page-header-small-screen">
          <img
            src="@/assets/vectors/img/back-icon.png"
            class="arrow_bacck"
            alt=""
            @click="window.history.back()"
          />
          <br />
          <h5>Dividend History</h5>
        </div>
      </div>
    </div>

    <div class="small_screen_effect_inner wallet_balance_page">
      <div class="row">
        <div class="col-md-12">
          <div class="divi-tabs">
            <TransactionTabs />
          </div>

          <div class="page-tab-content">
            <div class="relsify-tab-pane active" id="withdrawalTransaction">
              <Dividend />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import TransactionTabs from "@/components/transaction/TransactionTabs.vue";
import Dividend from "@/components/dividend/Dividend.vue";
export default {
  components: {
    Dividend,
    TransactionTabs,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
    goBackToWallet: function () {
      this.$router.push({
        name: "Wallet",
      });
    },
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.divi-tabs {
  display: none;
}

@media screen and (max-width: 980px) {
  .divi-tabs {
    display: block;
  }
}
</style>
