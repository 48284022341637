<template>
  <div>
    <div class="productDetailss" v-if="project">
      <h4>{{ project.title }}</h4>
      <div
        class="productDetailss_grid"
        :class="project.medias.length == 3 && 'hasThree'"
        v-if="project.mainImage && project.medias"
      >
        <div class="productDetailss_grid_item tem_1">
          <!-- <carousel :items-to-show="1">
            <slide v-for="slide in 3" :key="slide">
              <img class="" src="@/assets/vectors/img/projects.png" alt="" />
            </slide>

            <template #addons>
              <navigation />
            </template>
          </carousel> -->
          <img
            v-if="project.mainImage && project.mainImage.optimizedUrl"
            class=""
            :src="project.mainImage.optimizedUrl"
            alt=""
          />
        </div>
        <div
          class="productDetailss_grid_item tem_2"
          v-for="(image, index) in project.medias.slice(0, 4)"
          :key="index"
          :class="`tem_` + (index + 2)"
        >
          <img class="" :src="image.optimizedUrl" alt="" />
        </div>
      </div>
      <div
        class="productDetailss_grid_small"
        v-if="smallScreenMedias && smallScreenMedias.length"
      >
        <carousel :items-to-show="1">
          <slide v-for="(slide, index) in smallScreenMedias" :key="index">
            <img class="" :src="slide.optimizedUrl" alt="" />
          </slide>

          <template #addons>
            <navigation />
            <!-- <pagination /> -->
          </template>
        </carousel>
      </div>
      <div class="productDetailss_overview_grid">
        <div class="productDetailss_overview_grid_item">
          <div class="productDetailss_overview_grid_item_buttons">
            <button
              :class="selectedCategory == 'property' && 'active'"
              @click="selectProjectCategory('property')"
            >
              Property
            </button>
            <button
              :class="selectedCategory == 'map' && 'active'"
              @click="selectProjectCategory('map')"
            >
              Map
            </button>
            <button
              :class="selectedCategory == 'white-paper' && 'active'"
              @click="selectProjectCategory('white-paper')"
            >
              White paper
            </button>
            <button
              :class="selectedCategory == 'financials' && 'active'"
              @click="selectProjectCategory('financials')"
            >
              Financial
            </button>
            <button
              :class="selectedCategory == 'expertise' && 'active'"
              @click="selectProjectCategory('expertise')"
            >
              Expertise
            </button>
          </div>
          <div class="productDetailss_overview_grid_item_GREED">
            <div class="productDetailss_overview_grid_item_GReed_item">
              <div class="productDetailss_overview_overview">
                <div v-if="selectedCategory == 'property'">
                  <div class="summary">
                    <h5>Summary</h5>
                    <p v-html="project.description"></p>
                  </div>
                  <!-- <div class="summary_details">
                <div class="summary_details_item">
                  <h6>PROPERTY</h6>
                  <p>Type of property:</p>
                  <p>Building</p>
                  <p>Type of rental:</p>
                  <p>Long term rental</p>
                  <p>Floor:</p>
                  <p>1+2+3+4+5+6</p>
                </div>
              
              </div> -->
                  <div class="summary_inv">
                    <h5>Inventory</h5>
                    <p v-html="project.inventory"></p>
                  </div>
                </div>
                <div v-if="selectedCategory == 'map'">
                  <div class="summary_map">
                    <iframe
                      :src="`https://maps.google.com/maps?q=${project.projectMap}&amp;t=m&amp;z=13&amp;output=embed&amp;iwloc=near`"
                      height="450"
                      style="border: 0; width: 100%; border-radius: 15px"
                    ></iframe>
                  </div>
                  <div class="summary_inv">
                    <h5>Location</h5>
                    <p>City: {{ project.city }}</p>
                    <p>State: {{ project.state }}</p>
                    <p>
                      Country:
                      <span style="margin-left: 6px">
                        <img
                          v-if="countryFlag"
                          :src="countryFlag"
                          style="
                            width: 10px;
                            height: 10px;
                            object-fit: cover;
                            border-radius: 100%;
                          "
                        />
                        {{ project.country }}</span
                      >
                    </p>
                  </div>
                </div>
                <div v-if="selectedCategory == 'white-paper'">
                  <div class="summary_download">
                    <!-- <button
                  class="relsify-button"
                  @click="
                    downloadFile(project.whitePaper.url, project.whitePaper.id)
                  "
                >
                  Download
                </button> -->
                    <a
                      class="relsify-button"
                      :href="project.whitePaper.url"
                      download
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                  <div class="summary_inv">
                    <h5>Location</h5>
                    <p>City: {{ project.city }}</p>
                    <p>State: {{ project.state }}</p>
                    <p>
                      Country:
                      <span style="margin-left: 6px">
                        <img
                          v-if="countryFlag"
                          :src="countryFlag"
                          style="
                            width: 10px;
                            height: 10px;
                            object-fit: cover;
                            border-radius: 100%;
                          "
                        />
                        {{ project.country }}</span
                      >
                    </p>
                  </div>
                </div>
                <div v-if="selectedCategory == 'financials'">
                  <div class="summary_download">
                    <a
                      class="relsify-button"
                      :href="project.expertisePaper.url"
                      download
                      target="_blank"
                    >
                      Download
                    </a>
                  </div>
                  <div class="summary_inv">
                    <h5>Location</h5>
                    <p>City: {{ project.city }}</p>
                    <p>State: {{ project.state }}</p>
                    <p>
                      Country:
                      <span style="margin-left: 6px">
                        <img
                          v-if="countryFlag"
                          :src="countryFlag"
                          style="
                            width: 10px;
                            height: 10px;
                            object-fit: cover;
                            border-radius: 100%;
                          "
                        />
                        {{ project.country }}</span
                      >
                    </p>
                  </div>
                </div>
                <div v-if="selectedCategory == 'expertise'">
                  <div class="summary_download">
                    <a
                      :href="project.expertisePaper.url"
                      download
                      target="_blank"
                      class="relsify-button"
                    >
                      Download
                    </a>
                  </div>
                  <div class="summary_inv">
                    <h5>Location</h5>
                    <p>City: {{ project.city }}</p>
                    <p>State: {{ project.state }}</p>
                    <p>
                      Country:
                      <span style="margin-left: 6px">
                        <img
                          v-if="countryFlag"
                          :src="countryFlag"
                          style="
                            width: 10px;
                            height: 10px;
                            object-fit: cover;
                            border-radius: 100%;
                          "
                        />
                        {{ project.country }}</span
                      >
                    </p>
                  </div>
                </div>

                <div class="summary_inv">
                  <h5>Status</h5>
                  <p v-html="project.status"></p>
                </div>
                <div class="summary_inv">
                  <h5>Characteristics</h5>
                  <p v-html="project.characteristics"></p>
                </div>
              </div>
            </div>
            <div class="productDetailss_overview_grid_item_GReed_item">
              <div class="productDetailss_overview_grid_item_left">
                <div class="productDetailss_overview_grid_item_left_item">
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Valuation</p>
                    <p>
                      {{
                        project.baseCurrency?.symbol
                          ? project.baseCurrency?.symbol + " "
                          : ""
                      }}{{ addComma(project.propertyValuation) }}
                    </p>
                  </div>
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Target</p>
                    <p>
                      {{
                        project.baseCurrency?.symbol
                          ? project.baseCurrency?.symbol + " "
                          : ""
                      }}{{ addComma(project.target) }}
                    </p>
                  </div>
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Investors</p>
                    <p>{{ addComma(project.investors) }}</p>
                  </div>
                </div>
                <div class="productDetailss_overview_grid_item_left_item">
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Min Investment</p>
                    <p>
                      {{
                        project.baseCurrency?.symbol
                          ? project.baseCurrency?.symbol + " "
                          : ""
                      }}{{ addComma(project.minimumInvestment) }}
                    </p>
                  </div>
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Max investment</p>
                    <p>
                      {{
                        project.baseCurrency?.symbol
                          ? project.baseCurrency?.symbol + " "
                          : ""
                      }}{{ addComma(project.maximumInvestment) }}
                    </p>
                  </div>
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>fundraising Period</p>
                    <p>{{ project.fundraisingPeriod }}</p>
                  </div>
                </div>
                <div class="productDetailss_overview_grid_item_left_item">
                  <!-- <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Start Date</p>
                    <p>12/6/2022</p>
                  </div>
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>End Date</p>
                    <p>12/6/2022</p>
                  </div> -->
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>City</p>
                    <p>{{ project.city }}</p>
                  </div>
                </div>
                <div class="productDetailss_overview_grid_item_left_item">
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <!-- <p>Structure</p>
                <p>{{ project.category }}</p> -->
                    <p>Category</p>
                    <p>{{ project.category }}</p>
                  </div>
                  <!-- <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Token</p>
                    <p>
                      <img
                        v-if="project.coin.iconUrl"
                        :src="project.coin.iconUrl"
                        alt=""
                      />
                      {{ project.coin.symbol }}
                    </p>
                  </div> -->
                  <div
                    class="productDetailss_overview_grid_item_left_item_item"
                  >
                    <p>Forcasted Income</p>
                    <p>
                      {{
                        project.baseCurrency?.symbol
                          ? project.baseCurrency?.symbol + " "
                          : ""
                      }}{{ addComma(project.forecastedIncome) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="productDetailss_overview_grid_item">
          
        </div> -->
      </div>
      <!-- <div class="productDetailss_overview_overview">
        <div class="summary_inv">
          <h5>Documents</h5>
          <div class="summary_inv_docs">
            <div class="summary_inv_docs_item">
              <img class="" src="@/assets/vectors/img/Union.png" alt="" />
              <p>PPM (private placement memorandum)</p>

              <span>1 doc</span>
            </div>
            <div class="summary_inv_docs_item">
              <img class="" src="@/assets/vectors/img/Union.png" alt="" />
              <p>PPM (private placement memorandum)</p>
              <span>1 doc</span>
            </div>
            <div class="summary_inv_docs_item">
              <img class="" src="@/assets/vectors/img/Union.png" alt="" />
              <p>PPM (private placement memorandum)</p>

              <span>1 doc</span>
            </div>
            <div class="summary_inv_docs_item">
              <img class="" src="@/assets/vectors/img/Union.png" alt="" />
              <p>PPM (private placement memorandum)</p>

              <span>1 doc</span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="text-center mt-5">
        <br />
        <br />
        <button
          @click="proceed(project)"
          style="font-size: 14px"
          class="relsify-button px-5 py-3"
        >
          CO-OWN
        </button>
      </div>
    </div>
    <div class="relsify-small-table-grid" v-if="project == null">
      <div class="d-flex justify-content-center align-items-center">
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <LoadingComponent />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import LoadingComponent from "../loading/LoadingComponent.vue";
import "@/mixins";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["countries"]),
    smallScreenMedias() {
      let images = [];
      if (this.project && this.project.medias) {
        images = this.project.medias;
        images = [this.project.mainImage, ...images];
      }

      return images;
    },

    countryFlag() {
      let flag = "";
      if (this.project && this.project.country) {
        let newFlag = this.countries.find(
          (flag) =>
            flag.name.toLowerCase() == this.project.country.toLowerCase()
        );

        if (newFlag) {
          flag = newFlag.flag;
        }

        // console.log(newFlag);
      }

      return flag;
    },
  },

  data() {
    return {
      selectedCategory: "property",
      project: null,
    };
  },

  methods: {
    ...mapActions(["setCurrentProject"]),
    selectProjectCategory: function (category) {
      this.selectedCategory = category;
    },

    proceed: function (project) {
      // this.loading = true;
      localStorage.setItem("current-project", JSON.stringify(project));
      this.$router
        .push({
          name: "Checkout",
          query: { id: project.id },
        })
        .catch((err) => err);
    },

    checkRouterId: function () {
      if (this.$route.query.id) {
        let url = `${this.RELSIFY_URL}/api/project/${this.$route.query.id}/details`;
        // console.log(id);
        this.axios
          .get(url)
          .then((data) => {
            if (!data.data.error) {
              console.log(data.data);
              if (data.data.data) {
                this.project = data.data.data;
              }
            }
          })
          .catch((error) => console.log(error));
      } else {
        this.$router.push({
          name: "Home",
        });
      }
    },
  },

  mounted() {
    this.checkRouterId();
  },
};
</script>

<style scoped>
.productDetailss {
  padding: 40px 50px;
  background: #fff;
  text-align: center;
}

.productDetailss h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 65px;
  color: #000000;
  margin-bottom: 80px;
}

.productDetailss_grid_small {
  display: none;
}

.relsify-button:hover {
  transform: scale(1.1);
}

.productDetailss_grid {
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-auto-rows: 200px;
  grid-template-areas:
    "tem_1 tem_1 tem_2 tem_3"
    "tem_1 tem_1 tem_4 tem_5";
  gap: 15px;
}

.productDetailss_grid.hasThree {
  grid-template-areas:
    "tem_1 tem_1 tem_2 tem_3"
    "tem_1 tem_1 tem_4 tem_3";
}

.productDetailss_grid_item {
  width: 100%;
  height: 100%;
  border-radius: 5px !important;
}

.tem_1 {
  grid-area: tem_1;
  width: 100%;
  height: 100%;
}

.tem_2 {
  grid-area: tem_2;
}

.tem_3 {
  grid-area: tem_3;
}

.tem_4 {
  grid-area: tem_4;
}

.tem_5 {
  grid-area: tem_5;
}

.productDetailss img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px !important;
}

.productDetailss_overview_grid {
  margin-top: 50px;
  display: block;
  width: 100%;
}

.productDetailss_overview_grid_item_buttons {
  width: 100%;
  margin: 60px 0px;
  border-top: 1px solid rgba(24, 133, 111, 0.301);
  border-bottom: 1px solid rgba(24, 133, 111, 0.301);
  padding: 17px 0px;
  text-align: left;
}

.productDetailss_overview_grid_item_buttons button {
  border: none;
  background: white;
  color: var(--mainGreenColor);
  padding: 8px 25px;
  border: 1px solid var(--mainGreenColor);
  font-size: 14px;
  text-decoration: none;
  margin: 0px 19px;
  border-radius: 30px;
  font-weight: 500;
  transition: all 300ms ease;
}

.productDetailss_overview_grid_item_buttons button:hover {
  background: var(--mainLightGreenColor);
  color: var(--mainGreenColor);
}

.productDetailss_overview_grid_item_buttons button:focus {
  outline: none;
}

.productDetailss_overview_grid_item_buttons button:first-child {
  margin-left: 0px;
}

.productDetailss_overview_grid_item_buttons .active {
  background: var(--mainGreenColor);
  border: none;
  color: white;
}

.productDetailss_overview_overview {
  text-align: left;
}

.productDetailss_overview_overview h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 65px;
  color: #000000;
}

.productDetailss_overview_overview p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
}

.summary_details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  width: 50%;
  margin-top: 56px;
}

.summary_details h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 140.62%;
  color: #000000;
}

.summary_details p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 0px;
}

.summary_inv {
  margin-top: 30px;
}

.summary_inv p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 0px;
}

.summary_download {
  width: 70%;
  background: var(--mainLightGreenColor);
  border: 1px solid var(--mainGreenColor);
  box-sizing: border-box;
  border-radius: 20px;
  text-align: center;
  padding: 140px 0px;
}

.summary_download button,
.summary_download a {
  padding: 10px 50px;
  transition: all 300ms ease;
  text-decoration: none;
}

.summary_download button:hover,
.summary_download a:hover {
  background: transparent;
  border: 1px solid var(--mainYellowColor);
}

.summary_inv_docs {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.summary_inv_docs_item {
  width: 240px;
  margin-right: 30px;
  margin-bottom: 20px;
  background: #f6f6f6;
  padding: 15px;
  border-radius: 2px;
}

.summary_inv_docs_item img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 100%;
}

.summary_inv_docs_item p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-top: 15px;
  margin-bottom: 30px;
}

.summary_inv_docs_item span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #737373;
  display: block;
  margin-bottom: 15px;
}

.productDetailss_overview_grid_item_GREED {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 400px;
  column-gap: 30px;
}

.productDetailss_overview_grid_item_left {
  /* margin-top: 50px; */
  background: var(--mainLightGreenColor);
  padding: 50px;
  /* max-width: 490px; */
  width: 100%;
}

.productDetailss_overview_grid_item_left_item {
  padding-bottom: 20px;
  margin-bottom: 40px;
  border-bottom: 1px dashed var(--mainGreenColor);
  border-width: 2px;
}

.productDetailss_overview_grid_item_left_item:last-child {
  padding-bottom: 20px;
  margin-bottom: 0px;
  border-bottom: none;
}

.productDetailss_overview_grid_item_left_item_item {
  display: flex;
  justify-content: space-between;
}

.productDetailss_overview_grid_item_left_item_item p {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 140.62%;
  color: #000000;
}

.productDetailss_overview_grid_item_left_item_item p:last-child {
  font-weight: 600;
  font-size: 14px;
  color: #18856f;
}

.productDetailss_overview_grid_item_left_item_item p img {
  display: inline;
  width: 14px;
  height: 14px;
}

@media screen and (max-width: 1470px) {
  .productDetailss_overview_grid {
    margin-top: 50px;
    width: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .productDetailss_overview_grid {
    margin-top: 50px;
    width: 100%;
  }
}

@media screen and (max-width: 1250px) {
  .productDetailss_overview_grid_item_buttons {
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    padding-left: 20px;
    padding-right: 20px;
  }

  .productDetailss_overview_grid_item_buttons::-webkit-scrollbar {
    width: 2px !important;
    height: 9px !important;
    background: var(--mainGreenColor);
    border-radius: 20px;
  }

  /* Track */
  .productDetailss_overview_grid_item_buttons::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 30px;
  }

  /* Handle */
  .productDetailss_overview_grid_item_buttons::-webkit-scrollbar-thumb {
    background: var(--mainGreenColor);
    border-radius: 30px;
  }

  .productDetailss_overview_grid_item_buttons button {
    width: 100%;
    white-space: nowrap;
  }
}

@media screen and (max-width: 1150px) {
  .productDetailss_overview_grid_item_GREED {
    width: 100%;
    display: block;
  }
  .productDetailss_overview_grid_item_left {
    padding: 50px;
    max-width: 600px;
    width: 100%;
    margin: 50px 0px 0px;
  }
}
@media screen and (max-width: 1070px) {
  .productDetailss_overview_grid {
    margin-top: 50px;
    display: block !important;
    width: 100%;
  }
  .productDetailss_overview_grid_item:first-child {
    width: 100%;
  }

  .productDetailss_overview_grid_item_left {
    padding: 50px;
    /* max-width: 600px; */
    width: 100%;
    margin: 70px 0px 0px;
  }
}

@media screen and (max-width: 950px) {
  .productDetailss {
    padding: 40px 30px;
  }

  .productDetailss h4 {
    font-size: 32px;
    margin-bottom: 80px;
  }

  .productDetailss_grid {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-auto-rows: 300px;
    grid-template-areas:
      "tem_1 tem_1 tem_1 tem_1"
      "tem_2 tem_3 tem_4 tem_5";
    gap: 17px;
  }

  .productDetailss_grid.hasThree {
    grid-template-areas:
      "tem_1 tem_1 tem_1 tem_1"
      "tem_2 tem_3 tem_3 tem_4";
  }
}

@media screen and (max-width: 700px) {
  .productDetailss {
    padding: 40px 30px 50px;
  }

  .productDetailss h4 {
    font-size: 30px;
    margin-bottom: 30px;
  }

  .productDetailss_overview_grid_item_left {
    padding: 50px;
    max-width: 100%;
    width: 100%;
    margin: 50px 0px 0px;
  }

  .summary_download {
    width: 100%;
    padding: 140px 0px;
  }
}

@media screen and (max-width: 650px) {
  .productDetailss {
    padding: 40px 19px 150px;
  }

  .productDetailss_grid {
    grid-auto-rows: 180px;
    gap: 10px;
  }

  .productDetailss_overview_grid_item_left {
    padding: 50px 30px 20px;
  }

  .summary_inv_docs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .summary_inv_docs_item {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 0px;
    padding: 10px;
  }

  .productDetailss_overview_grid_item_buttons::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
  }
}

@media screen and (max-width: 580px) {
  .productDetailss {
    padding: 40px 19px 100px;
  }
  .productDetailss_grid {
    display: none;
  }

  .productDetailss_grid_small {
    display: block;
    height: 300px;
  }

  .productDetailss_grid_item {
    border-radius: 2px !important;
  }

  .productDetailss img,
  .productDetailss_grid_item img {
    border-radius: 2px !important;
  }
}
@media screen and (max-width: 480px) {
  .productDetailss_grid {
    grid-auto-rows: 160px;
    gap: 10px;
  }

  .productDetailss h4 {
    font-size: 25px;
    margin-bottom: 40px;
  }
  .summary_inv_docs {
    gap: 10px;
  }
}
</style>
