<template>
  <div class="">
    <div v-if="!status">
      <div class="siteTermsAndConditions">
        <h4>Terms & Conditions</h4>
        <h6>Last updated March 2021</h6>

        <div class="siteTermsAndConditionsDetails">
          <div class="siteTermsAndConditionsDetailsItem">
            <h5>1.Terms</h5>
            <p>
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was
            </p>
          </div>
          <div class="siteTermsAndConditionsDetailsItem">
            <h5>2.User Licence</h5>
            <p>
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised
              in the 1960s with the release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem Ipsum.
            </p>
          </div>
          <div class="siteTermsAndConditionsDetailsItem">
            <h5>3.User Data</h5>
            <p>
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged. It was popularised
              in the 1960s with the release of Letraset sheets containing Lorem
              Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions of Lorem
              Ipsum.typesetting, remaining essentially containing Lorem Ipsum
              passages, and more recently with desktop publishing software like
              Aldus PageMaker including versions of Lorem Ipsum.typesetting,
              remaining esse
            </p>
          </div>
        </div>
      </div>
      <div class="siteTermsAndConditionsDetailsItemButtons">
        <!-- <button class="relsify-button declined">Decline</button> -->
        <button @click="acceptTerms" class="relsify-button accept">
          Accept
        </button>
      </div>
    </div>
    <div v-if="status">
      <VerifiedDiv :title="'Terms and Condition Accepted'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import VerifiedDiv from "../../VerifiedDiv.vue";
import { mapActions } from "vuex";
export default {
  components: {
    VerifiedDiv,
  },
  props: ["status"],

  methods: {
    ...mapActions(["setPageLoading", "setAlertpopUp"]),
    acceptTerms: function () {
      let url = `${this.RELSIFY_URL}/api/verification/accept-terms`;
      this.setPageLoading(true);

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setPageLoading(false);
          this.globalFunction();
          // console.log(data.data);
          if (!data.error) {
            this.setAlertpopUp({
              status: true,
              title: " successful",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            setTimeout(() => {
              this.$router.push({
                name: "SiteVerificationLevel2",
              });
            }, 1300);
          } else {
            this.setAlertpopUp({
              status: true,
              title: " Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch(() => {
          this.setPageLoading(false);
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },
  },
};
</script>
