<template>
  <div>
    <div
      class="profile-settings-user-image"
      v-if="user"
      style="position: relative !important"
    >
      <img
        v-if="user?.image?.url"
        :src="user?.image?.url"
        class="profile-photo"
        alt=""
      />
      <img
        v-else
        src="@/assets/vectors/img/blank-avatar.png"
        class="profile-photo"
        alt=""
      />
      <img
        src="@/assets/vectors/img/camera-auth.png"
        class="camera-auth"
        alt=""
        @click="$refs.profile_photo.click()"
      />

      <input
        type="file"
        ref="profile_photo"
        style="display: none"
        accept="image/png, image/gif, image/jpeg"
        @change="uploadImage($event)"
      />

      <span class="image_loading" v-if="isImageUploading">
        <span
          class="spinner spinner-border"
          role="status"
          style="width: 20px; height: 20px; color: #3289c8"
        ></span>
      </span>
    </div>
    <div class="text-center" v-if="currentPageName == 'SiteVerificationLevel2'">
      <br />
      <a
        href="javascript:;"
        class="green-color"
        @click="$refs.profile_photo.click()"
        style="display: block; margin-top: -40px; font-size: 13px"
        >Tap to edit image</a
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "@/mixins";
export default {
  computed: {
    ...mapGetters(["userVerificationStatus"]),
  },
  data() {
    return {
      maritalStatus: "",
      profileImage: null,
      isImageUploading: false,
    };
  },

  methods: {
    ...mapActions(["globalFunction", "setAlertpopUp"]),
    uploadImage: function (event) {
      if (event.target.files[0]) {
        this.profileImage = event.target.files[0];
        var reader = new FileReader();
        this.isImageUploading = true;

        this.saveImageToDb(event);

        reader.onload = function (event) {
          let images = document.querySelectorAll(".profile-photo");

          images.forEach((image) => {
            image.setAttribute("src", event.target.result);
          });
        };

        reader.readAsDataURL(event.target.files[0]);
      }
    },

    saveImageToDb: function () {
      //   const token = sessionStorage.getItem("Appoen");

      if (this.currentPageName == "SiteVerificationLevel2") {
        let url = `${this.RELSIFY_URL}/api/verification/level-two/photo/upload`;
        let fd = new FormData();
        fd.append("photo", this.profileImage);

        this.axios
          .put(url, fd)
          .then((response) => {
            this.globalFunction();
            this.isImageUploading = false;
            if (!response.data.error) {
              // console.log(response.data);
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Upload successful",
                des: response.data.meta.message,
                type: "success",
                payload: null,
              });
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Error",
                des: response.data.meta.message,
                type: "error",
                payload: null,
              });
              // console.log(response.data);
            }
          })
          .catch(() => {
            this.isImageUploading = false;
            this.setAlertpopUp({
              status: true,
              title: "Image Update Status",
              des: "An Error occured while uploading profile Image",
              type: "error",
              payload: null,
            });
            // console.log(error);
          });
      } else {
        let url = `${this.RELSIFY_URL}/api/upload/image`;
        let fd = new FormData();
        fd.append("image", this.profileImage);

        this.axios
          .post(url, fd)
          .then((response) => {
            this.globalFunction();
            this.isImageUploading = false;
            if (!response.data.error) {
              // console.log(response.data);
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Upload successful",
                des: response.data.meta.message,
                type: "success",
                payload: null,
              });
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Error",
                des: response.data.meta.message,
                type: "error",
                payload: null,
              });
              // console.log(response.data);
            }
          })
          .catch(() => {
            this.isImageUploading = false;
            this.setAlertpopUp({
              status: true,
              title: "Image Update Status",
              des: "An Error occured while uploading profile Image",
              type: "error",
              payload: null,
            });
            // console.log(error);
          });
      }

      // let imageName =
      //   this.currentPageName == "SiteVerificationLevel2" ? "photo" : "image";

      // const token = sessionStorage.getItem("Appoen");

      // fetch(url, requestOptions)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.globalFunction();
      //     this.isImageUploading = false;
      //     if (!data.error) {
      //       console.log(data);
      //       this.globalFunction();
      //     } else {
      //       this.setAlertpopUp({
      //         status: true,
      //         title: "Error",
      //         des: data.meta.message,
      //         type: "error",
      //         payload: null,
      //       });
      //       console.log(data);
      //     }
      //   })
      //   .catch((error) => {
      //     this.isImageUploading = false;
      //     this.setAlertpopUp({
      //       status: true,
      //       title: "Image Update Status",
      //       des: "An Error occured while uploading profile Image",
      //       type: "error",
      //       payload: null,
      //     });
      //     console.log(error);
      //   });
    },
  },
};
</script>

<style scoped>
.image_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0%;
  left: 0.3%;
  background: rgba(255, 251, 251, 0.73);
  width: 100% !important;
  height: 100%;
  border-radius: 100%;
}
</style>
