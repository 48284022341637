<template>
  <tr class="table_roww" v-if="device">
    <td>
      <p>
        <i
          class="bx bx-mobile"
          v-if="device.deviceOs.toLowerCase() == 'android'"
          style="font-size: 19px"
        ></i>
        <i class="bx bx-laptop" v-else style="font-size: 19px"></i>

        <span style="margin-left: 10px"> {{ device.device }}</span>
      </p>
    </td>
    <td>
      <p>
        {{ formateDateAndTimeByName(device.date) }}
      </p>
    </td>
    <td>
      {{ device.city + ", " + device.country }}
    </td>
    <td style="color: var(--mainGreenColor)">{{ device.deviceClientType }}</td>
    <td>{{ device.ip }}</td>
  </tr>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["device"],
  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
  },
};
</script>

<style scoped>
.table_roww td {
  padding: 40px 10px;
  background: #fff;
}
.table_roww td p {
  display: inline;
  width: auto;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
</style>
