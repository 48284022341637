<template>
  <div class="histories">
    <h4><i class="bx bx-alarm"></i> History</h4>
    <ul class="timeline" v-if="activityLogs && activities && activities.length">
      <li v-for="(activity, index) in activities" :key="index">
        <h5>{{ formateDateByDayAndMonth(activity.date) }}</h5>
        <p>{{ activity.message }}</p>
      </li>
    </ul>
    <div v-if="activityLogs && activityLogs.length <= 0">
      <div class="text-center">Mo Activity</div>
    </div>
    <div class="histories-button" v-if="activityLogs && activityLogs.length">
      <button
        @click="
          $router.push({
            name: 'Activities',
          })
        "
        class="relsify-button"
      >
        Check All History
      </button>
    </div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="activityLogs == null"
    >
      <br />
      <LoadingComponent />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import "@/mixins";
import LoadingComponent from "../loading/LoadingComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["activityLogs"]),
    activities() {
      let activities = null;
      if (this.activityLogs && this.activityLogs.length) {
        activities = this.activityLogs.slice().sort(function (a, b) {
          return a.date > b.date ? -1 : 1;
        });

        activities = activities.slice(0, 5);

        // console.log(this.activityLogs);
        // console.log(activities);
      }

      return activities;
    },
  },
};
</script>

<style></style>
