<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header wallet-page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Notification details</h2>
          </div>
        </div>
        <div class="page-header-small-screen">
          <img
            src="@/assets/vectors/img/back-icon.png"
            class="arrow_bacck"
            alt=""
            onclick="window.history.back();"
          />
          <br />
          <h5>Notification details</h5>
        </div>
      </div>
    </div>

    <div class="small_screen_effect_inner wallet_balance_page">
      <NotificationDetails />
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import NotificationDetails from "../../components/notification/slider/NotificationDetails.vue";
export default {
  components: {
    NotificationDetails,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.small_screen_effect_inner {
  margin-left: 8px;
  margin-right: 8px;
  background: transparent;
}
</style>
