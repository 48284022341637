<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Activities</h2>
          </div>
          <!-- <a href="./trade-history.html" class="page-breadcrum">
            <p>Transaction History</p>
            <i class="bx bx-right-arrow-alt"></i>
          </a> -->
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <ActivityTable />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import ActivityTable from "../../components/activity/ActivityTable.vue";
export default {
  components: {
    ActivityTable,
  },

  computed: {
    selectedActivities: {
      get() {
        return this.$store.state.selectedActivities;
      },
      set(value) {
        this.$store.state.selectedActivities = value;
      },
    },
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .after-page-header {
    margin-top: 90px;
  }
}
</style>
