<template>
  <div class="site-security-settings">
    <div class="site-security-settings-pageTitle">
      <h3>Change Password</h3>
    </div>
    <div class="site-security-settings-contents">
      <form
        class="site-security-settings-contents-items-inner"
        v-on:submit.prevent="changePassword"
      >
        <div class="form-group mb-5">
          <div
            class="verifyInputs"
            style="margin-top: 10px; position: relative"
          >
            <label> Present Password </label>

            <input
              :type="showPassword ? 'text' : 'password'"
              placeholder="Your password here "
              v-model.trim="password"
              @input="validateForm"
            />
            <i
              class="bx bx-show-alt iconnAbs"
              style="cursor: pointer"
              v-if="!showPassword"
              @click="togglePasword"
            ></i>
            <i
              class="bx bx-hide iconnAbs"
              v-if="showPassword"
              style="cursor: pointer"
              @click="togglePasword"
            ></i>
          </div>

          <span class="form-settings-warning" v-if="passwordErrorMessage"
            >{{ passwordErrorMessage }}
          </span>
        </div>
        <div class="form-group mb-5">
          <div
            class="verifyInputs"
            style="margin-top: 10px; position: relative"
          >
            <label> New Password </label>

            <input
              @input="validateForm"
              :type="shownPassword ? 'text' : 'password'"
              v-model.trim="newPassword"
              placeholder="Your new password "
            />
            <i
              class="bx bx-show-alt iconnAbs"
              style="cursor: pointer"
              v-if="!shownPassword"
              @click="toggleNewPasword"
            ></i>
            <i
              class="bx bx-hide iconnAbs"
              v-if="shownPassword"
              style="cursor: pointer"
              @click="toggleNewPasword"
            ></i>
          </div>

          <span class="form-settings-warning" v-if="newPasswordErrorMessage"
            >{{ newPasswordErrorMessage }}
          </span>
        </div>
        <div class="form-group mb-5">
          <div
            class="verifyInputs"
            style="margin-top: 10px; position: relative"
          >
            <label> Confirm Password </label>

            <input
              :type="showCPassword ? 'text' : 'password'"
              placeholder="Your new password "
              v-model.trim="confirmPassword"
              @input="validateForm"
            />
            <i
              class="bx bx-show-alt iconnAbs"
              style="cursor: pointer"
              v-if="!showCPassword"
              @click="toggleConfirmPasword"
            ></i>
            <i
              class="bx bx-hide iconnAbs"
              v-if="showCPassword"
              style="cursor: pointer"
              @click="toggleConfirmPasword"
            ></i>
          </div>
          <span class="form-settings-warning" v-if="confirmPasswordErrorMessage"
            >{{ confirmPasswordErrorMessage }}
          </span>
        </div>
        <div class="form-group mb-5">
          <button class="relsify-button" :disabled="emptyFields">
            <span v-if="!loading">Submit</span>
            <span class="loader" v-if="loading"></span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
export default {
  components: {},
  data() {
    return {
      password: "",
      confirmPassword: "",
      newPassword: "",
      showPassword: false,
      shownPassword: false,
      showCPassword: false,
      emptyFields: true,
      loading: false,
      passwordErrorMessage: "",
      confirmPasswordErrorMessage: "",
      newPasswordErrorMessage: "",
      formMessage: "",
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "getUserProfile"]),
    togglePasword: function () {
      this.showPassword = !this.showPassword;
      // this.validateForm();
    },
    toggleNewPasword: function () {
      this.shownPassword = !this.shownPassword;
      // this.validateForm();
    },

    toggleConfirmPasword: function () {
      this.showCPassword = !this.showCPassword;
      // this.validateForm();
    },

    validateForm: function () {
      if (!this.password) {
        this.emptyFields = true;
        this.passwordErrorMessage = "Fill out this fields";
      } else {
        this.passwordErrorMessage = "";
      }

      if (!this.newPassword) {
        this.emptyFields = true;
        this.newPasswordErrorMessage = "Fill out this fields";
      } else {
        this.newPasswordErrorMessage = "";
      }
      if (this.newPassword && this.newPassword.length < 8) {
        this.emptyFields = true;
        this.newPasswordErrorMessage = "new password must be 8 characters";
      } else {
        this.newPasswordErrorMessage = "";
      }

      if (!this.confirmPassword) {
        this.emptyFields = true;
        this.confirmPasswordErrorMessage = "Fill out this fields";
      } else {
        this.confirmPasswordErrorMessage = "";
      }
      if (this.confirmPassword && this.confirmPassword.length < 8) {
        this.emptyFields = true;
        this.confirmPasswordErrorMessage =
          "confirm password must be 8 characters";
      } else {
        this.confirmPasswordErrorMessage = "";
      }

      if (
        this.newPassword &&
        this.confirmPassword &&
        this.newPassword !== this.confirmPassword
      ) {
        this.emptyFields = true;
        this.confirmPasswordErrorMessage = "password do not match";
        return false;
      }

      if (!this.password || !this.newPassword || !this.confirmPassword) {
        this.emptyFields = true;
        return false;
      }

      this.passwordErrorMessage = "";
      this.newPasswordErrorMessage = "";
      this.confirmPasswordErrorMessage = "";
      this.emptyFields = false;
      return true;
    },

    changePassword: function () {
      if (!this.password || !this.newPassword || !this.confirmPassword) {
        this.emptyFields = true;
        return false;
      }

      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/change-password`;
      let payload = {
        oldPassword: this.password,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      };

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          // console.log(data);
          if (!data.error) {
            this.globalFunction();
            this.clearForm();
            this.setAlertpopUp({
              status: true,
              title: "Password change successful",
              des: data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Password change Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Password change Error",
            des: "An Error occured while trying to change password",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },

    clearForm: function () {
      this.password = "";
      this.confirmPassword = "";
      this.newPassword = "";
      this.showPassword = false;
      this.shownPassword = false;
      this.showCPassword = false;
      this.emptyFields = true;
      this.loading = false;
      this.passwordErrorMessage = "";
      this.confirmPasswordErrorMessage = "";
      this.newPasswordErrorMessage = "";
      this.formMessage = "";
    },
  },
};
</script>

<style scoped>
.iconnAbs {
  position: absolute;
  top: 60%;
  right: 20px;
}

.form-settings-warning {
  color: rgb(197, 0, 0);
}
</style>
