<template>
  <tr class="table_roww" v-if="transaction">
    <td>
      <p>
        {{ formateDateAndTime(transaction.createdAt) }}
      </p>
    </td>
    <td>
      <!-- {{ transaction.coin.symbol }} -->
      <div class="td-with-image" style="cursor: pointer">
        <img
          v-if="transaction.coin.iconUrl"
          style="border-radius: 100%; height: 20px"
          :src="transaction.coin.iconUrl"
          alt=""
        />
        <span>{{ transaction.coin.name }}</span>
      </div>
    </td>
    <td>{{ formatAmount(transaction.amount) }}</td>
    <td>{{ transaction.status }}</td>
    <td>{{ transaction.networkName }}</td>
    <td>{{ transaction.hash }}</td>
    <td>{{ transaction.address }}</td>
    <td class="table-button" style="text-align: right">
      <button
        class="relsify-button light-shade-green"
        @click="goToTransactionDetails(transaction)"
      >
        Details
      </button>
    </td>
  </tr>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["transaction"],
  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
    goToTransactionDetails: function (transaction) {
      this.$router.push({
        name: "SingleTransaction",
        query: { id: transaction.id },
      });
    },
  },
};
</script>

<style scoped>
.table_roww td {
  padding: 40px 10px;
  background: #fff;
}
.table_roww td p {
  display: inline;
  width: auto;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
</style>
