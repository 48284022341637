<template>
  <div class="relsify-notification-item" v-if="activity">
    <!-- <div class="relsify-notification-item-icon">
      <input
        type="checkbox"
        v-model="clickedActivity"
        @change="toggleActivity(activity)"
      />
    </div> -->
    <div class="relsify-notification-item-title" style="cursor: pointer">
      <span>{{ formateDateAndTimeByName(activity.date) }}</span>
      <p v-if="!readMore">
        {{ activity.message.slice(0, 110) }}
        <span
          style="color: var(--mainGreenColor)"
          v-if="activity.message.length > 110"
          @click="toggleReadMore"
          >... <b>read more</b></span
        >
      </p>
      <p v-if="readMore">
        {{ activity.message }}
        <span
          @click="toggleReadMore"
          style="color: var(--mainGreenColor); display: block"
          ><b>show less</b></span
        >
      </p>
    </div>
  </div>
</template>

<script>
import "@/mixins";

export default {
  props: ["activity"],

  computed: {
    selectedActivities: {
      get() {
        return this.$store.state.selectedActivities;
      },
      set(value) {
        this.$store.state.selectedActivities = value;
      },
    },
  },

  data() {
    return {
      clickedActivity: false,
      readMore: false,
    };
  },

  methods: {
    goToDetailsPage: function (activity) {
      this.selectedActivities = [];
      this.$router.push({
        name: "ActivityDetails",
        query: { id: activity.id },
      });
    },
    toggleReadMore: function () {
      this.readMore = !this.readMore;
    },

    toggleActivity: function (newActivity) {
      if (this.clickedActivity) {
        this.selectedActivities = [...this.selectedActivities, newActivity];
      } else {
        this.selectedActivities = this.selectedActivities.filter(
          (activityy) => activityy.date !== newActivity.date
        );
      }

      // console.log(newActivity);
      // console.log(this.selectedActivities);
    },
  },
};
</script>

<style scoped>
.relsify-notification-item {
  justify-content: flex-start;
}

.relsify-notification-item input[type="checkbox"] {
  width: 19px !important;
  height: 19px !important;
  margin-right: 20px;
  cursor: pointer;
}
@media screen and (max-width: 580px) {
  .relsify-notification-items-div .relsify-notification-item {
    position: relative;
    padding: 10px 20px 20px;
    /* display: block; */
  }

  .relsify-notification-items-div
    .relsify-notification-item
    .relsify-notification-item-icon {
    position: absolute;
    top: 0px;
    right: 10px;
  }
  .relsify-notification-items-div
    .relsify-notification-item
    .relsify-notification-item-title {
    margin-right: 0px;
  }
  .relsify-notification-items-div
    .relsify-notification-item
    .relsify-notification-item-title
    p {
    margin-top: 36px;
  }

  .relsify-notification-item input[type="checkbox"] {
    width: 18px !important;
    height: 18px !important;
    margin-right: 0px;
  }
}
</style>
