<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <WalletHeader />

    <div class="small_screen_effect_inner wallet_balance_page">
      <div class="row">
        <div class="col-xl-12">
          <div class="page-tab-content">
            <div class="relsify-tab-pane active" id="WalletDetails">
              <WalletDetails />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import WalletHeader from "./WalletHeader.vue";
import WalletDetails from "@/components/wallet/WalletDetails.vue";

export default {
  components: {
    WalletHeader,
    WalletDetails,
  },

  methods: {
    ...mapActions(["setCurrentPageName", ""]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>
