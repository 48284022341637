<template>
  <div class="select-modal" v-if="selectCountryModal.status">
    <!-- hideModal -->

    <div class="select-modal-card vivify popInBottom">
      <div class="close-select-button" @click="closeSelectCountryModal">
        <i class="bx bx-x" id="closeToken"></i>
      </div>

      <div class="select-modal-search">
        <label for="">Please Select Country</label>
        <div class="relsify-search">
          <i class="bx bx-search-alt-2"></i>
          <input type="search" v-model="searchText" placeholder="Search" />
        </div>
      </div>

      <div class="select-modal-body">
        <div
          class="select-modal-content"
          v-if="allCountries && allCountries.length"
        >
          <div style="width: 100%">
            <div
              class="select-modal-item"
              :class="
                country.isoCode.toLowerCase() ==
                  selectedCountry.isoCode.toLowerCase() && 'selected'
              "
              v-for="(country, index) in allCountries"
              :key="index"
              @click="selectNewCountry(country)"
            >
              <p>
                <img v-if="country.flag" :src="country.flag" alt="" />
                <span>{{ country.isoCode }}</span>
                <small>{{ country.name }}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["selectCountryModal", "allCoins"]),
    countries() {
      return this.$store.state.countries;
    },
    allCountries() {
      let allCountries = null;

      if (this.countries && this.countries.length > 0) {
        allCountries = this.countries.filter(
          (country) =>
            country.name.toLowerCase().match(this.searchText.toLowerCase()) ||
            country.isoCode.toLowerCase().match(this.searchText.toLowerCase())
        );
      }

      return allCountries;
    },

    selectedCountry: {
      get() {
        return this.$store.state.selectedCountry;
      },
      set(value) {
        return (this.$store.state.selectedCountry = value);
      },
    },
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    ...mapActions(["setSelectCountryModal"]),
    closeSelectCountryModal: function () {
      this.setSelectCountryModal({
        status: false,
        title: "",
        type: "",
        payload: null,
      });
      this.searchText = "";
    },
    selectNewCountry: function (country) {
      // console.log(country);
      this.selectedCountry = country;
      this.closeSelectCountryModal();
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .select-modal-body {
    height: 100% !important;
  }
  .select-modal-card {
    width: 100%;
    height: 100%;
    padding: 17px 19px;
    border-radius: 0px;
  }

  .select-modal .select-modal-search label {
    font-size: 15px;
  }
  .select-modal-item img {
    border-radius: 50px;
  }

  .select-modal-item p img {
    width: 35px;
  }

  .select-modal-item p span {
    font-size: 19px;
  }

  .select-modal-item p small {
    font-size: 11px;
  }
}
</style>
