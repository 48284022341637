<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Trade</h2>
          </div>
          <router-link to="trade-history" class="page-breadcrum">
            <p>Trade History</p>
            <i class="bx bx-right-arrow-alt"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="after-page-header" v-if="currentProject">
      <TradePageTab />

      <div>
        <div class="text-center" v-if="showSellNotification">
          <div class="notification-alert notification-alert-warning">
            <div class="notification-alert-item">
              <img src="@/assets/vectors/img/warning-error.png" alt="" />
              <!-- <p>
                <b>Note:</b> You’re selling off your share of
                {{ capitalizeFirstLetter(sellingProject.coin.name) }}. once you
                do, your choice Crypto goes back to your wallet not into another
                property investment. If what you meant was to swap assets,
                please go to the swap tab to swap your invsetment.
              </p> -->
              <p>
                <b>Note:</b> You’re selling off your share of
                {{ capitalizeFirstLetter(sellingProject.title) }}. once you do,
                your choice Crypto goes back to your wallet not into another
                property investment. If what you meant was to swap assets,
                please go to the swap tab to swap your invsetment.
              </p>

              <i
                class="bx bx-x closeNotificationAlert"
                @click="showSellNotification = false"
              ></i>
            </div>
          </div>
        </div>
        <div class="page-card">
          <div class="row">
            <div class="mx-auto mt-4 sell-07-column">
              <div class="page-inner-content-grid one-grid">
                <div class="page-inner-content-grid-item">
                  <label
                    for=""
                    style="
                      font-weight: 500;
                      font-size: 13px;
                      line-height: 23px;
                      color: var(--mainGreenColor);
                    "
                    >Select asset To sell</label
                  >
                  <div class="project-coins">
                    <div class="project-coins-left">
                      <!-- <h5>Project Coin</h5> -->
                      <h5>Project</h5>
                      <p>
                        <img
                          v-if="currentProject.coin.iconUrl"
                          :src="currentProject.coin.iconUrl"
                          alt=""
                          style="
                            border-radius: 100%;
                            height: 20px !important;
                            object-fit: cover;
                          "
                        />
                        <!-- <span>{{ currentProject.coin.name }}</span> -->
                        <span>{{ currentProject.title }}</span>
                      </p>
                    </div>
                    <div class="project-coins-right">
                      <button
                        class="light-shade-green"
                        id="changeProjject"
                        @click="updateCurrentPageType"
                      >
                        Change Project
                      </button>
                    </div>
                  </div>

                  <div
                    class="text-center"
                    style="width: 100%"
                    v-if="currentProject.selectedToken"
                  >
                    <div class="select-button width-100 text-left">
                      <label for="">Select Asset To Receive</label>
                      <div
                        class="select-input select-input-input"
                        @click="
                          showSelectProjectModal(
                            currentProject.tradeInfo.allowedCoins
                          )
                        "
                      >
                        <div>
                          <img
                            v-if="currentProject.selectedToken.iconUrl"
                            :src="currentProject.selectedToken.iconUrl"
                            alt=""
                            style="
                              border-radius: 100%;
                              height: 20px !important;
                              object-fit: cover;
                            "
                          />
                          <input
                            type="text"
                            :value="currentProject.selectedToken.symbol"
                            disabled
                          />
                        </div>
                        <i class="bx bx-chevron-down" id="selectToken"></i>
                      </div>
                    </div>
                  </div>

                  <div
                    class="select-button-notes"
                    v-if="
                      currentProject &&
                      currentAmountBaseOnCurrentProject !== null
                    "
                  >
                    <div class="select-button-notes-content bg-green">
                      <img
                        src="@/assets/vectors/img/wallet-xm.png"
                        width="10px"
                        alt=""
                      />
                      <p>
                        You have
                        <!-- {{ userBalance ? userBalance.amount : 0 }} -->
                        {{ formatAmount(currentAmountBaseOnCurrentProject) }}
                        {{ currentProject.coin.symbol }} unit(s) in your wallet
                      </p>
                    </div>
                  </div>

                  <div class="select-button-notes">
                    <div class="select-button-notes-content bg-yellow-light">
                      <p>
                        Note : 1
                        {{
                          // currentProject.coin.
                          " unit "
                        }}
                        =
                        {{
                          formatAmount(sellingPrice.price) +
                          " " +
                          sellingPrice.symbol
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="counter-button-grid">
                    <div class="counter-button-grid-item">
                      <label for=""
                        >Amount of {{ currentProject.coin.symbol }}
                      </label>
                      <div class="counter-button">
                        <i class="bx bx-minus" @click="decreaseAmount"></i>
                        <input
                          type="text"
                          style="width: 100% !important; max-width: 70%"
                          v-model="amount"
                          @input="calculateAmount"
                        />
                        <i class="bx bx-plus" @click="increaseAmount"></i>
                      </div>
                    </div>
                    <div class="counter-button-grid-item">
                      <label for=""
                        >{{ sellingPrice.symbol }} Equivalent
                      </label>
                      <div class="counter-button">
                        <div class="flex_counter_button">
                          <!-- <span>$</span> -->
                          <input
                            style="width: 100% !important; max-width: 100%"
                            type="text"
                            v-model="equivalentAmount"
                            @input="calculateEquivalent"
                          />
                        </div>
                        <!-- <input
                          style="width: 100% !important; max-width: 100%"
                          type="text"
                          v-model="equivalentAmount"
                          @input="calculateEquivalent"
                        /> -->
                      </div>
                    </div>
                    <br />
                  </div>

                  <div class="accpted-terms" v-if="!acceptTerms">
                    <div class="relsify-checkbox">
                      <input
                        type="checkbox"
                        v-model="acceptTerms"
                        @change="toggleTerms"
                      />
                      <p>
                        I certify that I have read, understand and accept
                        Finnacle’s
                        <a href="" class="green_color">Terms & Conditions</a>
                      </p>
                    </div>
                  </div>

                  <div class="select-counter-button">
                    <button
                      class="relsify-button"
                      @click="sellAssets"
                      :disabled="emptyFields"
                    >
                      Sell Asset
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="after-page-header" v-else>
      <div
        class="d-flex justify-content-center align-items-center"
        style="background: #fff"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <LoadingComponent />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import TradePageTab from "@/components/trade/TradePageTab.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
import Decimal from "decimal.js";
export default {
  components: {
    TradePageTab,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["projects", "userWalletBalance", "portfolios"]),
    sellingProject() {
      let project = this.projects ? this.projects[0] : {};
      project.selectedToken =
        this.projects && this.projects[0].tradeInfo.allowedCoins[0];
      if (this.currentProject && this.portfolios) {
        project = this.currentProject;
        project.selectedToken = this.currentProject.tradeInfo.allowedCoins[0];
        // console.log(project);
      }

      // console.log(project);

      return project;
    },

    currentAmountBaseOnCurrentProject() {
      let amount = null;
      if (this.currentProject && this.portfolios && this.portfolios.length) {
        let projectAmount = this.portfolios.find(
          (projectName) =>
            projectName.balance.coin.symbol == this.currentProject.coin.symbol
        );

        // console.log(projectAmount);
        if (projectAmount) {
          amount = projectAmount.balance.usdValue;
        }
      }

      return amount;
    },
    currentPageType: {
      get() {
        return this.$store.state.currentPageType;
      },
      set(value) {
        return (this.$store.state.currentPageType = value);
      },
    },

    userBalance() {
      let balance = null;
      if (
        this.userWalletBalance &&
        this.userWalletBalance.length &&
        this.currentProject
      ) {
        balance = this.userWalletBalance
          .slice(0)
          .find(
            (balance) =>
              balance.coin.coinId == this.currentProject.selectedToken.coinId
          );
        // console.log(balance);
      }

      return balance;
    },

    sellingPrice() {
      let price = null;
      if (this.currentProject) {
        price = this.currentProject.tradeInfo.sellingPrices
          .slice(0)
          .find(
            (price) => price.symbol == this.currentProject.selectedToken.symbol
          );

        if (price) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.amount = 1;
          // this.amount =
          //   Math.round(
          //     (new Decimal(
          //       this.currentProject.tradeInfo.minimumBuyingAmount
          //     ).toFixed(2) -
          //       price.price) *
          //       1e12
          //   ) / 1e12;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.equivalentAmount = new Decimal(
            new Decimal(this.currentProject.tradeInfo.minimumBuyingAmount)
          )
            .times(price.price)
            .toFixed(2);
        }
        // console.log(price);
      }

      return price;
    },

    amount: {
      get() {
        return this.$store.state.amount;
      },
      set(value) {
        return (this.$store.state.amount = value);
      },
    },
    equivalentAmount: {
      get() {
        return this.$store.state.equivalentAmount;
      },
      set(value) {
        return (this.$store.state.equivalentAmount = value);
      },
    },

    acceptTerms: {
      get() {
        console.log(this.$store.state.acceptTerms);
        return this.$store.state.acceptTerms;
      },
      set(value) {
        this.$store.state.acceptTerms = value;
        return this.$store.state.acceptTerms;
      },
    },
  },

  data() {
    return {
      showSellNotification: true,
      totalAmount: 10,
      emptyFields: true,
      loading: false,
    };
  },

  methods: {
    ...mapActions([
      "setCurrentPageName",
      "setCurrentProject",
      "setSelectProjectModal",
      "getSingleProject",
      "setPageLoading",
      "setAlertpopUp",
    ]),

    toggleTerms: function () {
      // Should not be so, but it works, so I'll leave it for now
      // an error from somehwre in the code is causing the checkbox to be checked
      const x = this.acceptTerms;
      this.acceptTerms = x;

      if (this.acceptTerms) {
        localStorage.setItem("axxeptTerms", true);
      }
      this.validateForm();
    },
    updateCurrentPageType: function () {
      this.currentPageType = this.$router.currentRoute._rawValue.name;
      this.showMarketPlace();
    },

    showSelectProjectModal: function (networks) {
      this.setSelectProjectModal({
        status: true,
        title: "",
        desc: "",
        payload: networks,
        select1Type: "",
        select2Type: "",
        select3Type: "",
        select4Type: "",
        select5Type: "",
        select6Type: "",
        type: "SELL_PROJECT",
      });
      // console.log(networks);
    },

    decreaseAmount: function () {
      if (this.amount == 1) {
        return;
      }

      this.amount = --this.amount;
      this.calculateAmount();
    },

    increaseAmount: function () {
      this.amount = ++this.amount;
      this.calculateAmount();
    },

    calculateAmount: function () {
      if (!this.amount) {
        // this.amount = 1;
        return;
      }
      // this.equivalentAmount =
      //   Math.round((this.amount + this.sellingPrice.price) * 1e12) / 1e12;
      // console.log(this.amount);
      // console.log(this.sellingPrice.price);
      // console.log(this.equivalentAmount);
      if (this.sellingPrice) {
        this.equivalentAmount = new Decimal(this.amount)
          .times(this.sellingPrice.price)
          .toFixed(
            this.calculatePrecisedDecimalPlaces(this.sellingPrice.price)
          );
      }

      //   .toFixed(2)

      this.validateForm();
    },

    calculateEquivalent: function () {
      if (!this.equivalentAmount) {
        // this.equivalentAmount = 0;
        return;
      }
      // this.amount =
      //   Math.round((this.amount - this.sellingPrice.price) * 1e12) / 1e12;

      this.amount = new Decimal(this.equivalentAmount)
        .dividedBy(this.sellingPrice.price)
        .toFixed(2);
      // new Decimal(this.equivalentAmount).dividedBy(
      //   this.sellingPrice.price
      // );

      this.validateForm();
    },

    validateForm: function () {
      if (!this.amount || !this.acceptTerms) {
        this.emptyFields = true;
        return false;
      }

      this.emptyFields = false;
      return true;
    },

    checkRouterId: function () {
      if (this.$route.query.id) {
        this.getSingleProject(this.$route.query.id);
      } else {
        setTimeout(() => {
          if (this.projects) {
            // console.log(this.projects);
            let project = this.projects[0];
            this.getSingleProject(project.id);
          }
        }, 2000);
      }
    },

    sellAssets: function () {
      let url = `${this.RELSIFY_URL}/api/sell/project`;
      this.setPageLoading(true);
      this.emptyFields = true;

      // const token = sessionStorage.getItem("Appoen");

      let newPayload = {
        projectId: this.currentProject.id,
        coin: this.currentProject.selectedToken.id,
        amount: this.amount,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setPageLoading(false);
          // console.log(data.data);
          if (!data.error) {
            // console.log(data);
            this.resetFields();

            // this.setAlertpopUp({
            //   status: true,
            //   title: " Successful",
            //   des: data.meta.message,
            //   type: "success",
            //   payload: null,
            // });

            this.globalFunction();
            this.setAlertMessageModal({
              status: true,
              title: "Successful",
              des: data.meta.message,
              button1Text: "Portfolio",
              button1Url: "/portfolio",
              button2: true,
              button2Text: "Sell more",
              button2Url: "SELL",
              type: "SELL",
              payload: null,
            });

            setTimeout(() => {
              location.reload();
            }, 2000);

            this.loading = false;
          } else {
            this.setAlertpopUp({
              status: true,
              title: " Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch((error) => {
          this.setPageLoading(false);
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });

          error;
          // console.log(error);
        });
    },

    resetFields() {
      this.$store.state.currentProject = null;
      this.showBuyNotification = true;
      this.totalAmount = 10;
      this.emptyFields = true;
      this.loading = false;
      this.acceptTerms = false;
    },
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    this.checkRouterId();
  },

  beforeRouteLeave(to, from, next) {
    this.$store.state.currentPageType = null;
    next();
  },

  watch: {
    "$route.query.id": function () {
      this.resetFields();
      this.checkRouterId();
      this.validateForm();

      this.calculateAmount();
    },
    // amount: function () {
    //   this.calculateAmount();
    // },
    // equivalentAmount: function () {
    //   this.calculateEquivalent();
    // },
  },
};
</script>
