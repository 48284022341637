<template>
  <div class="page-card" style="background: transparent !important">
    <div
      v-if="!loading"
      class="page-inner-content-grid two-grid"
      style="background: #fff !important"
    >
      <div class="relsify-deposit-types">
        <button
          v-if="currentCurrencyType !== 'Crypto'"
          class="relsify-button"
          :class="currentDepositType === 'Transfer' && 'active'"
          type="button"
          @click.prevent="changeDepositType('Transfer')"
        >
          Bank Transfer
        </button>
        <!-- <button
          class="relsify-button"
          type="button"
          :class="currentDepositType === 'Card' && 'active'"
          @click.prevent="changeDepositType('Card')"
        >
          Card
        </button> -->
        <!-- <button
          class="relsify-button"
          type="button"
          :class="currentDepositType === 'Link' && 'active'"
          @click.prevent="changeDepositType('Link')"
        >
          Get a link
        </button> -->
      </div>
      <div
        class="deposit-type-content"
        v-if="currentDepositType === 'Transfer'"
      >
        <div class="row">
          <div
            v-if="!currentDepositAccount?.reference"
            class="col-md-9 mx-auto"
            style="max-width: 500px !important; width: 100% !important"
          >
            <div class="text-center" style="width: 100%">
              <div class="select-button width-100 text-left">
                <label for="">Select Token To Deposit</label>
                <div
                  class="select-input select-input-input"
                  style="cursor: pointer !important"
                  role="button"
                  tabindex="0"
                  @click="showSelectProjectModal(currentCoin)"
                >
                  <div style="cursor: pointer !important">
                    <img
                      style="cursor: pointer !important"
                      :src="currentCoin?.coin?.iconUrl || currentCoin?.iconUrl"
                      alt=""
                    />
                    <input
                      type="text"
                      style="cursor: pointer !important; pointer-events: none"
                      disabled
                      :value="currentCoin?.coin?.symbol || currentCoin?.symbol"
                      placeholder="Select token to deposit"
                    />
                  </div>
                  <i class="bx bx-chevron-down" id="selectToken"></i>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Enter Amount </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="number"
                      v-model="amount"
                      @input="validateForm"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>
            <div class="select-counter-button">
              <button
                class="relsify-button"
                :disabled="emptyFields"
                @click="getAccount"
              >
                <span v-if="!loading">Request bank details</span>
                <span class="loader" v-if="loading"></span>
              </button>
            </div>
          </div>
          <div v-if="currentDepositAccount?.reference" class="col-md-9 mx-auto">
            <div class="account-container">
              <p class="bank-name">{{ currentDepositAccount.bankName }}</p>
              <p class="acc-no">{{ currentDepositAccount.accountNumber }}</p>
              <!-- Changed to total, thats the total amount to be paid-->
              <p class="acc-no">{{ "₦ " + currentDepositAccount.total }}</p>
              <p class="desc">
                Use this account for this transaction only, account expires in
                <span>{{ accountExpiryTime ? accountExpiryTime : "..." }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="deposit-type-content" v-if="currentDepositType === 'Card'">
        <div class="row">
          <div
            class="col-md-9 mx-auto"
            style="max-width: 500px !important; width: 100% !important"
          >
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Currency</label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="text"
                      v-model="currency"
                      @input="validateForm"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Amount: </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="text"
                      v-model="amount"
                      @input="validateForm"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Card Number: </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="text"
                      v-model="cardNumber"
                      @input="validateForm"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>
            <div class="page-inner-content-grid two-grid">
              <div class="text-center">
                <div class="select-button text-left width-100">
                  <label for="">Exp: </label>
                  <div class="select-input select-input-single">
                    <div>
                      <input
                        type="text"
                        v-model="cardExp"
                        @input="validateForm"
                        placeholder="Enter amount here"
                      />
                    </div>
                    <!-- <i class="bx bx-chevron-down"></i> -->
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="select-button text-left width-100">
                  <label for="">CVV: </label>
                  <div class="select-input select-input-single">
                    <div>
                      <input
                        type="text"
                        v-model="cardCVV"
                        @input="validateForm"
                        placeholder="Enter amount here"
                      />
                    </div>
                    <!-- <i class="bx bx-chevron-down"></i> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="select-counter-button">
              <button
                class="relsify-button"
                :disabled="emptyFields"
                @click="submit"
              >
                <span v-if="!loading">Pay</span>
                <span class="loader" v-if="loading"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="deposit-type-content" v-if="currentDepositType === 'Link'">
        <div class="row">
          <div
            v-if="!currentDepositLink?.id"
            class="col-md-9 mx-auto"
            style="max-width: 500px !important; width: 100% !important"
          >
            <div class="text-center" style="width: 100%">
              <div class="select-button width-100 text-left">
                <label for="">Select Token To Deposit</label>
                <div
                  class="select-input select-input-input"
                  style="cursor: pointer !important"
                  @click="showSelectProjectModal(currentCoin)"
                >
                  <div style="cursor: pointer !important">
                    <img
                      style="cursor: pointer !important"
                      :src="currentCoin?.iconUrl"
                      alt=""
                    />
                    <input
                      type="text"
                      style="cursor: pointer !important"
                      disabled
                      :value="currentCoin?.symbol"
                    />
                  </div>
                  <i class="bx bx-chevron-down" id="selectToken"></i>
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Enter Amount </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="number"
                      v-model="amount"
                      @input="validateForm"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>
            <div class="select-counter-button">
              <button
                class="relsify-button"
                :disabled="emptyFields"
                @click="getLink"
              >
                <span v-if="!loading">Request link</span>
                <span class="loader" v-if="loading"></span>
              </button>
            </div>
          </div>
          <div v-if="currentDepositLink?.id" class="col-md-9 mx-auto">
            <div class="link-container">
              <p>Payment Link</p>
              <div class="relsify-button light-shade-green">
                <p>{{ currentDepositLink.url }}</p>
                <a :href="currentDepositLink.url" target="_blank"
                  ><i
                    class="bx bx-link-external"
                    style="font-size: 20px !important"
                  ></i
                ></a>
                <!-- <i class="bx bxs-copy" style="font-size: 20px !important"></i> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="loading"
      class="page-inner-content-grid"
      style="background: #fff !important; justify-items: center"
    >
      <LoadingComponent />
    </div>
  </div>
</template>
<script>
// import "@/mixins"
import { mapGetters, mapActions } from "vuex";
import LoadingComponent from "../../loading/LoadingComponent.vue";
import moment from "moment";

export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters([
      "userWalletBalance",
      "allCoins",
      "currentCurrencyType",
      "userVerificationStatus",
    ]),
    currentCoin: {
      get() {
        return this.$store.state.currentCoin;
      },
      set(value) {
        return (this.$store.state.currentCoin = value);
      },
    },
    currentDepositLink: {
      get() {
        return this.$store.state.currentDepositLink;
      },
      set(value) {
        return (this.$store.state.currentDepositLink = value);
      },
    },
    currentDepositAccount: {
      get() {
        return this.$store.state.currentDepositAccount;
      },
      set(value) {
        return (this.$store.state.currentDepositAccount = value);
      },
    },
    currentDepositType: {
      get() {
        return this.$store.state.depositType;
      },
      set(value) {
        return (this.$store.state.depositType = value);
      },
    },
    currentCoinSymbol: {
      get() {
        return this.currentCoin?.symbol;
      },
      set(value) {
        this.currentCoin = this.userWalletBalance.find(
          (balance) => balance.coin.symbol == value
        ).coin;
      },
    },
    currentCurrencyType: {
      get() {
        return this.$store.state.currentCurrencyType;
      },
      set(value) {
        return (this.$store.state.currentCurrencyType = value);
      },
    },
    filteredCoins: {
      get() {
        if (this.allCoins?.length) {
          return this.allCoins.filter((coin) =>
            String(coin.type).match(this.currentCurrencyType.toLowerCase())
          );
        }
        return null;
      },
      set() {},
    },
    accountExpiryTime: {
      get() {
        return this.expirationTime;
      },
      set(value) {
        return (this.expirationTime = value);
      },
    },
    emptyFields() {
      if (this.$route.query.code && this.amount && this.currentCoin) {
        return false;
      }

      if (
        (["Fiat", "Crypto"].includes(this.currentCurrencyType) &&
          !this.amount) ||
        !this.currentCoinSymbol
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      // cardDetails: reactive({

      // })
      // currency: '',
      amount: "",
      // cardNumber: '',
      // cardExp: '',
      // cardCVV: '',
      id: "",
      depositLink: "",
      loading: false,
      expirationTime: "",
    };
  },
  watch: {
    currentCurrencyType() {
      this.currentCoin = null;
      this.amount = null;
    },
    currentDepositType() {
      if (this.currentDepositType === "Link") {
        this.currentDepositAccount = null;
      } else {
        this.currentDepositLink = null;
      }
    },
  },
  methods: {
    ...mapActions([
      "setAlertpopUp",
      "getDepositAccount",
      "getAllCoins",
      "getDepositLink",
      "setSelectProjectModal",
    ]),
    expirationTimer() {
      const expiryDate = new Date(
        this.currentDepositAccount.expiryDate
      ).getTime();
      const countdownTimer = setInterval(() => {
        const now = new Date().getTime();
        const margin = expiryDate - now;
        if (moment.duration(margin).humanize(true) !== "a few seconds ago") {
          this.accountExpiryTime = moment
            .duration(margin)
            .humanize(true)
            .replace("in", "");
        } else {
          this.accountExpiryTime = "Expired";
          clearInterval(countdownTimer);
        }
      }, 1000);
    },
    changeDepositType(type) {
      this.currentDepositType = type;
      this.amount = null;
      this.currentCoin = null;
    },
    getSingleCoin(id) {
      this.loading = true;
      setTimeout(() => {
        if (this.userWalletBalance && this.userWalletBalance.length) {
          this.currentCoin = this.userWalletBalance.find(
            (balance) => balance.coin.id == id
          );
          this.currentCurrencyType =
            this.currentCoin.type.charAt(0).toUpperCase() +
            this.currentCoin.type.slice(1);
          if (this.currentCurrencyType === "Crypto") {
            this.currentDepositType = "Link";
          } else {
            this.currentDepositType = "Transfer";
          }
          this.loading = false;
        } else {
          this.getSingleBalance(id);
        }
      }, 2000);
    },

    showSelectProjectModal: function (networks) {
      if (!this.$route.query.code) {
        this.setSelectProjectModal({
          status: true,
          title: "",
          desc: "",
          payload: networks,
          select1Type: "",
          select2Type: "",
          select3Type: "",
          select4Type: "",
          select5Type: "",
          select6Type: "",
          type: "WALLET_DEPOSIT",
        });
      }
      return;
    },
    async getAccount() {
      this.loading = true;

      // console.log("heyyyy", this.currentCoin?.symbol);
      // check if user has passed level2verfication
      if (
        this.userVerificationStatus?.level2?.status === "done" &&
        this.currentCoin?.symbol === "NGN" &&
        this.userVerificationStatus?.level1?.phoneNumberVerified
      ) {
        try {
          const res = await this.getDepositAccount({
            coin: this.currentCoinSymbol,
            amount: this.amount,
          });
          if (!res.data.error) {
            this.loading = false;
            this.expirationTimer();
          }
        } catch (error) {
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: error.message || "An Error occured",
            type: "error",
            payload: null,
          });
        }
      } else {
        if (
          ((this.currentCoin?.symbol && this.currentCoin?.symbol === "NGN") ||
            (this.currentCoin?.coin?.symbol &&
              this.currentCoin?.coin?.symbol === "NGN")) &&
          !this.userVerificationStatus?.level1?.phoneNumberVerified
        ) {
          this.$router.push({
            name: "AddPhone",
          });
          this.setAlertpopUp({
            status: true,
            title: "Verification Required",
            des: "Please add your phone number",
            type: "error",
            payload: null,
          });
        } else {
          this.$router.push({
            name: "SiteVerificationLevel2",
          });
          this.setAlertpopUp({
            status: true,
            title: "Verification Required",
            des: "Please complete level 2 verification",
            type: "error",
            payload: null,
          });
        }
      }
    },
    async getLink() {
      this.loading = true;

      try {
        const res = await this.getDepositLink({
          coin: this.currentCoinSymbol,
          amount: this.amount,
        });
        if (!res.data.error) {
          this.loading = false;
        }
      } catch (error) {
        this.setAlertpopUp({
          status: true,
          title: "Server Error",
          des: error.message || "An Error occured",
          type: "error",
          payload: null,
        });
      }
    },
    async getCoins() {
      this.loading = true;

      try {
        const res = await this.getAllCoins();
        if (!res.data.error) {
          this.loading = false;
        }
      } catch (error) {
        this.setAlertpopUp({
          status: true,
          title: "Server Error",
          des: error.message || "An Error occured",
          type: "error",
          payload: null,
        });
      }
    },
  },
  mounted() {
    this.getCoins();
    if (this.$route.query.code) {
      this.getSingleCoin(this.$route.query.code);
    } else {
      this.currentCoin = null;
      if (this.currentCurrencyType === "Crypto") {
        this.currentDepositType = "Link";
      }
    }
  },
};
</script>

<style scoped>
.select-button {
  margin-top: 0px !important;
}
.relsify-deposit-types {
  display: flex;
  flex-direction: column;
  gap: 22px 0px;
}

.relsify-deposit-types button {
  background: rgba(31, 149, 125, 0.05);
  border-radius: 167.519px;
  padding: 15px 30px;
  color: var(--mainGreenColor);
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 15px;
  margin: 0px 15px;
  transition: all 400ms ease-in-out;
}

.relsify-deposit-types button.active {
  background: #18856f;
  color: #fff;
  font-weight: bold;
}

.page-inner-content-grid.two-grid {
  grid-template-columns: 200px 1fr !important;
  gap: 0px 10px !important;
}

.deposit-type-content .page-inner-content-grid {
  padding: 0px !important;
}

/* .deposit-type-content div div {
    gap: 
} */

.deposit-type-content .select-counter-button {
  margin-top: 15px;
}

.deposit-type-content .page-inner-content-grid.two-grid {
  grid-template-columns: repeat(2, 1fr) !important;
}

.link-container {
  padding: 24px;
  background: #f1f1f1;
  text-align: center;
  border-radius: 14.937px;
}

.account-container {
  padding: 24px;
  background: #f1f1f1;
  text-align: center;
  border-radius: 14.937px;
  color: #313131;
}

.account-container p:first-child {
  font-size: 17.236px;
  margin-bottom: 4.6px;
  width: 100%;
}

.account-container p:nth-child(2) {
  font-size: 24.13px;
  font-weight: 600;
  margin-bottom: 11.4px;
}

.account-container p:last-child {
  /* width: 190.74px;
  height: 32.173px;
  flex-shrink: 0; */
  font-size: 10.657px;
}

.account-container p:last-child span {
  font-weight: 600;
}

.link-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 26px;
  gap: 20px;
  margin-top: 45px;
}

.link-container div p {
  color: #474747;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0px;
  /* padding: 2px 3px; */
  font-size: 14px;
}

.page-card {
  margin: 30px 0px !important;
  width: 100% !important;
  -webkit-font-smoothing: antialiased !important;
  /* height: 60vh; */
}
/* .select-button {
    margin: ;
} */
.note-with-copy {
  display: block;
}
.note-with-copy-content {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.note-with-copy-content span {
  display: inline;
  width: auto;
  /* overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important; */
}
.note-with-copy-content i {
  display: inline;
}

@media screen and (max-width: 870px) {
  .card-details .page-inner-content-grid {
    display: block;
  }
}

@media screen and (max-width: 780px) {
  .page-inner-content-grid {
    padding: 20px !important;
  }
}

@media screen and (max-width: 580px) {
  .page-inner-content-grid.two-grid {
    padding: 10px 12px 60px 12px !important;
  }
  .page-inner-content-grid .relsify-deposit-types {
    margin-bottom: 20px;
  }
  .page-inner-content-grid .deposit-type-content {
    padding: 0px 10px;
  }
  .page-card {
    margin: 0px !important;
  }
}

@media screen and (max-width: 520px) {
  .note-with-copy-content span {
    display: inline;
    width: auto;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

@media screen and (max-width: 380px) {
  .page-inner-content-grid.two-grid {
    padding: 10px 0px 60px 0px !important;
  }
}
</style>
