<template>
  <div class="main_page_oauth_socail_div">
    <!-- <h3>LOG<span class="yellow">IN</span></h3> -->
    <div class="main_page_oauth_other">
      <h4>OR</h4>
      <hr />
    </div>

    <div class="main_page_oauth_socials">
      <a
        href="javascript:;"
        @click="handleClickSignIn"
        class="main_page_oauth_socials_box"
      >
        <img src="@/assets/img/googlee.jpg" alt="" />
        <span>Sign in With Google</span>
      </a>
      <!-- <a
        href="javascript:;"
        @click="loginWithFacebook"
        class="main_page_oauth_socials_box"
      >
        <img src="@/assets/img/facebook.jpg" alt="" />
        <span>Sign in With Facebook</span>
      </a> -->
    </div>
  </div>
</template>

<script>
import "@/assets/css/oauth.css";
import { mapActions, mapMutations } from "vuex";
import { initFbsdk } from "@/helpers/facebook_oAuth";
import { GOOGLE_CLIENT_ID } from "../../config/index";

export default {
  props: ["signInType"],

  data() {
    return {
      // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
      params: {
        client_id: GOOGLE_CLIENT_ID,
      },
      // only needed if you want to render the button with the google ui
      renderParams: {
        width: 250,
        height: 50,
        longtitle: true,
      },
      loginUser: null,
    };
  },

  methods: {
    ...mapActions(["saveGoogleDetailsToDB", "setAlertpopUp", "setPageLoading"]),
    ...mapMutations(["SET_REGISTRATION_TYPE", "GET_COUNTRIES"]),
    setSignUpType: function (value) {
      // this.$store.state.registrationType = value;
      this.SET_REGISTRATION_TYPE(value);
    },

    handleClickSignIn: async function () {
      try {
        const googleUser = await this.$gAuth.signIn();
        console.log(googleUser);
        if (!googleUser) {
          return null;
        }
        console.log("googleUser", googleUser);
        // this.loginUser = googleUser.getBasicProfile().getEmail();
        // console.log("getId", this.loginUser);
        // console.log("getBasicProfile", googleUser.getBasicProfile());
        // console.log("getAuthResponse", googleUser.getAuthResponse());
        // console.log(
        //   "getAuthResponse",
        //   this.$gAuth.instance.currentUser.get().getAuthResponse()
        // );

        this.loginUser = this.$gAuth.instance.currentUser
          .get()
          .getAuthResponse();

        console.log(this.loginUser);
        this.setPageLoading(true);
        this.saveGoogleDetailsToDB(this.loginUser)
          .then((data) => {
            if (data.data.error) {
              this.setPageLoading(false);
              this.setAlertpopUp({
                status: true,
                title: "Login Error",
                des: data.data.meta.message,
                type: "error",
                payload: null,
              });
            }
            // if (!data.data.error) {
            //   let dashboardUrl = this.$store.state.WEBSITE_URL;
            //   var createGuest = require("cross-domain-storage/guest");
            //   var bazStorage = createGuest("http://localhost:8086/");
            //   console.log(bazStorage);
            //   console.log(dashboardUrl);
            //   console.log(data.data);

            //   bazStorage.set(
            //     "Appoen",
            //     data.data.meta.token,
            //     function (error, data) {
            //       // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
            //       error, data;
            //       console.log(error);
            //       console.log(data);
            //     }
            //   );
            //   bazStorage.set(
            //     "actForms",
            //     data.data.data._id,
            //     function (error, data) {
            //       console.log(data);
            //       // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
            //       error;
            //       data;
            //     }
            //   );
            // }
          })
          .catch((error) => {
            error;
            this.setAlertpopUp({
              status: true,
              title: "Login Error",
              des: "Error occurred, Please try again",
              type: "error",
              payload: null,
            });
            this.setPageLoading(false);
          });
      } catch (error) {
        //on fail do something
        console.error(`Google sigin internal failure`);
        console.error(error);
        return null;
      }
    },

    loginWithFacebook() {
      window.FB.login((response) => {
        console.log("fb response", response);
      }, this.params);
    },
  },

  mounted() {
    // this.$store.state.registrationType = "withEmail";
    this.SET_REGISTRATION_TYPE("withEmail");
    initFbsdk();

    this.GET_COUNTRIES();
  },
};
</script>
