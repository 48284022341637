<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="page-tab">
        <a
          href="javascript:;"
          :class="
            ['Trade', 'Checkout', tabProjectClicked].includes(
              currentPageName
            ) && 'active'
          "
          @click="goToBuyPage"
          >Buy</a
        >
        <a
          href="javascript:;"
          :class="currentPageName == 'Sell' && 'active'"
          @click="goToSellPage"
          >Sell</a
        >
        <a
          href="javascript:;"
          :class="currentPageName == 'Swap' && 'active'"
          @click="goToSwapPage"
          >Swap</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
export default {
  components: {},

  methods: {
    ...mapActions(["setTabProjectClicked"]),
    goToBuyPage: function () {
      if (this.currentProject) {
        this.$router
          .push({
            name: "Checkout",
          })
          .then((err) => err);
      } else {
        this.$router
          .push({
            name: "Trade",
          })
          .then((err) => err);
      }

      this.setTabProjectClicked("buy");
    },
    goToSellPage: function () {
      this.$router.push("/sell").then((err) => err);
      this.setTabProjectClicked("sell");
    },
    goToSwapPage: function () {
      this.$router.push("/swap").then((err) => err);
      this.setTabProjectClicked("swap");
    },
  },
};
</script>
