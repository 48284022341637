<template>
  <div class="">
    <div v-if="!status">
      <div class="instructionMessage">
        <!-- <p>Please enter the 6 digit code sent to tyewilliams213@gmail.com</p> -->

        <div class="instructionMessageFlex">
          <a href="javascript:;" @click="resendEmailCode" class="green-color"
            >Get Code</a
          >
          <a href="javascript:;" @click="resendEmailCode">Resend Code</a>
        </div>
      </div>
      <div class="instructionMessageInputCodes">
        <input
          type="text"
          maxlength="1"
          ref="verifyEmailCode1"
          class="emailCode"
          id="emailCode1"
          @input="checkCode"
          data-next="2"
          data-prev="1"
        />
        <input
          type="text"
          maxlength="1"
          ref="verifyEmailCode2"
          class="emailCode"
          @input="checkCode"
          id="emailCode2"
          data-next="3"
          data-prev="2"
        />
        <input
          type="text"
          maxlength="1"
          ref="verifyEmailCode3"
          class="emailCode"
          id="emailCode3"
          @input="checkCode"
          data-next="4"
          data-prev="3"
        />
        <input
          type="text"
          maxlength="1"
          ref="verifyEmailCode4"
          class="emailCode"
          data-next="5"
          data-prev="4"
          @input="checkCode"
          id="emailCode4"
        />
        <input
          type="text"
          maxlength="1"
          class="emailCode"
          ref="verifyEmailCode5"
          data-next="6"
          @input="checkCode"
          id="emailCode5"
        />
        <input
          type="text"
          maxlength="1"
          ref="verifyEmailCode6"
          class="emailCode"
          data-next="6"
          id="emailCode6"
          @input="checkCode"
        />
      </div>
      <div class="text-center mt-5">
        <button
          :disabled="emptyFields"
          @click="VerifyNewEmail"
          class="relsify-button"
        >
          <span v-if="!loading">Confirm</span>
          <span class="loader" v-if="loading"></span>
        </button>
      </div>
    </div>
    <div v-if="status">
      <VerifiedDiv :title="'Email Verified'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import VerifiedDiv from "../../VerifiedDiv.vue";
import { mapActions } from "vuex";
export default {
  props: ["status"],
  components: {
    VerifiedDiv,
  },

  computed: {
    // secretId: {
    //   get() {
    //     return this.$store.state.secretId;
    //   },
    //   set(value) {
    //     return (this.$store.state.secretId = value);
    //   },
    // },
  },

  data() {
    return {
      emptyFields: true,
      loading: false,
      secretToken: "",
      secretId: "",
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "getUserProfile"]),

    checkCode() {
      this.secretToken =
        this.$refs.verifyEmailCode1.value +
        this.$refs.verifyEmailCode2.value +
        this.$refs.verifyEmailCode3.value +
        this.$refs.verifyEmailCode4.value +
        this.$refs.verifyEmailCode5.value +
        this.$refs.verifyEmailCode6.value;

      if (this.secretToken.length == 6) {
        this.emptyFields = false;
      } else {
        this.emptyFields = true;
      }
    },

    VerifyNewEmail: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/verify/email`;
      let payload = {
        secretTokenId: this.secretId,
        secretTokenCode: this.secretToken,
      };

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTimeout(() => {
            this.loading = false;
            // console.log(data);
            if (!data.error) {
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Email Verification successfully",
                des: data.meta.message,
                type: "success",
                payload: null,
              });
              this.clearFormFields();
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Email verification Error",
                des: data.meta.message,
                type: "error",
                payload: null,
              });
              this.clearFormFields();
            }
          }, 4000);
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Email verification Error",
            des: "An Error occured while verifying email",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },

    resendEmailCode: function () {
      let url = `${this.RELSIFY_URL}/api/initiate/email/code`;

      this.axios
        .get(url)
        .then((response) => {
          if (!response.data.error) {
            // console.log(response.data);
            this.secretId = response.data.data.secretTokenId;
            this.setAlertpopUp({
              status: true,
              title: "Email Code Sent",
              des: "Email sent! Please type the code sent to your email to verify your account.",
              type: "success",
              payload: null,
            });

            setTimeout(() => {
              this.setAlertpopUp({
                status: false,
                title: "",
                des: "",
                type: "error",
                payload: null,
              });
            }, 7000);

            // let newData = {
            //   secretTokenId: response.data.data.secretTokenId,

            // };

            // this.setVerifyPinCodeModal({
            //   status: true,
            //   title: "Verify Email",
            //   desc: response.data.meta.message,
            //   redirectUrl: null,
            //   type: "VERIFY_EMAIL_ACCOUNT",
            //   payload: newData,
            // });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Email verification Error",
              des: response.data.meta.message,
              type: "Error",
              payload: null,
            });
          }
        })
        .catch((error) => console.log(error));
    },

    clearFormFields: function () {
      this.$refs.verifyEmailCode1.value = "";
      this.$refs.verifyEmailCode2.value = "";
      this.$refs.verifyEmailCode3.value = "";
      this.$refs.verifyEmailCode4.value = "";
      this.$refs.verifyEmailCode5.value = "";
      this.$refs.verifyEmailCode6.value = "";
    },
  },

  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
</style>
