<template>
  <div
    class="settings-grid-item-left"
    :class="currentPageName !== 'Settings' && 'hideOnMobile'"
  >
    <div class="settings-grid-item-left-inner">
      <h4>Account Settings</h4>

      <div class="settings-grid-item-left-controls">
        <div>
          <router-link
            :to="{ name: 'Profile' }"
            class="settings-grid-item-left-controls-button"
            :class="currentPageName == 'Profile' && 'active'"
            >Profile</router-link
          >
        </div>
        <div>
          <router-link
            :to="{ name: 'SiteVerification' }"
            class="settings-grid-item-left-controls-button"
            :class="[
              [
                'SiteVerification',
                'SiteVerificationLevel2',
                'SiteVerificationLevel3',
              ].includes(currentPageName) && 'active',
            ]"
            >Verifications</router-link
          >
        </div>
        <div>
          <router-link
            :to="{ name: 'Security' }"
            class="settings-grid-item-left-controls-button"
            :class="
              ['Security', 'ChangePassword', 'ChangePhoneNumber'].includes(
                currentPageName
              ) && 'active'
            "
            >Security</router-link
          >
        </div>
        <div>
          <router-link
            :to="{ name: 'Notifications' }"
            class="settings-grid-item-left-controls-button"
            :class="currentPageName == 'Notifications' && 'active'"
            >Notifications</router-link
          >
        </div>

        <div>
          <a
            href="javascript:;"
            class="settings-grid-item-left-controls-button"
            @click="logoutUser"
            >Logout</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
// import { mapActions } from "vuex";
export default {
  components: {},

  methods: {},
};
</script>
