<template>
  <div class="page-card" style="background: transparent !important">
    <div
      v-if="!loading"
      class="page-inner-content-grid"
      style="background: #fff !important"
    >
      <!-- <div class="deposit-type-content">  -->
      <div class="row">
        <div v-if="currentCurrencyType === 'Fiat'" style="width: 100%">
          <div
            class="col-md-9 mx-auto"
            style="max-width: 500px !important; width: 100% !important"
          >
            <div class="text-center" style="width: 100%">
              <div class="select-button width-100 text-left">
                <label for="">Select A Token</label>
                <div
                  class="select-input select-input-input"
                  style="cursor: pointer !important"
                  @click="showSelectProjectModal(currentCoin)"
                >
                  <div style="cursor: pointer !important">
                    <img
                      style="cursor: pointer !important"
                      :src="currentCoin?.iconUrl"
                      alt=""
                    />
                    <input
                      type="text"
                      style="cursor: pointer !important; pointer-events: none"
                      disabled
                      :value="currentCoin?.symbol"
                      placeholder="Select a token"
                    />
                  </div>
                  <i class="bx bx-chevron-down" id="selectToken"></i>
                </div>
              </div>
            </div>
            <div class="text-center" style="width: 100%">
              <div class="select-button width-100 text-left">
                <label for="">Select bank:</label>
                <div
                  class="select-input select-input-input"
                  style="cursor: pointer !important"
                >
                  <!-- <div style="cursor: pointer !important"> -->
                  <!-- <img
                      style="cursor: pointer !important"
                      alt=""
                      :src="null"
                    /> -->
                  <select
                    v-model="currentWithdrawalBankName"
                    style="
                      cursor: pointer !important;
                      margin-right: 6px !important;
                    "
                    :disabled="currentCoinSymbol !== 'NGN'"
                  >
                    <option value="undefined" disabled selected hidden>
                      Choose a bank
                    </option>
                    <option v-for="bank in filteredBankList" :key="bank.code">
                      {{ bank.name }}
                    </option>
                  </select>
                  <!-- <i class="bx bx-chevron-down" id="selectToken"></i> -->
                </div>
              </div>
            </div>
            <!-- <div class="text-center">
                <div class="select-button text-left width-100">
                  <label for="">Select bank: </label>
                  <div class="select-input select-input-single">
                    <div>
                      <input
                        type="text"
                        v-model="cardNumber"
                        @input="validateForm"
                        placeholder="e.g GTB"
                      />
                    </div>
                    <i class="bx bx-chevron-down"></i>
                  </div>
                </div>
              </div> -->
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Enter account number: </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      v-model="accountNo"
                      type="tel"
                      @input="
                        () => {
                          if (
                            this.currentWithdrawalBankName &&
                            this.accountNo.length === 10
                          ) {
                            this.resolveAccount();
                          } else {
                            this.currentWithdrawalAccountUserName = null;
                          }
                        }
                      "
                      maxlength="10"
                      placeholder="Enter account here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>

              <div v-if="this.accountNo.length === 10">
                <p
                  style="
                    font-size: 10px;
                    text-align: right;
                    font-weight: 900;
                    margin: -5px 0px;
                    padding: 0px 10px;
                  "
                >
                  {{
                    currentWithdrawalAccountUserName
                      ? currentWithdrawalAccountUserName
                      : "..."
                  }}
                </p>
              </div>
            </div>
            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Enter amount: </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="text"
                      v-model="amount"
                      placeholder="Enter amount here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>
            <div class="select-counter-button">
              <button
                class="relsify-button"
                :disabled="emptyFields"
                @click="withdraw"
              >
                <span v-if="!loading">Withdraw</span>
                <span class="loader" v-if="loading"></span>
              </button>
            </div>
          </div>
        </div>
        <div v-if="currentCurrencyType === 'Crypto'" style="width: 100%">
          <div
            class="col-md-9 mx-auto"
            style="max-width: 500px !important; width: 100% !important"
          >
            <div class="text-center" style="width: 100%">
              <div class="select-button width-100 text-left">
                <label for="">Select A Token</label>
                <div
                  class="select-input select-input-input"
                  style="cursor: pointer !important"
                  @click="showSelectProjectModal(currentCoin)"
                >
                  <div style="cursor: pointer !important">
                    <img
                      style="cursor: pointer !important"
                      :src="currentCoin?.iconUrl"
                      alt=""
                    />
                    <input
                      type="text"
                      style="cursor: pointer !important"
                      disabled
                      :value="currentCoin?.symbol"
                    />
                  </div>
                  <i class="bx bx-chevron-down" id="selectToken"></i>
                </div>
              </div>
            </div>

            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Withdrawal Network </label>
                <!-- <div class="select-input select-input-single">
                    <select
                      v-if="currentCoin.networks"
                      v-model="networks"
                      @change="validateForm"
                    >
                      <option value="" disabled>Select Withdrawal network</option>
                      <option
                        :value="network"
                        v-for="(network, index) in currentCoin.networks"
                        :key="index"
                      >
                        {{ network.name }} -
                        {{ network.symbol }}
                      </option>
                    </select>
                  </div> -->
                <div
                  class="select-input select-input-input"
                  style="cursor: pointer !important"
                  @click="showSelectNetworkModal(currentCoin?.networks)"
                >
                  <div style="cursor: pointer !important">
                    <!-- <img
                        style="cursor: pointer !important"
                        v-if="currentDeposit.coin && currentDeposit.coin.iconUrl"
                        :src="currentDeposit.coin.iconUrl"
                        alt=""
                      /> -->
                    <input
                      type="text"
                      style="cursor: pointer !important"
                      disabled
                      :value="currentSelectedNetwork?.name"
                    />
                  </div>
                  <i class="bx bx-chevron-down" id="selectToken"></i>
                </div>
              </div>
            </div>

            <div class="text-center">
              <div class="select-button text-left width-100">
                <label for="">Withdrawal Address </label>
                <div class="select-input select-input-single">
                  <div>
                    <input
                      type="text"
                      v-model="address"
                      @input="validateForm"
                      placeholder="Enter address here"
                    />
                  </div>
                  <!-- <i class="bx bx-chevron-down"></i> -->
                </div>
              </div>
            </div>

            <!-- {{ currentCoin.selectedNetwork }} -->

            <div class="text-center">
              <div class="selected-counter-button text-left">
                <label for="">Amount To Withdraw </label>
                <div class="counter-button">
                  <i class="bx bx-minus" @click="decreaseAmount"></i>
                  <input
                    type="text"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                    v-model="amount"
                    @input="validateForm"
                    class="large_one"
                  />
                  <i class="bx bx-plus" @click="increaseAmount"></i>
                </div>
              </div>
            </div>

            <div class="select-button-notes">
              <div class="select-button-notes-content bg-green-light">
                <p class="display-block">
                  Transation fee =
                  {{ currentSelectedNetwork?.withdrawalFee }}
                  {{ currentSelectedNetwork?.symbol }}
                </p>
                <p class="display-block">Minimum Withdrawal = 5.5BTC</p>
                <p class="display-block">
                  Amount you’ll receive = {{ amountReceived }}
                  {{ currentSelectedNetwork?.symbol }}
                </p>
              </div>
            </div>

            <div class="select-counter-button">
              <button
                class="relsify-button"
                :disabled="emptyFields"
                @click="withdraw"
              >
                <span v-if="!loading">Withdraw</span>
                <span class="loader" v-if="loading"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <div
      v-if="loading"
      class="page-inner-content-grid"
      style="background: #fff !important; justify-items: center"
    >
      <LoadingComponent />
    </div>
  </div>
</template>
<script>
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "../../loading/LoadingComponent.vue";

export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters([
      "userWalletBalance",
      "allCoins",
      "currentCurrencyType",
      "supportedBankList",
      "loginInfo",
    ]),
    currentCoin: {
      get() {
        return this.$store.state.currentCoin;
      },
      set(value) {
        return (this.$store.state.currentCoin = value);
      },
    },
    currentCoinSymbol: {
      get() {
        return this.currentCoin?.symbol;
      },
      set(value) {
        this.currentCoin = this.userWalletBalance.find(
          (balance) => balance.coin.symbol == value
        ).coin;
      },
    },
    currentCurrencyType: {
      get() {
        return this.$store.state.currentCurrencyType;
      },
      set(value) {
        return (this.$store.state.currentCurrencyType = value);
      },
    },
    filteredCoins: {
      get() {
        if (this.allCoins?.length) {
          return this.allCoins.filter((coin) =>
            String(coin.type).match(this.currentCurrencyType.toLowerCase())
          );
        }
        return null;
      },
      set() {},
    },
    filteredBankList: {
      get() {
        if (this.supportedBankList.length && this.currentCoinSymbol === "NGN") {
          return this.supportedBankList.filter((bank) =>
            String(bank.currency).match(null)
          );
        }
        return [];
      },
      set() {},
    },
    currentWithdrawalBankName: {
      get() {
        return this.selectedBank?.name;
      },
      set(value) {
        this.selectedBank = this.filteredBankList.find(
          (bank) => bank.name == value
        );
      },
    },
    currentWithdrawalAccountUserName: {
      get() {
        return this.$store.state.currentWithdrawalAccountInfo?.accountName;
      },
      set(value) {
        this.$store.state.currentWithdrawalAccountInfo = value;
      },
    },
    currentSelectedNetwork: {
      get() {
        return this.$store.state.currentSelectedNetwork;
      },
      set(value) {
        return (this.$store.state.currentSelectedNetwork = value);
      },
    },
    amountReceived() {
      return this.amount - this.currentSelectedNetwork?.withdrawalFee;
    },
    emptyFields() {
      if (
        this.currentCurrencyType === "Fiat" &&
        (!this.amount ||
          this.accountNo.length !== 10 ||
          !this.currentWithdrawalBankName ||
          !this.currentCoinSymbol)
      ) {
        return true;
      } else if (
        this.currentCurrencyType === "Crypto" &&
        (!this.amount ||
          !this.currentCoinSymbol ||
          (!this.currentSelectedNetwork?.name && !this.address))
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      loading: false,
      selectedBank: null,
      accountNo: "",
      amount: "",
      copying: false,
      networks: "",
      address: "",
    };
  },
  watch: {
    currentCurrencyType() {
      this.currentCoin = null;
      this.amount = null;
      this.selectedBank = null;
      this.currentSelectedNetwork = null;
      this.accountNo = "";
      this.address = "";
    },
  },
  methods: {
    ...mapActions([
      "getAllCoins",
      "getSupportedBankList",
      "resolveBankAccount",
      "setCurrentPageName",
      "setAlertpopUp",
      "setSmallPopUp",
      "setDepositeAdresses",
      "setSelectProjectModal",
      "getWalletAddresses",
      "getSingleCoin",
      "setShareModal",
      "setVerifyPinCodeModal",
      "setSelectNewtorkModal",
    ]),
    // validateForm() {
    //   if (!this.amount || this.accountNo.length !== 10 || !this.currentWithdrawalBankName || !Object.key(this.currentCoin).length) {
    //     this.emptyFields = true;
    //     return false;
    //   }

    //   this.emptyFields = false;
    //   return true;
    // },
    getSingleCoin(id) {
      this.loading = true;

      setTimeout(() => {
        if (this.userWalletBalance && this.userWalletBalance.length) {
          this.currentCoin = this.userWalletBalance.find(
            (balance) => balance.coin.id == id
          ).coin;
          this.currentCurrencyType =
            this.currentCoin.type.charAt(0).toUpperCase() +
            this.currentCoin.type.slice(1);
          this.loading = false;
        } else {
          // this.getSingleBalance(id);
        }
      }, 2000);
    },

    async getCoins() {
      this.loading = true;
      const res = await this.getAllCoins();
      if (!res.data.error) {
        this.loading = false;
      }
    },

    async getBanks() {
      this.loading = true;
      const res = await this.getSupportedBankList();

      if (!res.data.error) {
        this.loading = false;
      }
    },

    async resolveAccount() {
      try {
        let res = await this.resolveBankAccount({
          bankCode: this.selectedBank.code,
          accountNumber: this.accountNo,
        });

        if (!res.data.error) {
          // console.log(res)
        }
      } catch (error) {
        this.setAlertpopUp({
          status: true,
          title: "Server Error",
          des: error.message || "An Error occured",
          type: "error",
          payload: null,
        });
      }
    },

    async withdraw() {
      try {
        if (this.loginInfo && this.loginInfo.emailVerified == true) {
          let url = `${this.RELSIFY_URL}/api/initiate/email/withdrawal/code`;
          this.initiateWithdrawalCode(url);

          return;
        }

        if (this.loginInfo && this.loginInfo.phoneVerified == true) {
          let url = `${this.RELSIFY_URL}/api/initiate/phone/withdrawal/code`;
          this.initiateWithdrawalCode(url);

          return;
        }
      } catch (error) {
        this.setAlertpopUp({
          status: true,
          title: "Server Error",
          des: error.message || "An Error occured",
          type: "error",
          payload: null,
        });
      }
    },

    initiateWithdrawalCode: function (url) {
      this.loading = true;
      // this.emptyFields = true;

      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            let newData;

            if (this.currentCurrencyType === "Fiat") {
              newData = {
                secretTokenId: data.data.secretTokenId,
                bankCode: this.selectedBank.code,
                accountNumber: this.accountNo,
                amount: String(this.amount),
                coin: this.currentCoin.symbol,
              };
            } else {
              newData = {
                secretTokenId: data.data.secretTokenId,
                symbol: this.currentCoin.symbol,
                network: this.currentSelectedNetwork.name,
                address: this.address,
                amount: String(this.amount),
              };
            }

            this.setVerifyPinCodeModal({
              status: true,
              title: "Verify wallet withdrawal",
              desc: data.meta.message,
              redirectUrl: null,
              type: "CONFIRM_WALLET_WITHDRWAL",
              payload: newData,
            });

            this.loading = false;
          }
        })
        .catch((error) => {
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: error.message || "An Error occured",
            type: "error",
            payload: null,
          });
        });
    },

    copyAddress: function (address) {
      this.copying = true;

      setTimeout(() => {
        var input = document.createElement("input");
        input.setAttribute("value", address);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand("copy");
        document.body.removeChild(input);

        this.setSmallPopUp({
          status: true,
          text: "Address copied",
        });

        this.copying = false;

        return result;
      }, 900);

      setTimeout(() => {
        this.setSmallPopUp({
          status: false,
          text: "",
        });
      }, 3500);
    },

    openShareModal: function (payload) {
      this.setShareModal({
        status: true,
        url: "",
        title: payload,
        desc: "",
        payload: null,
      });
    },

    showSelectProjectModal: function (networks) {
      if (!this.$route.query.code) {
        this.setSelectProjectModal({
          status: true,
          title: "",
          desc: "",
          payload: networks,
          select1Type: "",
          select2Type: "",
          select3Type: "",
          select4Type: "",
          select5Type: "",
          select6Type: "",
          type: "WALLET_DEPOSIT",
        });
      }

      return;
    },

    showSelectNetworkModal: function (networks) {
      this.setSelectNewtorkModal({
        status: true,
        title: "",
        desc: "",
        payload: networks,
        select1Type: "",
        select2Type: "",
        type: "WALLET_WITHDRAWAL",
      });
    },

    decreaseAmount: function () {
      if (this.amount == 1) {
        return;
      }

      this.amount = --this.amount;
    },

    increaseAmount: function () {
      this.amount = ++this.amount;
    },
  },
  mounted() {
    this.getCoins();
    this.getBanks();

    if (this.$route.query.code) {
      this.getSingleCoin(this.$route.query.code);
    } else {
      this.currentCoin = null;
    }
  },
};
</script>

<style scoped>
.select-button {
  margin-top: 0px !important;
}
/* .relsify-deposit-types {
  display: flex;
  flex-direction: column;
  gap: 22px 0px;
}

.relsify-deposit-types button {
  background: rgba(31, 149, 125, 0.05);
  border-radius: 167.519px;
  padding: 15px 30px;
  color: var(--mainGreenColor);
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 15px;
  margin: 0px 15px;
  transition: all 400ms ease-in-out;
}

.relsify-deposit-types button.active {
  background: #18856f;
  color: #fff;
  font-weight: bold;
} */
/* .page-inner-content-grid .row { */
/* padding: 50px 200px !important; */
/* max-width: 500px !important;
  width: 100% !important; */
/* } */

/* .deposit-type-content div div {
    gap: 
} */

.page-card {
  margin: 30px 0px !important;
  width: 100% !important;
  /* height: 60vh; */
}
/* .select-button {
    margin: ;
} */
.note-with-copy {
  display: block;
}
.note-with-copy-content {
  width: 100% !important;
  display: flex;
  justify-content: space-between;
}
.note-with-copy-content span {
  display: inline;
  width: auto;
  /* overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important; */
}
.note-with-copy-content i {
  display: inline;
}

@media screen and (max-width: 870px) {
  .card-details .page-inner-content-grid {
    display: block;
  }
}

@media screen and (max-width: 580px) {
  .page-card {
    margin: 0px !important;
  }
  .page-card .page-inner-content-grid {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 40px;
  }

  .page-card .page-inner-content-grid .row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 520px) {
  .note-with-copy-content span {
    display: inline;
    width: auto;
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}
</style>
