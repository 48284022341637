<template>
  <div class="table-mobile">
    <div class="table-mobile-grid" v-if="histories && histories.length > 0">
      <div
        class="table-mobile-grid-item"
        style="row-gap: 20px"
        v-for="(device, index) in histories"
        :key="index"
      >
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <div
              style="
                display: flex;
                align-items: center;
                color: var(--mainGreenColor);
                font-weight: bold;
                margin-bottom: 10px;
              "
            >
              <i
                class="bx bx-mobile"
                v-if="device.deviceOs.toLowerCase() == 'android'"
                style="font-size: 24px"
              ></i>
              <i class="bx bx-laptop" v-else style="font-size: 24px"></i>
              <span style="margin-left: 10px; font-size: 16px">{{
                device.device
              }}</span>
            </div>
          </div>
        </div>
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <p class="table-mobile-grid-item-title">Date</p>
          </div>
          <div class="table-item-right">
            <p class="table-mobile-grid-item-date">
              {{ formateDateAndTimeByName(device.date) }}
            </p>
          </div>
        </div>
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <p class="table-mobile-grid-item-title">Location</p>
          </div>
          <div class="table-item-right">
            <p class="table-mobile-grid-item-date">
              {{ device.city + ", " + device.country }}
            </p>
          </div>
        </div>
        <div class="table-mobile-grid-item-row">
          <div class="table-item-left">
            <p class="table-mobile-grid-item-title">IP Address</p>
          </div>
          <div class="table-item-right">
            <p class="table-mobile-grid-item-date">{{ device.ip }}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      class="table-mobile-light"
      v-if="loginDevices && histories && histories.length <= 0"
    >
      <div class="card text-center">
        <div class="card-body">
          <h6>No device history</h6>
        </div>
      </div>
    </div>
    <div class="table-mobile-light" v-if="loginDevices == null">
      <div class="d-flex justify-content-center align-items-center">
        <br />
        <LoadingComponent />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  props: ["loginDevices", "histories"],
  components: {
    LoadingComponent,
  },

  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
    showTransactionDetailsModal: function (transaction) {
      this.setTransactionDetailsModal({
        status: true,
        payload: transaction,
        type: "",
      });
    },
  },
};
</script>

<style></style>
