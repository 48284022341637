<template>
  <div>
    <div v-if="!status">
      <div class="instructionMessage">
        <p>Please upload a photo from your device</p>

        <div class="checkboxFlexs" v-if="approvedDocuments">
          <div
            class="checkboxFlexsItem"
            v-for="(document, index) in approvedDocuments"
            :key="index"
            style="cursor: pointer !important"
          >
            <input
              type="radio"
              class="checkboxFlexsItemInput"
              name="flexRadioDefault"
              :checked="index == 1"
              style="cursor: pointer !important"
              @change="changeDocumentType(document)"
              :id="'flexRadioDefault' + (index + 1)"
            />
            <label
              class="form-check-label"
              style="cursor: pointer !important"
              :for="'flexRadioDefault' + (index + 1)"
            >
              <!-- National ID -->
              {{ removeCamelCases(document) }}
            </label>
          </div>
        </div>

        <input
          type="file"
          ref="identity_image"
          @change="uploadDocument($event)"
          style="display: none"
          accept="image/png, image/gif, image/jpeg"
        />

        <div class="imageUploadBox">
          <i
            class="bx bx-cloud-upload"
            v-if="!selectedDocuments"
            @click="$refs.identity_image.click()"
          ></i>
          <span>
            <img
              class="documentPreviewImage"
              v-if="selectedDocuments"
              @click="$refs.identity_image.click()"
              src="@/assets/vectors/img/projects.png"
            />
            <!-- <i
              class="bx bx-file"
              style="cursor: default"
              
            ></i> -->
          </span>
        </div>

        <div class="text-center">
          <small
            v-if="selectedDocuments"
            style="display: block; margin-top: -18px; font-size: 12px"
            >{{ selectedDocumentName }}</small
          >
          <br />
          <a
            @click="$refs.identity_image.click()"
            href="javascript:;"
            class="green-color"
            style="display: block; margin-top: -20px; font-size: 13px"
            >Tap image to upload your
            {{ documentType ? removeCamelCases(documentType) : "document" }}
          </a>
        </div>

        <div class="verifyInputs">
          <label> ID card number </label>

          <input
            type="text"
            v-model="IdCardNumber"
            @input="validateFields"
            placeholder="e.g 67FB 6894 BJ765"
          />
        </div>
        <transition name="fade">
          <p
            v-if="formMessage"
            style="
              color: red !important;
              font-size: 13px !important;
              margin-top: 4px;
              margin-bottom: 4px;
              text-align: center;
            "
          >
            <svg
              v-if="formMessage"
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 20 20"
              svg-inline=""
              role="presentation"
              focusable="false"
              tabindex="-1"
              style="color: red !important"
              fill="red"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
              ></path>
            </svg>
            {{ formMessage }}
          </p>
        </transition>
      </div>

      <div class="siteVerificationButtons">
        <button class="relsify-button backbutton" @click="goBackToPrevious">
          <i class="bx bx-chevron-left"></i> <span>Back</span>
        </button>
        <button
          class="relsify-button continueButton"
          :disabled="emptyFields"
          @click="submitIdentyVerification"
        >
          <span>
            <span v-if="!loading">Save & Continue</span>
            <span class="loader" v-if="loading"></span>
          </span>
          <i class="bx bx-chevron-right"></i>
        </button>
      </div>
    </div>
    <div v-if="status">
      <VerifiedDiv :title="'Identity Verified'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import VerifiedDiv from "../../VerifiedDiv.vue";
import { mapActions, mapGetters } from "vuex";
import $ from "jquery";
export default {
  components: {
    VerifiedDiv,
  },
  computed: {
    ...mapGetters(["approvedDocuments"]),
  },

  props: ["status"],

  data() {
    return {
      gender: "",
      documentType: "nationalIdDocument",
      selectedDocuments: null,
      selectedDocumentName: "",
      IdCardNumber: "",
      formMessage: "",
      emptyFields: true,
      loading: false,
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "getUserProfile"]),
    changeDocumentType: function (document) {
      this.documentType = document;
      this.validateFields();
    },

    uploadDocument: function (event) {
      if (event.target.files[0]) {
        this.selectedDocuments = event.target.files[0];
        this.selectedDocumentName = event.target.files[0].name;
        var reader = new FileReader();

        reader.onload = function (event) {
          let images = document.querySelectorAll(".documentPreviewImage");

          images.forEach((image) => {
            image.setAttribute("src", event.target.result);
          });
        };

        reader.readAsDataURL(event.target.files[0]);

        this.validateFields();
      }
    },

    validateFields: function () {
      if (!this.documentType || !this.selectedDocuments || !this.IdCardNumber) {
        this.emptyFields = true;
        this.formMessage = "Fill out all fields";

        return false;
      }

      this.emptyFields = false;
      this.formMessage = "";
      return true;
    },

    goBackToPrevious: function () {
      let attrib = $("[tab-id=siteVerificationPersonalInfo]");
      attrib.click();
    },

    goToNext: function () {
      let attrib = $("[tab-id=siteVerificationLevel3]");
      attrib.click();
    },

    submitIdentyVerification: function () {
      this.loading = true;

      let url = `${this.RELSIFY_URL}/api/verification/level-two/document/upload?documentType=${this.documentType}&documentRefNumber=${this.IdCardNumber}`;
      let fd = new FormData();

      fd.append("document", this.selectedDocuments);

      this.axios
        .put(url, fd)
        .then((response) => {
          this.globalFunction();
          this.isImageUploading = false;
          if (!response.data.error) {
            this.globalFunction();
            this.selectedDocuments = null;
            this.selectedDocumentName = "";
            this.IdCardNumber = "";
            this.formMessage = "";
            this.validateFields();
            this.setAlertpopUp({
              status: true,
              title: "verification Submitted",
              des: response.data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Error",
              des: response.data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(response.data);
          }
        })
        .catch(() => {
          this.isImageUploading = false;
          this.setAlertpopUp({
            status: true,
            title: "Verification Error",
            des: "An Error occured while Verifying Document",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });

      // const token = sessionStorage.getItem("Appoen");

      // const requestOptions = {
      //   method: "PUT",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: token,
      //   },
      //   body: JSON.stringify(fd),
      // };

      // fetch(url, requestOptions)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     this.loading = false;
      //     console.log(data);
      //     if (!data.error) {
      //       this.globalFunction();
      //       this.selectedDocuments = null;
      //       this.selectedDocumentName = "";
      //       this.validateFields();
      //       this.setAlertpopUp({
      //         status: true,
      //         title: "verification Successful",
      //         des: data.meta.message,
      //         type: "success",
      //         payload: null,
      //       });
      //     } else {
      //       this.setAlertpopUp({
      //         status: true,
      //         title: "verification Error",
      //         des: data.meta.message,
      //         type: "error",
      //         payload: null,
      //       });
      //     }
      //   })
      //   .catch((error) => {
      //     this.loading = false;
      //     this.setAlertpopUp({
      //       status: true,
      //       title: "Identity verification status",
      //       des: "An Error occured while verifying identity",
      //       type: "error",
      //       payload: null,
      //     });
      //     console.log(error);
      //   });
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}

.imageUploadBox {
  position: relative !important;
}

.imageUploadBox img {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
  border: 4px dotted #777;
  margin: 10px auto 0px;
  cursor: pointer;
}

@media screen and (max-width: 540px) {
  .imageUploadBox {
    margin-top: 100px;
  }
}
</style>
