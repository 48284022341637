<template>
  <div>
    <div class="page-tab-line">
      <button
        class="relsify-button"
        :class="currentCurrencyType === 'Fiat' && 'active'"
        type="button"
        :disabled="$route.query.code && currentCurrencyType === 'Crypto'"
        @click="setCurrentCurrencyType('Fiat')"
      >
        Fiat
      </button>
      <button
        class="relsify-button"
        :class="currentCurrencyType === 'Crypto' && 'active'"
        type="button"
        :disabled="$route.query.code && currentCurrencyType === 'Fiat'"
        @click="setCurrentCurrencyType('Crypto')"
      >
        Coin
      </button>
    </div>
    <div class="relsify-search-form">
      <div class="relsify-table-search grid-search">
        <div class="relsify-table-search-item">
          <label for="">Filter By:</label>
          <div class="select_">
            <select
              v-if="activityFilters"
              v-model="this.currentCurrencyType"
              :disabled="$route.query.code"
            >
              <option
                v-for="(data, index) in activityFilters"
                :key="index"
                :value="data.value"
              >
                {{ data.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // mapGetters: [],
  data() {
    return {
      activityFilters: [
        {
          label: "Coin",
          value: "Crypto",
        },
        {
          label: "Fiat",
          value: "Fiat",
        },
      ],
    };
  },
  computed: {
    currentCurrencyType: {
      get() {
        return this.$store.state.currentCurrencyType;
      },
      set(value) {
        if (value === "Crypto") {
          this.currentDepositType = "Link";
        } else {
          this.currentDepositType = "Transfer";
        }
        return (this.$store.state.currentCurrencyType = value);
      },
    },
    currentDepositType: {
      get() {
        return this.$store.state.depositType;
      },
      set(value) {
        return (this.$store.state.depositType = value);
      },
    },
  },
  methods: {
    setCurrentCurrencyType(type) {
      this.currentCurrencyType = type;
    },
  },
  created() {
    if (!this.currentCurrencyType) {
      this.currentCurrencyType = "Fiat";
    }
  },
};
</script>
<style scoped>
.page-tab-line {
  background: unset;
  padding: unset;
  /* margin-bottom: 0px !important; */
}
.page-tab-line button:first-child {
  margin-left: 0px;
}
.page-tab-line button {
  background: rgba(31, 149, 125, 0.05);
  border: 1px solid #18856f;
  border-radius: 167.519px;
  padding: 12px 18px;
  color: #474747;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 12px;
  margin: 0px 15px;
  transition: all 400ms ease-in-out;
}

.page-tab-line button.active {
  background: #18856f;
  color: #fff;
  font-weight: bold;
}

.relsify-search-form {
  display: none !important;
}

.page-tab-line button:disabled {
  background: transparent !important;
  color: #474747;
}

@media (max-width: 580px) {
  .page-tab-line {
    display: none;
    padding: 0px 15px !important;
    margin-bottom: 0px !important;
  }
  .relsify-table-search.grid-search {
    margin: 0px !important;
  }
  .page-tab-line button {
    font-size: 14px;
    padding: 8px 10px;
  }
  .relsify-search-form {
    display: block !important;
    margin: 0px !important;
  }
}
</style>
