<template>
  <div class="select-modal" v-if="projectModal.status">
    <!-- hideModal -->
    <div class="select-modal-card vivify popInBottom">
      <div class="close-select-button" @click="closeProjectModal">
        <i class="bx bx-x" id="closeToken"></i>
      </div>

      <div class="select-modal-search">
        <label for="">Select Project</label>
        <div class="relsify-search">
          <i class="bx bx-search-alt-2"></i>
          <input type="search" v-model="searchText" placeholder="Search" />
        </div>
      </div>

      <div class="select-modal-body">
        <div
          class="select-modal-content"
          v-if="projects && projectss.length > 0"
        >
          <!-- {{ projects }} -->
          <div style="width: 100%" v-if="projectModal.select1Type">
            <div
              class="select-modal-item"
              v-for="(project, index) in projectss"
              :key="index"
              @click="selectProject(project)"
              :class="
                project.title.toLowerCase() ==
                  projectTosell.title.toLowerCase() && 'selected'
              "
            >
              <p>
                <img
                  v-if="project.mainImage.url"
                  style="
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 10px;
                  "
                  :src="project.mainImage.url"
                  alt=""
                />
                <span>{{ project.title }}</span>
                <!-- <small>{{ project.name }}</small> -->
              </p>
            </div>
          </div>
          <div style="width: 100%" v-else>
            <div
              class="select-modal-item"
              v-for="(project, index) in projectss"
              :key="index"
              @click="selectProject(project)"
              :class="
                project.title.toLowerCase() ==
                  projectToBuy.title.toLowerCase() && 'selected'
              "
            >
              <p>
                <img
                  v-if="project.mainImage.url"
                  style="
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                    border-radius: 10px;
                  "
                  :src="project.mainImage.url"
                  alt=""
                />
                <span>{{ project.title }}</span>
                <!-- <small>{{ project.name }}</small> -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["projectModal", "projects"]),
    projectss() {
      let allProjects = null;

      if (this.projectModal.status && this.projects.length > 0) {
        allProjects = this.projects.filter(
          (project) =>
            project.title.toLowerCase().match(this.searchText.toLowerCase()) ||
            project.country
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            project.category
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            project.ownerFullName
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            project.status.toLowerCase().match(this.searchText.toLowerCase()) ||
            project.description
              .toLowerCase()
              .match(this.searchText.toLowerCase())
        );

        // console.log(allProjects);
      }

      return allProjects;
    },

    projectToBuy: {
      get() {
        return this.$store.state.projectToBuy;
      },
      set(value) {
        return (this.$store.state.projectToBuy = value);
      },
    },

    projectTosell: {
      get() {
        return this.$store.state.projectTosell;
      },
      set(value) {
        return (this.$store.state.projectTosell = value);
      },
    },
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    ...mapActions(["setProjectModal"]),
    closeProjectModal: function () {
      this.setProjectModal({
        status: false,
        title: "",
        desc: "",
        payload: null,
        select1Type: "",
        select2Type: "",
        type: "",
      });
      this.searchText = "";
    },
    selectProject: function (project) {
      if (this.projectModal.select1Type) {
        this.projectTosell = project;
      } else {
        this.projectToBuy = project;
      }

      // console.log(project);
      this.closeProjectModal();
    },
  },
};
</script>

<style></style>
