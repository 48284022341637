<template>
  <div class="table-mobile">
    <div
      class="table-mobile-grid table_transsa"
      v-if="histories && histories.length > 0"
    >
      <div
        class="table-mobile-grid-item tabel-mobile-tran"
        v-for="(transaction, index) in histories"
        :key="index"
        @click="goToTransactionDetails(transaction)"
      >
        <div class="table-item-left">
          <div class="td-with-image">
            <img
              v-if="transaction.coin.iconUrl"
              style="border-radius: 100%; height: 28px; width: 28px"
              :src="transaction.coin.iconUrl"
              alt=""
            />
            <span>{{ transaction.coin.name }}</span>
          </div>
          <p>{{ formatAmount(transaction.amount) }}</p>
        </div>
        <div class="table-item-right">
          <p class="green-color">{{ transaction.status }}</p>
        </div>
      </div>
    </div>

    <div
      class="table-mobile-light"
      v-if="transactionHistory && histories && histories.length <= 0"
    >
      <div class="card text-center">
        <div class="card-body">
          <h6>No transaction history</h6>
        </div>
      </div>
    </div>
    <div class="table-mobile-light" v-if="transactionHistory == null">
      <div class="d-flex justify-content-center align-items-center">
        <br />
        <LoadingComponent />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  props: ["transactionHistory", "histories"],
  components: {
    LoadingComponent,
  },

  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
    goToTransactionDetails: function (transaction) {
      this.$router.push({
        name: "SingleTransaction",
        query: { id: transaction.id },
      });
    },
  },
};
</script>

<style></style>
