export const RELSIFY_URL = (state) => {
  return state.RELSIFY_URL;
};

export const DASHBOARD_URL = (state) => {
  return state.DASHBOARD_URL;
};

export const WEBSITE_URL = (state) => {
  return state.WEBSITE_URL;
};

export const RequestId = () => {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 10; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
};

export const user = (state) => {
  return state.user;
};

export const loginInfo = (state) => {
  return state.loginInfo;
};

export const token = (state) => {
  return state.token;
};

export const isLoggedIn = (state) => {
  return !!state.token;
  // return state.isUserLoggedIn;
};

// Current page name
export const currentPageName = (state) => {
  return state.currentPageName;
};

// Current page type
export const currentPageType = (state) => {
  return state.currentPageType;
};

//  modal Loading
export const modalLoading = (state) => {
  return state.modalLoading;
};

//  page Loading
export const pageLoading = (state) => {
  return state.pageLoading;
};

//  countries
export const countries = (state) => {
  return state.countries;
};

//  states
export const states = (state) => {
  return state.states;
};

// Marketplace modal
export const marketPlaceModal = (state) => {
  return state.marketPlaceModal;
};

// tab Project Clicked
export const tabProjectClicked = (state) => {
  return state.tabProjectClicked;
};

// current Project
export const currentProject = (state) => {
  return state.currentProject;
};

// Verify pincode modal
export const verifyPinCodeModal = (state) => {
  return state.verifyPinCodeModal;
};

// Verify Alert message modal
export const alertMessageModal = (state) => {
  return state.alertMessageModal;
};

// alert popUp
export const alertpopUp = (state) => {
  return state.alertpopUp;
};

// user Wallet Balance
export const userWalletBalance = (state) => {
  return state.userWalletBalance;
};

// projects
export const projects = (state) => {
  return state.projects;
};

// project Category
export const projectCategory = (state) => {
  return state.projectCategory;
};

// selected project Category
export const selectedCategory = (state) => {
  return state.selectedCategory;
};

export const projectToBuy = (state) => {
  return state.projectToBuy;
};

export const projectTosell = (state) => {
  return state.projectTosell;
};

// portfolios
export const portfolios = (state) => {
  return state.portfolios;
};

// trade History
export const tradeHistory = (state) => {
  return state.tradeHistory;
};

// transaction History
export const transactionHistory = (state) => {
  return state.transactionHistory;
};

// Deposit transaction History
export const depositTransactionHistory = (state) => {
  return state.depositTransactionHistory;
};

// Withdrawal transaction History
export const withdrawalTransactionHistory = (state) => {
  return state.withdrawalTransactionHistory;
};

export const dividends = (state) => {
  return state.dividends;
};

// login Histories
export const loginHistories = (state) => {
  return state.loginHistories;
};

// login Devices
export const loginDevices = (state) => {
  return state.loginDevices;
};

// activity Logs
export const activityLogs = (state) => {
  return state.activityLogs;
};

// approved Documents
export const approvedDocuments = (state) => {
  return state.approvedDocuments;
};

// user Verification Status
export const userVerificationStatus = (state) => {
  return state.userVerificationStatus;
};

// user level 2 Verification Data
export const level2VerificationData = (state) => {
  return state.level2VerificationData;
};

// all user Notifications
export const allNotifications = (state) => {
  return state.allNotifications;
};

// slider Notifications
export const sliderNotifications = (state) => {
  return state.sliderNotifications;
};

// unread Notification count
export const unreadNotificationCount = (state) => {
  return state.unreadNotificationCount;
};

// deposit Address
export const depositAddress = (state) => {
  return state.depositAddress;
};

export const depositType = (state) => {
  return state.depositType;
};

// all Coins
export const allCoins = (state) => {
  return state.allCoins;
};

//  current Coin
export const currentCoin = (state) => {
  return state.currentCoin;
};

export const currentCurrencyType = (state) => {
  return state.currentCurrencyType;
};

// select Country modal
export const selectCountryModal = (state) => {
  return state.selectCountryModal;
};

// select project modal
export const selectProjectModal = (state) => {
  return state.selectProjectModal;
};

// select network modal
export const selectNetworkModal = (state) => {
  return state.selectNetworkModal;
};

// project modal
export const projectModal = (state) => {
  return state.projectModal;
};

// portfolio modal
export const portfolioModal = (state) => {
  return state.portfolioModal;
};

// transaction Detail modal
export const transactionDetailModal = (state) => {
  return state.transactionDetailModal;
};

// small Popup
export const smallPopup = (state) => {
  return state.smallPopup;
};

// share Modal
export const shareModal = (state) => {
  return state.shareModal;
};

export const currentDepositAccount = (state) => {
  return state.currentDepositAccount;
};

export const currentDepositLink = (state) => {
  return state.currentDepositLink;
};

export const supportedBankList = (state) => {
  return state.supportedBankList;
};

export const currentWithdrawalAccountInfo = (state) => {
  return state.currentWithdrawalAccountInfo;
};

export const currentSelectedNetwork = (state) => {
  return state.currentSelectedNetwork;
};

export const userSelf = (state) => {
  return state.userSelf;
};

// activityFilters
export const activityFilters = () => {
  let today = new Date();
  let yesterday = new Date(today);
  let thisWeek = new Date(today);
  let thisMonth = new Date(today);
  let lastMonth = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  thisWeek.setDate(thisWeek.getDate() + 7);
  thisMonth.setDate(thisMonth.getDate() + 30);
  lastMonth.setDate(lastMonth.getDate() - 30);
  yesterday.toDateString();
  thisWeek.toDateString();
  thisMonth.toDateString();
  today.toDateString();

  return [
    {
      from: today,
      to: lastMonth,
      label: "Last 30 days",
    },
    {
      from: today,
      to: thisMonth,
      label: "This Month",
    },
    {
      from: today,
      to: thisWeek,
      label: "This Week",
    },
    {
      from: today,
      to: yesterday,
      label: "Yesterday",
    },
    {
      from: today,
      to: today,
      label: "Today",
    },
  ];
};
