<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header wallet-page-header">
          <div class="page-title">
            <!-- <i class='bx bx-left-arrow-alt' onclick="window.history.back();"></i> -->
            <h2>Portfolio</h2>
          </div>

          <router-link to="trade-history" class="page-breadcrum">
            <p>Trade History</p>
            <i class="bx bx-right-arrow-alt"></i>
          </router-link>
        </div>
        <div class="page-header-small-screen">
          <img
            src="@/assets/vectors/img/back-icon.png"
            class="arrow_bacck"
            alt=""
            onclick="window.history.back();"
          />
          <br />
          <h5>Portfolio</h5>
          <div class="light_green_small_button">
            <p>All Properties You Own</p>
          </div>
        </div>
      </div>
    </div>

    <div class="small_screen_effect_inner">
      <div class="row">
        <div class="col-md-6">
          <MyWalletBalance />
        </div>

        <Portfolio />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import Portfolio from "../components/portfolio/Portfolio.vue";
import MyWalletBalance from "../components/extras/MyWalletBalance.vue";
export default {
  components: {
    Portfolio,
    MyWalletBalance,
  },

  computed: {
    ...mapGetters(["userWalletBalance"]),
    totalAmount() {
      let sumall = 0;
      if (this.userWalletBalance && this.userWalletBalance.length) {
        sumall = this.userWalletBalance
          .map((item) => item.amount)
          .reduce((prev, curr) => prev + curr, 0);
      }

      return sumall;
    },
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style>
@media screen and (max-width: 580px) {
  .page-header-small-screen-balance {
    width: 95%;
    margin: 10px auto;
    /* padding: 20px; */
  }

  .light_green_small_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .light_green_small_button p {
    font-style: normal;
    font-weight: normal;
    font-size: 15.3753px;
    line-height: 18px;
    padding: 8px 13px;
    background: rgba(24, 133, 111, 0.05);
    border-radius: 6px;
    margin-bottom: 0px;
  }
}
</style>
