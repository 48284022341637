<template>
  <div class="infinitescrolltrigger">
    <div ref="infinitescrolltrigger" class="scroll-trigger text-center">
      <span
        v-if="isBussy"
        class="spinner-border spinner-border-md"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
// import PageHeader from "@/components/header/PageHeader";
// import Footer from "../components/footer/Footer";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isSearching"],
  components: {},
  computed: {
    ...mapGetters(["projects"]),

    currentPage: {
      get() {
        return this.$store.state.projectCurrentPage;
      },

      set(value) {
        return (this.$store.state.projectCurrentPage = value);
      },
    },

    totalPage: {
      get() {
        return this.$store.state.projectTotalPage;
      },

      set(value) {
        return (this.$store.state.projectTotalPage = value);
      },
    },
  },

  data() {
    return {
      isBussy: false,
      totalPages: 2,
      totalNoteResult: 100,
    };
  },

  methods: {
    ...mapActions(["fetchMoreProjects", "saveMoreProjects"]),

    scrollTrigger: function () {
      // if (!this.isSearching) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.intersectionRatio > 0 &&
            this.currentPage < this.totalPage &&
            this.projects.length > 0
            // this.totalNoteResult < this.allNotes.length
          ) {
            this.isBussy = true;
            this.currentPage += 1;
            this.fetchProjects(this.currentPage);
          }
        });
      });

      observer.observe(this.$refs.infinitescrolltrigger);
      // }
    },

    fetchProjects: function (currentPage) {
      // console.log(payload);

      this.fetchMoreProjects(currentPage)
        .then((response) => {
          this.isBussy = false;
          // console.log(response.data);
          // console.log(response.data.data);
          if (!response.error) {
            this.saveMoreProjects(response.data.data);

            this.currentPage = response.data.meta.page;
            this.totalPage = response.data.meta.pages;
          }
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.scrollTrigger();
  },
};
</script>
<style scoped>
.infinitescrolltrigger {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-trigger {
  margin-top: 50px;
  text-align: center;
}
</style>
