<template>
  <!-- {{ trade }} -->
  <tr class="table_roww" v-if="trade">
    <td>
      {{ formateDateAndTime(trade.date) }}
    </td>
    <td class="">
      <span
        :class="[
          trade.action.toLowerCase() == 'sell' && 'text-red',
          trade.action.toLowerCase() == 'swap' && 'text-orange',
          trade.action.toLowerCase() == 'buy' && 'text-green-dark',
        ]"
        >{{ trade.action }}</span
      >
    </td>
    <td v-if="trade.coinFrom">
      <div class="td-with-image">
        <img
          v-if="trade.coinFrom.iconUrl"
          style="border-radius: 100%; height: 20px"
          :src="trade.coinFrom.iconUrl"
          alt=""
        />
        <span>{{ trade.coinFrom.symbol }}</span>
      </div>
    </td>
    <td v-if="trade.coinTo">
      <div class="td-with-image">
        <img
          v-if="trade.coinTo.iconUrl"
          style="border-radius: 100%; height: 20px"
          :src="trade.coinTo.iconUrl"
          alt=""
        />
        <span>{{ trade.coinTo.symbol }}</span>
      </div>
    </td>
    <td>{{ formatAmount(trade.amount) }}</td>
    <td>{{ formatAmount(trade.price) }}</td>
    <td>{{ formatAmount(trade.tradingFee) }}</td>
    <td>{{ formatAmount(trade.totalAmount) }}</td>
    <td class="table-button">
      <button
        @click="goToTradeDetails(trade)"
        class="relsify-button light-shade-green font-weight-light"
      >
        Details
      </button>
    </td>
  </tr>
</template>

<script>
import "@/mixins";
export default {
  props: ["trade"],
  methods: {
    goToTradeDetails: function (trade) {
      this.$router.push({
        name: "SingleTrade",
        query: { id: trade.id },
      });
    },
  },
};
</script>

<style scoped>
.table_roww td {
  padding: 30px 10px;
  background: #fff;
}

.table_roww td p {
  display: inline;
  width: auto;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
</style>
