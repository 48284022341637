<template>
  <tr class="table_roww" v-if="history">
    <td>
      <p>
        {{ formateDateAndTimeByName(history.date) }}
      </p>
    </td>
    <td>
      {{ history.device }}
    </td>
    <td>{{ history.city + ", " + history.country }}</td>
    <td>{{ history.deviceClientType }}</td>
    <td>{{ history.ip }}</td>
  </tr>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["history"],
  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
    showTransactionDetailsModal: function (history) {
      this.setTransactionDetailsModal({
        status: true,
        payload: history,
        type: "",
      });
    },
  },
};
</script>

<style scoped>
.table_roww td {
  padding: 40px 10px;
  background: #fff;
}
.table_roww td p {
  display: inline;
  width: auto;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
</style>
