<template>
  <div class="slide-cards">
    <!-- <carousel
      :autoplay="false"
      :nav="false"
      :dots="false"
      :stagePadding="60"
      :margin="20"
      :autoplayTimeout="4000"
      :responsive="{
        0: {
          items: 1,
        },
        600: {
          items: 1,
        },
        1000: {
          items: 2,
        },
      }"
    > -->

    <div class="slide-card">
      <div class="slide-card-top">
        <div>
          <p>Deposit/withdrawal</p>
          <h4>Wallet</h4>
        </div>
        <img src="@/assets/vectors/img/wallet.png" alt="" />
      </div>
      <div class="slide-card-button">
        <button
          @click="
            $router.push({
              name: 'Wallet',
            })
          "
        >
          <span>Fund wallet</span>
          <i class="bx bx-right-arrow-circle"></i>
        </button>
      </div>
      <div class="slide-card-shadow">
        <img src="@/assets/vectors/img/Ellipse.png" alt="" />
      </div>
    </div>
    <div class="slide-card">
      <div class="slide-card-top">
        <div>
          <p>Buy & Sell Assets</p>
          <h4>Trade</h4>
        </div>
        <img src="@/assets/vectors/img/people-trading-2.png" alt="" />
      </div>
      <div class="slide-card-button">
        <button
          @click="
            $router.push({
              name: 'Trade',
            })
          "
        >
          <span>Buy/Sell</span> <i class="bx bx-right-arrow-circle"></i>
        </button>
      </div>
      <div class="slide-card-shadow">
        <img src="@/assets/vectors/img/Ellipse.png" alt="" />
      </div>
    </div>
    <!-- </carousel> -->
  </div>
</template>

<script>
// import carousel from "vue-owl-carousel";
export default {
  components: {
    // carousel,
  },
};
</script>

<style></style>
