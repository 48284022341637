<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Trade</h2>
          </div>
          <router-link to="trade-history" class="page-breadcrum">
            <p>Trade History</p>
            <i class="bx bx-right-arrow-alt"></i>
          </router-link>
        </div>
      </div>
    </div>

    <div class="after-page-header" v-if="projectToBuy">
      <TradePageTab />

      <div class="text-center" v-if="showSwapNotification">
        <div class="notification-alert notification-alert-warning">
          <div class="notification-alert-item">
            <img src="@/assets/vectors/img/warning-error.png" alt="" />
            <p>
              <b>Note:</b> You’re selling off your share of
              {{ projectToBuy?.title }} and swapping it for that of
              {{ projectTosell?.title }}. once you do, your investment in
              {{ projectToBuy?.title }} is transferred to that of
              {{ projectTosell?.title }}.
            </p>

            <i
              class="bx bx-x closeNotificationAlert"
              @click="showSwapNotification = false"
            ></i>
          </div>
        </div>
      </div>

      <!-- {{ swapPrice }} -->

      <div class="page-card">
        <div class="mt-4">
          <div class="page-inner-content-grid one-grid">
            <div class="page-inner-content-grid-item">
              <div class="row">
                <div class="col-md-9 mx-auto">
                  <div class="text-center" style="width: 100%">
                    <div
                      class="text-center"
                      style="width: 100%"
                      v-if="projectToBuy"
                    >
                      <div class="select-button width-100 text-left">
                        <label for="">Select asset To swap</label>

                        <div
                          class="select-input select-input-input"
                          @click="showProjectModal('')"
                        >
                          <div>
                            <img
                              v-if="projectToBuy.mainImage.url"
                              :src="projectToBuy.mainImage.url"
                              alt=""
                              style="
                                border-radius: 100%;
                                width: 25px !important;
                                height: 25px !important;
                                object-fit: cover;
                              "
                            />
                            <input
                              type="text"
                              :value="projectToBuy?.title"
                              disabled
                            />
                          </div>
                          <i class="bx bx-chevron-down" id="selectToken"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center" style="width: 100%">
                    <div
                      class="text-center"
                      style="width: 100%"
                      v-if="projectTosell"
                    >
                      <div class="select-button width-100 text-left">
                        <label for="">Asset To swap with</label>

                        <div
                          class="select-input select-input-input"
                          @click="showProjectModal('type1')"
                        >
                          <div>
                            <img
                              v-if="projectTosell.mainImage.url"
                              :src="projectTosell.mainImage.url"
                              alt=""
                              style="
                                border-radius: 100%;
                                width: 25px !important;
                                height: 25px !important;
                                object-fit: cover;
                              "
                            />
                            <input
                              type="text"
                              :value="projectTosell?.title"
                              disabled
                            />
                          </div>
                          <i class="bx bx-chevron-down" id="selectToken"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="select-button-notes">
                  <div class="select-button-notes-content bg-green">
                    <img
                      src="@/assets/vectors/img/wallet-xm.png"
                      width="10px"
                      alt=""
                    />
                    <p>
                      You have {{ userBalance ? userBalance.amount : 0 }}
                      {{ userBalance ? userBalance.coin.name : "coin" }} in your
                      wallet
                    </p>
                  </div>
                </div> -->

              <div class="select-button-notes" v-if="swapPrice">
                <div class="select-button-notes-content bg-yellow-light">
                  <p>
                    Note : {{ projectTosell?.title }} swapping price =
                    {{ formatAmount(swapPrice.swapPrice) }}
                  </p>
                </div>
              </div>

              <div class="counter-button-grid" v-if="swapPrice">
                <div class="counter-button-grid-item">
                  <label for="">Amount </label>
                  <div class="counter-button">
                    <i class="bx bx-minus" @click="decreaseAmount"></i>
                    <input
                      type="text"
                      style="width: 100% !important; max-width: 70%"
                      v-model="amount"
                      @input="calculateAmount"
                    />
                    <i class="bx bx-plus" @click="increaseAmount"></i>
                  </div>
                </div>
                <div class="counter-button-grid-item">
                  <label for=""> Equivalent </label>
                  <div class="counter-button">
                    <!-- <span>500</span> -->
                    <div class="flex_counter_button">
                      <!-- <span>$</span> -->
                      <input
                        style="width: 100% !important; max-width: 100%"
                        type="text"
                        v-model="equivalentAmount"
                        @input="calculateEquivalent"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="accpted-terms" v-if="swapPrice && !acceptTerms">
                <div class="relsify-checkbox">
                  <input
                    type="checkbox"
                    @change="toggleTerms"
                    :value="acceptTerms"
                  />
                  <p>
                    I certify that I have read, understand and accept Finnacle’s
                    <a href="" class="green_color">Terms & Conditions</a>
                  </p>
                </div>
              </div>

              <div class="select-counter-button" v-if="swapPrice">
                <button
                  class="relsify-button"
                  @click="swapAssets"
                  :disabled="emptyFields"
                >
                  Swap Asset
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="after-page-header" v-if="projectToBuy == null">
      <div
        class="d-flex justify-content-center align-items-center"
        style="background: #fff"
      >
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <LoadingComponent />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import Decimal from "decimal.js";
import { mapActions, mapGetters } from "vuex";
import TradePageTab from "@/components/trade/TradePageTab.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
export default {
  components: {
    TradePageTab,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["projects", "userWalletBalance"]),
    currentPageType: {
      get() {
        return this.$store.state.currentPageType;
      },
      set(value) {
        return (this.$store.state.currentPageType = value);
      },
    },
    userBalance() {
      let balance = null;
      if (
        this.userWalletBalance &&
        this.userWalletBalance.length &&
        this.currentProject
      ) {
        balance = this.userWalletBalance
          .slice(0)
          .find(
            (balance) =>
              balance.coin.coinId == this.currentProject.selectedToken.coinId
          );
        // console.log(balance);
      }

      return balance;
    },

    currentAmountBaseOnCurrentProject() {
      let amount = null;
      if (this.currentProject && this.portfolios && this.portfolios.length) {
        let projectAmount = this.portfolios.find(
          (projectName) =>
            projectName.balance.coin.symbol == this.currentProject.coin.symbol
        );

        // console.log(projectAmount);
        if (projectAmount) {
          amount = projectAmount.balance.usdValue;
        }
      }

      return amount;
    },

    projectToBuy: {
      get() {
        return this.$store.state.projectToBuy;
      },
      set(value) {
        return (this.$store.state.projectToBuy = value);
      },
    },

    projectTosell: {
      get() {
        return this.$store.state.projectTosell;
      },
      set(value) {
        return (this.$store.state.projectTosell = value);
      },
    },

    acceptTerms: {
      get() {
        console.log(typeof this.$store.state.acceptTerms);
        return this.$store.state.acceptTerms;
      },
      set(value) {
        // console.log('set', value)
        return (this.$store.state.acceptTerms = value);
      },
    },
  },
  data() {
    return {
      amount: 1,
      equivalentAmount: 0,
      showSwapNotification: true,
      emptyFields: true,
      loading: false,
      swapPrice: null,
    };
  },

  methods: {
    ...mapActions([
      "setCurrentPageName",
      "setCurrentProject",
      "setProjectModal",
      "getSingleProject",
      "setPageLoading",
      "setAlertpopUp",
    ]),
    updateCurrentPageType: function () {
      this.currentPageType = this.$router.currentRoute._rawValue.name;
      this.showMarketPlace();
    },

    toggleTerms: function () {
      this.acceptTerms = !this.acceptTerms;
      if (this.acceptTerms) {
        localStorage.setItem("axxeptTerms", true);
      }
      this.validateForm();
    },

    showProjectModal: function (swapping) {
      this.setProjectModal({
        status: true,
        title: "",
        desc: "",
        payload: null,
        select1Type: swapping,
        select2Type: "",
        select3Type: "",
        select4Type: "",
        select5Type: "",
        select6Type: "",
        type: "SWAP_PROJECT",
      });

      // console.log(this.projectTosell);
      // console.log(swapping);
    },

    decreaseAmount: function () {
      if (!this.amount) {
        return;
      }

      this.amount = --this.amount;
      this.calculateAmount();
    },

    increaseAmount: function () {
      this.amount = ++this.amount;
      this.calculateAmount();
    },

    calculateAmount: function () {
      if (!this.amount) {
        // this.amount = 1;
        return;
      }

      // this.equivalentAmount = new Decimal(this.amount)
      //   .times(this.swapPrice.swapPrice)
      //   .toFixed(2);

      this.equivalentAmount = new Decimal(this.amount)
        .times(this.swapPrice.swapPrice)
        .toFixed(2);

      this.validateForm();
    },

    calculateEquivalent: function () {
      if (!this.equivalentAmount) {
        // this.equivalentAmount = new Decimal(this.amount)
        //   .dividedBy(this.swapPrice.swapPrice)
        //   .toFixed(2);
        return;
      }
      // this.amount = new Decimal(this.amount)
      //   .dividedBy(this.swapPrice.swapPrice)
      //   .toFixed(2);

      this.amount = new Decimal(this.equivalentAmount)
        .dividedBy(this.swapPrice.swapPrice)
        .toFixed(2);
      this.validateForm();
    },

    validateForm: function () {
      if (!this.amount || !this.acceptTerms) {
        this.emptyFields = true;
        return false;
      }

      this.emptyFields = false;
      return true;
    },

    fetchSingleProject: function () {
      setTimeout(() => {
        if (this.$route.query?.id) {
          this.getSingleProject(this.$route.query?.id);
        } else {
          if (this.projects) {
            let project = this.projects[0];
            this.getSingleProject(project?.id);
          } else {
            this.fetchSingleProject();
          }
        }
      }, 2600);
    },

    getSwapPrice: function () {
      let url = `${this.RELSIFY_URL}/api/project/swap-price`;
      this.setPageLoading(true);
      this.emptyFields = true;

      // const token = sessionStorage.getItem("Appoen");

      let newPayload = {
        projectToBuy: this.$route.query?.id
          ? this.$route.query?.id
          : this.projectToBuy?.id,
        projectToSell: this.projectTosell?.id,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setPageLoading(false);
          console.log(data.data);
          if (!data.error) {
            this.swapPrice = data.data;
            // this.amount = this.swapPrice.minimumSwapAmount;
            this.amount = 1;
            this.calculateAmount();
          } else {
            this.setAlertpopUp({
              status: true,
              title: " Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch((error) => {
          this.setPageLoading(false);
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });
          error;
          // console.log(error);
        });
    },

    swapAssets: function () {
      let url = `${this.RELSIFY_URL}/api/swap/project`;
      this.setPageLoading(true);

      const token = sessionStorage.getItem("Appoen");

      let newPayload = {
        projectToBuy: this.projectToBuy?.id,
        projectToSell: this.projectTosell?.id,
        amount: this.amount,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setPageLoading(false);
          // console.log(data.data);
          if (!data.error) {
            this.setAlertpopUp({
              status: true,
              title: "Swap successful",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            this.globalFunction();
            this.setAlertMessageModal({
              status: true,
              title: "Swap successful",
              des: data.meta.message,
              button1Text: "Portfolio",
              button1Url: "/portfolio",
              button2: true,
              button2Text: "Swap more",
              button2Url: "SWAP",
              type: "SWAP",
              payload: null,
            });

            this.resetFields();
          } else {
            this.setAlertpopUp({
              status: true,
              title: " Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch((error) => {
          this.setPageLoading(false);
          this.setAlertpopUp({
            status: true,
            title: "Server Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });
          error;
          // console.log(error);
        });
    },

    resetFields() {
      this.amount = 0;
      this.equivalentAmount = 0;
      this.showSwapNotification = true;
      this.emptyFields = true;
      this.loading = false;
      this.acceptTerms = false;
      this.swapPrice = null;
    },
  },
  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    this.fetchSingleProject();

    setTimeout(() => {
      this.getSwapPrice();
    }, 1800);
  },

  beforeRouteLeave(to, from, next) {
    this.$store.state.currentPageType = null;
    this.setPageLoading(false);
    next();
  },

  watch: {
    "$route.query?.id": function () {
      this.resetFields();
      setTimeout(() => {
        this.getSwapPrice();
      }, 1800);

      this.fetchSingleProject();
      this.validateForm();
    },

    projectTosell: function () {
      this.swapPrice = null;
      this.getSwapPrice();
    },
  },

  //  beforeRouteLeave(to, from, next) {
  //   this.removeOffcanvas();
  //   next();
  // },
};
</script>

<style scoped></style>
