<template>
  <div class="relsify-notification-item" v-if="notification">
    <div
      class="relsify-notification-item-title"
      style="cursor: pointer"
      @click="goToDetailsPage(notification)"
    >
      <span>{{ formateDateAndTimeByName(notification.date) }}</span>
      <p>
        {{ notification.content.slice(0, 110) }}
        <span
          style="color: var(--mainGreenColor)"
          v-if="notification.content.length > 110"
          >... <b>read more</b></span
        >
      </p>
    </div>
    <div
      class="relsify-notification-item-icon"
      :class="!notification.isRead && 'unread'"
    >
      <i class="bx bxs-check-circle"></i>
    </div>
  </div>
</template>

<script>
import "@/mixins";

export default {
  props: ["notification"],

  methods: {
    updateReadNotification: function () {
      let url = `${this.RELSIFY_URL}/api/verification/level-two/document/upload?documentType=${this.documentType}&documentRefNumber=${this.IdCardNumber}`;
      let fd = new FormData();
      this.axios
        .put(url, fd)
        .then((response) => {
          this.globalFunction();
          this.isImageUploading = false;
          if (!response.data.error) {
            this.globalFunction();
            this.selectedDocuments = null;
            this.selectedDocumentName = "";
            this.IdCardNumber = "";
            this.formMessage = "";
            this.validateFields();
            this.setAlertpopUp({
              status: true,
              title: "verification Submitted",
              des: response.data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Error",
              des: response.data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(response.data);
          }
        })
        .catch(() => {
          this.isImageUploading = false;
          this.setAlertpopUp({
            status: true,
            title: "Verification Error",
            des: "An Error occured while Verifying Document",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },

    goToDetailsPage: function (notification) {
      this.$router.push({
        name: "NotificationDetails",
        query: { id: notification.id },
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .relsify-notification-page .relsify-notification-item {
    position: relative;
    padding: 10px 20px 20px;
  }

  .relsify-notification-page
    .relsify-notification-item
    .relsify-notification-item-icon {
    position: absolute;
    top: 0px;
    right: 10px;
  }
  .relsify-notification-page
    .relsify-notification-item
    .relsify-notification-item-title {
    margin-right: 0px;
  }
  .relsify-notification-page
    .relsify-notification-item
    .relsify-notification-item-title
    p {
    margin-top: 26px;
  }
}
</style>
