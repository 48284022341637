<template>
  <div class="alert-modal verifypinModal" v-if="verifyPinCodeModal.status">
    <div class="alert-modal-overlay" @click="closeVerifyPinCode"></div>
    <ModalLoading />
    <div class="alert-modal-card vivify popInBottom">
      <div class="close-alert-button">
        <i class="bx bx-x" id="closeAlertModal" @click="closeVerifyPinCode"></i>
      </div>

      <div class="alert-modal-body">
        <h5>{{ verifyPinCodeModal.title }}</h5>
        <p>{{ verifyPinCodeModal.desc }}</p>

        <div class="instructionMessageInputCodes">
          <input
            type="text"
            maxlength="1"
            ref="emailCode1"
            class="emailCode"
            id="emailCode1"
            data-next="2"
            data-prev="1"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode2"
            class="emailCode"
            id="emailCode2"
            data-next="3"
            data-prev="2"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode3"
            class="emailCode"
            id="emailCode3"
            data-next="4"
            data-prev="3"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode4"
            class="emailCode"
            data-next="5"
            data-prev="4"
            id="emailCode4"
          />
          <input
            type="text"
            maxlength="1"
            ref="emailCode5"
            class="emailCode"
            data-next="6"
            id="emailCode5"
          />
          <input
            type="text"
            maxlength="1"
            class="emailCode"
            data-next="6"
            ref="emailCode6"
            id="emailCode6"
            @input="checkCode"
          />
        </div>

        <div class="text-center resendCode">
          <!-- <a href="javascript:;" @click="resendCode" class="green-color"
            >Resend code</a
          > -->
        </div>

        <div class="alert-modal-button">
          <button class="relsify-button green-button">Verify</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import localForage from "localforage";
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
import ModalLoading from "./ModalLoading.vue";
export default {
  components: {
    ModalLoading,
  },
  computed: {
    ...mapGetters(["verifyPinCodeModal", "currentCurrencyType"]),
    sideNotificationModal() {
      return this.$store.state.sideNotificationModal;
    },
  },

  data() {
    return {
      modalLoading: false,
    };
  },

  methods: {
    ...mapActions([
      "setModalLoading",
      "setAlertpopUp",
      "getUserProfile",
      "saveLoginDetails",
    ]),
    checkCode() {
      let allValues =
        this.$refs.emailCode1.value +
        this.$refs.emailCode2.value +
        this.$refs.emailCode3.value +
        this.$refs.emailCode4.value +
        this.$refs.emailCode5.value +
        this.$refs.emailCode6.value;

      if (allValues.length == 6) {
        // console.log(allValues);
        this.setModalLoading(true);
        this.processAction(allValues);
      }
    },

    processAction: function (value) {
      if (this.verifyPinCodeModal.type == "LOGIN") {
        this.verifyLogin(value, this.verifyPinCodeModal.payload);
        return;
      }

      if (this.verifyPinCodeModal.type == "CHANGE_EMAIL") {
        this.changeEmail(value, this.verifyPinCodeModal.payload);
        return;
      }

      if (this.verifyPinCodeModal.type == "DELETE_ACCOUNT") {
        this.deleteAccountFn(value, this.verifyPinCodeModal.payload);
        return;
      }

      if (this.verifyPinCodeModal.type == "CONFIRM_WALLET_WITHDRWAL") {
        this.confirmWalletWithdrawal(value, this.verifyPinCodeModal.payload);
        return;
      }
      if (this.verifyPinCodeModal.type == "VERIFY_EMAIL_ACCOUNT") {
        this.verifyEmailAccount(value, this.verifyPinCodeModal.payload);
        return;
      }
    },

    confirmWalletWithdrawal: function (token, payload) {
      let url, newPayload;

      if (this.currentCurrencyType === "Fiat") {
        url = `${this.RELSIFY_URL}/api/wallet/withdraw/fiat/bank`;
        newPayload = {
          secretTokenId: payload.secretTokenId,
          secretTokenCode: token,
          bankCode: payload.bankCode,
          accountNumber: payload.accountNumber,
          coin: payload.coin,
          amount: payload.amount,
        };
      } else {
        url = `${this.RELSIFY_URL}/api/withdraw`;
        newPayload = {
          secretTokenId: payload.secretTokenId,
          secretTokenCode: token,
          coinIdOrSymbol: payload.symbol,
          networkName: payload.network,
          address: payload.address,
          amount: payload.amount,
        };
      }

      // console.log(newPayload);

      // const loginToken = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setModalLoading(false);
          this.clearFormFields();
          if (!data.error) {
            this.globalFunction();

            this.setAlertpopUp({
              status: true,
              title: "Withdrawal successful",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            this.closeVerifyPinCode();

            this.$router.push({
              name: "Wallet",
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Withdrawal Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });

            // console.log(data);
          }
        })
        .catch(() => {
          this.setModalLoading(false);
          this.clearFormFields();
          // console.log(error);
        });
    },

    verifyEmailAccount: function (value, payload) {
      let secretTokenId = payload.secretTokenId;
      let token = value;

      let url = `${this.RELSIFY_URL}/api/verify/email`;

      let newPayload = {
        secretTokenId: secretTokenId,
        secretTokenCode: token,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTimeout(() => {
            this.setModalLoading(false);
            if (!data.error) {
              this.clearFormFields();

              this.globalFunction();

              this.setAlertpopUp({
                status: true,
                title: "Email Verification successfully",
                des: data.meta.message,
                type: "success",
                payload: null,
              });
              this.closeVerifyPinCode();
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Email Verification Error",
                des: data.meta.message,
                type: "error",
                payload: null,
              });
              this.clearFormFields();
              // console.log(data);
            }
          }, 3000);
        })
        .catch(() => {
          this.setModalLoading(false);
          this.clearFormFields();
          // console.log(error);
        });
    },
    changeEmail: function (value, payload) {
      let secretTokenId = payload.secretTokenId;
      let token = value;

      let url = `${this.RELSIFY_URL}/api/verify/email/change`;

      let newPayload = {
        secretTokenId: secretTokenId,
        secretTokenCode: token,
      };

      // const loginToken = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setModalLoading(false);
          if (!data.error) {
            this.clearFormFields();

            this.globalFunction();

            this.setAlertpopUp({
              status: true,
              title: "Email change successfully",
              des: data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Email Change Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            this.clearFormFields();
            // console.log(data);
          }
        })
        .catch(() => {
          this.setModalLoading(false);
          this.clearFormFields();
          // console.log(error);
        });
    },

    deleteAccountFn: function (value, payload) {
      const otpId = payload.otpId;
      const reason = payload.reason;
      const otpCode = value;

      const url = `${this.RELSIFY_URL}/api/close-account`;

      const newPayload = {
        otpId,
        reason,
        otpCode,
      };

      // const loginToken = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(newPayload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setModalLoading(false);
          if (!data.error) {
            this.clearFormFields();

            this.globalFunction();

            this.setAlertpopUp({
              status: true,
              title: "Successful",
              des: data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Action Deletion Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            this.clearFormFields();
            // console.log(data);
          }
        })
        .catch(() => {
          this.setModalLoading(false);
          this.clearFormFields();
          // console.log(error);
        });
    },

    verifyLogin: function (value, payload) {
      let secretTokenId = payload.secretTokenId;
      let email = payload.email;
      let phone = payload.phoneNumber;
      let token = value;

      let url = `${this.RELSIFY_URL}/api/verify-login`;
      let newPayload;

      if (phone) {
        newPayload = {
          secretTokenId: secretTokenId,
          secretTokenCode: token,
          phoneNumber: phone,
        };
      } else {
        newPayload = {
          secretTokenId: secretTokenId,
          secretTokenCode: token,
          email: email,
        };
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newPayload),
      };
      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (!data.error) {
            // console.log(token);
            // console.log(data);
            this.saveLoginDetails(data.data);
            // console.log("here");
            this.clearFormFields();

            // localStorage.setItem("Appoen", data.meta.token);
            // localStorage.setItem("actForms", data.data.id);

            /**
             * Debugging loval storage in IOS
             */

            // const isIOS =  [
            //     'iPad Simulator',
            //     'iPhone Simulator',
            //     'iPod Simulator',
            //     'iPad',
            //     'iPhone',
            //     'iPod'
            //   ].includes(navigator.platform)
            //   // iPad on iOS 13 detection
            //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

            // if (isIOS) {
            //   const tokenStored = localStorage.getItem("Appoen");
            //   window.alert(tokenStored);
            // }

            // Result: local storage is working on iOS 13

            localForage.setItem("actForms", data.data.id);
            localForage.setItem("Appoen", data.meta.token).then(() => {
              this.axios.defaults.headers.common["Authorization"] =
                data.meta.token;

              this.setAlertpopUp({
                status: true,
                title: "Login Succesful",
                des: data.meta.message,
                type: "success",
                payload: null,
              });

              this.closeVerifyPinCode();
              this.getUserProfile(data.meta.token);

              setTimeout(() => {
                this.$store.dispatch("globalFunction");
                this.setModalLoading(false);
                this.$router.push({ name: "Home" });
                // location.reload();
              }, 900);
              setTimeout(() => {
                this.setAlertpopUp({
                  status: false,
                  title: "",
                  des: "",
                  type: "success",
                  payload: null,
                });
              }, 1900);
            });
            // setTimeout(() => {
            //   this.setAlertpopUp({
            //     status: false,
            //     title: "",
            //     des: "",
            //     type: "error",
            //     payload: null,
            //   });
            // }, 1200);
          } else {
            this.setModalLoading(false);
            this.setAlertpopUp({
              status: true,
              title: "Login Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(data);
            this.clearFormFields();
          }
        })
        .catch(() => {
          this.setModalLoading(false);
          this.clearFormFields();
          // console.log(error);
        });
    },

    resendEmailCode: function (email) {
      this.setModalLoading(true);
      let url = `${this.RELSIFY_URL}/api/change/email`;
      let payload = {
        newEmail: email,
      };

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.setModalLoading(false);
          this.clearFormFields();
          // console.log(data);
          if (!data.error) {
            this.setAlertpopUp({
              status: true,
              title: "Resend email code successfully",
              des: data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Resend email code Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });

            this.clearFormFields();
          }
        })
        .catch(() => {
          this.setModalLoading(false);
          this.setAlertpopUp({
            status: true,
            title: "Resend email code",
            des: "An Error occured while trying to resend email code",
            type: "error",
            payload: null,
          });
          // console.log(error);
          this.clearFormFields();
        });
    },

    resendCode: function () {
      if (this.verifyPinCodeModal.type == "LOGIN") {
        this.clearFormFields();
        this.closeVerifyPinCode();

        this.setAlertpopUp({
          status: true,
          title: "Retry Login",
          des: "Please retry to login",
          type: "error",
          payload: null,
        });
        return;
      }

      if (this.verifyPinCodeModal.type == "CHANGE_EMAIL") {
        this.resendEmailCode(this.verifyPinCodeModal.payload.email);
        return;
      }
    },

    clearFormFields: function () {
      this.$refs.emailCode1.value = "";
      this.$refs.emailCode2.value = "";
      this.$refs.emailCode3.value = "";
      this.$refs.emailCode4.value = "";
      this.$refs.emailCode5.value = "";
      this.$refs.emailCode6.value = "";
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
.alert-modal-card {
  width: 480px;
  height: auto;
}

.instructionMessageInputCodes {
  width: 100%;
}

.instructionMessageInputCodes input {
  width: 50px;
  height: 39px;
  background: rgba(24, 133, 111, 0.05);
  border-radius: 171.051px;
}

.alert-modal-body h5 {
  text-align: center;
  margin-top: 40px;
  font-weight: 500;
  font-size: 19px;
}

.resendCode {
  margin-top: 30px;
  margin-bottom: 20px;
}

.resendCode a {
  font-weight: bold;
  text-decoration: none;
  font-size: 13px;
}

.modalLoading {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(231, 231, 231, 0.73);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden !important;
  z-index: 9980 !important;
  border-radius: 30px;
}

@media screen and (max-width: 580px) {
  .alert-modal-card {
    width: 98%;
  }
}
</style>
