<template>
  <div class="home-header" v-if="user">
    <div class="left-home-header">
      <div class="say-hello">
        <img
          @click="
            $router.push({
              name: 'Profile',
            })
          "
          v-if="user.image?.url"
          :src="user.image?.url"
          alt=""
          class="helloImage"
          style="cursor: pointer; width: 58px; height: 58px; object-fit: cover"
        />
        <img
          v-else
          @click="
            $router.push({
              name: 'Profile',
            })
          "
          src="@/assets/img/default.png"
          alt=""
          class="helloImage"
          style="cursor: pointer; width: 58px; height: 58px; object-fit: cover"
        />
        <div class="say-hello-des">
          <h4 v-if="user.firstName">
            <span>Hi</span> {{ user.firstName }}
            <img src="@/assets/vectors/img/wave-hand.png" width="14px" alt="" />
          </h4>
          <h4 v-else>
            Welcome
            <img src="@/assets/vectors/img/wave-hand.png" width="14px" alt="" />
          </h4>
          <p><b>Ready to co-own a property?</b></p>
        </div>
      </div>
    </div>
    <div class="right-home-header">
      <div class="relsify-search">
        <i class="bx bx-search-alt-2"></i>
        <input
          type="search"
          @click="showMarketPlace"
          placeholder="Search Marketplace"
        />
      </div>
      <div class="small-screen-relsify-search-icon" @click="showMarketPlace">
        <i class="bx bx-search-alt-2"></i>
      </div>
      <div
        class="home-notification-icon"
        id="notification-icon"
        @click="showSideNotificationModal"
      >
        <i class="bx bxs-bell"></i>

        <span class="new-notification" v-if="unreadNotificationCount">
          {{ unreadNotificationCount.count }}</span
        >
      </div>
      <div class="relsify-icon-menu">
        <i class="bx bx-menu relsifyMenuIcon"></i>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
export default {};
</script>

<style></style>
