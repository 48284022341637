<template>
  <div class="site-security-settings">
    <div class="site-security-settings-pageTitle">
      <h3>Change Email</h3>
    </div>
    <div class="site-security-settings-contents">
      <div class="site-security-settings-contents-items-inner">
        <div class="form-group mb-5">
          <div class="" style="margin-top: 10px">
            <label> New Email </label>

            <div class="verifyInputsWithButton">
              <input
                type="text"
                @input="validateInput"
                placeholder="your new email "
                v-model="newEmail"
              />
            </div>
            <span class="form-settings-warning" v-if="emailErrorMessage"
              >{{ emailErrorMessage }}
            </span>
          </div>
        </div>

        <div class="form-group mb-5">
          <button
            :disabled="emptyFields"
            @click="changeEmail"
            class="relsify-button"
          >
            <span v-if="!loading">Submit</span>
            <span class="loader" v-if="loading"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
export default {
  components: {},

  data() {
    return {
      newEmail: "",
      emptyFields: true,
      loading: false,
      emailErrorMessage: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "setVerifyPinCodeModal"]),
    validateInput: function () {
      if (!this.newEmail) {
        this.emptyFields = true;
        this.emailErrorMessage = "Email field is required";
        return false;
      }

      if (!this.validEmail(this.newEmail)) {
        this.emptyFields = true;
        this.emailErrorMessage = "Invalid email field ";
        return false;
      }

      this.emptyFields = false;
      this.emailErrorMessage = "";
      return true;
    },

    changeEmail: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/change/email`;
      let payload = {
        newEmail: this.newEmail,
      };

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          // console.log(data);
          if (!data.error) {
            let newData = {
              secretId: data.data.secretId,
              email: this.newEmail,
            };

            this.responseData = data.data;
            this.setVerifyPinCodeModal({
              status: true,
              title: "Confirm change email",
              desc: data.meta.message,
              redirectUrl: null,
              type: "CHANGE_EMAIL",
              payload: newData,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Email change Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Email change Error",
            des: "An Error occured while trying to change email",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}

.form-settings-warning {
  color: rgb(197, 0, 0);
}
</style>
