<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <div class="site-security-settings">
                <div class="site-security-settings-pageTitle">
                  <h3>{{ isEmail ? "Add Email" : "Add Phone Number" }}</h3>
                </div>
                <div class="site-security-settings-contents">
                  <div
                    class="site-security-settings-contents-items-inner"
                    style="width: 100% !important; max-width: 460px !important"
                  >
                    <div class="form-group mb-5">
                      <div class="" style="margin-top: 10px">
                        <label v-if="!hasPendingVerification">
                          {{ isEmail ? "Email" : "Phone Number" }}
                        </label>
                        <label v-else>
                          Enter the code sent to {{ newValue }}
                        </label>

                        <div class="verifyInputsWithButton">
                          <div v-if="!hasPendingVerification">
                            <input
                              v-if="isEmail"
                              type="email"
                              :placeholder="
                                isEmail ? 'enter email' : 'enter phone number'
                              "
                              v-model="newValue"
                            />
                            <div v-else class="form_grid_double">
                              <div
                                class="mb-3 mt-3 form_groups_grid_item phone_register"
                                v-if="countries && countries.length"
                              >
                                <div
                                  class="select-input select-input-input"
                                  style="cursor: pointer !important"
                                  @click="showCountries"
                                >
                                  <div
                                    style="
                                      cursor: pointer !important;
                                      display: flex;
                                    "
                                  >
                                    <img
                                      style="cursor: pointer !important"
                                      :src="selectedCountry.flag"
                                      v-if="selectedCountry.flag"
                                      alt=""
                                    />
                                    <input
                                      type="text"
                                      style="
                                        cursor: pointer !important;
                                        text-align: left !important;
                                        padding-left: 0px !important;
                                        margin-left: 3px !important;
                                        padding-right: 5px !important;
                                      "
                                      disabled
                                      :value="selectedCountry.isoCode"
                                    />
                                  </div>
                                  <i
                                    class="bx bx-chevron-down"
                                    id="selectToken"
                                  ></i>
                                </div>
                              </div>

                              <div class="form_groups_grid_item">
                                <span
                                  ><b>{{ selectedCountry.dialCode }}</b></span
                                >
                                <input
                                  type="text"
                                  v-model="newValue"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                  autocomplete="off"
                                  placeholder="phone number"
                                  style="font-weight: 600"
                                />
                              </div>
                            </div>
                          </div>
                          <input
                            v-else
                            type="text"
                            placeholder="Enter code"
                            v-model="code"
                          />
                        </div>
                        <span class="form-settings-warning" v-if="errorMessage"
                          >{{ errorMessage }}
                        </span>
                      </div>
                    </div>

                    <div class="form-group mb-5">
                      <button
                        :disabled="
                          hasPendingVerification
                            ? !newValue || !code
                            : !newValue
                        "
                        @click="
                          () => {
                            if (hasPendingVerification) {
                              verifyCode();
                            } else {
                              handleTrigger();
                            }
                          }
                        "
                        class="relsify-button"
                      >
                        <span v-if="!isLoading">{{
                          hasPendingVerification ? "Submit" : "Get code"
                        }}</span>
                        <span class="loader" v-if="isLoading"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";
import localforage from "localforage";

export default {
  components: {
    SettingsLeftTab,
  },

  computed: {
    isEmail() {
      return this.$route.path === "/security/manage-email";
    },

    countries() {
      return this.$store.state.countries;
    },
    countryFlag: {
      get() {
        return this.$store.state.countryFlag;
      },
      set(value) {
        return (this.$store.state.countryFlag = value);
      },
    },

    countryDialCode: {
      get() {
        return this.$store.state.countryDialCode;
      },
      set(value) {
        return (this.$store.state.countryDialCode = value);
      },
    },

    selectedCountry: {
      get() {
        return this.$store.state.selectedCountry;
      },
      set(value) {
        this.validateForm();
        return (this.$store.state.selectedCountry = value);
      },
    },
  },

  data() {
    return {
      newValue: "",
      code: "",
      codeId: "",
      errorMessage: "",
      isLoading: false,
      hasPendingVerification: false,
      appToken: "",
    };
  },

  methods: {
    ...mapActions([
      "setCurrentPageName",
      "globalFunction",
      "setAlertpopUp",
      "setSelectCountryModal",
    ]),

    showCountries: function () {
      this.setSelectCountryModal({
        status: true,
        title: "",
        type: "",
        payload: null,
      });
    },
    handleTrigger: function () {
      // console.log("fireddd");
      if (this.newValue) {
        const url = `${this.RELSIFY_URL}/api/initiate/${
          this.isEmail ? "email" : "phonenumber"
        }/code?${this.isEmail ? "email" : "phoneNumber"}=${
          this.isEmail
            ? this.newValue.trim().split(" ").join("")
            : this.selectedCountry.dialCode + this.newValue
        }`;

        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.appToken,
          },
        };

        this.isLoading = true;

        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.isLoading = false;
            if (!data.error) {
              // console.log(data);
              this.codeId = data.data?.secretTokenId || "";
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Code sent!",
                des: data.meta.message,
                type: "success",
                payload: null,
              });

              this.hasPendingVerification = true;
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Update Status",
                des: data.meta.message,
                type: "error",
                payload: null,
              });
              // console.log(data);
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.setAlertpopUp({
              status: true,
              title: "Update Status",
              des: `An Error occured while adding your ${
                this.isEmail ? "email" : "phone number"
              }`,
              type: "error",
              payload: null,
            });
            // console.log(error);
          });
      } else {
        this.setAlertpopUp({
          status: true,
          title: this.isEmail
            ? "Email is required"
            : "Phone number is required",
          des: "All fields are required",
          type: "error",
          payload: null,
        });
      }
    },

    verifyCode: function () {
      if (this.code) {
        const url = `${this.RELSIFY_URL}/api/verify/${
          this.isEmail ? "email" : "phonenumber"
        }`;

        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: this.token || this.$store.state?.token,
          },
          body: JSON.stringify({
            secretTokenCode: this.code,
            secretTokenId: this.codeId,
          }),
        };

        this.isLoading = true;

        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.isLoading = false;
            if (!data.error) {
              // console.log(data);
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Success!",
                des: data.meta.message,
                type: "success",
                payload: null,
              });

              // navigate to settings
              // this.$router.push({
              //   name: "Settings",
              // });
              window.location.href = "/settings";
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Update Status",
                des: data.meta.message,
                type: "error",
                payload: null,
              });
              // console.log(data);
            }
          })
          .catch(() => {
            this.isLoading = false;
            this.setAlertpopUp({
              status: true,
              title: "Update Status",
              des: `An error occured while verifying ${
                this.isEmail ? "email" : "phone number"
              }`,
              type: "error",
              payload: null,
            });
            // console.log(error);
          });
      } else {
        this.setAlertpopUp({
          status: true,
          title: "Code is required",
          des: "All fields are required",
          type: "error",
          payload: null,
        });
      }
    },
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();

    localforage.getItem("Appoen").then((token) => {
      this.appToken = token || "";
    });
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
