<template>
  <div class="paged_div">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <!-- {{ user }} -->
          <HomeHeader />
        </div>
      </div>

      <div class="row relsify-row">
        <div class="col-md-8 mt-4 relsify-home-column-8">
          <SliderNotification />

          <SlideCards />

          <div class="projects">
            <h4>Projects</h4>
            <div class="projects-search">
              <div class="relsify-search">
                <i class="bx bx-search-alt-2"></i>
                <input
                  type="search"
                  @click="showMarketPlace"
                  placeholder="Search projects"
                />
              </div>
            </div>
            <!-- <div class="page-small-tabs">
              <div class="nav-tab">
                <a href="" class="active">Latest</a>
                <a href="">Trending</a>
                <a href="">Upcoming</a>
                <a href="">Featured</a>
                <a href="">Popular</a>
              </div>
            </div> -->

            <Projects />
          </div>

          <div class="get-started">
            <h4>GET STARTED</h4>

            <div class="get-started-grid">
              <div class="get-started-card">
                <img src="@/assets/vectors/img/user-pencil-edit.png" />
                <h5>Set up your profile</h5>
                <p>Set up your profile for ease of use whenever you login</p>

                <hr />
                <div class="get-started-button">
                  <button
                    @click="
                      $router.push({
                        name: 'Profile',
                      })
                    "
                  >
                    <span>Setup </span> <i class="bx bx-right-arrow-circle"></i>
                  </button>
                </div>
              </div>
              <div class="get-started-card">
                <img src="@/assets/vectors/img/user-details.png" />
                <h5>Verifications</h5>
                <p>We just want to know you more one click verification.</p>

                <hr />
                <div class="get-started-button">
                  <button
                    @click="
                      $router.push({
                        name: 'SiteVerification',
                      })
                    "
                  >
                    <span>Setup </span> <i class="bx bx-right-arrow-circle"></i>
                  </button>
                </div>
              </div>
              <div class="get-started-card">
                <img src="@/assets/vectors/img/security-lock.png" />
                <h5>2FA Setup</h5>
                <p>Secure your account with Two-Factor Authentication.</p>

                <hr />
                <div class="get-started-button">
                  <button>
                    <span>Setup </span> <i class="bx bx-right-arrow-circle"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 mt-4 relsify-home-column-4">
          <HomePortfolio />
          <HomeHistories />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import SlideCards from "../components/home/SlideCards.vue";
import HomeHeader from "../components/home/HomeHeader.vue";
import HomePortfolio from "../components/portfolio/HomePortfolio.vue";
import HomeHistories from "../components/activity/HomeHistories.vue";
import SliderNotification from "../components/notification/slider/SliderNotification.vue";
import Projects from "../components/projects/Projects.vue";
export default {
  name: "Home",
  components: {
    SlideCards,
    HomeHeader,
    HomePortfolio,
    HomeHistories,
    SliderNotification,
    Projects,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // console.log(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>
