<template>
  <div class="after-page-header">
    <div class="row">
      <div class="col-md-12 mb-3">
        <div class="relsify-search-form">
          <div class="relsify-table-search grid-search">
            <!-- <div class="relsify-table-search-item">
              <label for="">Type</label>
              <div class="select_">
                <select>
                  <option value="">All</option>
                  <option value="">Some</option>
                </select>
              </div>
            </div> -->
            <div class="relsify-table-search-item">
              <label for="">Filter By:</label>
              <div class="select_">
                <select v-if="activityFilters" v-model="selectedDate">
                  <option :value="null">All</option>
                  <option
                    v-for="(date, index) in activityFilters"
                    :key="index"
                    :value="date"
                  >
                    {{ date.label }}
                  </option>
                </select>
              </div>
            </div>
            <!-- <div class="relsify-table-search-item">
              <label for="">Assets</label>
              <div class="select_">
                <select>
                  <option value="">All</option>
                  <option value="">Some</option>
                </select>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-3">
        <div class="relsify-table tr-shadow">
          <div class="table-responsive table-web">
            <table class="table">
              <thead class="text-center">
                <tr class="">
                  <th>Date</th>
                  <th>Action</th>
                  <th>Token From</th>
                  <th>Token To</th>
                  <th>Amount</th>
                  <th>Price</th>
                  <th>Fee</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody
                class="text-center"
                v-if="histories && histories.length > 0"
              >
                <DividendHistoryTableRow
                  v-for="(dividend, index) in histories"
                  :key="index"
                  :dividend="dividend"
                />
                <tr style="box-shadow: none !important">
                  <td colspan="6">
                    <div
                      class="text-center d-flex justify-content-center align-items-center"
                    >
                      <Infinitescrolltrigger />
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-if="dividendHistory && histories && histories.length <= 0">
                <tr>
                  <td colspan="8">
                    <div class="text-center">
                      <h6>No dividend history</h6>
                    </div>
                    <br />
                    <br />
                    <br />
                  </td>
                </tr>
              </tbody>
              <tbody v-if="dividendHistory == null">
                <tr>
                  <td colspan="8">
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <br />
                      <LoadingComponent />
                      <br />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <DividendMobile :dividendHistory="dividendHistory" :histories="histories" />
        </div>
        <div v-if="histories && histories.length <= 0">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters } from "vuex";
import DividendHistoryTableRow from "./DividendHistoryTableRow.vue";
import DividendMobile from "./DividendMobile.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";
import Infinitescrolltrigger from "./Infinitescrolltrigger.vue";
export default {
  components: {
    DividendHistoryTableRow,
    LoadingComponent,
    DividendMobile,
    Infinitescrolltrigger,
  },
  computed: {
    ...mapGetters(["dividendHistory", "activityFilters"]),

    histories() {
      let histories = [];

      if (this.dividendHistory && this.dividendHistory.length > 0) {
        if (this.selectedDate == null) {
          histories = this.dividendHistory;
        } else {
          if (this.selectedDate.label == "Last 30 days") {
            histories = this.dividendHistory.filter((history) => {
              let date = new Date(history.date).toDateString();
              // console.log(this.selectedDate.from.toDateString());
              // console.log(date);
              // console.log(this.selectedDate.to.toDateString());
              return date >= this.selectedDate.to.toDateString();
            });
          } else if (this.selectedDate.label == "Today") {
            histories = this.dividendHistory.filter((history) => {
              let date = new Date(history.date).toDateString();

              return (
                this.selectedDate.from.toDateString() == date &&
                date <= this.selectedDate.to.toDateString()
              );
            });
          } else {
            histories = this.dividendHistory.filter((history) => {
              let date = new Date(history.date).toDateString();

              return (
                this.selectedDate.from.toDateString() >= date &&
                date <= this.selectedDate.to.toDateString()
              );
            });
          }
        }
      }

      // console.log(histories);

      return histories;
    },
  },

  data() {
    return {
      searchText: "",
      selectedDate: null,
    };
  },
};
</script>

<style></style>
