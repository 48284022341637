<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <UpdateProfile v-if="user" />
              <div
                v-if="!user"
                class="d-flex justify-content-center align-items-center"
                style="
                  background: #ffffff;
                  padding: 10px 40px 20px;
                  border-radius: 4.80178px;
                "
              >
                <br />
                <br />
                <LoadingComponent />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";
import UpdateProfile from "../../components/forms/profile/UpdateProfile.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
export default {
  components: {
    SettingsLeftTab,
    UpdateProfile,
    LoadingComponent,
  },

  data() {
    return {
      gender: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>
