import { createApp } from "vue";
import { GOOGLE_CLIENT_ID } from "./config";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Localbase from "localbase";
import localforage from "localforage";
import axios from "axios";

import numeral from "numeral";
// import TawkMessengerVue from "@tawk.to/tawk-messenger-vue";

import VueAxios from "vue-axios";
import GAuth from "vue3-google-oauth2";
const gAuthOptions = {
  clientId: GOOGLE_CLIENT_ID,
  scope: "email",
  prompt: "consent",
  fetch_basic_profile: false,
};

// import GoogleAuth from "@/helpers/google_oAuth.js";
// const gauthOption = {
//   clientId:
//     "193087593348-2o7b9pp460bt0ihc0i44et2ci58ipo3o.apps.googleusercontent.com",
//   scope: "profile email",
//   prompt: "select_account",
// };

// import VueSocialSharing from "vue-social-sharing";

import myMixin from "./mixins/index";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
let db = new Localbase("db");
db.config.debug = false;

const app = createApp(App);
app.config.globalProperties.axios = axios;
app.config.globalProperties.$localBase = db;
app.config.globalProperties.$numberic = numeral;
app.use(store);
app.use(router);
app.use(VueAxios, axios);
app.use(GAuth, gAuthOptions);
// app.use(numeral);
// app.use(TawkMessengerVue, {
//   propertyId: "6229e161a34c2456412a6312",
//   widgetId: "relsifychat",
// });
// app.use(GoogleAuth, gauthOption);
// app.use(VueSocialSharing);

app.mixin(myMixin);

// const token = localStorage.getItem("token");
// Supply a list of drivers, in order of preference.
localforage.setDriver([
  localforage.INDEXEDDB,
  localforage.WEBSQL,
  localforage.LOCALSTORAGE,
]);
localforage
  .ready()
  .then(function () {
    console.log(`localforage is ready - using ${localforage.driver()}`);
    // const token = localStorage.getItem("token");
    localforage.getItem("Appoen").then((token) => {
      if (token) {
        axios.defaults.headers.common["Authorization"] = token;
        // axios.defaults.baseURL  = process.env.VUE_APP_BASE_URL;
      } else {
        localforage.clear();
      }
    });
  })
  .catch(function (e) {
    console.log(e);
  });
app.mount("#app");
