<template>
  <div id="app">
    <!-- {{ currentRoute }} -->
    <NotificationModal />
    <div v-if="isProtectedRoutes" id="main-wrapper">
      <Sidebar />
      <MobileMenuNav />

      <div class="content-body" style="position: relative">
        <div class="main-container">
          <MarketPlaceModal />
          <!-- <AllModals /> -->
          <!-- <transition name="page" mode="out-in">
            <router-view />
          </transition> -->
          <router-view v-slot="{ Component }">
            <transition name="fade" mode="out-in">
              <!-- :enter-active-class="route.meta.enterClass"
              :leave-active-class="route.meta.leaveClass" -->
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
    <div class="oauth" v-else>
      <div class="main_page">
        <OauthHeader />
        <!-- <transition name="page" mode="out-in">
          <router-view />
        </transition> -->

        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
    <AllModals />
  </div>
</template>

<script>
import localForage from "localforage";
import store from "./store";
// createHost([
//   {
//     origin: store.state.DASHBOARD_URL,
//     allowedMethods: ["get", "set", "remove"],
//   },
//   {
//     origin: store.state.WEBSITE_URL,
//     allowedMethods: ["get", "set", "remove"],
//   },
// ]);

import OauthHeader from "./components/navbar/oauth/OauthHeader";
import Sidebar from "./components/navbar/relsifyNavbar/Sidebar";
import MobileMenuNav from "./components/navbar/relsifyNavbar/MobileMenuNav";
import NotificationModal from "./components/modals/NotificationModal";
import MarketPlaceModal from "./components/modals/MarketPlaceModal.vue";
import AllModals from "./components/modals/AllModals.vue";
import $ from "jquery";
import { mapActions } from "vuex";
import "@/mixins";
import { computed } from "vue";
import { useRoute } from "vue-router";

import { useGetters, useMutations } from "./helpers/helpers";
export default {
  setup() {
    const { countries } = useGetters(["countries"]);
    const { GET_COUNTRIES } = useMutations(["GET_COUNTRIES"]);
    const { GET_STATES } = useMutations(["GET_STATES"]);
    const currentRoute = computed(() => {
      return useRoute().meta.requiresAuth;
    });

    const isProtectedRoutes = computed(() => {
      return useRoute().meta.requiresAuth;
    });

    GET_STATES();
    GET_COUNTRIES();

    return {
      currentRoute,
      isProtectedRoutes,
      countries,
      GET_COUNTRIES,
    };
  },
  components: {
    OauthHeader,
    Sidebar,
    MobileMenuNav,
    NotificationModal,
    MarketPlaceModal,
    AllModals,
  },

  methods: {
    ...mapActions(["setCurrentProject", "globalFunction", "setAlertpopUp"]),
    checkUserAuthentication: function () {
      // const token = !!localStorage.getItem("Appoen");
      const self = this;
      localForage.getItem("Appoen").then((token) => {
        if (token) {
          self.globalFunction();
          self.$store.commit("GET_COUNTRIES");
          self.$store.commit("GET_STATES");
        }
      });
    },

    getCurrentProject: function () {
      let currentProject = localStorage.getItem("current-project")
        ? JSON.parse(localStorage.getItem("current-project"))
        : null;

      if (currentProject) {
        currentProject.selectedToken = currentProject.tradeInfo.allowedCoins[0];
        this.setCurrentProject(currentProject);

        // console.log(currentProject);
      }
    },

    checkIfUserIsAuthenticatedCrossSite: function () {
      var createHost = require("cross-domain-storage/host");
      createHost([
        {
          origin: store.state.DASHBOARD_URL,
          allowedMethods: ["get", "set", "remove"],
        },
        {
          origin: store.state.WEBSITE_URL,
          allowedMethods: ["get", "set", "remove"],
        },
      ]);
    },
  },

  mounted() {
    this.checkUserAuthentication();
    this.checkIfUserIsAuthenticatedCrossSite();
    const alertFn = this.setAlertpopUp;
    // Page-tab
    // $(document).on("click", ".relsify-tablinks", function (e) {
    //   e.preventDefault();
    //   if ($(this).hasClass("disabled-link")) {
    //     return;
    //   } else {
    //     $(".page-tab-line .relsify-tablinks").removeClass("active");
    //     $(this).addClass("active");
    //     $(".progressbar-tab-line .progressbar-tablink").removeClass("active");

    //     let TabId = $(this).attr("tab-id");

    //     $(".page-tab-content .relsify-tab-pane").removeClass("active");
    //     $(".page-tab-content #" + TabId).addClass("active");

    //     $(".progressbar-tablink").removeClass("active");

    //     if (TabId) {
    //       $(".progressbar-tablink").removeClass("active");
    //       let firstLink = $("#" + TabId + " .progressbar-tablink")[0];

    //       if (firstLink) {
    //         firstLink.classList.add("active");
    //       }

    //       // console.log(firstLink);
    //       $(".progressbar-tab-line .progressbar-tablink").removeClass("active");
    //       $(
    //         "#" +
    //           TabId +
    //           " .progressbar-tab-content .progressbar-tab-pane:first-child"
    //       ).addClass("active");

    //       $(
    //         ".progressbar-tab-content .progressbar-tab-pane #siteVerificationTermsCondition"
    //       ).removeClass("active");
    //     }
    //   }
    // });

    $(document).on("click", ".progressbar-tablink", function (e) {
      e.preventDefault();
      if ($(this).hasClass("disabled-link")) {
        if ($(this).attr("data-tab-error-message")) {
          alertFn({
            status: true,
            title: "Update Status",
            des: $(this).attr("data-tab-error-message"),
            type: "error",
            payload: null,
          });
        }

        return;
      } else {
        $(".progressbar-tab-line .progressbar-tablink").removeClass("active");
        $(this).addClass("active");

        let tabText = $(this).text();

        $(".settings-title").text("Verify " + tabText);

        let TabId = $(this).attr("tab-id");

        $(".progressbar-tab-content .progressbar-tab-pane").removeClass(
          "active"
        );
        $(".progressbar-tab-content #" + TabId).addClass("active");
      }
    });

    $(document).on("click", ".register_with .register_with_link", function (e) {
      e.preventDefault();

      $(".register_with .register_with_link").removeClass("isActive");
      $(this).addClass("isActive");

      let TabId = $(this).attr("tab-id");

      if (TabId == "registerWithBoth") {
        $(".forms_div .forms_div_tabs").addClass("active");
      } else {
        $(".forms_div .forms_div_tabs").removeClass("active");
        $(".forms_div #" + TabId).addClass("active");
      }
    });

    // $(".emailCode").keyup(function () {
    //   // console.log(e);
    //   if (this.value.length === this.maxLength) {
    //     let next = $(this).data("next");
    //     $("#emailCode" + next).focus();
    //   } else {
    //     let prev = $(this).data("prev");
    //     $("#emailCode" + prev).focus();
    //   }
    // });

    // $(document).on("keyup", ".instructionMessageInputCodes", function (e) {
    //   var target = e.srcElement || e.target;
    //   var valuee = e.target.value;

    //   var regex = /^[a-zA-Z]+$/;

    //   if (valuee.match(regex)) {
    //     e.target.value = "";
    //   } else {
    //     var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    //     var myLength = target.value.length;
    //     if (myLength >= maxLength) {
    //       var next = target;
    //       // eslint-disable-next-line no-cond-assign
    //       while ((next = next.nextElementSibling)) {
    //         if (next == null) break;
    //         if (next.tagName.toLowerCase() === "input") {
    //           next.focus();
    //           break;
    //         }
    //       }
    //     }
    //     // Move to previous field if empty (user pressed backspace)
    //     else if (myLength === 0) {
    //       var previous = target;
    //       // eslint-disable-next-line no-cond-assign
    //       while ((previous = previous.previousElementSibling)) {
    //         if (previous == null) break;
    //         if (previous.tagName.toLowerCase() === "input") {
    //           previous.focus();
    //           break;
    //         }
    //       }
    //     }
    //   }
    // });

    // if (document.getElementsByClassName("instructionMessageInputCodes")) {
    // var instructionMessageInputCodes = document.querySelector(
    //           "instructionMessageInputCodes"
    //         );
    //         instructionMessageInputCodes.onkeyup = function (e) {
    //           var target = e.srcElement || e.target;
    //           var valuee = e.target.value;

    //           var regex = /^[a-zA-Z]+$/;

    //           if (valuee.match(regex)) {
    //             e.target.value = "";
    //           } else {
    //             var maxLength = parseInt(target.attributes["maxlength"].value, 10);
    //             var myLength = target.value.length;
    //             if (myLength >= maxLength) {
    //               var next = target;
    //               // eslint-disable-next-line no-cond-assign
    //               while ((next = next.nextElementSibling)) {
    //                 if (next == null) break;
    //                 if (next.tagName.toLowerCase() === "input") {
    //                   next.focus();
    //                   break;
    //                 }
    //               }
    //             }
    //             // Move to previous field if empty (user pressed backspace)
    //             else if (myLength === 0) {
    //               var previous = target;
    //               // eslint-disable-next-line no-cond-assign
    //               while ((previous = previous.previousElementSibling)) {
    //                 if (previous == null) break;
    //                 if (previous.tagName.toLowerCase() === "input") {
    //                   previous.focus();
    //                   break;
    //                 }
    //               }
    //             }
    //           }
    //         };
    // }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.2s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  /* transform: scale(0.9); */
}

.carousel__prev,
.carousel__next {
  background-color: var(--vc-nav-background-color);
  border-radius: var(--vc-nav-width);
  width: var(--vc-nav-width);
  height: var(--vc-nav-width);
  text-align: center;
  font-size: calc(var(--vc-nav-width) * 2 / 3);
  padding: 0;
  color: var(--vc-nav-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
}

.carousel__prev:focus,
.carousel__next:focus {
  outline: none;
}

.carousel__prev {
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.carousel__next {
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.productDetailss .carousel__prev {
  left: 20px;
}

.productDetailss .carousel__next {
  right: 20px;
}

.productDetailss .carousel__prev,
.productDetailss .carousel__next {
  background-color: var(--vc-clr-white);
  border-radius: var(--vc-nav-width);
  width: var(--vc-nav-width);
  height: var(--vc-nav-width);
  text-align: center;
  font-size: calc(var(--vc-nav-width) * 2 / 3);
  padding: 0;
  color: var(--vc-clr-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  border: 0;
  cursor: pointer;
}

:root {
  /* Color */
  --vc-clr-primary: #18856f;
  --vc-clr-secondary: #cacaca;
  --vc-clr-white: #ffffff;

  /* Icon */
  --vc-icn-width: 1.2em;

  /* Navigation */
  --vc-nav-width: 30px;
  --vc-nav-color: #ffffff;
  --vc-nav-background-color: var(--vc-clr-primary);

  /* Pagination */
  --vc-pgn-width: 5px;
  --vc-pgn-height: 9px;
  --vc-pgn-margin: 5px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: var(--vc-clr-secondary);
  --vc-pgn-active-color: var(--vc-clr-primary);
}
.carousel__icon {
  width: var(--vc-icn-width);
  height: var(--vc-icn-width);
  fill: currentColor;
}
.carousel__slide {
  scroll-snap-stop: auto;
  flex-shrink: 0;
  margin: 0;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel {
  position: relative;
  text-align: center;
  box-sizing: border-box;
}

.productDetailss .carousel {
  width: 100%;
  height: 100% !important;
}

.productDetailss .carousel__viewport {
  width: 100%;
  height: 100% !important;
}

.carousel * {
  box-sizing: border-box;
}

.carousel__track {
  display: flex;
  margin: 0;
  padding: 0;
  position: relative;
}

.productDetailss .carousel__track {
  height: 100% !important;
}

.carousel__viewport {
  overflow: hidden;
}
.carousel__pagination {
  display: flex;
  justify-content: center;
  list-style: none;
}
.carousel__pagination-button {
  margin: var(--vc-pgn-margin);
  width: var(--vc-pgn-width);
  height: var(--vc-pgn-height);
  border-radius: var(--vc-pgn-height);
  border: 0;
  cursor: pointer;
  background-color: var(--vc-pgn-background-color);
}
.carousel__pagination-button:focus {
  outline: none;
}

.carousel__pagination-button--active {
  background-color: var(--vc-pgn-active-color);
}
</style>
