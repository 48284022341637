<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <div class="site-verification-settings">
                <div class="row" v-if="userVerificationStatus">
                  <div class="col-xl-12">
                    <VerificationLevelTab />

                    <div class="page-tab-content">
                      <div
                        class="relsify-tab-pane active"
                        id="siteVerificationLevel3"
                      >
                        <!-- v-if="
                            userVerificationStatus.level3.status == 'verified'
                          " -->
                        <div
                          class="settings-verify-email"
                          v-if="userVerificationStatus.level3.status !== 'done'"
                        >
                          <h4 class="settings-title">
                            Verify Personal Information
                          </h4>
                          <IdentityVerificationLevel3 />
                        </div>
                        <div
                          class="settings-verify-email"
                          v-if="userVerificationStatus.level2.status !== 'done'"
                        >
                          <br /><br />
                          <br /><br />

                          <h4 class="settings-title">
                            Please complete Level 2 verification
                          </h4>
                          <br /><br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!userVerificationStatus">
                  <div class="col-md-12">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="
                        background: #ffffff;
                        padding: 10px 40px 20px;
                        border-radius: 4.80178px;
                      "
                    >
                      <br />
                      <br />
                      <LoadingComponent />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";
import IdentityVerificationLevel3 from "../../components/settings/verification/IdentityVerificationLevel3.vue";
import VerificationLevelTab from "../../components/settings/verification/VerificationLevelTab.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
export default {
  components: {
    SettingsLeftTab,
    VerificationLevelTab,
    IdentityVerificationLevel3,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["userVerificationStatus"]),

    hasVerifiedAllProfileDetails() {
      let verified = false;

      if (
        this.user &&
        this.user.firstName &&
        this.user.lastName &&
        this.user.middleName &&
        this.user.gender &&
        this.user.country &&
        this.user.state &&
        this.user.address
      ) {
        verified = true;
      }

      return verified;
    },
  },

  data() {
    return {
      gender: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();

    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
