<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <!-- <div class="hideOnMobile"> -->
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <div class="site-verification-settings">
                <div class="row" v-if="userVerificationStatus">
                  <div class="col-xl-12">
                    <VerificationLevelTab />

                    <div class="page-tab-content">
                      <div
                        class="relsify-tab-pane active"
                        id="siteVerificationLevel1"
                      >
                        <div class="settings-verify-email">
                          <h4 class="settings-title">Verify Email</h4>

                          <ul id="progressbar" class="progressbar-tab-line">
                            <li
                              class="active progressbar-tablink"
                              tab-id="siteVerificationEmail"
                              :class="[hasVerifiedEmailOrPhone && 'verified']"
                            >
                              Email
                            </li>
                            <li
                              class="progressbar-tablink"
                              :class="[hasVerifiedEmailOrPhone && 'verified']"
                              tab-id="siteVerificationPhone"
                            >
                              Phone Number
                            </li>
                            <li
                              class="progressbar-tablink"
                              tab-id="siteVerificationTermsCondition"
                              :class="[
                                userVerificationStatus.level1.acceptedTerms &&
                                  'verified',
                              ]"
                            >
                              Accept Terms & Condition
                            </li>
                          </ul>
                          <div class="progressbar-tab-content">
                            <div
                              class="progressbar-tab-pane active"
                              id="siteVerificationEmail"
                            >
                              <VerifyEmail :status="hasVerifiedEmailOrPhone" />
                            </div>
                            <div
                              class="progressbar-tab-pane"
                              id="siteVerificationPhone"
                            >
                              <VerifyPhone :status="hasVerifiedEmailOrPhone" />
                            </div>
                            <div
                              class="progressbar-tab-pane"
                              id="siteVerificationTermsCondition"
                            >
                              <AcceptTerms
                                :status="
                                  userVerificationStatus.level1.acceptedTerms
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!userVerificationStatus">
                  <div class="col-md-12">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="
                        background: #ffffff;
                        padding: 10px 40px 20px;
                        border-radius: 4.80178px;
                      "
                    >
                      <br />
                      <br />
                      <LoadingComponent />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";
import VerifyEmail from "../../components/settings/verification/VerifyEmail.vue";
import VerifyPhone from "../../components/settings/verification/VerifyPhone.vue";
import AcceptTerms from "../../components/settings/verification/AcceptTerms.vue";
import VerificationLevelTab from "../../components/settings/verification/VerificationLevelTab.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
export default {
  components: {
    SettingsLeftTab,
    VerificationLevelTab,
    VerifyEmail,
    VerifyPhone,
    AcceptTerms,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["userVerificationStatus"]),

    hasVerifiedEmailOrPhone() {
      let verified = false;

      if (
        this.userVerificationStatus.level1.phoneNumberVerified ||
        this.userVerificationStatus.level1.emailVerified
      ) {
        verified = true;
      }

      return verified;
    },
  },

  data() {
    return {
      gender: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),

    checkLevelsOne: function () {
      if (
        this.userVerificationStatus &&
        this.userVerificationStatus.level1.status == "done" &&
        (this.userVerificationStatus.level2.status == "unverified" ||
          this.userVerificationStatus.level3.status == "unverified")
      ) {
        this.$router.push({
          name: "SiteVerificationLevel2",
        });
      } else {
        setTimeout(() => {
          this.checkLevelsOne();
        }, 1000);
      }
    },
  },
  mounted() {
    this.closeMarketPlace();
    this.checkLevelsOne();

    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
