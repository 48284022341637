<template>
  <div class="page-tab-line">
    <ul>
      <li>
        <a
          href="javascript:;"
          class="relsify-tablinks"
          tab-id="UserTransaction"
          @click="$router.push({ name: 'UserTransactionHistory' })"
          :class="currentPageName == 'UserTransactionHistory' && 'active'"
          >User Transaction</a
        >
      </li>
      <li>
        <a
          href="javascript:;"
          @click="$router.push({ name: 'DepositTransactionHistory' })"
          class="relsify-tablinks"
          tab-id="DepositTransaction"
          :class="currentPageName == 'DepositTransactionHistory' && 'active'"
          >Deposit Transaction</a
        >
      </li>
      <li>
        <a
          href="javascript:;"
          @click="$router.push({ name: 'WithdrawalTransactionHistory' })"
          class="relsify-tablinks"
          :class="currentPageName == 'WithdrawalTransactionHistory' && 'active'"
          tab-id="withdrawalTransaction"
          >Withdrawal Transaction</a
        >
      </li>
      <li>
        <a
          href="javascript:;"
          @click="$router.push({ name: 'Dividend' })"
          class="relsify-tablinks"
          :class="currentPageName == 'Dividend' && 'active'"
          tab-id="dividend"
          >Dividend History</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import "@/mixins";
export default {
  // computed: {
  //   ...mapGetters(["depositAddress"]),
  // },

  // methods: {
  //   ...mapActions(["setCurrentPageName", ""]),

  //   checkDepositAddress: function () {
  //     this.$router.push({
  //       name: "WalletDeposit",
  //     });
  //   },
  //   goToWithdrawal: function () {
  //     this.$router.push({
  //       name: "WalletWithdrawal",
  //     });
  //   },
  // },
};
</script>

<style scoped>
@media (max-width: 580px) {
  .page-tab-line ul {
    display: grid !important;
    grid: auto / 50% 50% !important;
    gap: 20px 0px
  }
}
</style>
