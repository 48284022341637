<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <WalletHeader />

    <div class="small_screen_effect_inner wallet_balance_page">
      <div class="row">
        <div class="col-md-6" id="LargeBalancee">
          <MyWalletBalance />
        </div>
        <div class="col-xl-12">
          <div class="transcHistory">
            <router-link
              :to="{
                name: 'UserTransactionHistory',
              }"
              class="page-breadcrum"
            >
              Transaction History
            </router-link>
          </div>
          <WalletTabs />
          <WalletFilters />
          <div class="page-tab-content">
            <div class="relsify-tab-pane active" id="walletBalance">

              <WalletBalance />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import WalletHeader from "./WalletHeader.vue";
import WalletBalance from "@/components/wallet/wallet-balance/WalletBalance.vue";
import WalletTabs from "../../components/wallet/WalletTabs.vue";
import MyWalletBalance from "../../components/extras/MyWalletBalance.vue";
import WalletFilters from "../../components/wallet/WalletFilters.vue";

export default {
  components: {
    WalletHeader,
    WalletTabs,
    WalletBalance,
    MyWalletBalance,
    WalletFilters
},

  methods: {
    ...mapActions(["setCurrentPageName", ""]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .container-fluid .small_screen_effect_inner .row {
    margin-left: 0px ;
    margin-right: 0px;
  }
}
</style>
