<template>
  <div class="row">
    <div class="col-md-12 mb-3">
      <div
        class="relsify-notification-items-div relsify-notification-page"
        v-if="notification"
      >
        <div class="relsify-notification-item" v-if="notification">
          <div class="relsify-notification-item-title">
            <span>12/11/2021</span>
            <h4 style="margin-top: 50px; margin-left: 0px; margin-bottom: 33px">
              {{ notification.topic }}
            </h4>
            <p>
              {{ notification.content }}
            </p>
            <br />
            <br />
            <br />
            <br />
          </div>
          <!-- <div
            class="relsify-notification-item-icon"
            :class="!notification.isRead && 'unread'"
          >
            <i class="bx bxs-check-circle"></i>
          </div> -->
        </div>
      </div>

      <div class="relsify-notification-items-div" v-if="notification == null">
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <LoadingComponent />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["allNotifications"]),

    // notification() {
    //   let notifications = null;

    //   if (this.allNotifications && this.allNotifications.length > 0) {
    //     notifications = this.allNotifications.filter(
    //       (notification) => notification.id == this.$route.query.id
    //     );
    //   }

    //   return notifications;
    // },
  },

  data() {
    return {
      searchText: "",
      notification: null,
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "globalFunction"]),
    getSingleNotification: function () {
      let url = `${this.RELSIFY_URL}/api/user-notification/${this.$route.query.id}`;

      this.axios
        .get(url)
        .then((response) => {
          this.globalFunction();
          if (!response.data.error) {
            this.globalFunction();
            this.notification = response.data.data;
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Error",
              des: response.data.meta.message,
              type: "error",
              payload: null,
            });

            setTimeout(() => {
              this.$router.push({
                name: "Home",
              });
            }, 2000);
          }
        })
        .catch(() => {
          this.setAlertpopUp({
            status: true,
            title: "Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });
          // console.log(error);
          setTimeout(() => {
            this.$router.push({
              name: "Home",
            });
          }, 2000);
        });
    },
  },

  mounted() {
    if (!this.$route.query.id) {
      window.history.back();

      return;
    }

    this.getSingleNotification();
  },
};
</script>

<style scoped>
.relsify-notification-items-div h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 6px;
  text-align: left;
  letter-spacing: -0.24px;
  color: #18856f;
  margin-left: 10px;
  margin-bottom: 10px;
}

@media screen and (max-width: 580px) {
  .relsify-notification-page .relsify-notification-item {
    position: relative;
    padding: 10px 20px 20px;
  }

  .relsify-notification-page
    .relsify-notification-item
    .relsify-notification-item-icon {
    position: absolute;
    top: 0px;
    right: 10px;
  }
  .relsify-notification-page
    .relsify-notification-item
    .relsify-notification-item-title {
    margin-right: 0px;
  }
  .relsify-notification-page
    .relsify-notification-item
    .relsify-notification-item-title
    p {
    margin-top: 26px;
  }
}
</style>
