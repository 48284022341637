<template>
  <div class="col-md-12 mb-3">
    <div class="relsify-search-form">
      <div class="relsify-table-search grid-search">
        <!-- <div class="relsify-table-search-item">
            <label for="">Type</label>
            <div class="select_">
              <select>
                <option value="">All</option>
                <option value="">Some</option>
              </select>
            </div>
          </div> -->
        <div class="relsify-table-search-item">
          <label for="">Filter By:</label>
          <div class="select_">
            <select v-if="activityFilters" v-model="selectedDate">
              <option :value="null">All</option>
              <option
                v-for="(date, index) in activityFilters"
                :key="index"
                :value="date"
              >
                {{ date.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relsify-table tr-shadow">
    <div
      class="table-responsive table-web"
      style="background: transparent !important"
    >
      <table class="table">
        <thead class="text-left">
          <tr class="">
            <th>Date/Time</th>
            <th>Source</th>
            <th>Location</th>
            <th>Type</th>
            <th>IP Address</th>
          </tr>
        </thead>

        <tbody class="text-left" v-if="histories && histories.length > 0">
          <LoginHistoryTableRow
            v-for="(history, index) in histories"
            :key="index"
            :history="history"
          />
        </tbody>
        <tbody v-if="loginHistories && histories && histories.length <= 0">
          <tr>
            <td colspan="8">
              <div class="text-center">
                <h6>No Login history</h6>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="loginHistories == null">
          <tr>
            <td colspan="8">
              <div class="d-flex justify-content-center align-items-center">
                <br />
                <LoadingComponent />
                <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <LoginHistoryMobile
      :histories="histories"
      :loginHistories="loginHistories"
    />
    <div v-if="histories && histories.length <= 0">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters } from "vuex";
import LoginHistoryTableRow from "./LoginHistoryTableRow.vue";
import LoginHistoryMobile from "./LoginHistoryMobile.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    LoginHistoryTableRow,
    LoginHistoryMobile,
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["loginHistories", "activityFilters"]),
    histories() {
      let histories = [];

      if (this.loginHistories && this.loginHistories.length > 0) {
        if (this.selectedDate == null) {
          histories = this.loginHistories;
        } else {
          if (this.selectedDate.label == "Last 30 days") {
            histories = this.loginHistories.filter((history) => {
              let date = new Date(history.date).toDateString();
              return date >= this.selectedDate.to.toDateString();
            });
          } else {
            histories = this.loginHistories.filter((history) => {
              let date = new Date(history.date).toDateString();

              return (
                this.selectedDate.from.toDateString() >= date &&
                date <= this.selectedDate.to.toDateString()
              );
            });
          }
        }
      }

      return histories;
    },
  },
  data() {
    return {
      searchText: "",
      selectedDate: null,
    };
  },
};
</script>

<style></style>
