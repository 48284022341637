<template>
  <div class="">
    <div v-if="!status">
      <div class="instructionMessage" style="margin-bottom: 10px">
        <!-- <p>Please enter the 6 digit code sent to 080*******24</p> -->
        <div
          class="select-button text-left width-100"
          style="margin-bottom: 18px"
        >
          <label for="">Phone number: </label>
          <div class="select-input select-input-single">
            <div>
              <input
                type="number"
                v-model="newPhoneNumber"
                :placeholder="currentPhoneNumber || 'Enter your phone here'"
                :disabled="!!currentPhoneNumber"
                :style="{
                  cursor: !!currentPhoneNumber ? 'not-allowed' : undefined,
                }"
              />
            </div>
            <!-- <i class="bx bx-chevron-down"></i> -->
          </div>
        </div>

        <div class="instructionMessageFlex">
          <a
            v-if="!currentPhoneNumber"
            href="javascript:;"
            @click="resendPhoneCode"
            class="green-color"
            style="margin-right: 32px"
            >Get Code</a
          >
          <a
            href="javascript:;"
            @click="resendPhoneCode"
            :class="{
              'green-color': currentPhoneNumber,
            }"
            >Resend Code</a
          >
        </div>
      </div>

      <div
        class="select-button text-left"
        style="width: 50%; margin-inline: auto"
      >
        <label for="">Enter SMS Code: </label>
        <div
          class="instructionMessageInputCodes"
          style="
            margin-top: 0px;
            justify-content: space-between !important;
            max-width: 500px;
          "
        >
          <input
            type="text"
            maxlength="1"
            ref="verifyPhoneCode1"
            class="emailCode"
            id="emailCode1"
            @input="checkCode"
            data-next="2"
            data-prev="1"
          />
          <input
            type="text"
            maxlength="1"
            ref="verifyPhoneCode2"
            class="emailCode"
            @input="checkCode"
            id="emailCode2"
            data-next="3"
            data-prev="2"
          />
          <input
            type="text"
            maxlength="1"
            ref="verifyPhoneCode3"
            class="emailCode"
            id="emailCode3"
            @input="checkCode"
            data-next="4"
            data-prev="3"
          />
          <input
            type="text"
            maxlength="1"
            ref="verifyPhoneCode4"
            class="emailCode"
            data-next="5"
            data-prev="4"
            @input="checkCode"
            id="emailCode4"
          />
          <input
            type="text"
            maxlength="1"
            class="emailCode"
            ref="verifyPhoneCode5"
            data-next="6"
            @input="checkCode"
            id="emailCode5"
          />
          <input
            type="text"
            maxlength="1"
            ref="verifyPhoneCode6"
            class="emailCode"
            data-next="6"
            id="emailCode6"
            @input="checkCode"
          />
        </div>
      </div>

      <div class="text-center mt-5">
        <button
          :disabled="emptyFields"
          @click="VerifyPhoneNumber"
          class="relsify-button"
        >
          <span v-if="!loading">Confirm</span>
          <span class="loader" v-if="loading"></span>
        </button>
        <br />
        <router-link
          :to="{ name: 'ChangePhoneNumber' }"
          v-if="hasErrors"
          class="mt-4 green-color"
          style="display: block; font-size: 13px"
          >Update Phone Number</router-link
        >
      </div>
    </div>

    <div v-if="status">
      <VerifiedDiv :title="'Phone Number Verified'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import VerifiedDiv from "../../VerifiedDiv.vue";
export default {
  components: {
    VerifiedDiv,
  },

  props: {
    status: Boolean,
    currentPhoneNumber: String,
  },

  computed: {
    secretId: {
      get() {
        return this.$store.state.secretId;
      },
      set(value) {
        return (this.$store.state.secretId = value);
      },
    },
  },

  data() {
    return {
      emptyFields: true,
      loading: false,
      hasErrors: false,
      secretToken: "",
      newPhoneNumber: "",
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "getUserProfile"]),

    checkCode() {
      this.secretToken =
        this.$refs.verifyPhoneCode1.value +
        this.$refs.verifyPhoneCode2.value +
        this.$refs.verifyPhoneCode3.value +
        this.$refs.verifyPhoneCode4.value +
        this.$refs.verifyPhoneCode5.value +
        this.$refs.verifyPhoneCode6.value;

      // console.log(this.secretToken);
      if (this.secretToken.length == 6) {
        this.emptyFields = false;
      } else {
        this.emptyFields = true;
      }
    },

    VerifyPhoneNumber: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/verify/phonenumber`;
      let payload = {
        secretTokenId: this.secretId,
        secretTokenCode: this.secretToken,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setTimeout(() => {
            this.loading = false;
            // console.log(data);
            if (!data.error) {
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Phone verification",
                des: data.meta.message,
                type: "success",
                payload: null,
              });
              this.clearFormFields();
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Phone verification Error",
                des: data.meta.message,
                type: "error",
                payload: null,
              });
            }
          }, 4000);
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Phone verification Error",
            des: "An Error occured while verifying Phone number",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },

    resendPhoneCode: function () {
      let url = `${this.RELSIFY_URL}/api/initiate/phonenumber/code`;

      // const token = sessionStorage.getItem("Appoen");
      this.hasErrors = false;
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (!response.data.error) {
            // console.log(response.data);
            this.secretId = response.data.data.secretTokenId;
            this.setAlertpopUp({
              status: true,
              title: "Phone Code Sent",
              des: response.data.meta.message,
              type: "success",
              payload: null,
            });

            setTimeout(() => {
              this.setAlertpopUp({
                status: false,
                title: "",
                des: "",
                type: "error",
                payload: null,
              });
            }, 7000);

            // let newData = {
            //   secretTokenId: response.data.data.secretTokenId,

            // };

            // this.setVerifyPinCodeModal({
            //   status: true,
            //   title: "Verify Email",
            //   desc: response.data.meta.message,
            //   redirectUrl: null,
            //   type: "VERIFY_EMAIL_ACCOUNT",
            //   payload: newData,
            // });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Phone verification Error",
              des: response.data.meta.message,
              type: "Error",
              payload: null,
            });
          }
        })
        .catch((error) => {
          error;
          this.setAlertpopUp({
            status: true,
            title: "Phone verification Error",
            des: "An Error ocurred. please try again later",
            type: "Error",
            payload: null,
          });
        });
    },

    clearFormFields: function () {
      this.$refs.verifyPhoneCode1.value = "";
      this.$refs.verifyPhoneCode2.value = "";
      this.$refs.verifyPhoneCode3.value = "";
      this.$refs.verifyPhoneCode4.value = "";
      this.$refs.verifyPhoneCode5.value = "";
      this.$refs.verifyPhoneCode6.value = "";
    },
  },

  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
</style>
