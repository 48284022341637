<template>
  <div class="page-tab-line">
    <ul>
      <li>
        <a
          href="javascript:;"
          class="relsify-tablinks"
          tab-id="walletBalance"
          @click="$router.push({ name: 'Wallet' })"
          :class="currentPageName == 'Wallet' && 'active'"
          >Wallet Balance</a
        >
      </li>
      <li>
        <a
          href="javascript:;"
          @click="checkDepositAddress"
          class="relsify-tablinks"
          tab-id="walletDeposit"
          :class="currentPageName == 'WalletDeposit' && 'active'"
          >Deposit</a
        >
      </li>
      <li>
        <a
          href="javascript:;"
          @click="goToWithdrawal"
          class="relsify-tablinks"
          :class="currentPageName == 'WalletWithdrawal' && 'active'"
          tab-id="walletWithdrawal"
          >Withdraw</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import "@/mixins";
export default {
  computed: {
    ...mapGetters(["depositAddress"]),
  },

  methods: {
    ...mapActions(["setCurrentPageName", ""]),

    checkDepositAddress: function () {
      this.$router.push({
        name: "WalletDeposit",
      });
    },
    goToWithdrawal: function () {
      this.$router.push({
        name: "WalletWithdrawal",
      });
    },
  },
};
</script>

<style></style>
