<template>
  <div>
    <AlertModal />
    <AlertPopup />
    <VerifyPinCodeModal />
    <SelectCountryModal />
    <SelectTokenModal />
    <ProjectModal />
    <SelectNetworkModal />
    <PortfolioOverviewModal />
    <TransactionDetailsModal />
    <SmallPopUp />
    <ShareModal />
    <PageLoading />
  </div>
</template>

<script>
import VerifyPinCodeModal from "./VerifyPinCodeModal.vue";
import AlertModal from "./AlertModal.vue";
import AlertPopup from "./AlertPopup.vue";
import SelectCountryModal from "./SelectCountryModal.vue";
import SelectTokenModal from "./SelectTokenModal.vue";
import SelectNetworkModal from "./SelectNetworkModal.vue";
import ProjectModal from "./ProjectModal.vue";
import PortfolioOverviewModal from "./PortfolioOverviewModal.vue";
import TransactionDetailsModal from "./TransactionDetailsModal.vue";
import SmallPopUp from "./SmallPopUp.vue";
import ShareModal from "./ShareModal.vue";
import PageLoading from "../loading/PageLoading.vue";
export default {
  components: {
    AlertModal,
    VerifyPinCodeModal,
    AlertPopup,
    SelectCountryModal,
    SelectTokenModal,
    ProjectModal,
    PortfolioOverviewModal,
    TransactionDetailsModal,
    SelectNetworkModal,
    SmallPopUp,
    ShareModal,
    PageLoading,
  },
};
</script>

<style></style>
