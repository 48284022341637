<template>
  <div
    class="smallPopup"
    @click="closeSmallPopUp"
    :class="smallPopup.status && 'showSmallPopup'"
  >
    <p>{{ smallPopup.text }}</p>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["smallPopup"]),
  },

  methods: {
    ...mapActions(["setSmallPopUp"]),
    closeSmallPopUp: function () {
      this.setSmallPopUp({
        status: false,
        text: "",
      });
    },
  },
};
</script>

<style scoped>
.smallPopup {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 4000;
  background: #fff6e7;
  border: 2px solid var(--mainGreenColor);
  padding: 14px 50px;
  border-radius: 5px;
  transition: all 400ms ease-in-out;
  transform: translateY(1000%);
  cursor: pointer;
}

.showSmallPopup {
  transform: translateY(0);
}

.smallPopup p {
  margin: 0px;
  font-size: 13px;
  font-weight: 550;
  color: #111;
}

@media screen and (max-width: 620px) {
  .smallPopup {
    bottom: 70px;
    right: 15px;
    left: 15px;
    text-align: center;
  }
}
</style>
