<template>
  <div class="home_small_portfolio">
    <h4>Your Portfolio</h4>

    <div v-if="homePortfolios && homePortfolios.length > 0">
      <div class="portfolio_allBalnces">
        <div class="portfolio_allBalnce_balance">
          <p @click="toggleAllBalances">
            <span>See all</span>
            <i class="fa fa-eye-slash" v-if="showAllBalance"></i>
            <i v-else class="fa fa-eye"></i>
          </p>
        </div>
        <div class="portfolio_allBalnce_coins">
          <HomePortfolioItem
            v-for="(portfolio, index) in homePortfolios"
            :key="index"
            :showAllBalance="showAllBalance"
            :portfolio="portfolio"
            @hide-balances="hideAllBalance"
          />
        </div>
        <div class="portfolio_allBalnces_button">
          <button @click="$router.push({ name: 'Portfolio' })">
            <span class="portfolio_allBalnces_button_flex">
              <span>View Portfolio</span>
              <i class="bx bx-right-arrow-alt"></i>
            </span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="homePortfolios && homePortfolios.length <= 0">
      <div class="portfolio_allBalnce_coins">
        <div class="portfolio_allBalnce_coins_item" style="display: block">
          No Balance
        </div>
      </div>
    </div>

    <div
      class="d-flex justify-content-center align-items-center"
      v-if="homePortfolios == null"
    >
      <br />
      <LoadingComponent />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HomePortfolioItem from "./HomePortfolioItem.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    LoadingComponent,
    HomePortfolioItem,
  },
  computed: {
    ...mapGetters(["portfolios"]),

    homePortfolios() {
      let portfolios = null;

      if (this.portfolios && this.portfolios.length > 0) {
        portfolios = this.portfolios
          .slice()
          .sort(
            (a, b) =>
              parseFloat(b.balance.usdValue) - parseFloat(a.balance.usdValue)
          );

        if (portfolios) {
          portfolios = portfolios.slice(0, 5);
        }
      }

      return portfolios;
    },
  },

  data() {
    return {
      searchText: "",
      showAllBalance: false,
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "setPortfolioModal"]),

    toggleAllBalances: function () {
      this.showAllBalance = !this.showAllBalance;
    },
    hideAllBalance: function (status) {
      this.showAllBalance = status;
    },
  },
};
</script>

<style scoped>
/* home small portfolio */

.home_small_portfolio {
  margin-top: 40px;
  background: #fff;
  padding: 30px 20px;
  backdrop-filter: blur(59.271px);
  border-radius: 10px;
}

.home_small_portfolio h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  color: var(--mainGreenColor);
  margin-bottom: 35px;
}

.portfolio_allBalnces .portfolio_allBalnce_balance {
  text-align: right;
}

.portfolio_allBalnces .portfolio_allBalnce_balance p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 10px;
  color: #717171;
  cursor: pointer;
}

.portfolio_allBalnces .portfolio_allBalnce_balance i {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 10px;
  color: var(--mainGreenColor);
  margin-left: 10px;
  cursor: pointer;
}

.portfolio_allBalnce_coins {
  width: 100%;
}

.portfolio_allBalnces_button {
  margin-top: 50px;
  text-align: right;
}

.portfolio_allBalnces_button button {
  border: 1px solid var(--mainGreenColor);
  background: #ffffff;
  box-shadow: 0px 3.81986px 9.54964px 3.81986px rgba(222, 221, 221, 0.25);
  border-radius: 9.54964px;
  padding: 10px 20px;
  font-size: 13px;
}

.portfolio_allBalnces_button button:focus {
  outline: none;
}

.portfolio_allBalnces_button_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;
  color: var(--mainGreenColor);
}

.portfolio_allBalnce_coins_item {
  background: #ffffff;
  box-shadow: 0px 4px 15px 2px rgba(171, 171, 171, 0.35);
  border-radius: 8px;
  margin-top: 30px;
}
</style>
