<template>
  <div
    class="select-network-modal relsify-all-modals"
    v-if="selectNetworkModal.status"
  >
    <div class="modal-overlay close-modal"></div>
    <!-- hideModal -->
    <div class="select-network-modal-card vivify popInBottom">
      <div class="close-select-button" @click="closeNewtorkModal">
        <i class="bx bx-x close-modal"></i>
      </div>

      <div class="select-network-modal-header">
        <h5>Select Network</h5>
        <p>
          Ensure the network matches the addresses network entered to avoid
          withdrawal losses.
        </p>
      </div>

      <div class="select-network-modal-body" v-if="selectNetworkModal.payload">
        <div
          class="select-network-modal-content"
          v-if="selectNetworkModal.payload.length"
        >
          <div
            class="select-network-modal-content-items"
            :class="
              network.symbol == currentSelectedNetwork?.symbol && 'active'
            "
            v-for="(network, index) in selectNetworkModal.payload"
            :key="index"
            @click="selectNetwork(network)"
          >
            <div class="select-network-modal-content-item-left">
              <p>{{ network.symbol }}</p>
              <p>{{ network.name }}</p>
            </div>
            <div class="select-network-modal-content-item-right">
              <p>Network Fee {{ network.symbol }}</p>
              <p>≈ {{ network.withdrawalFee }}</p>
            </div>
          </div>
          <!-- <div class="select-network-modal-content-items ">
            <div class="select-network-modal-content-item-left">
              <p>BEP2</p>
              <p>Binance Chain (BNB)</p>
            </div>
            <div class="select-network-modal-content-item-right">
              <p>Network Fee0.0000049 BTC</p>
              <p>≈ $0.198901</p>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["selectNetworkModal", "projects"]),
    currentCoin: {
      get() {
        return this.$store.state.currentCoin;
      },
      set(value) {
        return (this.$store.state.currentCoin = value);
      },
    },
    currentSelectedNetwork: {
      get() {
        return this.$store.state.currentSelectedNetwork
      },
      set(value) {
        return (this.$store.state.currentSelectedNetwork = value)
      }
    },
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    ...mapActions(["setSelectNewtorkModal"]),
    closeNewtorkModal: function () {
      this.setSelectNewtorkModal({
        status: false,
        title: "",
        desc: "",
        payload: null,
        select1Type: "",
        select2Type: "",
        type: "",
      });
      this.searchText = "";
    },
    selectNetwork: function (network) {
      this.currentSelectedNetwork = network;

      // console.log(network);
      this.closeNewtorkModal();
    },
  },
};
</script>

<style scoped>
.select-network-modal-content-items {
  cursor: pointer;
  transition: all 500ms ease;
}
.select-network-modal-content-items:hover {
  background: rgb(241, 241, 241);
}
/* .select-network-modal-content-items.active:hover {
  background: transparent;
} */
</style>
