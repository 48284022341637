<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header wallet-page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Devices</h2>
          </div>
        </div>
        <div class="page-header-small-screen">
          <img
            src="@/assets/vectors/img/back-icon.png"
            class="arrow_bacck"
            alt=""
            onclick="window.history.back();"
          />
          <br />
          <h5>Devices</h5>
        </div>
      </div>
    </div>

    <div class="small_screen_effect_inner wallet_balance_page">
      <Devices />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import Devices from "../components/devices/Devices.vue";
export default {
  components: {
    Devices,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.small_screen_effect_inner {
  background: transparent;
}
</style>
