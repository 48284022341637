<template>
  <div class="page-tab-line content-space-between mb-1 mt-0">
    <ul>
      <li>
        <a
          href="javascript:;"
          @click="goToLevel($event, 'SiteVerification')"
          class="relsify-tablinks"
          :class="currentPageName == 'SiteVerification' && 'active'"
          tab-id="siteVerificationLevel1"
          >Level 1
          <i
            class="bx bxs-checkbox-checked"
            v-if="userVerificationStatus.level1.status.toLowerCase() == 'done'"
          ></i
        ></a>
      </li>
      <li>
        <a
          href="javascript:;"
          class="relsify-tablinks"
          :class="currentPageName == 'SiteVerificationLevel2' && 'active'"
          tab-id="siteVerificationLevel2"
          @click="goToLevel($event, 'SiteVerificationLevel2')"
          >Level 2
          <i
            class="bx bxs-checkbox-checked"
            v-if="userVerificationStatus.level2.status.toLowerCase() == 'done'"
          ></i
        ></a>
      </li>
      <li>
        <a
          href="javascript:;"
          class="relsify-tablinks"
          :class="currentPageName == 'SiteVerificationLevel3' && 'active'"
          @click="goToLevel($event, 'SiteVerificationLevel3')"
          tab-id="siteVerificationLevel3"
          >Level 3
          <i
            class="bx bxs-checkbox-checked"
            v-if="userVerificationStatus.level3.status.toLowerCase() == 'done'"
          ></i
        ></a>
      </li>
    </ul>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions, mapGetters } from "vuex";

// import $ from "jquery";
export default {
  computed: {
    ...mapGetters(["userVerificationStatus"]),
  },

  methods: {
    ...mapActions(["setAlertpopUp"]),
    goToLevel: function (event, name) {
      event.preventDefault();
      if (name == "SiteVerification") {
        this.$router.push({
          name: name,
        });
      }
      if (name == "SiteVerificationLevel2") {
        if (
          this.userVerificationStatus &&
          this.userVerificationStatus.level1.status.toLowerCase() == "done"
        ) {
          this.$router.push({
            name: name,
          });
        } else {
          this.setAlertpopUp({
            status: true,
            title: "Update Status",
            des: "You have to complete Level 1 verifications ",
            type: "error",
            payload: null,
          });
        }
      }
      if (name == "SiteVerificationLevel3") {
        if (
          this.userVerificationStatus &&
          this.userVerificationStatus.level2.status.toLowerCase() == "done"
        ) {
          this.$router.push({
            name: name,
          });
        } else {
          this.setAlertpopUp({
            status: true,
            title: "Update Status",
            des: "You have to complete Level 2 verifications",
            type: "error",
            payload: null,
          });
        }
      }
      //   let className = event.target;

      //   if ($(className).hasClass("active")) {
      //     return;
      //   }

      //   $(".page-tab-line .relsify-tablinks").removeClass("active");

      //   $(className).addClass("active");
    },
  },
};
</script>

<style></style>
