import { FACEBOOK_APP_ID } from "../config";

export const initFbsdk = () => {
  return new Promise(() => {
    window.fbAsyncInit = function () {
      // eslint-disable-next-line no-undef
      FB.init({
        appId: FACEBOOK_APP_ID,
        cookie: true,
        xfbml: true, // parse social plugins on this page
        version: "v2.8", // use graph api version 2.8
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  });
};
