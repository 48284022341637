<template>
  <div class="main_page_oauth login_register">
    <br />
    <br />
    <div class="main_page_oauth_header">
      <h3>REGISTER</h3>
    </div>
    <br />
    <br />

    <div class="register_with">
      <button
        class="register_with_link isActive"
        tab-id="registerWithEmail"
        @click="setSignUpType('withEmail')"
      >
        Email
      </button>
      <button
        class="register_with_link"
        tab-id="registerWithPhone"
        @click="setSignUpType('withPhone')"
      >
        Phone Number
      </button>
      <button
        class="register_with_link"
        tab-id="registerWithBoth"
        @click="setSignUpType('withBoth')"
      >
        Both
      </button>
    </div>

    <RegistrationForm />

    <SocialLoginRegistration :signInType="`REGISTER`" />
  </div>
</template>

<script>
import "../assets/css/oauth.css";
import RegistrationForm from "../components/forms/registration/RegistrationForm.vue";
import SocialLoginRegistration from "../components/forms/SocialLoginRegistration.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    RegistrationForm,
    SocialLoginRegistration,
  },
  computed: {
    registrationType: {
      get() {
        return this.$store.state.registrationType;
      },
      set(value) {
        return (this.$store.state.registrationType = value);
      },
    },
  },

  methods: {
    ...mapMutations(["SET_REGISTRATION_TYPE", "GET_COUNTRIES"]),
    setSignUpType: function (value) {
      this.SET_REGISTRATION_TYPE(value);
    },
  },

  mounted() {
    this.GET_COUNTRIES();
  },

  // setup() {
  //   const { SET_REGISTRATION_TYPE } = useMutations(["SET_REGISTRATION_TYPE"]);
  //   const setSignUpType = (value) => {
  //     SET_REGISTRATION_TYPE(value);
  //   };

  //   return {
  //     setSignUpType,
  //   };
  // },
};
</script>
