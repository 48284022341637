<template>
  <div class="row">
    <div class="col-md-12 mb-3">
      <div class="transactionaass" v-if="trade">
        <div class="transaction-transactionList">
          <div class="transaction-transactionListItem">
            <p>Date:</p>
            <p>
              {{ formateDateAndTime(trade.date) }}
            </p>
          </div>
          <div class="transaction-transactionListItem">
            <p>From Token Name:</p>
            <p>{{ trade.coinFrom.name }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>To Token Name:</p>
            <p>{{ trade.coinTo.name }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Amount:</p>
            <p>{{ formatAmount(trade.amount) }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Price:</p>
            <p>{{ formatAmount(trade.price) }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Fee:</p>
            <p>{{ formatAmount(trade.tradingFee) }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Total:</p>
            <p>{{ formatAmount(trade.totalAmount) }}</p>
          </div>
        </div>
      </div>

      <div class="relsify-notification-items-div" v-if="trade == null">
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <LoadingComponent />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["tradeHistory"]),
    trade() {
      let singleTrade = null;
      if (this.tradeHistory && this.tradeHistory.length > 0) {
        singleTrade = this.tradeHistory.find(
          (history) => history.id == this.$route.query.id
        );
        // console.log(singleTrade);
      }

      return singleTrade;
    },
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "globalFunction"]),
  },

  mounted() {
    if (!this.$route.query.id) {
      window.history.back();

      return;
    }
  },
};
</script>

<style scoped>
.transactionaass {
  background: #fff;
  padding: 70px 50px;
  border-radius: 10px;
  margin-top: 60px;
}
.transaction-transactionList {
  width: 60%;
}

.transaction-transactionListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 18px; */
}

.transaction-transactionListItem p {
  text-align: left;
  font-size: 16px;
  color: rgb(136, 132, 132);
}

.transaction-transactionListItem p:last-child {
  text-align: right;
  color: #111;
  font-weight: bold;
  display: block;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

@media screen and (max-width: 680px) {
  .transactionaass {
    padding: 40px 10px;
    margin-top: 40px;
  }
  .transaction-transactionList {
    width: 100%;
  }

  .transaction-transactionListItem {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .transaction-transactionListItem p {
    font-size: 15px;
  }

  .transaction-transactionListItem p:last-child {
    font-size: 13.5px;
    padding-right: 10px;
    overflow-wrap: break-word;
    width: 100%;
    text-align: left;
  }
}
</style>
