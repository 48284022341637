import localforage from "localforage";
import { WEBSITE_URL, RELSIFY_URL, DASHBOARD_URL } from "../config";

/* eslint-disable */
const state = {
  // iMPORTANT URLS
  RELSIFY_URL,
  DASHBOARD_URL,
  WEBSITE_URL,

  // AUTHS
  token: localStorage.getItem("Appoen") || "",
  user: null,
  userSelf: null,
  loginInfo: null,
  registrationType: "withEmail",
  isUserLoggedIn: false,
  modalLoading: false,
  pageLoading: false,
  sideNotificationModal: false,
  marketPlaceModal: false,
  currentPageName: "",
  currentPageType: null,
  tabProjectClicked: "buy",
  currentProject: null,
  verifyPinCodeModal: {
    status: false,
    title: "",
    desc: "",
    redirectUrl: null,
    type: "",
    payload: null,
  },

  alertMessageModal: {
    status: false,
    title: "",
    des: "",
    button1Text: "",
    button1Url: "",
    button2: true,
    button2Text: "",
    button2Url: "",
    type: "",
    payload: null,
  },

  alertpopUp: {
    status: false,
    title: "",
    des: "",
    type: "",
    payload: null,
  },
  smallPopup: {
    status: false,
    text: "",
  },

  shareModal: {
    status: false,
    url: "",
    title: "",
    desc: "",
    payload: null,
  },

  currentSsecretId: "617813fd91b934ed26f24ccf",

  countries: null,
  states: null,
  countryDialCode: null,
  countryFlag: null,

  loginHistories: null,
  loginDevices: null,
  activityLogs: null,

  projects: null,
  projectCategory: null,
  selectedCategory: "",
  projectCurrentPage: 1,
  projectTotalPage: 2,

  portfolios: null,
  portfolioCurrentPage: 1,
  portfolioTotalPage: 2,

  transactionHistory: null,
  transactionHistoryCurrentPage: 1,
  transactionHistoryTotalPage: 2,

  depositTransactionHistory: null,
  depositTransactionCurrentPage: 1,
  depositTransactionTotalPage: 2,
  depositType: null,

  withdrawalTransactionHistory: null,
  withdrawalTransactionCurrentPage: 1,
  withdrawalTransactionTotalPage: 2,

  tradeHistory: null,
  tradeHistoryCurrentPage: 1,
  tradeHistoryTotalPage: 2,

  dividends: null,
  dividendCurrentPage: 1,
  dividendTotalPage: 2,

  approvedDocuments: null,
  userVerificationStatus: null,
  level2VerificationData: null,
  allNotifications: null,
  sliderNotifications: null,
  unreadNotificationCount: null,

  userWalletBalance: null,
  userWalletBalanceCurrentPage: 1,
  userWalletBalanceTotalPage: 2,

  depositAddress: null,
  allCoins: null,
  selectProjectModal: {
    status: false,
    title: "",
    desc: "",
    payload: null,
    select1Type: "",
    select2Type: "",
    select3Type: "",
    select4Type: "",
    select5Type: "",
    select6Type: "",
    type: "",
  },
  selectCountryModal: {
    status: false,
    type: "",
    title: "",
    payload: null,
  },

  projectModal: {
    status: false,
    title: "",
    desc: "",
    payload: null,
    select1Type: "",
    select2Type: "",
    type: "",
  },

  selectNetworkModal: {
    status: false,
    title: "",
    desc: "",
    payload: null,
    select1Type: "",
    select2Type: "",
    type: "",
  },

  portfolioModal: {
    status: false,
    title: "",
    desc: "",
    payload: null,
    select1Type: "",
    select2Type: "",
    type: "",
  },

  transactionDetailModal: {
    status: false,
    payload: null,
    type: "",
  },

  showPortfolioBalance: false,
  showWalletBalance: false,

  selectedActivities: [],

  projectToBuy: null,
  projectTosell: null,

  currentDeposit: null,
  currentCoin: null,
  currentCurrencyType: null,
  currentDepositAccount: null,
  currentDepositLink: null,
  currentWithdrawalAccountInfo: null,
  currentSelectedNetwork: null,
  // currentWithdrawalB

  supportedBankList: [],

  amount: 0,
  equivalentAmount: 0,

  acceptTerms: localStorage.getItem("axxeptTerms") ? true : false,

  selectedCountry: {
    name: "Nigeria",
    dialCode: "+234",
    isoCode: "NG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
  },
};

localforage.getItem("Appoen").then((token) => {
  if (token) {
    state.token = token;
  }
});

export default state;
