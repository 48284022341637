<template>
  <div class="settings-profile-settings">
    <h4 class="settings-profile-settings-title">Profile Settings</h4>
    <p class="settings-profile-settings-des">
      Plesse take a proper picture or upload a photo from your device
    </p>

    <UploadImage />

    <UpdateProfileForm />
  </div>
</template>

<script>
import UploadImage from "./UploadImage.vue";
import UpdateProfileForm from "./UpdateProfileForm.vue";
export default {
  components: {
    UploadImage,
    UpdateProfileForm,
  },
};
</script>

<style scoped>
.settings-profile-settings button.relsify-button {
  padding: 10px 50px;
  font-weight: bold;
  font-size: 14px;
}
</style>
