<template>
  <div class="col-xl-12 portfolio_page">
    <div class="portfolio_page_large">
      <div class="page-tab-line">
        <ul>
          <li>
            <a href="" tab-id="allAssets" class="relsify-tablinks active"
              >All Assets You Own</a
            >
          </li>
          <!-- <li>
              <a href="" tab-id="allSells" class="relsify-tablinks">Sell</a>
            </li> -->
        </ul>
      </div>
      <page></page>
      <div class="page-tab-content">
        <div class="relsify-tab-pane active" id="allAssets">
          <div class="relsify-table">
            <div class="relsify-table-search">
              <form class="relsify-search" @submit.prevent="searchPort">
                <i class="bx bx-search-alt-2"></i>
                <input
                  type="search"
                  v-model="searchText"
                  placeholder="Search "
                />
              </form>
            </div>
            <!-- <PortfolioTable
             :allPortfolios="allPortfolios"
            /> -->

            <div class="table-responsive">
              <table class="table">
                <thead class="text-left">
                  <tr class="">
                    <th>Asset Name</th>
                    <th>Amount you own</th>
                    <th>{{ userDefaultCurrency?.symbol + " " }}Value</th>
                    <!-- <th></th> -->
                  </tr>
                </thead>

                <tbody
                  class="text-left"
                  v-if="allPortfolios && allPortfolios.length > 0"
                >
                  <PortfolioTable
                    v-for="(portfolio, index) in allPortfolios"
                    :key="index"
                    :portfolio="portfolio"
                  />
                </tbody>
                <tbody v-if="allPortfolios && allPortfolios.length <= 0">
                  <tr>
                    <td colspan="8">
                      <div class="card text-center">
                        <div class="card-body">
                          <h6>No portfolio found</h6>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="allPortfolios == null">
                  <tr>
                    <td colspan="8">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <br />
                        <LoadingComponent />
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="relsify-small-table">
      <div
        class="relsify-small-table-grid"
        v-if="allPortfolios && allPortfolios.length > 0"
      >
        <div
          class="relsify-small-table-grid-item"
          v-for="(portfolio, index) in allPortfolios"
          :key="index"
          @click="showPortfolioModal(portfolio)"
        >
          <div class="td-with-image">
            <img
              v-if="portfolio.balance.coin.iconUrl"
              style="border-radius: 100%"
              :src="portfolio.balance.coin.iconUrl"
              alt=""
            />
            <span>{{ portfolio.balance.coin.name }}</span>
          </div>
          <div>
            <span>
              <!-- {{ addComma(portfolio.balance.amount) }} -->
              <span v-if="showPortfolioBalance" class="td-with-image">
                <img
                  v-if="userDefaultCurrency.iconUrl"
                  :src="userDefaultCurrency.iconUrl"
                  alt=""
                />
                <span style="margin-bottom: 0px">{{
                  formatUsdValue(portfolio.balance.value)
                }}</span>
              </span>
              <!-- <span v-if="showPortfolioBalance"
                >${{ formatUsdValue(portfolio.balance.value) }}</span
              > -->
              <span v-if="!showPortfolioBalance">*********</span>
            </span>
          </div>
        </div>
      </div>
      <div
        class="relsify-small-table-grid"
        v-if="allPortfolios && allPortfolios.length <= 0"
      >
        <div class="text-center">
          <h6>No portfolio found</h6>
        </div>
      </div>
      <div class="relsify-small-table-grid" v-if="allPortfolios == null">
        <div class="d-flex justify-content-center align-items-center">
          <br />
          <LoadingComponent />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters, mapActions } from "vuex";
import PortfolioTable from "./PortfolioTable.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";

export default {
  components: {
    PortfolioTable,
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["portfolios"]),

    allPortfolios() {
      let portfolios = null;

      if (this.portfolios && this.portfolios.length > 0) {
        portfolios = this.portfolios.filter(
          (portfolio) =>
            portfolio.balance.coin.name
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            portfolio.project.title
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            portfolio.project.state
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            portfolio.project.description
              .toLowerCase()
              .match(this.searchText.toLowerCase()) ||
            portfolio.project.country
              .toLowerCase()
              .match(this.searchText.toLowerCase())
        );

        if (portfolios) {
          portfolios = portfolios.sort(
            (a, b) => parseFloat(b.balance.value) - parseFloat(a.balance.value)
          );
        }
      }

      return portfolios;
    },
    userDefaultCurrency: {
      get() {
        return this.$store.state.userSelf?.defaultCurrency;
      },
      set() {},
    },
    showPortfolioBalance: {
      get() {
        return this.$store.state.showPortfolioBalance;
      },
      set(value) {
        return (this.$store.state.showPortfolioBalance = value);
      },
    },
  },

  data() {
    return {
      searchText: "",
    };
  },

  methods: {
    ...mapActions(["setModalLoading", "setAlertpopUp", "setPortfolioModal"]),
    showPortfolioModal: function (portfolio) {
      this.setPortfolioModal({
        status: true,
        title: "",
        desc: "",
        payload: portfolio,
        select1Type: "",
        select2Type: "",
        type: "PORTFOLIO",
      });
    },

    searchPort: function () {},
  },
};
</script>

<style scoped>
.portfolio_page .relsify-small-table {
  display: none;
}

@media screen and (max-width: 580px) {
  .portfolio_page .portfolio_page_large {
    display: none;
  }

  .portfolio_page .relsify-small-table {
    display: block;
    margin-top: 40px;
    padding-bottom: 20px;
  }

  .relsify-small-table-grid .relsify-small-table-grid-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px 10px 20px;
    margin: 13px 0px;
  }

  .relsify-small-table-grid .relsify-small-table-grid-item img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    margin-right: 8px;
  }

  .relsify-small-table-grid .relsify-small-table-grid-item span {
    font-style: normal;
    font-weight: 550;
    font-size: 15.3753px;
    line-height: 18px;
    color: #717171;
  }
}
</style>
