<template>
  <div class="infinitescrolltrigger">
    <div ref="infinitescrolltrigger" class="scroll-trigger text-center">
      <LoadingComponent v-if="isBussy" />
    </div>
  </div>
</template>

<script>
// import PageHeader from "@/components/header/PageHeader";
import LoadingComponent from "../loading/LoadingComponent.vue";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["isSearching"],
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["dividends"]),

    currentPage: {
      get() {
        return this.$store.state.dividendCurrentPage;
      },

      set(value) {
        return (this.$store.state.dividendCurrentPage = value);
      },
    },

    totalPage: {
      get() {
        return this.$store.state.dividendTotalPage;
      },

      set(value) {
        return (this.$store.state.dividendTotalPage = value);
      },
    },
  },

  data() {
    return {
      isBussy: false,
      totalPages: 2,
    };
  },

  methods: {
    ...mapActions(["fetchMoreDividends", "saveMoreDividends"]),

    scrollTrigger: function () {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (
            entry.intersectionRatio > 0 &&
            this.currentPage < this.totalPage &&
            this.dividends.length > 0
          ) {
            this.isBussy = true;
            this.currentPage += 1;
            this.fetchDividends(this.currentPage);
          }
        });
      });

      observer.observe(this.$refs.infinitescrolltrigger);
    },

    fetchDividends: function (currentPage) {
      // console.log(payload);

      this.fetchMoreDividends(currentPage)
        .then((response) => {
          this.isBussy = false;
          if (!response.error) {
            this.saveMoreDividends(response.data.data);

            this.currentPage = response.data.meta.page;
            this.totalPage = response.data.meta.pages;
          }
        })
        .catch((error) => console.log(error));
    },
  },

  mounted() {
    this.scrollTrigger();
  },
};
</script>
<style scoped>
.infinitescrolltrigger {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-trigger {
  margin-top: 0px;
  text-align: center;
}

[class*="loader"]:before {
  content: "fetching more...";
}
</style>
