<template>
  <div class="table-mobile">
    <div
      class="table-mobile-grid table_transsa"
      v-if="allDividend && allDividend.length > 0"
    >
      <div
        class="table-mobile-grid-item tabel-mobile-tran"
        v-for="(dividend, index) in allDividend"
        :key="index"
      >
        <div class="table-item-left">
          <div class="td-with-image">
            <img
              v-if="dividend.coin.iconUrl"
              style="border-radius: 100%; height: 28px; width: 28px"
              :src="dividend.coin.iconUrl"
              alt=""
            />
            <span>{{ dividend.coin.name }}</span>
          </div>
          <p>
            {{ $numberic(dividend.amount).format("0.00") }}
          </p>
          <!-- <p>{{ formatAmount(dividend.amount) }}</p> -->
        </div>
        <div class="table-item-right">
          <p class="green-color">{{ dividend.project.title }}</p>
        </div>
      </div>
    </div>

    <div
      class="table-mobile-light"
      v-if="allDividend && allDividend.length <= 0"
    >
      <div class="card text-center">
        <div class="card-body">
          <h6>No dividend found</h6>
        </div>
      </div>
    </div>
    <div class="table-mobile-light" v-if="allDividend == null">
      <div class="d-flex justify-content-center align-items-center">
        <br />
        <LoadingComponent />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  props: ["allDividend"],
  components: {
    LoadingComponent,
  },

  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
    goToDividendDetails: function (dividend) {
      this.$router.push({
        name: "SingleTransaction",
        query: { id: dividend.id },
      });
    },
  },
};
</script>

<style></style>
