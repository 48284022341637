<template>
  <div class="home-top-advert">
    <div
      class="home-top-advert-img"
      v-if="sliderNotifications && sliderNotifications.length"
    >
      <!-- <carousel
        
      >
        <img src="@/assets/vectors/img/top-advert.png" alt="" />
        
      </carousel> -->
      <carousel :items-to-show="1" :autoplay="3000">
        <slide
          v-for="slide in sliderNotifications"
          :key="slide"
          @click="goToUrl(slide)"
        >
          <!-- {{ slide }} -->
          <img
            style="cursor: pointer"
            v-if="slide.media.url"
            :src="slide.media.url"
            alt=""
          />
        </slide>

        <template #addons>
          <!-- <navigation /> -->
          <div style="width: 100%; scale: 80%">
            <pagination />
          </div>
        </template>
      </carousel>
    </div>
    <div class="home-top-advert-img" v-if="sliderNotifications == null">
      <div class="d-flex justify-content-center align-items-center">
        <br />
        <LoadingComponent />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
// import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";
import LoadingComponent from "../../loading/LoadingComponent.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    LoadingComponent,
    // Navigation,
  },

  computed: {
    ...mapGetters(["sliderNotifications"]),
  },

  methods: {
    goToUrl: function (slide) {
      // console.log(slide);
      // console.log(this.sliderNotifications);
      if (slide.url) {
        location.href = slide.url;
      } else {
        this.$router.push({
          name: "SliderNotificationDetails",
          query: { id: slide.id },
        });
      }
    },
  },
};
</script>

<style scoped></style>
