import localforage from "localforage";
import { createRouter, createWebHistory } from "vue-router";

import store from "../store";
import Home from "../views/Home.vue";
import Trade from "@/views/trade/Trade.vue";
import Sell from "@/views/trade/Sell.vue";
import Swap from "@/views/trade/Swap.vue";
import Checkout from "@/views/trade/Checkout.vue";
import SingleTrade from "@/views/trade/SingleTrade.vue";
import ProjectDetails from "@/views/ProjectDetails.vue";
import Portfolio from "@/views/Portfolio.vue";
import Wallet from "@/views/wallet/Wallet.vue";
import WalletDeposit from "@/views/wallet/WalletDeposit.vue";
import WalletWithdrawal from "@/views/wallet/WalletWithdrawal.vue";
import WalletDetails from "@/views/wallet/WalletDetails.vue";
import Settings from "@/views/settings/Settings.vue";
import Profile from "@/views/settings/Profile.vue";
import SiteVerification from "@/views/settings/SiteVerification.vue";
import SiteVerificationLevel2 from "@/views/settings/SiteVerificationLevel2.vue";
import SiteVerificationLevel3 from "@/views/settings/SiteVerificationLevel3.vue";
import Security from "@/views/security/Security.vue";
import ChangePassword from "@/views/security/ChangePassword.vue";
import ChangePhoneNumber from "@/views/security/ChangePhoneNumber.vue";
import DeleteAccount from "@/views/security/DeleteAccount.vue";
import ChangeEmail from "@/views/security/ChangeEmail.vue";
import AddEmailOrPhone from "@/views/security/AddEmailOrPhone.vue";
import UserTransactionHistory from "@/views/transaction/UserTransactionHistory.vue";
import DepositTransactionHistory from "@/views/transaction/DepositTransactionHistory.vue";
import WithdrawalTransactionHistory from "@/views/transaction/WithdrawalTransactionHistory.vue";
import SingleTransaction from "@/views/transaction/SingleTransaction.vue";
import TradeHistory from "@/views/trade/TradeHistory.vue";
import LoginHistory from "@/views/LoginHistory.vue";
import Device from "@/views/Device.vue";
import Notifications from "@/views/notification/Notifications.vue";
import NotificationDetails from "@/views/notification/NotificationDetails.vue";
import SliderNotificationDetails from "@/views/notification/SliderNotificationDetails.vue";
import Activities from "@/views/activities/Activities.vue";
import ActivityDetails from "@/views/activities/ActivityDetails.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Dividend from "@/views/dividend/Dividend.vue";
import DividendHistory from "@/views/dividend/DividendHistory.vue";

import Localbase from "localbase";
let db = new Localbase("db");
db.config.debug = false;

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Blockchain-based Real Estate Platform",
      requiresAuth: true,
      enterClass: "animate__animated animate__fadeInLeft",
      leaveClass: "animate__animated animate__fadeOutLeft",
    },
  },

  {
    path: "/trade",
    name: "Trade",
    component: Trade,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Trade",
      requiresAuth: true,
      enterClass: "animate__animated animate__fadeInLeft",
      leaveClass: "animate__animated animate__fadeOutRight",
    },
    // children: [
    //   {
    //     path: "",
    //     name: "Buy",
    //     component: Buy,
    //   },
    //   {
    //     path: "sell",
    //     name: "Sell",
    //     component: Sell,
    //   },
    // ],
  },
  {
    path: "/sell",
    name: "Sell",
    component: Sell,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Sell",
      requiresAuth: true,
    },
  },
  {
    path: "/swap",
    name: "Swap",
    component: Swap,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Swap",
      requiresAuth: true,
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: Checkout,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Checkout",
      requiresAuth: true,
    },
  },
  {
    path: "/trade/details",
    name: "SingleTrade",
    component: SingleTrade,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Trade details",
      requiresAuth: true,
    },
  },
  {
    path: "/project/details",
    name: "ProjectDetails",
    component: ProjectDetails,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Project Details",
      requiresAuth: true,
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: Portfolio,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Portfolio",
      requiresAuth: true,
    },
  },
  {
    path: "/wallet",
    name: "Wallet",
    component: Wallet,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Wallet",
      requiresAuth: true,
    },
  },
  {
    path: "/wallet/deposit",
    name: "WalletDeposit",
    component: WalletDeposit,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Wallet Deposit",
      requiresAuth: true,
    },
  },
  {
    path: "/wallet/Withdrawal",
    name: "WalletWithdrawal",
    component: WalletWithdrawal,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Wallet Withdrawal",
      requiresAuth: true,
    },
  },
  {
    path: "/wallet/details",
    name: "WalletDetails",
    component: WalletDetails,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Wallet Details",
      requiresAuth: true,
    },
  },
  {
    path: "/dividend",
    name: "Dividend",
    component: Dividend,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Dividend",
      requiresAuth: true,
    },
  },
  {
    path: "/dividend-history",
    name: "DividendHistory",
    component: DividendHistory,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Dividend History",
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Settings",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Profile",
      requiresAuth: true,
    },
  },
  {
    path: "/site-verification/leve-1",
    name: "SiteVerification",
    component: SiteVerification,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Site Verification Level 1",
      requiresAuth: true,
    },
  },
  {
    path: "/site-verification/level-2",
    name: "SiteVerificationLevel2",
    component: SiteVerificationLevel2,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Site Verification Level 2",
      requiresAuth: true,
    },
  },
  {
    path: "/site-verification/level-3",
    name: "SiteVerificationLevel3",
    component: SiteVerificationLevel3,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Site Verification Level 3",
      requiresAuth: true,
    },
  },
  {
    path: "/security",
    name: "Security",
    component: Security,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Security",
      requiresAuth: true,
    },
  },
  {
    path: "/security/change-password",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Change Password",
      requiresAuth: true,
    },
  },
  {
    path: "/security/change-phone-number",
    name: "ChangePhoneNumber",
    component: ChangePhoneNumber,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Change PhoneNumber",
      requiresAuth: true,
    },
  },
  {
    path: "/security/manage-phone",
    name: "AddPhone",
    component: AddEmailOrPhone,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Manage Phone",
      requiresAuth: true,
    },
  },
  {
    path: "/security/delete-account",
    name: "DeleteAccount",
    component: DeleteAccount,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Delete Account",
      requiresAuth: true,
    },
  },
  {
    path: "/security/change-email",
    name: "ChangeEmail",
    component: ChangeEmail,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Change Email",
      requiresAuth: true,
    },
  },
  {
    path: "/security/manage-email",
    name: "AddEmail",
    component: AddEmailOrPhone,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Manage Email",
      requiresAuth: true,
    },
  },
  {
    path: "/transaction-history",
    name: "UserTransactionHistory",
    component: UserTransactionHistory,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Transaction History",
      requiresAuth: true,
    },
  },
  {
    path: "/transaction/deposit-history",
    name: "DepositTransactionHistory",
    component: DepositTransactionHistory,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Deposit History",
      requiresAuth: true,
    },
  },
  {
    path: "/transaction/withdrawal-history",
    name: "WithdrawalTransactionHistory",
    component: WithdrawalTransactionHistory,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Withdrawal History",
      requiresAuth: true,
    },
  },
  {
    path: "/transaction/details",
    name: "SingleTransaction",
    component: SingleTransaction,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Transaction details",
      requiresAuth: true,
    },
  },
  {
    path: "/trade-history",
    name: "TradeHistory",
    component: TradeHistory,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Trade History",
      requiresAuth: true,
    },
  },
  {
    path: "/login-history",
    name: "LoginHistory",
    component: LoginHistory,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Login History",
      requiresAuth: true,
    },
  },
  {
    path: "/devices",
    name: "Device",
    component: Device,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Devices",
      requiresAuth: true,
    },
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Notifications",
      requiresAuth: true,
    },
  },

  {
    path: "/notification/details",
    name: "NotificationDetails",
    component: NotificationDetails,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Notification Details",
      requiresAuth: true,
    },
  },
  {
    path: "/notification/slider/details",
    name: "SliderNotificationDetails",
    component: SliderNotificationDetails,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Notification Details",
      requiresAuth: true,
    },
  },

  {
    path: "/activities",
    name: "Activities",
    component: Activities,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Activities",
      requiresAuth: true,
    },
  },
  {
    path: "/activity/details",
    name: "ActivityDetails",
    component: ActivityDetails,
    meta: {
      transition: "fade-in-down",
      title: "Relsify - Activity Details",
      requiresAuth: true,
    },
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Relsify - Login",
      guest: true,
    },
  },

  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "Relsify - Register",
      guest: true,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      title: "Relsify - ResetPassword",
      guest: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: {
      title: "Relsify - ForgotPassword",
      guest: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

// async function processLogin() {
//   let userData = await db.collection("user").doc("user-info").get();

//   store.commit("GET_STATES");
//   store.commit("GET_COUNTRIES");

//   if (userData) {
//     store.dispatch("login", userData);

//     // console.log(userData);
//     setTimeout(() => {
//       store.dispatch("globalFunction");
//     }, 2000);
//   }
// }

router.beforeEach((to, from, next) => {
  // let token = !!localStorage.getItem("Appoen");

  /**
   * Debugging loval storage in IOS
   */

  // const isIOS =  [
  //     'iPad Simulator',
  //     'iPhone Simulator',
  //     'iPod Simulator',
  //     'iPad',
  //     'iPhone',
  //     'iPod'
  //   ].includes(navigator.platform)
  //   // iPad on iOS 13 detection
  //   || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

  // if (isIOS) {
  //   const tokenStored = localStorage.getItem("Appoen");
  //   window.alert(`Token Stored: ${tokenStored}`);
  // }

  // Result: After the page is reloaded, the token stored in local storage is not available.
  localforage
    .getItem("Appoen")
    .then((token) => {
      if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (token) {
          // processLogin();
          next();
        } else {
          next("/login");
        }
      } else {
        next();
      }
    })
    .catch(() => {
      next("/login");
    });
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.guest)) {
    localforage
      .getItem("Appoen")
      .then((token) => {
        if (!!token && !!store.getters.isLoggedIn) {
          next("/");
        } else {
          next();
        }
      })
      .catch(() => {
        next();
      });
  } else {
    next();
  }
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
