<template>
  <div v-if="userVerificationStatus">
    <div v-if="userVerificationStatus?.level3?.status != 'verified'">
      <div class="instructionMessage" style="margin-top: 10px">
        <p>Please put in your address</p>
        <div v-if="!userVerificationStatus?.level3?.proofOfAddress">
          <input
            type="file"
            ref="proofOfAdress"
            style="display: none"
            @change="uploadDocument($event)"
            accept="image/png, image/gif, image/jpeg"
          />

          <div class="imageUploadBox">
            <i
              class="bx bx-cloud-upload"
              v-if="!addressDocument"
              @click="$refs?.proofOfAdress?.click()"
            ></i>
            <span>
              <i
                class="bx bx-file"
                style="cursor: default"
                v-if="addressDocument"
              ></i>
              <small
                style="display: block; margin-top: -50px; font-size: 12px"
                >{{ addressDocumentName }}</small
              >
            </span>
          </div>

          <div class="text-center">
            <br />
            <a
              href="javascript:;"
              class="green-color"
              @click="$refs?.identity_image?.click()"
              style="display: block; margin-top: -40px; font-size: 13px"
              >Tap image to upload proof of address</a
            >
          </div>
        </div>

        <div
          v-if="
            userVerificationStatus?.level3?.proofOfAddress &&
            !userVerificationStatus?.level3?.address
          "
        >
          <div class="verifyInputs">
            <label> <b>Your Address</b> </label>
            <textarea
              placeholder="e.g No 3 Gray street oceanic part, Lagos Nigeria "
              cols="30"
              @input="validateInput"
              v-model="address"
              rows="7"
            ></textarea>
          </div>
        </div>

        <!-- <div class="verifyInputs">
        <label> ID card number </label>

        <input type="text" placeholder="e.g 67FB 6894 BJ765" />
      </div> -->
      </div>

      <div class="siteVerificationButtons">
        <button class="relsify-button backbutton" @click="goBackToPrevious">
          <i class="bx bx-chevron-left" v-if="!loading2"></i>
          <span>
            <span v-if="!loading2">Back</span>
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              v-if="loading2"
              aria-hidden="true"
            ></span>
          </span>
        </button>
        <button
          class="relsify-button continueButton"
          :disabled="emptyFields"
          @click="submitAddress"
        >
          <span
            ><span v-if="!loading">Save & Continue</span>
            <span class="loader" v-if="loading"></span
          ></span>
          <i class="bx bx-chevron-right"></i>
        </button>
      </div>
    </div>

    <div v-if="userVerificationStatus.level3.status == 'verified'">
      <VerifiedDiv :title="'Level 3 Verified'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import VerifiedDiv from "../../VerifiedDiv.vue";
// import $ from "jquery";
export default {
  components: {
    VerifiedDiv,
  },
  computed: {
    ...mapGetters(["userVerificationStatus"]),
    hasAddreess() {
      return this.userVerificationStatus &&
        this.userVerificationStatus.level3Status.addressVerification == "done"
        ? true
        : false;
    },
  },

  data() {
    return {
      address: "",
      addressDocument: null,
      addressDocumentName: "",
      emptyFields: true,
      showProve: false,
      hasSubmittedAddress: false,
      loading: false,
      loading2: false,
    };
  },

  methods: {
    ...mapActions(["setTabProjectClicked", "setAlertpopUp"]),

    uploadDocument: function (event) {
      if (event.target.files[0]) {
        this.addressDocument = event.target.files[0];
        this.addressDocumentName = event.target.files[0].name;

        this.validateInput();
      }
    },

    validateInput: function () {
      if (!this.userVerificationStatus.level3.proofOfAddress) {
        if (!this.addressDocument) {
          this.emptyFields = true;
          return false;
        }
        this.emptyFields = false;
        return true;
      }

      if (
        this.userVerificationStatus.level3.proofOfAddress &&
        !this.userVerificationStatus.level3.address
      ) {
        if (!this.address) {
          this.emptyFields = true;
          return false;
        }
        this.emptyFields = false;
        return true;
      }

      this.emptyFields = false;
      return true;
    },

    submitAddress: function () {
      this.loading = true;
      let url;
      let fd;

      if (!this.userVerificationStatus.level3.address && this.address) {
        url = `${this.RELSIFY_URL}/api/verification/level-three`;
        fd = {
          address: this.address,
        };
      }

      if (!this.userVerificationStatus.level3.address && this.addressDocument) {
        url = `${this.RELSIFY_URL}/api/verification/level-three/address-document/upload`;
        fd = new FormData();
        fd.append("document", this.addressDocument);
      }

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "PUT",
        headers: {
          // "Content-Type": "application/json",
          Authorization: this.token,
        },
        // body: JSON.stringify(fd),
        body: fd,
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          this.emptyFields = true;
          if (!data.error) {
            // console.log(data);
            this.globalFunction();
            this.setAlertpopUp({
              status: true,
              title: "Success! Verification in progress...",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            this.addressDocument = null;
            this.addressDocumentName = "";

            // window.location.reload();
            setTimeout(() => {
              this.$router.push({
                name: "SiteVerificationLevel3",
              });
            }, 1300);

            // if (!this.showProve) {
            //   this.showProve = true;
            // } else {

            //   setTimeout(() => {
            //     this.$router.push({
            //       name: "SiteVerification",
            //     });
            //   }, 1500);
            // }
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Update Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(data);
          }
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Update Status",
            des: "An Error occured while updating document",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },

    goBackToPrevious: function () {
      this.loading2 = true;

      setTimeout(() => {
        this.loading2 = false;
        this.$router.push({
          name: "SiteVerificationLevel2",
        });
      }, 1200);
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
</style>
