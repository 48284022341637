<template>
  <div
    class="container-fluid small_screen_effect_container"
    style="position: relative"
  >
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header wallet-page-header">
          <div class="page-title">
            <i class="bx bx-left-arrow-alt" @click="goBackToWallet"></i>
            <h2>Transaction details</h2>
          </div>
        </div>
        <div class="page-header-small-screen">
          <img
            src="@/assets/vectors/img/back-icon.png"
            class="arrow_bacck"
            alt=""
            @click="goBackToWallet"
          />
          <br />
          <h5>Transaction details</h5>
        </div>
      </div>
    </div>

    <div class="small_screen_effect_inner wallet_balance_page">
      <SingleTransaction />
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import SingleTransaction from "../../components/transaction/SingleTransaction.vue";
export default {
  components: {
    SingleTransaction,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
    goBackToWallet: function () {
      this.$router.push({
        name: "Wallet",
      });
    },
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.small_screen_effect_inner {
  margin-left: 8px;
  margin-right: 8px;
  background: transparent;
}
</style>
