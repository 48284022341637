<template>
  <div class="product-grid three-grid" v-if="projects && projects.length > 0">
    <ProjectItem
      v-for="(project, index) in allProjects"
      :key="index"
      :project="project"
    />
  </div>
</template>

<script>
import ProjectItem from "./ProjectItem.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    ProjectItem,
  },

  computed: {
    ...mapGetters(["projects"]),

    allProjects() {
      let projects = [];

      if (this.projects && this.projects.length > 0) {
        let filterProjectsWithImages = this.projects
          .slice(0)
          .filter((project) => project.mainImage.url !== "");
        if (filterProjectsWithImages.length > 0) {
          projects = filterProjectsWithImages.slice(0, 4);
        }
      }

      return projects;
    },
  },
};
</script>

<style scoped>
.product-grid .product-card:last-child {
  display: none;
}

@media screen and (max-width: 860px) {
  .product-grid .product-card:last-child {
    display: unset;
  }
}
</style>
