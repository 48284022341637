<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <div class="site-security-settings">
                <div class="site-security-settings-pageTitle">
                  <h3>Secure Your Account</h3>
                </div>
                <div class="site-security-settings-contents">
                  <router-link
                    :to="{ name: 'ChangePassword' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Change Password</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>

                  <router-link
                    v-if="!userSelf?.email"
                    :to="{ name: 'ChangeEmail' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Add and Verify Email</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                  <router-link
                    v-if="userSelf?.email"
                    :to="{ name: 'ChangeEmail' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Change Email</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>

                  <router-link
                    v-if="!userSelf?.phoneNumber"
                    :to="{ name: 'AddPhone' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Add and Verify Phone Number</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                  <router-link
                    v-if="userSelf?.phoneNumber"
                    :to="{ name: 'ChangePhoneNumber' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Change Phone Number</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>

                  <router-link
                    :to="{ name: 'LoginHistory' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Login History</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                  <router-link
                    :to="{ name: 'Activities' }"
                    class="site-security-settings-contents-items settingsHistories"
                  >
                    <span>Activities</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                  <router-link
                    :to="{ name: 'Device' }"
                    class="site-security-settings-contents-items"
                  >
                    <span>Devices</span>
                    <i class="bx bx-chevron-right"></i>
                  </router-link>
                  <router-link
                    :to="{ name: 'DeleteAccount' }"
                    class="site-security-settings-contents-items"
                  >
                    <span style="color: red">Delete Account</span>
                    <i class="bx bx-chevron-right" style="color: red"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";

export default {
  components: {
    SettingsLeftTab,
  },

  computed: {
    ...mapGetters(["userSelf"]),
  },

  data() {
    return {
      gender: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
