<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <div class="site-verification-settings">
                <div class="row" v-if="userVerificationStatus">
                  <div class="col-xl-12">
                    <VerificationLevelTab />

                    <div class="page-tab-content">
                      <div class="relsify-tab-pane active">
                        <div
                          class="settings-verify-email"
                          v-if="userVerificationStatus.level1.status == 'done'"
                        >
                          <!-- check for country -->
                          <div
                            v-if="
                              !userCountry &&
                              userVerificationStatus.level2.status !== 'done'
                            "
                            class="profile-settings-user-form-item"
                            style="
                              max-width: 600px;
                              margin-inline: auto;
                              min-height: 15vh;
                            "
                          >
                            <label
                              for=""
                              style="width: 100%; text-align: center"
                              >Select your country</label
                            >
                            <div class="profile-settings-div-input">
                              <select
                                v-model="userCountry"
                                aria-placeholder="Select country"
                                style="cursor: pointer"
                              >
                                <option value="" disabled>
                                  choose an option...
                                </option>
                                <option
                                  :value="country.name"
                                  v-for="(country, index) in countries"
                                  :key="index"
                                >
                                  {{ country.name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div v-else>
                            <!-- for nigerians -->
                            <div
                              v-if="
                                userCountry.toLowerCase() === 'nigeria' ||
                                (!userCountry &&
                                  userSelf?.profile?.country?.toLowerCase() ===
                                    'nigeria')
                              "
                            >
                              <div
                                style="
                                  display: flex;
                                  flex-direction: column;
                                  gap: 54px;
                                  max-width: 360px;
                                  margin-inline: auto;
                                "
                              >
                                <div>
                                  <!--photo upload -->
                                  <div
                                    class="profile-settings-user-form-item"
                                    style="margin-bottom: 6px"
                                  >
                                    <label for="" style="margin-bottom: 12px"
                                      >1. Photo (a clear picture of your face)
                                      <span class="text-danger">*</span></label
                                    >

                                    <div
                                      class="profile-settings-user-image"
                                      style="
                                        position: relative !important;
                                        margin: 0 !important;
                                      "
                                    >
                                      <img
                                        :src="
                                          imageUrl ||
                                          '@/assets/vectors/img/blank-avatar.png'
                                        "
                                        id="bvn_photo"
                                        ref="bvn_photo_image"
                                        style="
                                          border-radius: 50%;
                                          width: 140px;
                                          height: 140px;
                                          object-position: cover;
                                          border: 1px solid
                                            var(--mainGreenColor);
                                        "
                                        alt=""
                                      />
                                      <img
                                        src="@/assets/vectors/img/camera-auth.png"
                                        class="camera-auth"
                                        style="right: -1px"
                                        alt=""
                                        @click="
                                          !hasCompletedBVN &&
                                            $refs.bvn_photo_input.click()
                                        "
                                      />

                                      <input
                                        type="file"
                                        ref="bvn_photo_input"
                                        style="display: none"
                                        accept="image/png, image/gif, image/jpeg"
                                        @change="handlePhotoUpload($event)"
                                      />
                                    </div>
                                  </div>

                                  <!-- BVN -->
                                  <div>
                                    <div
                                      class="profile-settings-user-form-item"
                                    >
                                      <label for=""
                                        >2. BVN (photo is required)
                                        <span class="text-danger"
                                          >*</span
                                        ></label
                                      >
                                      <div
                                        class="profile-settings-div-input"
                                        style="margin-bottom: 12px"
                                      >
                                        <input
                                          class="relsify-form-control-input"
                                          placeholder="enter your bvn"
                                          type="number"
                                          v-model="bvn"
                                          :disabled="hasCompletedBVN"
                                        />
                                      </div>

                                      <button
                                        class="relsify-button"
                                        style="
                                          max-width: fit-content !important;
                                          padding: 8px 32px;
                                          font-size: 14px;
                                        "
                                        :disabled="!!hasCompletedBVN"
                                        @click="() => handleSubmit('bvn')"
                                      >
                                        <span>
                                          <span v-if="isSubmitting !== 'bvn'">{{
                                            hasCompletedBVN
                                              ? "Verified"
                                              : "Submit"
                                          }}</span>
                                          <span
                                            class="loader"
                                            v-if="isSubmitting === 'bvn'"
                                          ></span>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <!-- NIN -->
                                <div>
                                  <div class="profile-settings-user-form-item">
                                    <label for=""
                                      >3. NIN (first submit your BVN)
                                    </label>
                                    <div
                                      class="profile-settings-div-input"
                                      style="margin-bottom: 12px"
                                    >
                                      <input
                                        class="relsify-form-control-input"
                                        placeholder="enter your nin"
                                        type="number"
                                        v-model="nin"
                                        :disabled="!hasCompletedBVN"
                                      />
                                    </div>

                                    <button
                                      class="relsify-button"
                                      :disabled="!hasCompletedBVN"
                                      style="
                                        max-width: fit-content !important;
                                        padding: 8px 32px;
                                        font-size: 14px;
                                      "
                                      @click="() => handleSubmit('nin')"
                                    >
                                      <span>
                                        <span v-if="isSubmitting !== 'nin'"
                                          >Submit</span
                                        >
                                        <span
                                          class="loader"
                                          v-if="isSubmitting === 'nin'"
                                        ></span>
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- for other locales -->
                            <div v-else>
                              <h4 class="settings-title">Verify Photo ID</h4>

                              <ul id="progressbar" class="progressbar-tab-line">
                                <li
                                  class="active progressbar-tablink"
                                  tab-id="siteVerificationPhotoID"
                                  :class="[
                                    userVerificationStatus.level2.photo &&
                                      'verified',
                                  ]"
                                >
                                  Photo ID
                                </li>

                                <li
                                  class="progressbar-tablink"
                                  :class="[
                                    userVerificationStatus.level2
                                      .personalInformation && 'verified',
                                  ]"
                                  tab-id="siteVerificationPersonalInfo"
                                >
                                  Personal Information
                                </li>
                                <li
                                  class="progressbar-tablink"
                                  tab-id="siteVerificationIdentityVerify"
                                  :class="[
                                    userVerificationStatus.level2.identity &&
                                      'verified',
                                  ]"
                                >
                                  Identity Verification
                                </li>
                              </ul>

                              <div class="progressbar-tab-content">
                                <div
                                  class="progressbar-tab-pane active"
                                  id="siteVerificationPhotoID"
                                >
                                  <VerifyPhotoID
                                    :status="
                                      !!userVerificationStatus.level2.photo
                                    "
                                  />
                                </div>
                                <div
                                  class="progressbar-tab-pane"
                                  id="siteVerificationPersonalInfo"
                                >
                                  <VerifyPersonalInfo
                                    :status="
                                      userVerificationStatus.level2
                                        .personalInformation
                                    "
                                  />
                                </div>
                                <div
                                  class="progressbar-tab-pane"
                                  id="siteVerificationIdentityVerify"
                                >
                                  <IdentityVerification
                                    :status="
                                      userVerificationStatus.level2.identity
                                    "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          class="settings-verify-email"
                          v-if="userVerificationStatus.level1.status !== 'done'"
                        >
                          <br /><br />
                          <br /><br />

                          <h4 class="settings-title">
                            Please complete Level 1 verification
                          </h4>
                          <br /><br />
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="!userVerificationStatus">
                  <div class="col-md-12">
                    <div
                      class="d-flex justify-content-center align-items-center"
                      style="
                        background: #ffffff;
                        padding: 10px 40px 20px;
                        border-radius: 4.80178px;
                      "
                    >
                      <br />
                      <br />
                      <LoadingComponent />
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import $ from "jquery";
import { mapActions, mapGetters } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";
import VerifyPhotoID from "../../components/settings/verification/VerifyPhotoID.vue";
import VerifyPersonalInfo from "../../components/settings/verification/VerifyPersonalInfo.vue";
import IdentityVerification from "../../components/settings/verification/IdentityVerification.vue";
import VerificationLevelTab from "../../components/settings/verification/VerificationLevelTab.vue";
import LoadingComponent from "../../components/loading/LoadingComponent.vue";
import localforage from "localforage";

export default {
  components: {
    SettingsLeftTab,
    VerificationLevelTab,
    VerifyPhotoID,
    VerifyPersonalInfo,
    IdentityVerification,
    LoadingComponent,
  },

  computed: {
    ...mapGetters([
      "userVerificationStatus",
      "userSelf",
      "level2VerificationData",
      "token",
    ]),

    // type userVerificationStatus {
    //   level1: {
    //     status: 'done',
    //     emailVerified: true,
    //     phoneNumberVerified: false,
    //     acceptedTerms: true
    //   },
    //   level2: {
    //     status: 'unverified',
    //     photo: false,
    //     personalInformation: false,
    //     identity: false,
    //     bvn: false,
    //     nin: false
    //   },
    //   level3: {
    //     status: 'unverified',
    //     address: false,
    //     proofOfAddress: false
    //   },
    //   level: 1
    // }
    countries() {
      return this.$store.state.countries;
    },
    hasVerifiedAllProfileDetails() {
      let verified = false;

      if (
        this.user &&
        this.user.firstName &&
        this.user.lastName &&
        this.user.middleName &&
        this.user.gender &&
        this.user.country &&
        this.user.state &&
        this.user.address
      ) {
        verified = true;
      }

      return verified;
    },
    hasCompletedBVN() {
      if (this.userVerificationStatus?.level2?.bvn) {
        return true;
      }
      return false;
    },
  },

  data() {
    return {
      gender: "",
      userCountry: "",
      verificationPhoto: "",
      bvn: "",
      nin: "",
      imageUrl: "",
      isSubmitting: "",
      appToken: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName", "globalFunction", "setAlertpopUp"]),
    checkLevelsTwo: function () {
      if (
        this.userVerificationStatus &&
        this.userVerificationStatus.level2.status == "done" &&
        this.userVerificationStatus.level3.status == "unverified"
      ) {
        this.$router.push({
          name: "SiteVerificationLevel3",
        });
      } else {
        setTimeout(() => {
          this.checkLevelsTwo();
        }, 1000);
      }
    },
    handlePhotoUpload: function (event) {
      if (event.target?.files[0]) {
        this.verificationPhoto = event.target.files[0];

        let reader = new FileReader();
        reader.onload = function (loadEvent) {
          let imgElement = document.querySelector("#bvn_photo");

          imgElement.setAttribute("src", loadEvent?.target?.result);
        };

        reader.readAsDataURL(event.target.files[0]);
      }
    },
    handleSubmit: function (dataType) {
      if (
        (dataType === "bvn" && this.bvn && this.verificationPhoto) ||
        (dataType === "nin" && this.nin)
      ) {
        const url = `${this.RELSIFY_URL}/api/verification/verify-${dataType}`;

        const fd = new FormData();
        if (dataType === "bvn") {
          fd.append("photo", this.verificationPhoto);
          fd.append("bvn", this.bvn);
        } else {
          fd.append("nin", this.nin);
        }

        const requestOptions = {
          method: "PUT",
          headers: {
            Authorization: this.appToken,
          },
          body: fd,
        };

        this.isSubmitting = dataType;

        fetch(url, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            this.isSubmitting = "";
            if (!data.error) {
              // console.log(data);
              this.globalFunction();
              this.setAlertpopUp({
                status: true,
                title: "Succesful",
                des: data.meta.message,
                type: "success",
                payload: null,
              });

              if (
                this.currentPageName == "SiteVerificationLevel2" &&
                dataType === "nin"
              ) {
                const attrib = $("[tab-id=siteVerificationLevel3]");
                attrib.click();
              }
            } else {
              this.setAlertpopUp({
                status: true,
                title: "Update Status",
                des: data.meta.message,
                type: "error",
                payload: null,
              });
              // console.log(data);
            }
          })
          .catch(() => {
            // console.log("iiiiii", err);
            this.isSubmitting = "";
            this.setAlertpopUp({
              status: true,
              title: "Update Status",
              des: `An Error occured while submiting your ${dataType}`,
              type: "error",
              payload: null,
            });
            // console.log(error);
          });
      } else {
        this.setAlertpopUp({
          status: true,
          title: "Missing Field",
          des:
            dataType === "bvn"
              ? "Please ensure you have BVN and Photo added before submitting"
              : "Please ensure your Nin is added before submitting",
          type: "error",
          payload: null,
        });
      }
    },
  },
  mounted() {
    if (this.userVerificationStatus?.level2?.bvn) {
      this.userCountry = this.user?.country || "";
      this.bvn = this.level2VerificationData?.bvn || "";
      this.nin = this.level2VerificationData?.nin || "";
      this.imageUrl = this.level2VerificationData?.photo?.url || "";
    }
    // this.checkVerificationAuthentication();
    this.closeMarketPlace();
    this.checkLevelsTwo();

    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();

    localforage.getItem("Appoen").then((token) => {
      this.appToken = token || "";
    });
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
