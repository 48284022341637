<template>
  <div
    class="mobile-menu"
    :class="
      ['SiteVerification', 'Profile'].includes(currentPageName) &&
      'hideMobileMenu'
    "
  >
    <router-link
      :to="{ name: 'Home' }"
      :class="['Home'].includes(currentPageName) && 'active'"
    >
      <img
        src="@/assets/vectors/img/home-icon.png"
        style="height: 20px; width: 20px"
        alt=""
        v-if="currentPageName == 'Home'"
      />
      <img
        v-else
        src="@/assets/vectors/img/mobile-home-icon.png"
        style="height: 20px; width: 20px"
        alt=""
      />
      <span class="nav-text">Home</span>
    </router-link>
    <router-link
      :to="{ name: 'Trade' }"
      :class="
        ['Trade', 'Sell', 'Swap', 'Checkout'].includes(currentPageName) &&
        'active'
      "
    >
      <img
        v-if="['Trade', 'Sell', 'Swap', 'Checkout'].includes(currentPageName)"
        src="@/assets/vectors/img/people-trading-2.png"
        style="height: 20px; width: 20px"
        alt=""
      />

      <img
        v-else
        src="@/assets/vectors/img/mobile-people-trading.png"
        style="height: 20px; width: 20px"
        alt=""
      />
      <span class="nav-text">Trade</span>
    </router-link>

    <router-link
      :to="{ name: 'Portfolio' }"
      :class="['Portfolio'].includes(currentPageName) && 'active'"
    >
      <img
        src="@/assets/vectors/img/mobile-investment-active.png"
        v-if="currentPageName == 'Portfolio'"
        alt=""
      />
      <img src="@/assets/vectors/img/mobile-investment.png" v-else alt="" />
      <span class="nav-text">Portfolio</span>
    </router-link>

    <router-link
      :to="{ name: 'Wallet' }"
      :class="
        [
          'Wallet',
          'WalletDeposit',
          'WalletWithdrawal',
          'WalletDetails',
        ].includes(currentPageName) && 'active'
      "
    >
      <img
        src="@/assets/vectors/img/sidebar-wallet.png"
        v-if="
          [
            'Wallet',
            'WalletDeposit',
            'WalletWithdrawal',
            'WalletDetails',
          ].includes(currentPageName)
        "
        alt=""
      />
      <img src="@/assets/vectors/img/mobile-wallet.png" v-else alt="" />
      <span class="nav-text">Wallet</span>
    </router-link>

    <router-link
      :to="{ name: 'Settings' }"
      :class="['Settings'].includes(currentPageName) && 'active'"
    >
      <i class="bx bxs-cog"></i>
      <span class="nav-text">Settings</span>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
