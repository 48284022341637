<template>
  <div class="main_page_oauth login_register">
    <br />
    <br />
    <div class="main_page_oauth_header">
      <h3>LOG<span class="yellow">IN</span></h3>
    </div>
    <br />
    <br />

    <div class="register_with">
      <button
        class="register_with_link isActive"
        tab-id="registerWithEmail"
        @click="setSignUpType('withEmail')"
      >
        Email
      </button>
      <button
        class="register_with_link"
        tab-id="registerWithPhone"
        @click="setSignUpType('withPhone')"
      >
        Phone Number
      </button>
    </div>

    <LoginForm />
    <SocialLoginRegistration :signInType="`LOGIN`" />
  </div>
</template>

<script>
import "../assets/css/oauth.css";
import { mapMutations } from "vuex";
import LoginForm from "../components/forms/login/LoginForm.vue";
import SocialLoginRegistration from "../components/forms/SocialLoginRegistration.vue";

export default {
  components: {
    LoginForm,
    SocialLoginRegistration,
  },

  methods: {
    ...mapMutations(["SET_REGISTRATION_TYPE", "GET_COUNTRIES"]),
    setSignUpType: function (value) {
      // this.$store.state.registrationType = value;
      this.SET_REGISTRATION_TYPE(value);
    },
  },

  mounted() {
    // this.$store.state.registrationType = "withEmail";
    this.SET_REGISTRATION_TYPE("withEmail");

    this.GET_COUNTRIES();
  },
};
</script>
