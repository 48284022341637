<template>
  <div class="">
    <div class="page-header wallet-page-header">
      <div class="page-title">
        <i class="bx bx-left-arrow-alt" @click="goBackToWallet"></i>
        <h2>Wallet</h2>
      </div>
      <router-link
        :to="{
          name: 'UserTransactionHistory',
        }"
        class="page-breadcrum"
      >
        <p>Transaction History</p>
        <i class="bx bx-right-arrow-alt"></i>
      </router-link>
    </div>
    <div class="page-header-small-screen">
      <img
        src="@/assets/vectors/img/back-icon.png"
        class="arrow_bacck"
        alt=""
        @click="goBackToWallet"
      />
      <!-- <i class="bx bx-left-arrow-alt" onclick="window.history.back();"></i> -->
      <br />
      <h5>Wallet</h5>
      <MyWalletBalance />

      <!-- <div class="page-header-small-screen-balance">
        <div class="page-header-small-screen-balance-content">
          <p>Total Wallet value</p>
          <h6>${{ totalAmount }}</h6>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MyWalletBalance from "../../components/extras/MyWalletBalance.vue";
export default {
  components: {
    MyWalletBalance,
  },
  computed: {
    ...mapGetters(["userWalletBalance"]),
    // totalAmount() {
    //   let sumall = 0;
    //   if (this.userWalletBalance && this.userWalletBalance.length) {
    //     sumall = this.userWalletBalance
    //       .map((item) => item.amount)
    //       .reduce((prev, curr) => prev + curr, 0);
    //   }

    //   return sumall;
    // },
  },

  methods: {
    goBackToWallet: function () {
      if (this.currentPageName == "WalletDetails") {
        window.history.back();
      } else {
        this.$router
          .push({
            name: "Wallet",
          })
          .catch((err) => err);
      }
    },
  },
};
</script>
