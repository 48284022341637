<template>
  <div class="main_page_oauth reset_password_header">
    <div class="main_page_oauth_header">
      <h3>FORGOT <span class="yellow">PASSWORD</span></h3>
    </div>

    <form class="forms_div" v-on:submit.prevent="submitForm">
      <div class="form_groups_grid">
        <div>
          <!-- TAB HEADER -->
          <div class="ry_flex">
            <button
              type="button"
              :class="{ ry_btn: true, inActive: !isEmail }"
              tab-id="registerWithEmail"
              @click="
                () => {
                  isEmail = true;
                  if (!email) {
                    emptyFields = true;
                  } else {
                    emptyFields = false;
                  }
                }
              "
            >
              Email
            </button>
            <button
              type="button"
              :class="{ ry_btn: true, inActive: isEmail }"
              tab-id="registerWithPhone"
              @click="
                () => {
                  isEmail = false;
                  if (!phoneNumber) {
                    emptyFields = true;
                  } else {
                    emptyFields = false;
                  }
                }
              "
            >
              Phone Number
            </button>
          </div>

          <div class="ry_tabs">
            <!-- email -->
            <div v-if="isEmail">
              <div class="form_groups_grid_item">
                <i class="bx bxs-user"></i>
                <input
                  type="text"
                  v-model="email"
                  @input="validateForm"
                  placeholder="Email or phone number"
                />
              </div>
            </div>
            <!-- phone -->
            <div v-if="!isEmail">
              <div class="form_grid_double">
                <div
                  class="mb-3 mt-3 form_groups_grid_item phone_register"
                  v-if="countries && countries.length"
                >
                  <div
                    class="select-input select-input-input"
                    style="cursor: pointer !important"
                    @click="showCountries"
                  >
                    <div style="cursor: pointer !important; display: flex">
                      <img
                        style="cursor: pointer !important"
                        :src="selectedCountry.flag"
                        v-if="selectedCountry.flag"
                        alt=""
                      />
                      <input
                        type="text"
                        style="
                          cursor: pointer !important;
                          text-align: left !important;
                          padding-left: 0px !important;
                          margin-left: 3px !important;
                          padding-right: 5px !important;
                        "
                        disabled
                        :value="selectedCountry.isoCode"
                      />
                    </div>
                    <i class="bx bx-chevron-down" id="selectToken"></i>
                  </div>
                </div>

                <div class="form_groups_grid_item">
                  <span
                    ><b>{{ selectedCountry.dialCode }}</b></span
                  >
                  <input
                    type="text"
                    v-model="phoneNumber"
                    oninput="
                      this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
                    "
                    @input="validateForm"
                    autocomplete="off"
                    placeholder="phone number"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" :disabled="emptyFields">
          <span v-if="!loading">SEND RESET LINK </span>
          <span class="loader" v-if="loading"></span>
        </button>
      </div>
    </form>

    <div class="dont_have_account">
      <span
        >Already have an account?
        <router-link to="/login" class="green_color">Login</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import "../assets/css/oauth.css";
import "@/mixins";
import { mapActions } from "vuex";
export default {
  computed: {
    countries() {
      return this.$store.state.countries;
    },
    countryFlag: {
      get() {
        return this.$store.state.countryFlag;
      },
      set(value) {
        return (this.$store.state.countryFlag = value);
      },
    },

    countryDialCode: {
      get() {
        return this.$store.state.countryDialCode;
      },
      set(value) {
        return (this.$store.state.countryDialCode = value);
      },
    },

    selectedCountry: {
      get() {
        return this.$store.state.selectedCountry;
      },
      set(value) {
        this.validateForm();
        return (this.$store.state.selectedCountry = value);
      },
    },
  },

  data() {
    return {
      email: "",
      phoneNumber: "",
      isEmail: true,
      isOtpMode: false,
      country: "NG",
      loading: false,
      emptyFields: true,
      responseData: null,
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "setSelectCountryModal"]),
    validateForm: function () {
      if (this.isEmail) {
        if (!this.email || !this.validEmail(this.email)) {
          this.emptyFields = true;

          return false;
        }

        this.emptyFields = false;
        return true;
      }

      if (!this.isEmail) {
        if (!this.phoneNumber) {
          this.emptyFields = true;

          return false;
        }

        this.emptyFields = false;
        return true;
      }
    },

    showCountries: function () {
      this.setSelectCountryModal({
        status: true,
        title: "",
        type: "",
        payload: null,
      });
    },

    setCountry: function () {
      this.$store.commit("SET_COUNTRY_FLAG", this.country);
      this.phoneNumber = "";
      this.validateForm();
    },

    submitForm: function () {
      this.loading = true;
      this.emptyFields = true;

      let payload = {};
      if (this.isEmail) {
        payload = {
          email: this.email,
        };
      } else {
        payload = {
          phoneNumber: this.countryDialCode + this.phoneNumber,
        };
      }

      let url = `${this.RELSIFY_URL}/api/forgot-password`;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.validateForm();
          this.loading = false;
          if (!data.error) {
            // console.log(data);

            this.setAlertpopUp({
              status: true,
              title: "Status",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            let routeQuery = {};

            if (this.isEmail) {
              routeQuery = { email: this.email };
            } else {
              routeQuery = { phone: this.phoneNumber };
            }

            if (data.data && data.data.secretTokenId) {
              setTimeout(() => {
                this.$router.push({
                  name: "ResetPassword",
                  query: {
                    ...routeQuery,
                    secret: data.data.secretTokenId,
                  },
                });
              }, 2000);
            }
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Status",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(data);
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}

.ry_flex {
  display: flex;
  gap: 16px;
}

div.ry_flex button.ry_btn {
  padding-inline: 2rem !important;
  padding-block: 0.25rem;

  width: fit-content;
  color: #ffffff;
  background: var(--mainGreenColor);
  border: none;
  border-radius: 30px;
  margin-top: 0px;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}

div.ry_flex button.ry_btn.inActive {
  color: #aaaaaa;
  background: transparent;
}

.ry_tabs {
  padding-top: 22px;
}
</style>
