<template>
  <div class="instructionMessage">
    <div v-if="!status">
      <p>Plesse take a proper picture or upload a photo from your device</p>

      <UploadImage />

      <div class="text-center mt-5">
        <button class="relsify-button" @click="checkImage">
          <span v-if="!loading">{{
            user && user.image?.url ? "Continue" : "Submit"
          }}</span>

          <span
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="loading"
            aria-hidden="true"
          ></span>
        </button>
      </div>
    </div>
    <div v-if="status">
      <VerifiedDiv :title="'Photo ID Verified'" />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import UploadImage from "../../forms/profile/UploadImage.vue";
import VerifiedDiv from "../../VerifiedDiv.vue";
import $ from "jquery";
import { mapActions } from "vuex";
export default {
  components: {
    UploadImage,
    VerifiedDiv,
  },

  props: ["status"],

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp"]),

    goToNext: function () {
      let attrib = $("[tab-id=siteVerificationPersonalInfo]");
      attrib.click();
    },

    checkImage: function () {
      // console.log("user", this.user);

      if (this.user && !this.user.image?.url) {
        this.setAlertpopUp({
          status: true,
          title: "Update Status",
          des: "Please choose an Image to upload",
          type: "error",
          payload: null,
        });
      } else {
        this.loading = true;

        setTimeout(() => {
          this.loading = false;
          this.goToNext();
        }, 1400);
      }
    },
  },
};
</script>
