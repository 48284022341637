<template>
  <div>
    <div class="page-header-small-screen-balance">
      <div class="page-header-small-screen-balance-content">
        <p style="width: 100%">
          <span>
            {{
              currentPageName == "Portfolio"
                ? "Total portfolio value "
                : "Total wallet value "
            }}
          </span>

          <span
            v-if="currentPageName == 'Portfolio'"
            style="
              font-size: 12px;
              background-color: rgba(255, 255, 255, 0.4);
              backdrop-filter: blur(10px);
              border-radius: 6px;
              padding-inline: 12px;
              color: #111;
            "
            @click="togglePortfolioBalances"
          >
            <span style="font-weight: 600; font-size: 12px"
              >{{ showPortfolioBalance ? "Hide" : "Show" }} Balance</span
            >
            <i
              v-if="!showPortfolioBalance"
              style="margin-left: 10px; font-size: 17px; cursor: pointer"
              class="fa fa-eye-slash"
            ></i>
            <i
              v-else
              style="margin-left: 10px; font-size: 17px; cursor: pointer"
              class="fa fa-eye"
            ></i>
          </span>
          <span
            v-if="
              [
                'Wallet',
                'WalletDeposit',
                'WalletWithdrawal',
                'WalletDetails',
              ].includes(currentPageName)
            "
            style="font-size: 12px"
            @click="toggleWalletBalances"
          >
            <span>{{ showWalletBalance ? "Hide" : "Show" }} Balance</span>
            <i
              v-if="!showWalletBalance"
              style="margin-left: 10px; font-size: 17px; cursor: pointer"
              class="fa fa-eye-slash"
            ></i>
            <i
              v-else
              style="margin-left: 10px; font-size: 17px; cursor: pointer"
              class="fa fa-eye"
            ></i>
          </span>
        </p>
        <LoadingComponent v-if="userWalletBalance == null" />

        <span v-if="currentPageName == 'Portfolio'">
          <div
            v-if="portfolios && showPortfolioBalance"
            style="
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 5px;
            "
          >
            <img
              v-if="userDefaultCurrency.iconUrl"
              style="border-radius: 100%; width: 30px; height: 30px"
              :src="userDefaultCurrency.iconUrl"
              alt=""
            />
            <h6 style="margin-bottom: 0px !important">
              <!-- <vue-numeric
              currency="$"
              separator=","
              v-model="totalPortfolioAmount"
            ></vue-numeric> -->
              {{ formatUsdValue(totalPortfolioAmount) }}
            </h6>
          </div>

          <h6
            v-if="portfolios && !showPortfolioBalance"
            style="margin-top: 22px"
          >
            ************
          </h6>
        </span>
        <span
          v-if="
            [
              'Wallet',
              'WalletDeposit',
              'WalletWithdrawal',
              'WalletDetails',
            ].includes(currentPageName)
          "
        >
          <div
            v-if="userWalletBalance && showWalletBalance"
            style="
              cursor: pointer;
              display: flex;
              align-items: center;
              gap: 5px;
            "
          >
            <img
              v-if="userDefaultCurrency.iconUrl"
              style="border-radius: 100%; width: 30px; height: 30px"
              :src="userDefaultCurrency.iconUrl"
              alt=""
            />
            <h6 style="margin-bottom: 0px !important">
              <!-- <vue-numeric
              currency="$"
              separator=","
              v-model="totalPortfolioAmount"
            ></vue-numeric> -->
              {{ formatUsdValue(totalAmount) }}
            </h6>
          </div>
          <h6 v-if="userWalletBalance && !showWalletBalance">************</h6>
        </span>
        <span class="relsify_currency_type_container">
          <i
            class="fa fa-edit"
            style="font-size: 24px"
            @click="
              showSelectProjectModal({
                currency: userDefaultCurrency.symbol,
              })
            "
          ></i>
          <p>Change currency</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";

export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["userWalletBalance", "portfolios", "user"]),
    totalAmount() {
      let sumall = 0;
      if (this.userWalletBalance && this.userWalletBalance.length) {
        sumall = this.userWalletBalance
          .map((item) => Number(item.value))
          .reduce((prev, curr) => prev + curr, 0);

        sumall = this.$numberic(sumall).format("0,0.0[000]");
      }

      return sumall;
    },
    totalPortfolioAmount() {
      let sumall = 0;
      if (this.portfolios && this.portfolios.length) {
        sumall = this.portfolios
          .map((item) => Number(item.balance.value))
          .reduce((prev, curr) => prev + curr, 0);

        sumall = this.$numberic(sumall).format("0,0.0[000]");
      }

      return sumall;
    },
    userDefaultCurrency: {
      get() {
        return this.$store.state.userSelf.defaultCurrency;
      },
      set() {},
    },

    showPortfolioBalance: {
      get() {
        return this.$store.state.showPortfolioBalance;
      },
      set(value) {
        return (this.$store.state.showPortfolioBalance = value);
      },
    },

    showWalletBalance: {
      get() {
        return this.$store.state.showWalletBalance;
      },
      set(value) {
        return (this.$store.state.showWalletBalance = value);
      },
    },
  },

  methods: {
    ...mapActions(["setSelectProjectModal"]),
    togglePortfolioBalances: function () {
      this.showPortfolioBalance = !this.showPortfolioBalance;
    },
    toggleWalletBalances: function () {
      this.showWalletBalance = !this.showWalletBalance;
    },
    showSelectProjectModal: function (networks) {
      if (!this.$route.query.code) {
        this.setSelectProjectModal({
          status: true,
          title: "",
          desc: "",
          payload: networks,
          select1Type: "",
          select2Type: "",
          select3Type: "",
          select4Type: "",
          select5Type: "",
          select6Type: "",
          type: "CHANGE_CURRENCY",
        });
      }
      return;
    },
  },
};
</script>

<style>
.page-header-small-screen-balance-content p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}

.page-header-small-screen-balance-content p span:first-child {
  text-transform: capitalize;
}

.page-header-small-screen-balance-content p span:last-child {
  cursor: pointer;
  padding: 4px;
}

@media screen and (max-width: 580px) {
  /* #LargeBalancee {
    display: none;
  } */
  .page-header-small-screen-balance {
    width: 95%;
    margin: 10px auto;
    /* padding: 20px; */
  }

  .light_green_small_button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .light_green_small_button p {
    font-style: normal;
    font-weight: normal;
    font-size: 15.3753px;
    line-height: 18px;
    padding: 8px 13px;
    background: rgba(24, 133, 111, 0.05);
    border-radius: 6px;
    margin-bottom: 0px;
  }
}
</style>
