<template>
  <transition name="fade">
    <div class="marketPlaceModal" v-if="marketPlaceModal">
      <div class="row">
        <div class="col-xl-12">
          <div class="page-header">
            <div class="page-title">
              <i class="bx bx-left-arrow-alt" @click="closeOutMarketPlace"></i>
              <h2>Marketplace</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="relsify-center-search">
            <form class="relsify-search" @submit.prevent="searchAllProjects">
              <i class="bx bx-search-alt-2"></i>
              <input
                type="search"
                v-model="searchText"
                @input="validateInput"
                placeholder="Search Marketplace"
              />
              <button type="submit" class="relsify_search_submit">
                Search
              </button>
            </form>
          </div>
        </div>
      </div>

      <div v-if="projects && projects.length">
        <div class="row" v-if="isSearching == false && onSearch == false">
          <div
            class="col-xl-12"
            v-if="projectCategory && projectCategory.length"
          >
            <div class="page-small-tabs text-center">
              <div class="nav-tab">
                <a
                  href="javascript:;"
                  :class="selectedCategory == '' && 'active'"
                  @click="filterProduct('')"
                  style="text-transform: capitalize"
                  >All</a
                >
                <a
                  v-for="(category, index) in projectCategory"
                  :key="index"
                  href="javascript:;"
                  :class="category.category == selectedCategory && 'active'"
                  @click="filterProduct(category.category)"
                  style="text-transform: capitalize"
                  >{{ category.category }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10 mx-auto mb-5 market-place-10-column">
            <div v-if="searchResult.length <= 0 && onSearch == false">
              <div v-if="allProjects.length">
                <div class="product-grid three-grid">
                  <ProjectItem
                    v-for="(project, index) in allProjects"
                    :key="index"
                    :project="project"
                  />
                </div>
                <div class="text-center">
                  <Infinitescrolltrigger />
                </div>
              </div>

              <div
                class="card"
                style="margin-top: 80px"
                v-if="allProjects.length <= 0"
              >
                <div class="text-center">
                  <br />
                  <br />
                  <br />
                  <p>No project Found</p>
                  <br />
                  <br />
                </div>
              </div>
            </div>
            <div v-if="onSearch == true">
              <div v-if="searchResult.length">
                <div class="product-grid three-grid">
                  <ProjectItem
                    v-for="(project, index) in searchResult"
                    :key="index"
                    :project="project"
                  />
                </div>
              </div>

              <div
                class="row"
                v-if="isSearching == false && searchResult.length <= 0"
              >
                <div class="col-md-9 mx-auto">
                  <div class="card" style="margin-top: 80px">
                    <div class="text-center">
                      <br />
                      <br />
                      <br />
                      <p>{{ errorMessage }}</p>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center" v-if="isSearching == true">
              <br />
              <br />
              <br />
              <span
                class="spinner-border spinner-border-md"
                role="status"
                aria-hidden="true"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions, mapGetters } from "vuex";

import ProjectItem from "../projects/ProjectItem.vue";
import Infinitescrolltrigger from "../projects/Infinitescrolltrigger.vue";
export default {
  components: {
    ProjectItem,
    Infinitescrolltrigger,
  },

  computed: {
    ...mapGetters(["projects", "projectCategory"]),
    allProjects() {
      let projects = [];

      if (this.projects && this.projects.length > 0) {
        let filterProjectsWithImages = this.projects
          .slice(0)
          .filter((project) => project.mainImage.url !== "");
        if (filterProjectsWithImages.length > 0) {
          projects = filterProjectsWithImages;
        }
      }

      return projects;
    },

    // projectCategory() {
    //   let category = [];

    //   if (this.projects && this.projects.length > 0) {
    //     category = this.eliminateDuplicateCategory(this.projects);
    //   }

    //   return category;
    // },

    selectedCategory: {
      get() {
        return this.$store.state.selectedCategory;
      },
      set(value) {
        return (this.$store.state.selectedCategory = value);
      },
    },
  },

  data() {
    return {
      searchText: "",
      // selectedCategory: "",
      searchResult: [],
      onSearch: false,
      isSearching: false,
      errorMessage: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName", "filterProjects", "getProjects"]),
    closeOutMarketPlace: function () {
      this.closeMarketPlace();
      this.searchText = "";
      this.selectedCategory = "";
      this.errorMessage = "";
      this.searchResult = [];
      this.onSearch = false;
      this.isSearching = false;
    },

    filterProduct: function (category) {
      if (this.selectedCategory == category) {
        return;
      }
      this.selectedCategory = category;

      this.isSearching = false;
      // this.onSearch = false;

      if (category == "") {
        // Get all projects
        this.getProjects({
          page: 1,
          limit: 20,
        }).then((data) => {
          if (!data.data.error) {
            // console.log(data.data);
            this.isSearching = false;
          }
        });
      } else {
        let url = `${
          this.RELSIFY_URL
        }/api/projects?page=${1}&limit=${20}&category=${this.selectedCategory}`;
        this.filterProjects(url).then((data) => {
          if (!data.data.error) {
            // console.log(data.data);
            this.isSearching = false;
          }
        });
      }
    },

    validateInput: function () {
      if (this.searchText && this.searchText.length <= 2) {
        this.isSearching = false;
        this.onSearch = false;
        this.searchResult = [];
        return;
      }
    },

    searchAllProjects: async function () {
      if (!this.searchText) {
        this.isSearching = false;
        this.onSearch = false;

        return;
      }

      this.searchResult = [];
      this.isSearching = true;
      this.onSearch = true;

      let url = `${this.RELSIFY_URL}/api/search?page=1&limit=200`;

      let payload = {
        search: this.searchText,
        // country: this.searchText,
        // state: this.searchText,
        // city: this.searchText,
        // status: this.searchText,
      };

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(url, requestOptions);
      const data = await response.json();

      try {
        this.isSearching = false;
        if (!data.error) {
          // console.log(data);
          this.searchResult = data.data;
          this.errorMessage = data.meta.message;
        } else {
          this.searchResult = data.data;
          this.errorMessage = data.meta.message;
          // console.log(data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    eliminateDuplicateCategory(arr) {
      return arr
        .map((e) => e["category"])
        .map((e, i, final) => final.indexOf(e) === i && i)
        .filter((obj) => arr[obj])
        .map((e) => arr[e]);
    },
  },

  watch: {
    searchText: function () {
      if (this.searchText == "") {
        this.searchResult = [];
        this.isSearching = false;
        this.onSearch = false;
      }
    },
  },
};
</script>

<style scoped>
.relsify_search_submit {
  border-radius: 40px;
  border: 1px solid #ced4dc;
  padding: 4px 16px;
  font-size: 12px;
  font-weight: bold;
  margin-right: -8px;
  background: #f8f8f8;
  color: #18856f;
  cursor: pointer;
}
.relsify_search_submit:focus {
  outline: none;
}
</style>
