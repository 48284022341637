<template>
  <tr class="table_roww" v-if="dividend">
    <td>{{ dividend.project.title }}</td>
    <td>
      <div class="td-with-image">
        <img
          v-if="dividend.coin.iconUrl"
          :src="dividend.coin.iconUrl"
          alt=""
        />
        <span>{{ dividend.coin.name }}</span>
      </div>
    </td>
    <td>
      <span >
        {{
           $numberic(dividend.amount).format("0.00")+ ' ' + dividend.coin.symbol
        }}
      </span>
    </td>
    <td>
      <p>
        {{ formateDateAndTime(dividend.createdAt) }}
      </p>
    </td>
    <!-- <td>
      {{ transaction.coin.symbol }}
      <div class="td-with-image" style="cursor: pointer">
        <img
          v-if="transaction.coin.iconUrl"
          style="border-radius: 100%; height: 20px"
          :src="transaction.coin.iconUrl"
          alt=""
        />
        <span>{{ transaction.coin.name }}</span>
      </div>
    </td>
    <td>{{ formatAmount(transaction.amount) }}</td>
    <td>{{ transaction.status }}</td>
    <td>{{ transaction.networkName }}</td>
    <td>{{ transaction.hash }}</td>
    <td>{{ transaction.address }}</td>
    <td class="table-button" style="text-align: right">
      <button
        class="relsify-button light-shade-green"
        @click="goToTransactionDetails(transaction)"
      >
        Details
      </button>
    </td> -->
  </tr>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
export default {
  props: ["dividend"],
  methods: {
    ...mapActions([
      "setTransactionDetailsModal",
      "setModalLoading",
      "setAlertpopUp",
      "setPortfolioModal",
      "getSingleProject",
    ]),
    goToTransactionDetails: function (transaction) {
      this.$router.push({
        name: "SingleTransaction",
        query: { id: transaction.id },
      });
    },
    showPortfolioModal: function (portfolio) {
      let projectId = portfolio.project.id;

      this.getSingleProject(projectId);
      this.setPortfolioModal({
        status: true,
        title: "",
        desc: "",
        payload: portfolio,
        select1Type: "",
        select2Type: "",
        type: "PORTFOLIO",
      });
    },
    valuationInDollars(value) {
      return `$${Number(value).toLocaleString()}`
    }
  },
};
</script>

<style scoped>
.table_roww td {
  padding: 40px 10px;
  background: #fff;
}
.table_roww td p {
  display: inline;
  width: auto;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

[class*="loader"]:before {
  content: "loading...";
}

.td-with-image img {
  border-radius: 100%;
  height: 20px;
}
</style>
