<template>
  <div class="mb-3 mt-3">
    <div class="relsify-search-form">
      <div class="relsify-table-search grid-search">
        <div class="relsify-table-search-item">
          <form class="relsify-search" @submit.prevent="searchPort">
            <i class="bx bx-search-alt-2"></i>
            <input
              type="search"
              v-model="searchText"
              placeholder="Search "
            />
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="relsify-table tr-shadow">
    <div class="table-responsive table-web">
      <table class="table">
        <thead class="text-left">
          <tr class="">
            <th>Project</th>
            <th>Coin </th>
            <th>Amount</th>
            <th>Date/Time</th>
          </tr>
        </thead>

        <tbody class="text-left" v-if="allDividend && allDividend.length > 0">
          <DividendTable
            v-for="(dividend, index) in allDividend"
            :key="index"
            :dividend="dividend"
          />
          <tr style="box-shadow: none !important">
            <td colspan="6">
              <div
                class="text-center d-flex justify-content-center align-items-center"
              >
                <Infinitescrolltrigger />
              </div>
            </td>
          </tr>
        </tbody>
        <tbody
          v-if="allDividend && allDividend.length <= 0"
        >
          <tr style="
            background-color: unset !important;
          ">
            <td colspan="8">
              <div class="card text-center" style="
                border: unset !important;
              ">
                <div class="card-body">
                  <h6>No dividend found</h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="allDividend == null">
          <tr>
            <td colspan="8">
              <div class="d-flex justify-content-center align-items-center">
                <br />
                <LoadingComponent />
                <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <DividendMobile
      :allDividend="allDividend"
    />
    <div v-if="allDividend && allDividend.length <= 0">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters, mapActions } from "vuex";
import DividendTable from "./DividendTable.vue";
import DividendMobile from "./DividendMobile.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";
import Infinitescrolltrigger from "./Infinitescrolltrigger.vue";

export default {
  components: {
    DividendTable,
    DividendMobile,
    LoadingComponent,
    Infinitescrolltrigger 
  },
  computed: {
    ...mapGetters(["dividends", "activityFilters"]),
    allDividend() {
        let dividends = [];
  
        if (this.dividends && this.dividends.length > 0) {
          dividends = this.dividends.filter(
            (dividend) =>
              dividend.coin.name
                .toLowerCase()
                .match(this.searchText.toLowerCase()) ||
              dividend.project.title
                .toLowerCase()
                .match(this.searchText.toLowerCase()) ||
              dividend.project.state
                .toLowerCase()
                .match(this.searchText.toLowerCase()) ||
              dividend.project.description
                .toLowerCase()
                .match(this.searchText.toLowerCase()) ||
              dividend.project.country
                .toLowerCase()
                .match(this.searchText.toLowerCase())  
          );
        }
  
        return dividends;
      },

      showPortfolioBalance: {
        get() {
          return this.$store.state.showPortfolioBalance;
        },
        set(value) {
          return (this.$store.state.showPortfolioBalance = value);
        },
      },
  },
  data() {
    return {
      searchText: "",
      selectedDate: null,
    };
  },
  methods: {
      ...mapActions(["setModalLoading", "setAlertpopUp", "setPortfolioModal",]),
      showPortfolioModal: function (portfolio) {
        // console.log(portfolio);
        this.setPortfolioModal({
          status: true,
          title: "",
          desc: "",
          payload: portfolio,
          select1Type: "",
          select2Type: "",
          type: "PORTFOLIO",
        });
      },
  
      searchPort: function () {},
    },
};
</script>

<style></style>
