<template>
  <div class="alert-modal" v-if="alertMessageModal.status">
    <div class="alert-modal-overlay" @click="closeAlertMessageModal"></div>
    <div class="alert-modal-card vivify popInBottom">
      <div class="close-alert-button">
        <i
          class="bx bx-x"
          id="closeAlertModal"
          @click="closeAlertMessageModal"
        ></i>
      </div>

      <div class="alert-modal-body">
        <div class="alert-modal-icon">
          <i class="bx bx-check"></i>
        </div>
        <h4>{{ alertMessageModal.title }}</h4>
        <p>
          {{ alertMessageModal.des }}
        </p>

        <div class="alert-modal-button">
          <button
            @click="buttonTwoButton"
            class="relsify-button white-button"
            style="margin-right: 10px"
            v-if="alertMessageModal.button2"
          >
            {{ alertMessageModal.button2Text }}
            <!-- Buy more -->
          </button>
          <button @click="buttonOneButton" class="relsify-button">
            {{ alertMessageModal.button1Text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["alertMessageModal"]),
    sideNotificationModal() {
      return this.$store.state.sideNotificationModal;
    },

    currentPageType: {
      get() {
        return this.$store.state.currentPageType;
      },
      set(value) {
        return (this.$store.state.currentPageType = value);
      },
    },
  },

  methods: {
    buttonOneButton: function () {
      if (this.alertMessageModal.button1Url == "PORTFOLIO") {
        this.$router
          .push({
            name: "Portfolio",
          })
          .catch((err) => err);
      }
    },
    buttonTwoButton: function () {
      if (this.alertMessageModal.button2Url == "CHECKOUT") {
        this.currentPageType = this.$router.currentRoute._rawValue.name;
        this.showMarketPlace();
        this.closeAlertMessageModal();

        return;
      }

      if (this.alertMessageModal.button2Url == "SWAP") {
        this.currentPageType = this.$router.currentRoute._rawValue.name;
        this.showMarketPlace();
        this.closeAlertMessageModal();
        return;
      }
      if (this.alertMessageModal.button2Url == "SELL") {
        this.currentPageType = this.$router.currentRoute._rawValue.name;
        this.showMarketPlace();
        this.closeAlertMessageModal();
        return;
      }
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped></style>
