<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Settings</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <div class="row">
        <div class="col-xl-12">
          <div class="settings-grid">
            <SettingsLeftTab />

            <div class="settings-grid-item-right">
              <ChangeEmail />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import SettingsLeftTab from "@/components/settings/SettingsLeftTab.vue";
import ChangeEmail from "../../components/settings/security/ChangeEmail.vue";

export default {
  components: {
    SettingsLeftTab,
    ChangeEmail,
  },

  data() {
    return {
      gender: "",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 1 !important;
}
</style>
