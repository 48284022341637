<template>
  <div class="row">
    <div class="col-md-12 mb-4">
      <div class="relsify-search-form">
        <div class="relsify-table-search grid-search grid-search-space-between">
          <div class="relsify-table-search-item">
            <div class="select_">
              <select v-if="activityFilters" v-model="selectedDate">
                <option :value="null">All</option>
                <option
                  v-for="(date, index) in activityFilters"
                  :key="index"
                  :value="date"
                >
                  {{ date.label }}
                </option>
              </select>
            </div>
          </div>
          <!-- <div class="relsify-table-search-item">
            <div class="relsify-search">
              <i class="bx bx-search-alt-2"></i>
              <input v-model="searchText" type="search" placeholder="Search" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="col-md-12" v-if="selectedActivities.length > 0">
      <div class="relsify-notification-items-div" style="margin-bottom: 0px">
        <div
          class="relsify-notification-item"
          style="padding-top: 20px; padding-left: 14px"
        >
          <div class="notification-item_delete">
            <span>Delete</span> <i class="bx bx-trash-alt"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <div
        class="relsify-notification-items-div"
        v-if="activities && activities.length"
      >
        <ActivityItem
          v-for="(activity, index) in activities"
          :key="index"
          :activity="activity"
        />
      </div>
      <div
        class="relsify-notification-items-div"
        v-if="activityLogs && activities && activities.length <= 0"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <br />
          No Activity log
          <br />
          <br />
        </div>
      </div>
      <div class="relsify-notification-items-div" v-if="activityLogs == null">
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <LoadingComponent />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters } from "vuex";
import ActivityItem from "./ActivityItem.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";

export default {
  components: {
    ActivityItem,
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["activityLogs", "activityFilters"]),

    // activities() {
    //   let activities = [];

    //   if (this.activityLogs && this.activityLogs.length > 0) {
    //     activities = this.activityLogs.filter((notification) =>
    //       notification.type.toLowerCase().match(this.searchText.toLowerCase())
    //     );
    //   }

    //   return activities;
    // },
    activities() {
      let activities = [];

      if (this.activityLogs && this.activityLogs.length > 0) {
        if (this.selectedDate == null) {
          activities = this.activityLogs;
        } else {
          if (this.selectedDate.label == "Last 30 days") {
            activities = this.activityLogs.filter((activity) => {
              let date = new Date(activity.date).toDateString();
              return date >= this.selectedDate.to.toDateString();
            });
          } else {
            activities = this.activityLogs.filter((activity) => {
              let date = new Date(activity.date).toDateString();

              return (
                this.selectedDate.from.toDateString() >= date &&
                date <= this.selectedDate.to.toDateString()
              );
            });
          }
        }
      }

      return activities;
    },

    selectedActivities: {
      get() {
        return this.$store.state.selectedActivities;
      },
      set(value) {
        this.$store.state.selectedActivities = value;
      },
    },
  },

  data() {
    return {
      searchText: "",
      selectedDate: null,
    };
  },
};
</script>

<style scoped>
.relsify-notification-items-div h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 6px;
  text-align: left;
  letter-spacing: -0.24px;
  color: #18856f;
  margin-left: 10px;
  margin-bottom: 10px;
}

.notification-item_delete {
  background: rgba(133, 24, 24, 0.137);
  padding: 7px 12px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
}

.notification-item_delete span {
  font-size: 13px;
  margin-right: 10px;
}

.notification-item_delete i {
  color: rgb(110, 2, 2);
}
</style>
