<template>
  <div class="select-modal" v-if="selectProjectModal.status">
    <!-- hideModal -->

    <div
      class="select-modal-card vivify popInBottom"
      style="position: relative"
    >
      <div class="close-select-button" @click="closeSelectedProjectModal">
        <i class="bx bx-x" id="closeToken"></i>
      </div>

      <div class="select-modal-search">
        <label for="">{{
          selectProjectModal.type == "CHANGE_CURRENCY"
            ? "Select Preferred Currency"
            : "Select Preferred Token From Your Wallet"
        }}</label>
        <div class="relsify-search">
          <i class="bx bx-search-alt-2"></i>
          <input type="search" v-model="searchText" placeholder="Search" />
        </div>
      </div>

      <div
        class="select-modal-body"
        v-if="
          selectProjectModal.type == 'CHECKOUT_PROJECT' ||
          selectProjectModal.type == 'SELL_PROJECT' ||
          selectProjectModal.type == 'SWAP_PROJECT'
        "
      >
        <div
          class="select-modal-content"
          v-if="selectProjectModal.payload && allTokens && allTokens.length > 0"
        >
          <div style="width: 100%" v-if="selectProjectModal.select1Type">
            <div
              class="select-modal-item"
              :class="
                token.name.toLowerCase() ==
                  currentProject.selectedSellingToken.name.toLowerCase() &&
                'selected'
              "
              v-for="(token, index) in selectProjectModal.payload"
              :key="index"
              @click="selectToken(token)"
            >
              <p>
                <img v-if="token.iconUrl" :src="token.iconUrl" alt="" />
                <span>{{ token.symbol }}</span> <small>{{ token.name }}</small>
              </p>
            </div>
          </div>
          <div style="width: 100%" v-else>
            <div
              class="select-modal-item"
              :class="
                token.name.toLowerCase() ==
                  currentProject.selectedToken.name.toLowerCase() && 'selected'
              "
              v-for="(token, index) in selectProjectModal.payload"
              :key="index"
              @click="selectToken(token)"
            >
              <p>
                <img v-if="token.iconUrl" :src="token.iconUrl" alt="" />
                <span>{{ token.symbol }}</span> <small>{{ token.name }}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="select-modal-body"
        v-if="selectProjectModal.type == 'WALLET_DEPOSIT'"
      >
        <div
          class="select-modal-content"
          v-if="filteredCoins && filteredCoins.length > 0"
        >
          <div
            class="select-modal-item"
            :class="
              token?.symbol.toLowerCase() ==
                selectProjectModal.payload?.symbol.toLowerCase() && 'selected'
            "
            v-for="(token, index) in filteredCoins"
            :key="index"
            @click="selectDepositToken(token)"
          >
            <p>
              <img v-if="token.iconUrl" :src="token.iconUrl" alt="" />
              <span>{{ token.symbol }}</span> <small>{{ token.name }}</small>
            </p>
          </div>
        </div>
      </div>
      <div
        class="select-modal-body"
        v-if="selectProjectModal.type == 'CHANGE_CURRENCY'"
      >
        <div
          class="select-modal-content"
          v-if="
            filteredCurrencyChangeCoins &&
            filteredCurrencyChangeCoins.length > 0
          "
        >
          <div
            class="select-modal-item"
            :class="
              token?.symbol.toLowerCase() ==
                selectProjectModal.payload?.currency.toLowerCase() && 'selected'
            "
            v-for="(token, index) in filteredCurrencyChangeCoins"
            :key="index"
            @click="changeCurrency(token)"
          >
            <p>
              <img v-if="token.iconUrl" :src="token.iconUrl" alt="" />
              <span>{{ token.symbol }}</span> <small>{{ token.name }}</small>
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 30px;
          width: 100%;
          height: 100%;
          background-color: rgba(211,211,211, .4);
          backdrop-filter: blur(3px);
          z-index: 1000;
        "
      >
        <LoadingComponent />
      </div>
    </div>
  </div>
</template>
<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["selectProjectModal", "allCoins", "currentCurrencyType"]),
    allTokens() {
      let tokens = null;

      if (
        this.selectProjectModal.status &&
        this.selectProjectModal.payload.length > 0
      ) {
        tokens = this.selectProjectModal.payload.filter(
          (token) =>
            token.symbol.toLowerCase().match(this.searchText.toLowerCase()) ||
            token.name.toLowerCase().match(this.searchText.toLowerCase())
        );
      }

      return tokens;
    },

    currentProject: {
      get() {
        return this.$store.state.currentProject;
      },
      set(value) {
        return (this.$store.state.currentProject = value);
      },
    },

    currentCoin: {
      get() {
        return this.$store.state.currentCoin;
      },
      set(value) {
        return (this.$store.state.currentCoin = value);
      },
    },

    filteredCurrencyChangeCoins: {
      get() {
        if (this.allCoins?.length) {
          return this.allCoins.filter(
            (coin) =>
              String(coin.external).match(true) &&
              (coin.symbol.toLowerCase().match(this.searchText.toLowerCase()) ||
                coin.name.toLowerCase().match(this.searchText.toLowerCase()))
          );
        }
        return null;
      },
      set() {},
    },

    filteredCoins: {
      get() {
        if (this.allCoins?.length) {
          return this.allCoins.filter(
            (coin) =>
              String(coin.type).match(this.currentCurrencyType.toLowerCase()) &&
              !String(coin.symbol).match("USD") &&
              (coin.symbol.toLowerCase().match(this.searchText.toLowerCase()) ||
                coin.name.toLowerCase().match(this.searchText.toLowerCase()))
          );
        }
        return null;
      },
      set() {},
    },
  },

  data() {
    return {
      searchText: "",
      loading: false,
    };
  },

  methods: {
    ...mapActions(["setSelectProjectModal", "changeUserCurrency"]),
    closeSelectedProjectModal: function () {
      this.setSelectProjectModal({
        status: false,
        title: "",
        desc: "",
        payload: null,
        select1Type: "",
        select2Type: "",
        select3Type: "",
        select4Type: "",
        select5Type: "",
        select6Type: "",
        type: "",
      });
      this.searchText = "";
    },
    selectToken: function (token) {
      this.currentProject.selectedToken = token;
      this.closeSelectedProjectModal();
    },
    selectDepositToken: function (coin) {
      if (this.selectProjectModal.type == "WALLET_DEPOSIT") {
        if (this.currentPageName == "WalletDeposit") {
          this.currentCoin = coin;
        }

        if (this.currentPageName == "WalletWithdrawal") {
          this.currentCoin = coin;
        }
      }

      this.closeSelectedProjectModal();
    },
    async changeCurrency(coin) {
      this.loading = true;

      try {
        const res = await this.changeUserCurrency({
          currency: coin.symbol,
        });
        if (!res.data.error) {
          // this.loading = false;
        }
      } catch (error) {
        this.setAlertpopUp({
          status: true,
          title: "Server Error",
          des: error.message || "An Error occured",
          type: "error",
          payload: null,
        });
      } finally {
        this.loading = false;
        this.closeSelectedProjectModal();
      }
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .select-modal-card {
    width: 100%;
    height: 100%;
    padding: 17px 19px;
    border-radius: 0px;
  }

  .select-modal .select-modal-search label {
    font-size: 15px;
  }

  .select-modal-item p img {
    width: 35px;
  }

  .select-modal-item p span {
    font-size: 19px;
  }

  .select-modal-item p small {
    font-size: 11px;
  }
}
</style>
