<template>
  <div
    class="main_page_oauth reset_password_header"
    style="padding-bottom: 40px"
  >
    <div class="main_page_oauth_header">
      <h3>RESET <span class="yellow">PASSWORD</span></h3>
    </div>

    <form class="forms_div" v-on:submit.prevent="submitForm">
      <div class="form_groups_grid">
        <div
          class="form_groups_grid_item disabledInput"
          v-if="$route.query.email"
        >
          <!-- <i class="bx bxs-user"></i> -->
          <input
            type="text"
            :value="$route.query.email"
            disabled
            placeholder="Email or phone number"
          />
        </div>
        <div
          class="form_groups_grid_item disabledInput"
          v-if="$route.query.phone"
        >
          <!-- <i class="bx bxs-user"></i> -->
          <input
            type="text"
            :value="$route.query.phone"
            disabled
            placeholder="Email or phone number"
          />
        </div>
        <transition name="fade">
          <p
            v-if="formError"
            style="
              color: red !important;
              font-size: 13px !important;
              margin-top: 5px;
              margin-bottom: 0px;
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 20 20"
              svg-inline=""
              role="presentation"
              focusable="false"
              tabindex="-1"
              style="color: red !important"
              fill="red"
            >
              <path
                d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
              ></path>
            </svg>
            {{ formMessage }}
          </p>
        </transition>

        <div class="text-left" style="margin-bottom: -15px">
          <label for="" style="font-size: 13px"
            ><b>Token</b>: 6 Digit secret token sent to your email</label
          >
        </div>
        <div class="form_groups_grid_item">
          <!-- <i class="bx bxs-user"></i> -->
          <input
            type="text"
            @input="validateForm"
            autocomplete="false"
            v-model="token"
            placeholder="Enter token"
          />
        </div>
        <div class="text-left" style="margin-bottom: -15px">
          <label for="" style="font-size: 13px"> New Password</label>
        </div>
        <div class="form_groups_grid_item">
          <i class="bx bxs-lock"></i>
          <input
            :type="showPassword ? 'text' : 'password'"
            @input="validateForm"
            autocomplete="off"
            placeholder="New Password"
            v-model="password"
          />
          <i
            class="bx bx-show-alt"
            v-if="!showPassword"
            style="cursor: pointer"
            @click="togglePasword"
          ></i>
          <i
            class="bx bx-hide"
            v-if="showPassword"
            style="cursor: pointer"
            @click="togglePasword"
          ></i>
        </div>
        <div class="text-center">
          <transition name="fade">
            <p
              v-if="passwordError"
              style="
                color: red !important;
                font-size: 13px !important;
                margin-top: 5px;
                margin-bottom: 0px;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 20 20"
                svg-inline=""
                role="presentation"
                focusable="false"
                tabindex="-1"
                style="color: red !important"
                fill="red"
              >
                <path
                  d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
                ></path>
              </svg>
              {{ passwordMessage }}
            </p>
          </transition>
        </div>

        <div class="text-left" style="margin-bottom: -15px">
          <label for="" style="font-size: 13px">Confirm New Password</label>
        </div>

        <div class="form_groups_grid_item">
          <i class="bx bxs-lock"></i>
          <input
            :type="showcPassword ? 'text' : 'password'"
            @input="validateForm"
            autocomplete="off"
            placeholder="Confirm New Password"
            v-model="cpassword"
          />
          <i
            class="bx bx-show-alt"
            v-if="!showcPassword"
            style="cursor: pointer"
            @click="togglecPasword"
          ></i>
          <i
            class="bx bx-hide"
            v-if="showcPassword"
            style="cursor: pointer"
            @click="togglecPasword"
          ></i>
        </div>

        <button type="submit" :disabled="emptyFields">
          <span v-if="!loading">RESET PASSWORD </span>
          <span class="loader" v-if="loading"></span>
        </button>

        <div class="dont_have_account">
          <span
            >Already have an account?
            <router-link to="/login" class="green_color">Login</router-link>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import "../assets/css/oauth.css";
import { mapActions } from "vuex";
import "@/mixins";
export default {
  data() {
    return {
      token: "",
      password: "",
      cpassword: "",
      country: "NG",
      formError: false,
      formMessage: "",
      showPassword: false,
      showcPassword: false,
      passwordError: false,
      passwordMessage: "",
      emptyFields: true,
      loading: false,
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp"]),
    togglePasword: function () {
      this.showPassword = !this.showPassword;
      this.validateForm();
    },

    togglecPasword: function () {
      this.showcPassword = !this.showcPassword;
      this.validateForm();
    },

    validateForm: function () {
      if (!this.token) {
        this.formError = true;
        this.formMessage = "Please include the secret token sent to your email";
        this.emptyFields = true;
      } else {
        this.formError = false;
        this.formMessage = "";
      }

      if (!this.password) {
        this.passwordError = true;
        this.passwordMessage = "Please include a new Passwords";
        this.emptyFields = true;
        return false;
      }
      if (this.password && this.password.length < 8) {
        this.passwordError = true;
        this.passwordMessage = "Passwords must be at least 8 characters.";
        this.emptyFields = true;
        return false;
      }

      if (!this.cpassword) {
        this.passwordError = true;
        this.passwordMessage = "Please confirm Password";
        this.emptyFields = true;
        return false;
      }
      if (this.cpassword && this.password !== this.cpassword) {
        this.passwordError = true;
        this.passwordMessage = "Password Do not match";
        this.emptyFields = true;
        return false;
      }

      this.passwordError = false;
      this.passwordMessage = "";
      this.emptyFields = false;
      return true;
    },

    submitForm: function () {
      this.loading = true;
      this.emptyFields = true;

      let url = `${this.RELSIFY_URL}/api/reset-password`;
      let payload = {};

      if (this.$route.query.email) {
        payload = {
          otpId: this.$route.query.secret,
          email: this.$route.query.email,
          otpCode: this.token,
          newPassword: this.password,
          confirmPassword: this.cpassword,
        };
      }
      if (this.$route.query.phone) {
        payload = {
          otpId: this.$route.query.secret,
          phoneNumber: this.$route.query.phone,
          otpCode: this.token,
          newPassword: this.password,
          confirmPassword: this.cpassword,
        };
      }

      const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };

      // console.log(JSON.parse(requestOptions.body));

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.validateForm();
          this.loading = false;
          if (!data.error) {
            // console.log(data);

            // let newData = {
            //   secretId: data.data.secretId,
            //   email: this.email,
            // };

            this.setAlertpopUp({
              status: true,
              title: "Reset Password Successful",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            setTimeout(() => {
              this.$router.push({
                name: "Login",
              });
            }, 2000);
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Reset Password Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(data);
          }
        })
        .catch((error) => {
          error;
          this.validateForm();
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Reset Password Error",
            des: "",
            type: "error",
            payload: null,
          });
        });
    },
  },

  mounted() {
    if (
      !this.$route.query.secret &&
      (!this.$route.query.email || !this.$route.query.phone)
    ) {
      this.$router.push({ name: "Login" });
    }
  },
};
</script>
