<template>
  <div class="site-security-settings">
    <div class="site-security-settings-pageTitle">
      <h3>Change Phone Number</h3>
    </div>
    <div class="site-security-settings-contents">
      <div class="site-security-settings-contents-items-inner">
        <!-- <div class="form-group mb-5">
          <div class="" style="margin-top: 10px">
            <label> Present Number </label>

            <div class="verifyInputsWithButton">
              <input type="text" value="+234 8025 777 224" />
              <a
                href="javascript:;"
                @click="
                  showVerifyPinCodeModal(
                    'Please enter the 6 digit code sent to 080*********24'
                  )
                "
                >Send verification code</a
              >
            </div>
          </div>
          <span class="form-settings-warning"
            >Password must be atleast 8 characters with upper case letters
          </span>
        </div> -->
        <div class="form-group mb-5">
          <div class="" style="margin-top: 10px">
            <label> New Number </label>

            <div class="verifyInputsWithButton">
              <input
                type="text"
                @input="validateInput"
                placeholder="e.g +23480022000000 "
                v-model="newPhoneNumber"
              />
              <!-- <a
                href="javascript:;"
                @click="
                  showVerifyPinCodeModal(
                    'Please enter the 6 digit code sent to 070*********13'
                  )
                "
                >Send verification code</a
              > -->
            </div>
          </div>
        </div>

        <div class="form-group mb-5">
          <button
            :disabled="emptyFields"
            @click="VerifyPhoneNumber"
            class="relsify-button"
          >
            <span v-if="!loading">Submit</span>
            <span class="loader" v-if="loading"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
export default {
  components: {},

  data() {
    return {
      newPhoneNumber: "",
      emptyFields: true,
      loading: false,
      secretToken: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp"]),
    validateInput: function () {
      if (!this.newPhoneNumber) {
        this.emptyFields = true;

        return false;
      }
      if (this.newPhoneNumber && this.newPhoneNumber.length <= 10) {
        this.emptyFields = true;

        return false;
      }

      this.emptyFields = false;

      return true;
    },

    VerifyPhoneNumber: function () {
      this.loading = true;
      let url = `${this.RELSIFY_URL}/api/change/phonenumber`;
      let payload = {
        newPhoneNumber: this.newPhoneNumber,
      };

      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          // console.log(data);
          if (!data.error) {
            this.globalFunction();
            this.setAlertpopUp({
              status: true,
              title: "Phone change",
              des: data.meta.message,
              type: "success",
              payload: null,
            });
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Phone change Error",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Phone change Error",
            des: "An Error occured while verifying Phone number",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },
    showVerifyPinCodeModal: function (title) {
      this.setVerifyPinCodeModal({
        status: true,
        title: title,
        redirectUrl: null,
        type: "",
        payload: null,
      });
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
</style>
