<template>
  <div class="alert-modal" v-if="transactionDetailModal.status">
    <div
      class="alert-modal-overlay"
      @click="closeTransactionDetailsModal"
    ></div>
    <div class="alert-modal-card vivify popInBottom">
      <div class="close-alert-button">
        <i
          class="bx bx-x"
          id="closeAlertModal"
          @click="closeTransactionDetailsModal"
        ></i>
      </div>
      <h4>Transaction history</h4>

      <div class="alert-modal-body">
        <div
          class="alert-modal-body-transactionList"
          v-if="transactionDetailModal.payload"
        >
          <div class="alert-modal-body-transactionListItem">
            <p>Date:</p>
            <p>
              {{
                formateDateAndTimeByName(transactionDetailModal.payload.time)
              }}
            </p>
          </div>
          <div class="alert-modal-body-transactionListItem">
            <p>Token Name:</p>
            <p>{{ transactionDetailModal.payload.coin.name }}</p>
          </div>
          <div class="alert-modal-body-transactionListItem">
            <p>Amount:</p>
            <p>{{ addComma(transactionDetailModal.payload.amount) }}</p>
          </div>
          <div class="alert-modal-body-transactionListItem">
            <p>Status:</p>
            <p>{{ transactionDetailModal.payload.status }}</p>
          </div>
          <div class="alert-modal-body-transactionListItem">
            <p>Network:</p>
            <p>{{ transactionDetailModal.payload.networkName }}</p>
          </div>
          <div class="alert-modal-body-transactionListItem">
            <p>Hash:</p>
            <p>{{ transactionDetailModal.payload.hash }}</p>
          </div>
          <div class="alert-modal-body-transactionListItem">
            <p>Address:</p>
            <p>{{ transactionDetailModal.payload.address }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["transactionDetailModal"]),
  },

  methods: {
    ...mapActions(["setTransactionDetailsModal"]),
    closeTransactionDetailsModal: function () {
      this.setTransactionDetailsModal({
        status: false,
        payload: null,
        type: "",
      });
    },
  },
  mounted() {
    this.inputCodeNext();
  },
};
</script>

<style scoped>
.alert-modal-card {
  width: 520px;
  height: auto;
  padding: 0px;
}

.close-alert-button {
  padding: 10px 15px;
}

.alert-modal-body {
  background: #f1f1f1;
  padding: 17px 19px;
  border-radius: 10px;
  height: 100%;
  display: block;
}

.alert-modal h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.382609px;
  color: var(--mainGreenColor);
  /* margin-top: 12px; */
  margin-bottom: 22px;
}

.alert-modal-body-transactionList {
  background: #fff;
  width: 100%;
  padding: 17px 19px;
  border-radius: 10px;
}

.alert-modal-body-transactionListItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 18px; */
}

.alert-modal-body-transactionListItem p {
  text-align: left;
  font-size: 16px;
}

.alert-modal-body-transactionListItem p:last-child {
  text-align: right;
  color: var(--mainGreenColor);
  font-weight: bold;
  display: block;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
}

@media screen and (max-width: 580px) {
  .alert-modal-body {
    padding: 20px 10px 0px;
    height: 100%;
  }

  .alert-modal h4 {
    font-size: 19px;
  }

  .alert-modal-body-transactionList {
    height: auto;
    overflow-x: hidden;
    /* padding-right: 10px; */
  }

  .alert-modal-card {
    position: fixed !important;
    width: 100% !important;
    height: calc(100vh - 190px);
    bottom: 0px;
    overflow-y: scroll;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-bottom: 0px;
  }

  .alert-modal-card::-webkit-scrollbar {
    width: 0px;
  }

  .alert-modal-body-transactionListItem p {
    font-size: 15px;
  }

  .alert-modal-body-transactionListItem p:last-child {
    font-size: 13.5px;
    padding-right: 10px;
  }
}
</style>
