<template>
  <div class="product-card">
    <div class="product-card-img">
      <img v-if="project.mainImage.url" :src="project.mainImage.url" alt="" />
      <!-- <a href="javascript:;" class="product-card-category">{{
        project.status
      }}</a> -->
    </div>
    <div class="product-card-description">
      <div class="product-card-description-header">
        <h3
          @click="
            $router.push({
              name: 'ProjectDetails',
              query: { id: project.id },
            })
          "
          style="cursor: pointer"
        >
          {{ project.title }}
        </h3>
        <!-- <p v-html="project.description">
          {{ project.description }}
        </p> -->
      </div>
      <hr />

      <div class="product-card-description-details">
        <div class="product-card-description-details-item">
          <p>
            <span class="product-card-description-details-item-title"
              >Country</span
            >
            <span class="product-card-description-details-item-prc green-color"
              ><img
                v-if="countryFlag"
                :src="countryFlag"
                alt=""
                style="margin-right: 2px"
              />
              {{ project.country }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Target</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.baseCurrency?.symbol }} {{ project.target }}</span
            >
          </p>
        </div>

        <div class="product-card-description-details-item">
          <p>
            <span class="product-card-description-details-item-title"
              >Deadline
            </span>
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.fundraisingPeriod }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Raised</span
            >
            <span class="product-card-description-details-item-prc green-color"
              >{{ project?.baseCurrency?.symbol }}
              {{ project.totalRaised }}</span
            >
          </p>
        </div>
        <div class="product-card-description-details-item">
          <p>
            <span class="product-card-description-details-item-title"
              >City</span
            >
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.city }}</span
            >
          </p>
          <p>
            <span class="product-card-description-details-item-title"
              >Investors</span
            >
            <span
              class="product-card-description-details-item-prc green-color"
              >{{ project.investors }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["project"],
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    countries() {
      return this.$store.state.countries;
    },

    countryFlag() {
      let flag = "";
      if (this.countries && this.project) {
        let countryFlag = this.countries.find(
          (flag) =>
            flag.name.toLowerCase() == this.project.country.toLowerCase()
        );
        if (countryFlag) {
          flag = countryFlag.flag;
        }
      }

      return flag;
    },
  },
  methods: {
    ...mapActions(["setCurrentProject"]),
  },
};
</script>

<style></style>
