<template>
    <div class="container-fluid" style="position: relative">
      <div class="row">
        <div class="col-xl-12">
          <div class="page-header">
            <div class="page-title">
              <i
                class="bx bx-left-arrow-alt"
                onclick="window.history.back();"
              ></i>
              <h2>Dividend History</h2>
            </div>
          </div>
        </div>
      </div>
  
      <DividendHistory />
    </div>
  </template>
  
  <script>
  import "@/assets/css/dashboard.css";
  import "@/mixins";
  import { mapActions } from "vuex";
  import DividendHistory from "@/components/dividend/DividendHistory.vue";
  export default {
    components: {
      DividendHistory,
    },
  
    methods: {
      ...mapActions(["setCurrentPageName"]),
      goBackToWallet: function () {
        this.$router.push({
          name: "UserTransactionHistory",
        });
      },
    },
    mounted() {
      this.closeMarketPlace();
      this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
      // this.fillData();
    },
  };
  </script>
  