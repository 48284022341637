<template>
  <div class="relsify-product-review-modal" v-if="portfolioModal.status">
    <div class="relsify-product-review-modal-card vivify popInBottom">
      <div
        class="close-relsify-product-review-button"
        @click="closePortfolioModal"
      >
        <i class="bx bx-x" id="closeProductReviewModal"></i>
      </div>

      <div
        class="relsify-product-review-modal-header"
        v-if="portfolioModal.payload"
      >
        <div class="relsify-product-review-modal-header-image">
          <img
            v-if="portfolioModal.payload.balance.coin.iconUrl"
            :src="portfolioModal.payload.balance.coin.iconUrl"
            alt=""
          />
        </div>
        <!-- <h3>{{ portfolioModal.payload.balance.coin.name }}</h3> -->
        <h3>{{ portfolioModal.payload?.project?.title }}</h3>
      </div>
      <div
        class="relsify-product-review-modal-body"
        v-if="portfolioModal.payload"
      >
        <div class="relsify-product-review-modal-body-grid">
          <div
            class="relsify-product-review-modal-body-grid-item-image"
            v-if="projectToBuy"
          >
            <img
              v-if="projectToBuy.mainImage.url"
              :src="projectToBuy.mainImage.url"
            />
            <img v-else src="@/assets/vectors/img/projects.png" />
          </div>
          <div class="relsify-product-review-modal-body-grid-item-content">
            <div class="product-card-description-details">
              <div class="product-card-description-details-item">
                <p>
                  <span class="product-card-description-details-item-title"
                    >Target</span
                  >
                  <span class="td-with-image">
                    <!-- <img
                      v-if="portfolioModal.payload.project.baseCurrency.iconUrl"
                      style="border-radius: 100%"
                      :src="portfolioModal.payload.project.baseCurrency.iconUrl"
                      alt="icon"
                    /> -->
                    <span>{{
                      portfolioModal?.payload?.project?.baseCurrency?.symbol
                    }}</span>
                    <span
                      class="product-card-description-details-item-prc green-color"
                      >{{
                        $numberic(portfolioModal.payload.project.target).format(
                          "0,0"
                        )
                      }}</span
                    >
                  </span>
                </p>
                <p>
                  <span class="product-card-description-details-item-title"
                    >Raised</span
                  >
                  <span class="td-with-image">
                    <!-- <img
                      v-if="portfolioModal.payload.project.baseCurrency.iconUrl"
                      style="border-radius: 100%"
                      :src="portfolioModal.payload.project.baseCurrency.iconUrl"
                      alt="icon"
                    /> -->
                    <span>{{
                      portfolioModal?.payload?.project?.baseCurrency?.symbol
                    }}</span>
                    <span
                      class="product-card-description-details-item-prc green-color"
                      >{{
                        $numberic(
                          portfolioModal.payload.project.totalRaised
                        ).format("0,0")
                      }}</span
                    >
                  </span>
                </p>
              </div>
              <div class="product-card-description-details-item">
                <p>
                  <span class="product-card-description-details-item-title"
                    >Investors</span
                  >
                  <span
                    class="product-card-description-details-item-prc green-color"
                    >{{ portfolioModal.payload.project.investors }}</span
                  >
                </p>
                <p>
                  <span class="product-card-description-details-item-title"
                    >Property Valuation</span
                  >
                  <span class="td-with-image">
                    <!-- <img
                      v-if="portfolioModal.payload.project.baseCurrency.iconUrl"
                      style="border-radius: 100%"
                      :src="portfolioModal.payload.project.baseCurrency.iconUrl"
                      alt="icon"
                    /> -->
                    <span>{{
                      portfolioModal?.payload?.project?.baseCurrency?.symbol
                    }}</span>
                    <span
                      class="product-card-description-details-item-prc green-color"
                      >{{
                        $numberic(
                          portfolioModal.payload.project.propertyValuation
                        ).format("0,0")
                      }}</span
                    >
                  </span>
                </p>
              </div>
              <div
                class="product-card-description-details-item"
                style="
                  background: var(--mainLightGreenColor2);

                  padding: 10px;
                  border-radius: 10px;
                  margin-top: 22px;
                "
              >
                <p>
                  <span class="product-card-description-details-item-title"
                    ><b>Balance</b></span
                  >
                  <span class="product-card-description-details-item-prc">{{
                    formatAmount(portfolioModal.payload.balance.amount) +
                    "/" +
                    $numberic(
                      portfolioModal.payload.project.tokenAmount
                    ).format("0,0") +
                    " " +
                    // portfolioModal.payload.balance.coin.symbol +
                    "UNIT" +
                    " (" +
                    formatPercentageValue(
                      portfolioModal.payload.balance.ownedPercentage
                    ) +
                    "%)"
                  }}</span>
                </p>
                <p>
                  <span class="product-card-description-details-item-title"
                    ><b>Dollar Value</b></span
                  >
                  <span class="product-card-description-details-item-prc">
                    {{ portfolioModal?.payload?.project?.baseCurrency?.symbol }}
                    {{
                      formatUsdValue(portfolioModal.payload.balance.usdValue)
                    }}
                    <!-- ${{
                      addComma(
                        this.parseNumber(
                          portfolioModal.payload.balance.usdValue
                        )
                      )
                    }} -->
                  </span>
                </p>
              </div>
              <!-- <div class="product-card-description-details-item">
                <p>
                  <span class="product-card-description-details-item-title"
                    >ROI</span
                  >
                  <span
                    class="product-card-description-details-item-prc green-color"
                    >12.5-19%
                    <small
                      style="display: block; margin-top: -5px; font-weight: 400"
                      >Annually</small
                    ></span
                  >
                </p>
                <p>
                  <span class="product-card-description-details-item-title"
                    >Minimum Investment</span
                  >
                  <span
                    class="product-card-description-details-item-prc green-color"
                    ><img src="@/assets/vectors/img/bitcoin-btc.png" alt="" />
                    <span>Bella Token</span></span
                  >
                </p>
              </div> -->
            </div>
          </div>
        </div>
        <div class="relsify-product-review-modal-body-button">
          <button
            class="relsify-button"
            @click="goToProjectDetails(portfolioModal.payload.project.id)"
          >
            Property overview
          </button>
        </div>
        <div class="text-center" v-if="!selectedAction">
          <div class="page-tab">
            <a
              href="javascript:;"
              class="active"
              @click="selectActionType('Buy')"
              >Buy</a
            >
            <a href="javascript:;" @click="selectActionType('Sell')">Sell</a>
            <a href="javascript:;" @click="selectActionType('Swap')">Swap</a>
          </div>
        </div>
        <div class="text-center" v-if="selectedAction">
          <div class="page-tab" style="margin-bottom: 0px !important">
            <a href="javascript:;" class="active" @click="goToProjectPage">
              <span v-if="!loading">confirm {{ selectedType }}</span>
              <span class="loader" v-if="loading"></span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/mixins";
import "@/assets/css/modal.css";
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["portfolioModal", "projects"]),
    projectToBuy: {
      get() {
        return this.$store.state.projectToBuy;
      },
      set(value) {
        return (this.$store.state.projectToBuy = value);
      },
    },
  },

  data() {
    return {
      selectedAction: false,
      selectedType: "buy",
      loading: false,
    };
  },

  methods: {
    ...mapActions(["setPortfolioModal"]),
    closePortfolioModal: function () {
      this.setPortfolioModal({
        status: false,
        title: "",
        desc: "",
        payload: null,
        select1Type: "",
        select2Type: "",
        type: "",
      });
      this.selectedAction = false;
      this.selectedType = "buy";
    },

    selectActionType: function (type) {
      if (type == "Buy") {
        this.$router
          .push({
            name: "Checkout",
            query: { id: this.portfolioModal.payload.project.id },
          })
          .then((err) => err);
      }
      if (type == "Sell") {
        this.$router
          .push({
            name: "Sell",
            query: { id: this.portfolioModal.payload.project.id },
          })
          .then((err) => err);
      }
      if (type == "Swap") {
        this.projectToBuy = null;
        this.$router
          .push({
            name: "Swap",
            query: { id: this.portfolioModal.payload.project.id },
          })
          .then((err) => err);
      }
      this.closePortfolioModal();
    },

    goToProjectDetails: function (projectId) {
      this.closePortfolioModal();

      this.$router
        .push({
          name: "ProjectDetails",
          query: { id: projectId },
        })
        .then((err) => err);
    },
  },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}

.td-with-image {
  display: flex;
  align-items: center;
}
</style>
