<template>
  <div class="row">
    <div class="col-md-12 mb-4">
      <div class="relsify-search-form">
        <div class="relsify-table-search grid-search grid-search-space-between">
          <div class="relsify-table-search-item">
            <div class="select_">
              <select
                v-if="activityFilters"
                @change="removeSearchText"
                v-model="selectedDate"
              >
                <option :value="null">All</option>
                <option
                  v-for="(date, index) in activityFilters"
                  :key="index"
                  :value="date"
                >
                  {{ date.label }}
                </option>
              </select>
            </div>
          </div>
          <div class="relsify-table-search-item">
            <div class="relsify-search">
              <i class="bx bx-search-alt-2"></i>
              <input
                v-model="searchText"
                :disabled="notifications && notifications.length <= 0"
                type="search"
                placeholder="Search"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 mb-3">
      <div
        class="relsify-notification-items-div relsify-notification-page"
        v-if="
          !searchText &&
          !selectedDate &&
          newNotifications &&
          newNotifications.length
        "
      >
        <h4>Unread</h4>

        <NotificationItem
          v-for="(notification, index) in newNotifications"
          :key="index"
          :notification="notification"
        />
      </div>
      <div
        class="relsify-notification-items-div"
        v-if="
          !searchText &&
          !selectedDate &&
          oldNotifications &&
          oldNotifications.length
        "
      >
        <h4>Notification</h4>

        <NotificationItem
          v-for="(notification, index) in oldNotifications"
          :key="index"
          :notification="notification"
        />
      </div>
      <div
        class="relsify-notification-items-div"
        v-if="
          (searchText || selectedDate) && notifications && notifications.length
        "
      >
        <NotificationItem
          v-for="(notification, index) in notifications"
          :key="index"
          :notification="notification"
        />
      </div>
      <div
        class="relsify-notification-items-div"
        v-if="allNotifications && notifications && notifications.length <= 0"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <br />
          <br />
          <br />
          <br />
          No Notification
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div
        class="relsify-notification-items-div"
        v-if="allNotifications == null"
      >
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <LoadingComponent />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters } from "vuex";
import NotificationItem from "./NotificationItem.vue";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    NotificationItem,
    LoadingComponent,
  },

  computed: {
    ...mapGetters(["allNotifications", "activityFilters"]),

    newNotifications() {
      let notifications = [];

      if (this.allNotifications && this.allNotifications.length > 0) {
        if (this.selectedDate == null) {
          notifications = this.allNotifications.filter(
            (notification) => notification.isRead == false
          );
        } else {
          notifications = this.allNotifications.filter((notification) => {
            let date = new Date(notification.time).toDateString();

            return (
              this.selectedDate.from.toDateString() >= date &&
              date <= this.selectedDate.to.toDateString()
            );
          });
        }
      }

      return notifications;
    },

    oldNotifications() {
      let notifications = [];

      if (this.allNotifications && this.allNotifications.length > 0) {
        if (this.selectedDate == null) {
          notifications = this.allNotifications.filter(
            (notification) => notification.isRead == true
          );
        } else {
          notifications = this.allNotifications.filter((notification) => {
            let date = new Date(notification.time).toDateString();

            return (
              this.selectedDate.from.toDateString() >= date &&
              date <= this.selectedDate.to.toDateString()
            );
          });
        }
      }

      return notifications;
    },

    notifications() {
      let notifications = [];

      if (this.allNotifications && this.allNotifications.length > 0) {
        if (this.selectedDate == null) {
          notifications = this.allNotifications.filter((notification) =>
            notification.content
              .toLowerCase()
              .match(this.searchText.toLowerCase())
          );
        } else {
          if (this.selectedDate.label == "Last 30 days") {
            notifications = this.allNotifications.filter((notification) => {
              let date = new Date(notification.time).toDateString();
              return date >= this.selectedDate.to.toDateString();
            });
          } else {
            notifications = this.allNotifications.filter((notification) => {
              let date = new Date(notification.time).toDateString();

              return (
                this.selectedDate.from.toDateString() >= date &&
                date <= this.selectedDate.to.toDateString()
              );
            });
          }

          if (this.searchText) {
            notifications = notifications.filter((notification) =>
              notification.content
                .toLowerCase()
                .match(this.searchText.toLowerCase())
            );
          }
        }
      }

      return notifications;
    },
  },

  data() {
    return {
      searchText: "",
      selectedDate: null,
    };
  },

  methods: {
    removeSearchText: function () {
      this.searchText = "";
    },
  },
};
</script>

<style scoped>
.relsify-notification-items-div h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  line-height: 6px;
  text-align: left;
  letter-spacing: -0.24px;
  color: #18856f;
  margin-left: 10px;
  margin-bottom: 10px;
}
</style>
