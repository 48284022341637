<template>
  <div class="mb-3 mt-3">
    <div class="relsify-search-form">
      <div class="relsify-table-search grid-search">
        <div class="relsify-table-search-item">
          <label for="">Filter By:</label>
          <div class="select_">
            <select v-if="activityFilters" v-model="selectedDate">
              <option :value="null">All</option>
              <option
                v-for="(date, index) in activityFilters"
                :key="index"
                :value="date"
              >
                {{ date.label }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="relsify-table tr-shadow">
    <div class="table-responsive table-web">
      <table class="table">
        <thead class="text-left">
          <tr class="">
            <th>Date</th>
            <th>Token</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Network</th>
            <th>Hash</th>
            <th>Address</th>
            <th></th>
          </tr>
        </thead>

        <tbody class="text-left" v-if="histories && histories.length > 0">
          <TransactionHistoryTableRow
            v-for="(transaction, index) in histories"
            :key="index"
            :transaction="transaction"
          />
        </tbody>
        <tbody
          v-if="depositTransactionHistory && histories && histories.length <= 0"
        >
          <tr>
            <td colspan="8">
              <div class="text-center">
                <h6>No deposit history</h6>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-if="depositTransactionHistory == null">
          <tr>
            <td colspan="8">
              <div class="d-flex justify-content-center align-items-center">
                <br />
                <LoadingComponent />
                <br />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <TransactionHistoryMobile
      :transactionHistory="depositTransactionHistory"
      :histories="histories"
    />
    <div v-if="histories && histories.length <= 0">
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapGetters } from "vuex";
import TransactionHistoryTableRow from "../TransactionHistoryTableRow.vue";
import TransactionHistoryMobile from "../TransactionHistoryMobile.vue";
import LoadingComponent from "../../loading/LoadingComponent.vue";
export default {
  components: {
    TransactionHistoryTableRow,
    TransactionHistoryMobile,
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["depositTransactionHistory", "activityFilters"]),
    histories() {
      let histories = [];

      if (
        this.depositTransactionHistory &&
        this.depositTransactionHistory.length > 0
      ) {
        if (this.selectedDate == null) {
          histories = this.depositTransactionHistory;
        } else {
          // console.log(this.selectedDate);
          if (this.selectedDate.label == "Last 30 days") {
            histories = this.depositTransactionHistory.filter((history) => {
              let date = new Date(history.time).toDateString();
              // console.log(this.selectedDate.from.toDateString());
              // console.log(date);
              // console.log(this.selectedDate.to.toDateString());
              return date >= this.selectedDate.to.toDateString();
            });
          } else {
            histories = this.depositTransactionHistory.filter((history) => {
              let date = new Date(history.time).toDateString();
              // console.log(this.selectedDate.from.toDateString());
              // console.log(date);
              // console.log(this.selectedDate.to.toDateString());
              return (
                this.selectedDate.from.toDateString() >= date &&
                date <= this.selectedDate.to.toDateString()
              );
            });
          }
        }
      }

      return histories;
    },
  },
  data() {
    return {
      searchText: "",
      selectedDate: null,
    };
  },
};
</script>

<style></style>
