<template>
  <div class="container-fluid" style="position: relative">
    <div class="row">
      <div class="col-xl-12">
        <div class="page-header">
          <div class="page-title">
            <i
              class="bx bx-left-arrow-alt"
              onclick="window.history.back();"
            ></i>
            <h2>Project details</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="after-page-header">
      <ProjectDetails />
    </div>
  </div>
</template>

<script>
import "@/assets/css/dashboard.css";
import "@/mixins";
import { mapActions } from "vuex";
import ProjectDetails from "../components/projects/ProjectDetails.vue";
export default {
  components: {
    ProjectDetails,
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },
  mounted() {
    this.closeMarketPlace();
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
    // this.fillData();
  },
};
</script>

<style scoped>
@media screen and (max-width: 580px) {
  .after-page-header {
    margin-top: 90px;
  }

  .container-fluid {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>
