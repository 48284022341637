<template>
  <form v-on:submit.prevent="updateProfile" v-if="user">
    <div class="profile-settings-user-form">
      <!-- <div class="profile-settings-user-form-grid">

                                        </div> -->
      <div class="profile-settings-user-form-item">
        <label for="">First Name<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <input
            class="relsify-form-control-input"
            placeholder="e.g John"
            type="text"
            @input="validateForm"
            v-model="firstName"
          />
        </div>
      </div>
      <div class="profile-settings-user-form-item">
        <label for="">Last Name<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <input
            class="relsify-form-control-input"
            placeholder="e.g Doe"
            type="text"
            @input="validateForm"
            v-model="lastName"
          />
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="currentPageName !== 'SiteVerificationLevel2'"
      >
        <label for="">Middle Name<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <input
            class="relsify-form-control-input"
            placeholder="e.g middle name"
            type="text"
            @input="validateForm"
            v-model="middleName"
          />
        </div>
      </div>
      <!-- <div class="profile-settings-user-form-item">
          <label for="">Phone Number</label>
          <div class="profile-settings-div-input disabledInput">
            <img src="@/assets/vectors/img/nigeria-flag.png" alt="" />
            <input
              class="relsify-form-control-input"
              placeholder="+2348066345678"
              type="text"
              disabled
            />
          </div>
        </div> -->

      <!-- <div class="profile-settings-user-form-item">
        <label for="">Marital Status<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <select
            class="relsify-form-control-input"
            v-model="maritalStatus"
            @change="validateForm"
          >
            <option value="" disabled>Select marital status</option>
            <option value="married">Married</option>
            <option value="single">Single</option>
          </select>
        </div>
      </div> -->
      <div class="profile-settings-user-form-item">
        <label for="">Gender<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <select
            class="relsify-form-control-input"
            v-model="gender"
            @change="validateForm"
          >
            <option value="" disabled>select your gender</option>
            <option value="male">Male</option>
            <option value="female">female</option>
          </select>
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="currentPageName == 'SiteVerificationLevel2'"
      >
        <label for="">Date of birth<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <input
            class="relsify-form-control-input"
            type="date"
            @input="validateForm"
            v-model="dateOfBirth"
          />
        </div>
      </div>
      <div class="profile-settings-user-form-item">
        <label for="">Country<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <!-- <img src="@/assets/vectors/img/nigeria-flag.png" alt="" /> -->
          <img :src="countryFlag" alt="" />
          <select v-model="country" @change="setCountry">
            <option value="" disabled>Select Country</option>
            <option
              :value="country.name"
              v-for="(country, index) in countries"
              :key="index"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="
          country.toLowerCase() !== 'nigeria' &&
          currentPageName !== 'SiteVerificationLevel2'
        "
      >
        <label for="">State<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <input
            v-model="state"
            @input="validateForm"
            class="relsify-form-control-input"
            placeholder="your state"
            type="text"
          />
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="
          country.toLowerCase() == 'nigeria' &&
          currentPageName !== 'SiteVerificationLevel2'
        "
      >
        <label for="">State<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <select v-model="state" @change="validateForm">
            <option value="" disabled>Select State</option>
            <option
              :value="state.name"
              v-for="(state, index) in states"
              :key="index"
            >
              {{ state.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="
          country.toLowerCase() !== 'nigeria' &&
          currentPageName == 'SiteVerificationLevel2'
        "
      >
        <label for="">City<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <input
            v-model="city"
            @input="validateForm"
            class="relsify-form-control-input"
            placeholder="your city"
            type="text"
          />
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="
          country.toLowerCase() == 'nigeria' &&
          currentPageName == 'SiteVerificationLevel2'
        "
      >
        <label for="">city<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <select v-model="city" @change="validateForm">
            <option value="" disabled>Select city</option>
            <option
              :value="citi.name"
              v-for="(citi, index) in states"
              :key="index"
            >
              {{ citi.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="profile-settings-user-form-item"
        v-if="currentPageName !== 'SiteVerificationLevel2'"
      >
        <label for="">Language<span class="text-danger">*</span></label>
        <div class="profile-settings-div-input">
          <select
            class="relsify-form-control-input"
            v-model="language"
            @change="validateForm"
          >
            <option value="" disabled>select your language</option>
            <option value="english">English</option>
            <option value="french">French</option>
            <option value="spanish">Spanish</option>
          </select>
        </div>
      </div>
    </div>

    <!-- <div
      class="profile-settings-user-form form_width_100"
      style="margin-top: 0px"
      v-if="user && currentPageName !== 'SiteVerificationLevel2'"
    >
      <div class="profile-settings-user-form-item">
        <label for="">Address<span class="text-danger">*</span></label>
        <textarea
          class="relsify-form-control-input"
          cols="30"
          @input="validateForm"
          v-model="address"
          rows="3"
        ></textarea>
      </div>
    </div> -->

    <transition name="fade">
      <p
        v-if="formError"
        style="
          color: red !important;
          font-size: 13px !important;
          margin-top: 4px;
          margin-bottom: 4px;
          text-align: center;
        "
      >
        <svg
          v-if="formMessage"
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 20 20"
          svg-inline=""
          role="presentation"
          focusable="false"
          tabindex="-1"
          style="color: red !important"
          fill="red"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
          ></path>
        </svg>
        {{ formMessage }}
      </p>
    </transition>
    <div
      class="text-center mt-4"
      v-if="currentPageName !== 'SiteVerificationLevel2'"
    >
      <button class="relsify-button" :disabled="emptyFields">
        <span v-if="!loading">Submit</span>
        <span class="loader" v-if="loading"></span>
      </button>
    </div>
    <div
      class="siteVerificationButtons"
      v-if="currentPageName == 'SiteVerificationLevel2'"
    >
      <button
        type="button"
        class="relsify-button backbutton"
        @click="goBackToPrevious"
      >
        <i v-if="!loading2" class="bx bx-chevron-left"></i>
        <span>
          <span v-if="!loading2">Back</span>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            v-if="loading2"
            aria-hidden="true"
          ></span>
        </span>
      </button>
      <button
        type="submit"
        :disabled="emptyFields"
        class="relsify-button continueButton"
      >
        <span>
          <span v-if="!loading">Save & Continue</span>
          <span class="loader" v-if="loading"></span>
        </span>
        <i class="bx bx-chevron-right"></i>
      </button>
    </div>
  </form>
</template>

<script>
import "@/mixins";
import { mapActions } from "vuex";
import $ from "jquery";
export default {
  components: {},
  computed: {
    countries() {
      return this.$store.state.countries;
    },

    states() {
      return this.$store.state.states;
    },

    firstName: {
      get() {
        return this.user.firstName;
      },
      set(value) {
        return (this.user.firstName = value);
      },
    },

    lastName: {
      get() {
        return this.user.lastName;
      },
      set(value) {
        return (this.user.lastName = value);
      },
    },
    middleName: {
      get() {
        return this.user.middleName;
      },
      set(value) {
        return (this.user.middleName = value);
      },
    },
    address: {
      get() {
        return this.user.address;
      },
      set(value) {
        return (this.user.address = value);
      },
    },
    country: {
      get() {
        return this.user.country;
      },
      set(value) {
        return (this.user.country = value);
      },
    },
    state: {
      get() {
        return this.user.state
          ? this.user.state
          : this.states && this.states[0].name.toLowerCase();
      },
      set(value) {
        return (this.user.state = value);
      },
    },
    city: {
      get() {
        return this.user.city
          ? this.user.city
          : this.states && this.states[0].name.toLowerCase();
      },
      set(value) {
        return (this.user.city = value);
      },
    },
    gender: {
      get() {
        return this.user.gender;
      },
      set(value) {
        return (this.user.gender = value);
      },
    },
  },
  data() {
    return {
      language: "",
      maritalStatus: "",
      countryFlag: "",
      emptyFields: true,
      loading: false,
      loading2: false,
      formError: false,
      formMessage: "",
      changesMade: false,
      dateOfBirth: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "globalFunction"]),
    goBackToPrevious: function () {
      this.loading2 = true;

      setTimeout(() => {
        this.loading2 = false;
        let attrib = $("[tab-id=siteVerificationPhotoID]");
        attrib.click();
      }, 1200);
    },

    goToNext: function () {
      let attrib = $("[tab-id=siteVerificationIdentityVerify]");
      attrib.click();
    },
    setCountry: function () {
      if (this.user) {
        this.state = "";
        this.city = "";
        setTimeout(() => {
          if (this.countries !== null) {
            let countryFlag = this.countries.find(
              (flag) => flag.name.toLowerCase() == this.country.toLowerCase()
            );
            if (countryFlag) {
              this.countryFlag = countryFlag.flag;

              if (this.country.toLowerCase() == "nigeria") {
                this.state = this.states[0].name;
                this.city = this.states[0].name;
              } else {
                this.state = "";
                this.city = "";
              }
              this.validateForm();
            }
          }
        }, 500);
        //
      }
    },

    validateForm: function () {
      this.changesMade = true;

      if (this.currentPageName == "SiteVerificationLevel2") {
        if (
          !this.firstName ||
          !this.lastName ||
          !this.country ||
          !this.city ||
          !this.gender ||
          !this.dateOfBirth
           || !this.maritalStatus
        ) {
          this.emptyFields = true;
          this.formError = true;
          this.formMessage = "Fill out all Fields";
          return false;
        }
        this.formError = false;
        this.formMessage = "";
        this.emptyFields = false;
        return true;
      } else {
        if (
          !this.firstName ||
          !this.lastName ||
          // !this.address ||
          !this.country ||
          !this.language ||
          !this.middleName ||
          !this.state ||
          !this.gender 
          // || !this.maritalStatus
        ) {
          this.emptyFields = true;
          this.formError = true;
          this.formMessage = "Fill out all Fields";
          return false;
        }
        this.formError = false;
        this.formMessage = "";
        this.emptyFields = false;
        return true;
      }
    },

    updateProfile: function () {
      if (!this.changesMade) {
        if (this.currentPageName == "SiteVerificationLevel2") {
          this.goToNext();
        }

        return;
      }
      this.loading = true;
      this.formError = false;

      let url;

      let payload;

      if (this.currentPageName == "SiteVerificationLevel2") {
        url = `${this.RELSIFY_URL}/api/verification/level-two`;
        payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          country: this.country,
          city: this.state,
          gender: this.gender,

          maritalStatus: this.maritalStatus,
          dateOfBirth: this.dateOfBirth,
        };
      } else {
        url = `${this.RELSIFY_URL}/api/profile-update`;
        payload = {
          firstName: this.firstName,
          lastName: this.lastName,
          middleName: this.middleName,
          country: this.country,
          state: this.state,
          address: this.address,
          gender: this.gender,
          language: this.language,
        };
      }
      // const token = sessionStorage.getItem("Appoen");

      const requestOptions = {
        method:
          this.currentPageName == "SiteVerificationLevel2" ? "PUT" : "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: this.token,
        },
        body: JSON.stringify(payload),
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          this.loading = false;
          if (!data.error) {
            // console.log(data);
            this.globalFunction();
            this.setAlertpopUp({
              status: true,
              title: " Update Status",
              des: data.meta.message,
              type: "success",
              payload: null,
            });

            if (this.currentPageName == "SiteVerificationLevel2") {
              this.goToNext();
            }
            this.changesMade = false;
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Update Status",
              des: data.meta.message,
              type: "error",
              payload: null,
            });
            // console.log(data);
          }
        })
        .catch(() => {
          this.loading = false;
          this.setAlertpopUp({
            status: true,
            title: "Update Status",
            des: "An Error occured while updating profile details",
            type: "error",
            payload: null,
          });
          // console.log(error);
        });
    },
  },
  mounted() {
    // this.setCountry();
  },
};
</script>

<style scoped>
.settings-profile-settings button.relsify-button {
  padding: 10px 50px;
  font-weight: bold;
  font-size: 14px;
}

[class*="loader"]:before {
  content: "Please wait...";
}
</style>
