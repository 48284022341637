<template>
  <div>
    <div
      class="right-notification-fixed"
      :class="
        sideNotificationModal && sideNotificationModal && 'show-notification'
      "
    >
      <div class="right-notification-fixed-header">
        <h4>Notifications</h4>
        <i
          class="bx bx-x"
          id="close-notification"
          @click="closeSideNotificationModal"
        ></i>
      </div>
      <!-- {{ newNotifications }} -->
      <div
        class="right-notification-fixed-body"
        v-if="
          sideNotificationModal &&
          newNotifications &&
          newNotifications.length > 0
        "
      >
        <div class="right-notification-fixed-body-scroll">
          <ul
            class="relsify-notification-items-div pl-0 pt-0"
            style="padding-top: 0px !important; margin-bottom: 0px; gap: 5px"
          >
            <div
              class="relsify-notification-item"
              v-for="(notification, index) in newNotifications"
              :key="index"
              style="
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 10px;
                justify-content: flex-start;
                align-items: flex-start;
                cursor: pointer;
              "
              @click="goToDetails(notification)"
            >
              <img
                src="@/assets/vectors/img/gift.png"
                style="width: 22px; height: 22px; margin-right: 20px"
                alt=""
              />
              <p style="font-size: 14.5px; margin-bottom: 5px">
                {{ notification.content.slice(0, 41) }}...
              </p>
            </div>
          </ul>
        </div>
      </div>
      <div
        class="right-notification-fixed-body"
        v-if="allNotifications && newNotifications.length <= 0"
      >
        <div class="right-notification-fixed-body-scroll">
          <div class="right-notification-fixed-body-empty">
            <img src="@/assets/vectors/img/notification-bell-big.png" alt="" />
            <p>No Notifications Yet</p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="right-notification-fixed-wrapper"
      @click="closeSideNotificationModal"
      :class="sideNotificationModal && 'show-notification-wrapper'"
    ></div>
  </div>
</template>

<script>
import "@/mixins";
export default {
  computed: {
    sideNotificationModal() {
      return this.$store.state.sideNotificationModal;
    },

    allNotifications() {
      return this.$store.state.allNotifications;
    },

    newNotifications() {
      let notifications = this.$store.state.allNotifications;

      if (this.notifications && this.notifications.length > 0) {
        notifications = this.notifications.filter(
          (notification) => notification.isRead == false
        );
      }
      return notifications;
    },
  },

  methods: {
    goToDetails: function (notification) {
      this.closeSideNotificationModal();
      // console.log(notification);
      this.$router.push({
        name: "NotificationDetails",
        query: { id: notification.id },
      });
    },
  },
};
</script>

<style scoped>
.relsify-notification-item:hover {
  background: #f1f1f1;
}
</style>
