<template>
  <div class="row">
    <div class="col-md-12 mb-3">
      <div class="transactionaass" v-if="transaction">
        <div class="transaction-transactionList">
          <div class="transaction-transactionListItem">
            <p>Date:</p>
            <p>
              {{ formateDateAndTime(transaction.time) }}
            </p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Token Name:</p>
            <p>{{ transaction.coin.name }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Amount:</p>
            <p>{{ formatAmount(transaction.amount) }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Status:</p>
            <p>{{ transaction.status }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Network:</p>
            <p>{{ transaction.networkName }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Hash:</p>
            <p>{{ transaction.hash }}</p>
          </div>
          <div class="transaction-transactionListItem">
            <p>Address:</p>
            <p>{{ transaction.address }}</p>
          </div>
        </div>
      </div>

      <div class="relsify-notification-items-div" v-if="transaction == null">
        <div
          class="d-flex justify-content-center align-items-center"
          style="
            background: #ffffff;
            padding: 10px 40px 20px;
            border-radius: 4.80178px;
          "
        >
          <br />
          <LoadingComponent />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/mixins";
import { mapActions, mapGetters } from "vuex";
import LoadingComponent from "../loading/LoadingComponent.vue";
export default {
  components: {
    LoadingComponent,
  },
  computed: {
    ...mapGetters(["allNotifications"]),
  },

  data() {
    return {
      searchText: "",
      transaction: null,
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "globalFunction"]),
    getSingleTransaction: function () {
      let url = `${this.RELSIFY_URL}/api/transaction-history/${this.$route.query.id}`;

      this.axios
        .get(url)
        .then((response) => {
          this.globalFunction();
          if (!response.data.error) {
            this.globalFunction();
            this.transaction = response.data.data;
          } else {
            this.setAlertpopUp({
              status: true,
              title: "Error",
              des: response.data.meta.message,
              type: "error",
              payload: null,
            });

            setTimeout(() => {
              this.$router.push({
                name: "Home",
              });
            }, 2000);
          }
        })
        .catch(() => {
          this.setAlertpopUp({
            status: true,
            title: "Error",
            des: "An Error occured",
            type: "error",
            payload: null,
          });
          // console.log(error);
          setTimeout(() => {
            this.$router.push({
              name: "Home",
            });
          }, 2000);
        });
    },
  },

  mounted() {
    if (!this.$route.query.id) {
      window.history.back();

      return;
    }

    this.getSingleTransaction();
  },
};
</script>

<style scoped>
.transactionaass {
  background: #fff;
  padding: 70px 50px;
  border-radius: 10px;
  margin-top: 60px;
}
.transaction-transactionList {
  width: 60%;
}

.transaction-transactionListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 18px; */
}

.transaction-transactionListItem p {
  text-align: left;
  font-size: 16px;
  color: rgb(136, 132, 132);
}

.transaction-transactionListItem p:last-child {
  text-align: right;
  color: #111;
  font-weight: bold;
  display: block;
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
}

@media screen and (max-width: 680px) {
  .transactionaass {
    padding: 40px 10px;
    margin-top: 40px;
  }
  .transaction-transactionList {
    width: 100%;
  }

  .transaction-transactionListItem {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .transaction-transactionListItem p {
    font-size: 15px;
  }

  .transaction-transactionListItem p:last-child {
    font-size: 13.5px;
    padding-right: 10px;
    overflow-wrap: break-word;
    width: 100%;
    text-align: left;
  }
}
</style>
