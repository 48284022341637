<template>
  <form class="forms_div" autocomplete="off" v-on:submit.prevent="register">
    <transition name="fade">
      <p
        v-if="formError"
        style="
          color: red !important;
          font-size: 13px !important;
          margin-top: 28px;
          margin-bottom: 20px;
          text-align: center !important;
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="10"
          viewBox="0 0 20 20"
          svg-inline=""
          role="presentation"
          focusable="false"
          tabindex="-1"
          style="color: red !important"
          fill="red"
        >
          <path
            d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 11c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1 4H9v-2h2v2z"
          ></path>
        </svg>
        {{ formMessage }}
      </p>
    </transition>
    <div class="form_groups_grid">
      <div
        class="forms_div_tabs active"
        id="registerWithEmail"
        :class="registrationType"
      >
        <div class="form_groups_grid_item">
          <i class="bx bxs-user"></i>
          <input
            type="text"
            v-model="email"
            @input="validateForm"
            placeholder="Email"
          />
        </div>
      </div>
      <div
        class="forms_div_tabs"
        id="registerWithPhone"
        :class="registrationType"
      >
        <!-- <label
          for=""
          style="
            text-align: left !important;
            width: 100%;
            font-size: 12.5px;
            margin-top: 10px;
          "
          >Phone Number must start with +(CountryCode)</label
        > -->
        <div class="form_grid_double">
          <div class="form_groups_grid_item mb-3 mt-3 phone_register">
            <!-- <img :src="countryFlag" v-if="countryFlag" alt="" />
         
            <select v-model="country" @change="setCountry">
              <option value="" disabled>Select Country code</option>
              <option
                :value="country.isoCode"
                v-for="(country, index) in countries"
                :key="index"
              >
                {{ country.name }}
              </option>
            </select> -->
            <div
              class="select-input select-input-input"
              style="cursor: pointer !important"
              @click="showCountries"
            >
              <div style="cursor: pointer !important; display: flex">
                <img
                  style="cursor: pointer !important"
                  :src="selectedCountry.flag"
                  v-if="selectedCountry.flag"
                  alt=""
                />
                <input
                  type="text"
                  style="
                    cursor: pointer !important;
                    text-align: left !important;
                    padding-left: 0px !important;
                    margin-left: 3px !important;
                    padding-right: 5px !important;
                  "
                  disabled
                  :value="selectedCountry.isoCode"
                />
              </div>
              <i class="bx bx-chevron-down" id="selectToken"></i>
            </div>
          </div>

          <div class="form_groups_grid_item">
            <span
              ><b>{{ selectedCountry.dialCode }}</b></span
            >
            <input
              type="text"
              v-model="phoneNumber"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
              @input="validateForm"
              autocomplete="off"
              placeholder="phone number"
            />
          </div>
        </div>
      </div>
      <div class="form_groups_grid_item">
        <i class="bx bxs-lock"></i>
        <input
          :type="showPassword ? 'text' : 'password'"
          @input="validateForm"
          autocomplete="off"
          placeholder="Password"
          v-model="password"
        />
        <i
          class="bx bx-show-alt"
          v-if="!showPassword"
          style="cursor: pointer"
          @click="togglePasword"
        ></i>
        <i
          class="bx bx-hide"
          v-if="showPassword"
          style="cursor: pointer"
          @click="togglePasword"
        ></i>
      </div>

      <div class="accept_terms">
        <input type="checkbox" @change="validateForm" v-model="acceptTerms" />
        <p>
          I certify that I have read, understand and accept Finnacle’s
          <a href="" class="green_color">Terms & Conditions</a>
        </p>
      </div>

      <button :disabled="emptyFields">
        <span v-if="!loading">SIGN ME UP</span>
        <span class="loader" v-if="loading"></span>
      </button>

      <div class="dont_have_account">
        <span
          >Already have an account?
          <router-link to="/login" class="green_color">Login</router-link>
        </span>
        <router-link :to="{ name: 'ForgotPassword' }" class="green_color"
          >Forgot Password</router-link
        >
      </div>
      <br />
    </div>
  </form>
</template>

<script>
import "@/assets/css/oauth.css";
import "@/mixins";
import { mapActions } from "vuex";

// import { ref, computed } from "vue";
// import { useStore } from "vuex";
// import { useGetters, useMutations, useState } from "@/helpers/helpers";
export default {
  computed: {
    countries() {
      return this.$store.state.countries;
    },
    countryFlag: {
      get() {
        return this.$store.state.countryFlag;
      },
      set(value) {
        return (this.$store.state.countryFlag = value);
      },
    },
    registrationType: {
      get() {
        return this.$store.state.registrationType;
      },
      set(value) {
        return (this.$store.state.registrationType = value);
      },
    },
    countryDialCode: {
      get() {
        return this.$store.state.countryDialCode;
      },
      set(value) {
        return (this.$store.state.countryDialCode = value);
      },
    },

    selectedCountry: {
      get() {
        return this.$store.state.selectedCountry;
      },
      set(value) {
        return (this.$store.state.selectedCountry = value);
      },
    },
  },

  data() {
    return {
      email: "",
      password: "",
      phoneNumber: "",
      country: "NG",
      showPassword: false,
      acceptTerms: false,
      emptyFields: true,
      loading: false,
      formError: false,
      formMessage: "",
    };
  },

  methods: {
    ...mapActions(["setAlertpopUp", "setSelectCountryModal"]),
    showCountries: function () {
      this.setSelectCountryModal({
        status: true,
        title: "",
        type: "",
        payload: null,
      });
    },

    togglePasword: function () {
      this.showPassword = !this.showPassword;
      this.validateForm();
    },

    setCountry: function () {
      this.$store.commit("SET_COUNTRY_FLAG", this.country);
      this.phoneNumber = "";
      this.validateForm();
    },

    validateForm: function () {
      if (this.registrationType == "withEmail") {
        if (
          !this.email ||
          !this.validEmail(this.email) ||
          !this.password ||
          !this.acceptTerms
        ) {
          this.emptyFields = true;
          this.formError = true;
          this.formMessage = "Fill out all Fields";
          return false;
        }

        this.emptyFields = false;
        return true;
      }
      if (this.registrationType == "withPhone") {
        if (!this.phoneNumber || !this.password || !this.acceptTerms) {
          this.emptyFields = true;
          this.formError = true;
          this.formMessage = "Fill out all Fields";
          return false;
        }

        this.emptyFields = false;
        return true;
      }
      if (this.registrationType == "withBoth") {
        if (
          !this.email ||
          !this.validEmail(this.email) ||
          !this.phoneNumber ||
          !this.password ||
          !this.acceptTerms
        ) {
          this.emptyFields = true;
          this.formError = true;
          this.formMessage = "Fill out all Fields";
          return false;
        }

        this.emptyFields = false;
        this.formError = false;
        this.formMessage = "";
        return true;
      }
    },

    register: async function () {
      this.loading = true;
      this.formError = false;
      this.emptyFields = true;

      let url = `${this.RELSIFY_URL}/api/register`;
      let payload;

      if (this.registrationType == "withEmail") {
        payload = {
          email: this.email,
          password: this.password,
        };
      }

      if (this.registrationType == "withPhone") {
        payload = {
          phoneNumber: this.countryDialCode + this.phoneNumber,
          password: this.password,
        };
      }

      if (this.registrationType == "withBoth") {
        payload = {
          email: this.email,
          phoneNumber: this.countryDialCode + this.phoneNumber,
          password: this.password,
        };
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      };
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      try {
        this.validateForm();
        this.loading = false;
        if (!data.error) {
          // console.log(data);
          this.setAlertpopUp({
            status: true,
            title: "Registration Status",
            des: data.meta.message,
            type: "success",
            payload: null,
          });

          this.$store.state.registrationType = "withEmail";

          // localStorage.setItem("registrationData", JSON.stringify(data.data));

          setTimeout(() => {
            this.closeAlertPopup();
            this.$router.push("/login");
          }, 3300);
        } else {
          this.setAlertpopUp({
            status: true,
            title: "Registration Status",
            des: data.meta.message,
            type: "error",
            payload: null,
          });
          // console.log(data);
        }
      } catch (error) {
        // console.log(error);
      }

      // console.log(data);
    },
  },
  // setup() {
  //   const store = useStore();
  //   const countries = computed(() => store.state.countries);
  //   const countryFlag = computed(() => store.state.countryFlag);
  //   const registrationType = computed(() => store.state.registrationType);
  //   const countryDialCode = computed(() => store.state.countryDialCode);
  //   const country = ref("NG");
  //   const showPassword = ref(false);
  //   const acceptTerms = ref(false);
  //   const emptyFields = ref(true);
  //   const email = ref("");
  //   const phoneNumber = ref("");
  //   const password = ref("");

  //   const togglePasword = () => {
  //     showPassword.value = !showPassword.value;
  //     validateForm();
  //   };
  //   const setCountry = () => {
  //     store.commit("SET_COUNTRY_FLAG", country.value);
  //     phoneNumber.value = "";
  //     validateForm();

  //     console.log(countryDialCode);
  //   };

  //   const validateForm = () => {
  //     if (registrationType.value == "withEmail") {
  //       if (!email.value || !password.value || !acceptTerms.value) {
  //         emptyFields.value = true;

  //         console.log("here 1");
  //         console.log(email.value);
  //         console.log(password.value);
  //         console.log(acceptTerms.value);
  //         return false;
  //       }

  //       console.log("here 2");

  //       emptyFields.value = false;
  //       return true;
  //     }
  //     if (registrationType.value == "withPhone") {
  //       console.log("here 2");
  //       if (!phoneNumber.value || !password.value || !acceptTerms.value) {
  //         emptyFields.value = true;
  //         return false;
  //       }

  //       emptyFields.value = false;
  //       return true;
  //     }
  //   };

  //   let flag = null;

  //   return {
  //     setCountry,
  //     countries,
  //     country,
  //     flag,
  //     countryFlag,
  //     countryDialCode,
  //     showPassword,
  //     togglePasword,
  //     acceptTerms,
  //     emptyFields,
  //     registrationType,
  //     validateForm,
  //     password,
  //     phoneNumber,
  //     email,
  //   };
  // },
};
</script>

<style scoped>
[class*="loader"]:before {
  content: "Please wait...";
}
</style>
