<template>
  <transition name="fade">
    <div class="shareModal" v-if="shareModal.status">
      <div class="shareModal-overlay" @click="closeShareModal"></div>

      <div class="shareModal-card vivify popInBottom">
        <ModalLoading />
        <div class="shareModal-card-header">
          <h5>Share</h5>
          <div class="close-alert-button">
            <i class="bx bx-x" @click="closeShareModal"></i>
          </div>
        </div>

        <div class="shareModal-body" id="shareModal-body">
          <facebook :url="shareModal.title" scale="3"></facebook>
          <twitter
            :url="shareModal.title"
            :title="shareModal.title"
            scale="3"
          ></twitter>
          <linkedin :url="shareModal.title" scale="3"></linkedin>
          <telegram :url="shareModal.title" scale="3"></telegram>
          <whats-app
            :url="shareModal.title"
            :title="shareModal.title"
            scale="3"
          ></whats-app>
          <pinterest :url="shareModal.title" scale="3"></pinterest>
          <reddit
            :url="shareModal.title"
            scale="3"
            :title="shareModal.title"
          ></reddit>
          <google :url="shareModal.title" scale="3"></google>
          <email
            :url="shareModal.title"
            :subject="shareModal.title"
            scale="3"
          ></email>
          <!-- <div class="shareModal-body_icons">
            <ShareNetwork
              network="facebook"
              :url="shareModal.url"
              :title="shareModal.title"
              :description="shareModal.desc"
            >
              <img
                alt="Facebook icon"
                :src="facebookImage"
                style="height: 48px; width: 48px"
              />
            </ShareNetwork>
            <ShareNetwork
              network="whatsapp"
              :url="shareModal.url"
              :title="shareModal.title"
              :description="shareModal.desc"
            >
              <img
                :src="whatsappImage"
                alt="WhatsApp icon"
                loading="lazy"
                style="height: 48px; width: 48px"
              />
            </ShareNetwork>
            <ShareNetwork
              network="twitter"
              :url="shareModal.url"
              :title="shareModal.title"
            >
              <img
                alt="Twitter icon"
                :src="twitterImage"
                style="height: 48px; width: 48px"
              />
            </ShareNetwork>

            <ShareNetwork
              network="linkedIn"
              :url="shareModal.url"
              :title="shareModal.title"
            >
              <img
                alt="LinkedIn icon"
                :src="linkedInImage"
                style="height: 48px; width: 48px"
              />
            </ShareNetwork>
            <ShareNetwork
              network="skype"
              :url="shareModal.url"
              :title="shareModal.title"
              :description="shareModal.desc"
            >
              <img
                alt="Skype icon"
                :src="skypeImage"
                style="height: 48px; width: 48px"
              />
            </ShareNetwork>
           
            <ShareNetwork
              network="email"
              :url="shareModal.url"
              :title="shareModal.title"
              :description="shareModal.desc"
            >
              <img
                alt="Mail icon"
                src="https://img.icons8.com/fluency/2x/new-post.png"
                style="height: 48px; width: 48px"
              />
            </ShareNetwork>

            <a href="javascript:;" @click="copyLink(shareModal.url)">
              <i class="bx bx-copy-alt" style="font-size: 40px"></i>
            </a>
          </div> -->

          <div class="copied_code vivify popInBottom" v-if="copied">
            <p>Link copied <i class="bx bx-check"></i></p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import "@/mixins";
import ModalLoading from "./ModalLoading.vue";
import { mapGetters, mapActions } from "vuex";

import { Facebook } from "vue-socialmedia-share";
import { Twitter } from "vue-socialmedia-share";
import { Linkedin } from "vue-socialmedia-share";
import { Telegram } from "vue-socialmedia-share";
import { WhatsApp } from "vue-socialmedia-share";
import { Pinterest } from "vue-socialmedia-share";
import { Reddit } from "vue-socialmedia-share";
import { Google } from "vue-socialmedia-share";
import { Email } from "vue-socialmedia-share";

export default {
  components: {
    ModalLoading,
    Facebook,
    Twitter,
    Linkedin,
    Telegram,
    WhatsApp,
    Pinterest,
    Reddit,
    Google,
    Email,
  },
  computed: {
    ...mapGetters(["shareModal", "paymentOptionTitle"]),
  },

  data() {
    return {
      copied: false,
    };
  },

  methods: {
    ...mapActions(["setShareModal"]),

    closeShareModal: function () {
      this.setShareModal({
        status: false,
        url: "",
        title: "",
        desc: "",
        payload: null,
      });
    },
    copyLink: function (link) {
      this.$store.state.modalLoading = true;

      setTimeout(() => {
        var input = document.createElement("input");
        input.setAttribute("value", link);
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand("copy");
        document.body.removeChild(input);
        this.$store.state.modalLoading = false;
        this.copied = true;

        return result;
      }, 1000);
      setTimeout(() => {
        this.copied = false;

        setTimeout(() => {
          this.closeShareModal();
        }, 200);
      }, 3700);
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* Alert Modal */
.shareModal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  /* background: rgba(58, 56, 56, 0.507); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9900 !important;
  transition: 800ms ease;
}

.shareModal.alertPOP {
  z-index: 9990 !important;
}

.shareModal-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(61, 61, 63, 0.877);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 9910 !important;
  transition: 800ms ease;
  cursor: pointer;
}

.shareModal.alertPOP .shareModal-overlay {
  z-index: 9991 !important;
}

.shareModal-card {
  position: relative !important;
  background: rgb(255, 255, 255);
  width: 300px;
  height: auto;
  display: block;
  border-radius: 30px;
  box-shadow: 3px 6px 10px 0 rgb(0 102 245 / 7%);
  z-index: 9940 !important;
  overflow: hidden;
}

.shareModal-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 19px;
}

.shareModal-card-header h5 {
  font-size: 17px;
}

.shareModal-card .close-alert-button {
  text-align: right;
}

.shareModal-card .close-alert-button i {
  color: #1e1d1d;
  font-size: 27px;
  cursor: pointer;
  padding: 2px;
}

.shareModal-body {
  width: 100%;
  position: relative;
  padding: 0px 19px 3px;
  background: #f4f4f4;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.shareModal-body-scroll {
  height: 100%;
  overflow-y: scroll;
}

.shareModal-body .shareModal-icon {
  background: var(--mainGreenColor);
  color: #fff;
  width: 70.22px;
  height: 70px;
  line-height: normal;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 30px;
}

.alertPOP .shareModal-body .shareModal-icon {
  width: 50px;
  height: 50px;
  line-height: normal;
}

.shareModal-body .shareModal-icon.error {
  background: #d70000;
}

.shareModal-body .shareModal-icon i {
  font-size: 62px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.alertPOP .shareModal-body .shareModal-icon i {
  font-size: 40px;
}

.shareModal .shareModal-body h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 27px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.382609px;
  color: var(--mainGreenColor);
  margin-bottom: 12px;
}

.shareModal.alertPOP .shareModal-body h4 {
  font-size: 19px;
}

.shareModal .shareModal-body p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.382609px;
  color: #5f5f5f;
  width: 79%;
  margin: 4px auto 15px;
}

.shareModal .shareModal-body .shareModal-button {
  margin-top: 15px;
  margin-bottom: 20px;
}

.shareModal .shareModal-body .shareModal-button button {
  padding: 10px 30px;
  font-weight: bold;
}

.shareModal .shareModal-body .shareModal-button button.green-button {
  padding: 10px 100px;
  font-size: 15px;
  font-weight: bold;
}

.shareModal .shareModal-body .shareModal-button button:hover {
  transform: scale(1.1);
}

.shareModal .shareModal-body .shareModal-button button.white-button {
  background: #fff;
  color: #1e1d1d;
  border: 1px solid var(--mainYellowColor);
  margin-right: 16px;
}

.shareModal-body_icons {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.shareModal-body_icons a {
  margin-right: 20px;
}

.copied_code {
  position: absolute;
  top: 30px;
  right: 80px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.copied_code p {
  text-align: center;
  background: rgb(24, 116, 255);
  padding: 4px 40px 4px 40px;
  color: #fff !important;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

@media screen and (max-width: 795px) {
  .shareModal-card {
    width: 95%;
    height: auto;
    /* padding: 15px; */
  }
}

@media screen and (max-width: 580px) {
  .shareModal-overlay {
    background: rgba(61, 61, 63, 0.877);
  }

  .shareModal-card {
    width: 95%;
    height: auto;
    /* padding: 15px; */
  }

  .alertPOP .shareModal-card {
    width: 95%;
    height: auto;
  }

  .shareModal-card .close-alert-button i {
    font-size: 25px;
  }

  .shareModal-body .shareModal-icon {
    width: 50.22px;
    height: 50px;
    line-height: 50px;
  }

  .shareModal-body .shareModal-icon i {
    font-size: 45px;
  }

  .shareModal .shareModal-body h4 {
    font-size: 23px;
  }

  .shareModal .shareModal-body p {
    font-size: 13px;
    width: 89%;
    margin: 4px auto 15px;
  }
}
</style>
