<template>
  <div class="text-center verified-div">
    <br /><br /><br />
    <div class="verified-icon">
      <i class="bx bx-check"></i>
    </div>
    <h4>{{ title }}</h4>
    <br /><br />
  </div>
</template>

<script>
export default {
  props: ["title"],
};
</script>

<style scoped>
.verified-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verified-icon {
  background: var(--mainGreenColor);
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: normal;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 30px;
}

.verified-icon.error {
  background: #d70000;
}

.verified-icon i {
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.verified-div h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.382609px;
  color: var(--mainGreenColor);
  margin-bottom: 12px;
}
</style>
